import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { renderRoutes, matchRoutes } from "react-router-config";
import Header from "./components/header";
import CustomModal from "./components/modal";
import Layout from "./components/layout";
import { getToken, checkAuth } from "./actions";
import { toastmessage } from "./utils";
import { getClubs } from "./actions/clubs";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    let { route } = this.props;

    if (this.props.token) {
      this.props.getClubs();
    }
    let currentPath = matchRoutes(route.routes, window.location.pathname);
    if (!this.props.token) {
      this.props.getToken();
    } else if (
      currentPath &&
      currentPath.length &&
      currentPath[0].match.path === "/login"
    ) {
      // this.props.checkAuth({token: this.props.token});
    }
  }

  isInviteLink(lobj = {}) {
    return lobj.route && lobj.route.path === "/invite-link/:token";
  }

  render() {
    let { route, authData = {}, isLogin } = this.props;
    let currentPath = matchRoutes(route.routes, window.location.pathname);
    let routeD = currentPath[0];

    const sideBarList = authData?.roleId?.accessAllowed?.filter(
      (menu) => menu.view
    );

    return (
      <React.Fragment>
        {/* {isLogin && !this.isInviteLink(currentPath[0]) && <Header />} */}
        {isLogin && (
          <React.Fragment>
            <Layout.Sidenav
              isOpen={this.state.sidenav}
              authData={authData}
              closeDrawer={this.closeDrawer}
              sideBarList={sideBarList}
              {...routeD}
            />
            <Layout.Header
              toggleDrawer={this.toggleDrawer}
              title={routeD.route.title}
            />
            <div id="content">
              <div className="wrapper h-100 w-100">
                {renderRoutes(route.routes, {
                  toastmessage: toastmessage,
                })}
              </div>
            </div>
            <Layout.Footer />
          </React.Fragment>
        )}
        {!isLogin &&
          renderRoutes(route.routes, {
            toastmessage: toastmessage,
          })}
        <CustomModal {...currentPath[0]} toastmessage={toastmessage} />
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { isLogin = false, token, authData = {} } }) {
  return { isLogin, token, authData };
}
export default connect(mapStateToProps, { getToken, checkAuth, getClubs })(
  withRouter(App)
);
