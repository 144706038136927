import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const usePopNotifications = () => {
  const [allModuleCodes, setAllModuleCodes] = useState([]);
  const [allScheduledNoti, setAllScheduledNoti] = useState([]);
  const [allExecutedNoti, setAllExecutedNoti] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getModCode = async () => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.PUSHNOTICODE}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setAllModuleCodes(response?.data?.codes)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getPopNotifications = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.POPNOTI}`);
      setIsLoading(false);
      if (response?.data?.status && response?.data?.status !== "false") {
        setAllScheduledNoti(response?.data?.scheduled);
        setAllExecutedNoti(response?.data?.executed);
      } else {
        setErrMsg(response?.data?.msg);
      }
    } catch (error) {
      console.log("error--", error);
      setIsLoading(false);
      setErrMsg("Something went wrong");
    }
  };

  const addPopNotification = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.POPNOTI}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updatePopNotification = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.POPNOTI}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const deletePopNotification = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(`${Endpoint.POPNOTI}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allModuleCodes,
    allScheduledNoti,
    allExecutedNoti,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getModCode,
    getPopNotifications,
    addPopNotification,
    updatePopNotification,
    deletePopNotification,
  };
};

export default usePopNotifications;
