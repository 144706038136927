import React, { Component } from "react";
import "../style/privacypolicy.scss";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <h3 className="main-h3"> TERMS & CONDITIONS</h3>

        <div>
          <h3>Interpretation</h3>
          <p>
            The definitions and rules of interpretation in these terms and
            conditions apply in the Program Subscription Agreement (“Contract”)
            as well.
          </p>
          <p>
            “Additional User” means a User above the number of Users specified
            in the applicable ordering document.
          </p>
          <p>“Agreement” means Program Subscription Agreemet.</p>
          <p>
            “Conditions”: means these terms and conditions for the supply of the
            Software and the Schedule which are available online in the Google
            Play store and Apple App store as THE THE QUORUM offered and
            developed by Incred Applications Pvt. Ltd. Powered by Incred
            Applications Pvt. Ltd. as amended from time to time in accordance
            with the Contract.
          </p>
          <p>“Program” as defined in the Program Subscription Agreement</p>
          <p>
            THE QUORUM – is an ONLINE APPLICATION for THE QUORUM club members
            and staff, Powered by Incred Applications Pvt. Ltd., a technology
            created, owned and Operated by INCRED APPLICATIONS PVT LTD.
          </p>
          <p>
            The App allows Club Members and Staff to connect with the club and
            the community as a whole, to receive notifications on THE QUORUM
            Events, make reservations in the club, view information on
            reciprocal clubs and make reservations there, edit member details,
            view videos and promotional material created by the club, etc..
          </p>
        </div>
        <div>
          <h3>App Related Terms</h3>
          <p>
            Depending on the version of the Application you have downloaded,
            these App Terms incorporate Apple’s or Google Android’s terms and
            conditions and privacy policies (“Platform Terms”). If there is any
            conflict between these App Terms and the Platform Terms then these
            App Terms will prevail.
          </p>
          <p>
            The Company may from time to time vary these App Terms. Please check
            these App Terms regularly to ensure you are aware of any variations
            made by us. If you continue to use this App, you are deemed to have
            accepted such variations. If you do not agree to such variations,
            you should not use the App.
          </p>
          <p>
            The User acknowledges that your agreement with your mobile network
            provider (“Mobile Provider”) will apply to your use of the App. You
            acknowledge that you may be charged by the Mobile Provider for data
            services while using certain features of the App or any such third
            party charges as may arise and you accept responsibility for such
            charges.
          </p>
          <p>
            You acknowledge that where you use services provided by Apple or
            Google (or any other third parties) in connection with your use of
            the App, you will be subject to Apple’s, Google’s (or the applicable
            third party’s) terms and conditions and privacy policy and you
            should ensure that you have read such terms.
          </p>
          <p>
            The Company provides the App on an ‘as is’ and ‘as available’ basis
            without any promises or representations, express or implied. In
            particular, the Company does not warrant or make any representation
            regarding the validity, accuracy, reliability or availability of the
            App or its content.
          </p>
          <p>
            The App is intended to provide general information only and, as
            such, should not be considered as a substitute for advice covering
            any specific situation. You should seek appropriate advice before
            taking or refraining from taking any action in reliance on any
            information contained in the App.
          </p>
        </div>

        <div>
          <h3>
            THE APP IS IN NO WAY TO BE CONSIDERED OR USED AS A REPLACEMENT FOR
            PROFESSIONAL ADVICE.
          </h3>
          <p>
            To the fullest extent permitted under applicable law, in no event
            shall the Company be liable to you with respect to use of the App
            and/or be liable to you for any direct, indirect, special or
            consequential damages including, without limitation, damages for
            loss of goodwill, lost profits, or loss, theft or corruption of your
            information, the inability to use the App, Device failure or
            malfunction.
          </p>
          <p>
            The Company shall not be liable even if it has been advised of the
            possibility of such damages, including without limitation damages
            caused by error, omission, interruption, defect, failure of
            performance, unauthorised use, delay in operation or transmission,
            line failure, computer virus or other harm.
          </p>
        </div>

        <div>
          <ol>
            <li>
              {" "}
              <h3>PROGRAM POLICIES AND AGREEMENT</h3>
              <p>
                Use of the Program Services is also governed by the following
                policies, which are incorporated herein by reference. By using
                the Services of the Program, you also agree to the terms of the
                following policies, in addition to other points of the Program
                Subscription Agreement mentioned here.
              </p>
              <ul>
                <li>Privacy Policy</li>
                <li>Copyright Infringement Policy</li>
              </ul>
            </li>
            <li>
              <h3>ACCOUNT ELIGIBILITY</h3>
              <ul>
                {" "}
                <li>
                  By registering for or using the Services, you represent and
                  warrant that: You are eighteen (18) years of age or older. If
                  you are younger than 18 years of age, you are required to
                  review this agreement with your parent(s) or guardian to
                  ensure they read and agree to them.
                </li>
                <li>
                  By creating an Account or otherwise using the Service, you
                  represent that you are at least 18 years of age and agree to
                  these Terms & Conditions, or that you are younger than 18
                  years of age and your parent(s) or legal guardian has read and
                  agreed to these Terms & Conditions, and you understand and
                  agree that we are relying upon that representation in allowing
                  you to use the Service. Only one person may use an Account.
                </li>
                <li>You will only have one THE QUORUM account.</li>
                <li>
                  If you use the Services on behalf of another party you agree
                  that you are authorized to bind such other party to this
                  Agreement and to act on such other party's behalf with respect
                  to any actions you take in connection with the Services.
                </li>
                <li>
                  It is your responsibility to provide accurate, current, and
                  complete information on the registration forms. If there is
                  ever an abuse issue or we need to contact you, we will use the
                  primary email address that you have provided to us. It is your
                  responsibility to ensure that the contact information for your
                  account, is accurate, correct and complete at all times. THE
                  QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.) is not
                  responsible for any lapse in the Services, as a result of,
                  including and not limited to, incomplete or inaccurate contact
                  and profile data provided by you. Providing false contact
                  information of any kind may result in the termination of your
                  account.
                </li>
                <li>
                  You agree to be fully responsible for all use of your account
                  and for any actions that take place through your account. It
                  is your responsibility to maintain the confidentiality of your
                  password and other information related to the security of your
                  account.
                </li>
                <li>
                  You will not share an account login with anyone else. You are
                  responsible for anything that happens through your account
                  unless you close it or report misuse.
                </li>
                <li>
                  The Service and any data you provide to THE QUORUM (Powered by
                  INCRED APPLICATIONS PVT. LTD.) is hosted in India unless
                  otherwise provided. If you access the Service from outside of
                  the India , you are voluntarily transferring information
                  (potentially including personally-identifiable information)
                  and content to the India and you agreeing that our collection,
                  use, storage and sharing of your information and content is
                  subject to the laws of the India , and not necessarily of the
                  jurisdiction in which you are located.
                </li>
                <li>
                  Registered users of our Services are “Members” and
                  unregistered users are “Visitors”. This Agreement applies to
                  both.
                </li>
              </ul>
            </li>
            <li>
              <h3>PAYMENT</h3>
              <p>
                If you purchase any of our paid Services or paid memberships,
                you agree to pay us the applicable fees and taxes. Failure to
                pay these fees may result in the termination of your
                subscription. Also:
              </p>
              <ul>
                <li>
                  Your purchase may be subject to foreign exchange fees or
                  differences in prices based on location (e.g. exchange rates).
                </li>
                <li>
                  You authorize us to store and continue billing your payment
                  method (e.g. credit card) even after it has expired, to avoid
                  interruptions in your service (e.g. subscriptions) and to
                  facilitate easy payment for new services.
                </li>
                <li>
                  You must pay us for applicable fees and taxes unless you
                  cancel the Premium Service, in which case you agree to still
                  pay these fees through the end of the applicable subscription
                  period.
                </li>
                <li>
                  Taxes are calculated based on the billing information that you
                  provide us at the time of purchase.
                </li>
              </ul>
            </li>
            <li>
              <h3>NOTICE AND SERVICE MESSAGES</h3>
              <p>
                You agree that we may provide notices to you in the following
                ways: (1) a banner notice on the Service, or (2) an email sent
                to an address you provided, or (3) through other means including
                mobile number, telephone, or mail. You agree to keep your
                contact information up to date. Our Services allow messaging and
                sharing of information in many ways, such as your profile,
                in-platform messaging service (private & public), links to news
                articles, job postings and blogs. Information and content that
                you share or post may be seen by other Members or, if public, by
                Visitors. Where we have made settings available, we will honour
                the choices you make about who can see content or information.
                We are not obligated to publish any information or content on
                our Service and can remove it in our sole discretion, with or
                without notice.
              </p>
            </li>
            <li>
              {" "}
              <h3> 5. RIGHTS AND LIMITS</h3>
              <p>
                YOUR LICENSE WITH THE QUORUM (Powered by INCRED APPLICATIONS
                PVT. LTD.) As between you and THE QUORUM (Powered by INCRED
                APPLICATIONS PVT. LTD.), you own the content and information
                that you submit or post to the Services and you are only
                granting THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.)
                the following non-exclusive license: A worldwide, transferable
                and sub-licensable right to use, copy, modify, distribute,
                publish, and process, information and content that you provide
                through our Services, without any further consent, notice and/or
                compensation to you or others. These rights are limited in the
                following ways:
              </p>
              <ol className="nested-ol">
                <li>
                  You can end this license for specific content by deleting such
                  content from the Services, or generally by closing your
                  account, except (a) to the extent you shared it with others as
                  part of the Service and they copied or stored it and (b) for
                  the reasonable time it takes to remove from backup and other
                  systems.
                </li>
                <li>
                  You agree that we can include your content in advertisements
                  for the products and services of THE QUORUM (Powered by INCRED
                  APPLICATIONS PVT. LTD.) to others without your separate
                  consent, albeit with your content being attributed to you
                  through your profile on THE QUORUM (Powered by INCRED
                  APPLICATIONS PVT. LTD.) THE QUORUM (Powered by INCRED
                  APPLICATIONS PVT. LTD.) will only use your content to promote
                  THE QUORUM and/or your THE QUORUM profile to others. We have
                  the right, without compensation to you or others, to serve ads
                  near your content and information.
                </li>
                <li>
                  We reserve the right to rate your profile within parameters
                  that we see fit and display the same publicly to other members
                  of THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.) or
                  outside visitors to our website and mobile apps (e.g. ratings
                  on Professionalism, Authenticity, etc.)
                </li>
                <li>
                  We reserve the rights to apply preferential treatment with
                  regard, but not limited to, to Profile visibility or job
                  recommendations and other such features provided by THE QUORUM
                  (Powered by INCRED APPLICATIONS PVT. LTD.). based on the
                  parameters of relevance that have been determined by an
                  algorithmic system used by THE QUORUM (Powered by INCRED
                  APPLICATIONS PVT. LTD.) The system evaluates your profile
                  ratings based on your type of THE QUORUM account and
                  membership as well as other rating criterion that we consider
                  professionally relevant. We reserve the right to change or
                  modify the evaluation system without prior notification to our
                  members or visitors.
                </li>
                <li>
                  We will get your consent if we want to give others the right
                  to publish your posts beyond the Service. However, other
                  Members and/or Visitors may access and share your content and
                  information, consistent with your settings and degree of
                  connection with them.
                </li>
                <li>
                  While we may edit and make formatting changes to your content
                  (such as translating it, modifying the size, layout or file
                  type or removing metadata), we will not modify the meaning of
                  your expression.
                </li>
                <li>
                  Because you own your content and information and we only have
                  non-exclusive rights to it, you may, however, choose to make
                  it available to others if the provision is included in our
                  services.
                </li>
                <li>
                  You agree that we may access, store and use any information
                  that you provide in accordance with the terms of the Privacy
                  Policy and your privacy settings.
                </li>
                <li>
                  You agree that THE QUORUM (Powered by INCRED APPLICATIONS PVT.
                  LTD.) can use and share (but does not have to) any feedback
                  from you for any purpose without compensation to you.
                </li>
                <li>
                  You agree to only provide content or information if that does
                  not violate the law nor anyone's rights (e.g., without
                  violating any intellectual property rights as mentioned in the
                  Copyright Infringement policy applicable here or breaching a
                  contract).
                </li>
              </ol>
            </li>
            <li>
              {" "}
              <h3>SERVICE AVAILABILITY</h3>
              <ol className="nested-ol">
                <li>
                  We may change, suspend or end any Service, or change and
                  modify prices prospectively at our discretion. To the extent
                  allowed under law, these changes may be effective upon notice
                  provided to you. THE QUORUM (Powered by INCRED APPLICATIONS
                  PVT. LTD.) is not a storage service. You agree that we have no
                  obligation to store, maintain or provide you a copy of any
                  content or information that you or others provide, except to
                  the extent required by applicable law and as noted in our
                  Privacy Policy.
                </li>
                <li>
                  <h4>The Program:</h4>
                  <ol className="third-level-ol">
                    <li>
                      The Company reserves the right to withdraw and/or modify
                      the Program where there are technical or legal reasons to
                      do so.
                    </li>
                    <li>
                      Usernames and passwords (a) The Company shall allocate the
                      User privileges to create user names and passwords for the
                      Users to allow access to the Program. All usernames and
                      passwords are personal to each User and the User ensures
                      these shall be kept confidential. (b) The Company may
                      withdraw any User privileges, username and password and
                      allocate a new username and password to any User where
                      there is reason to believe such privileges or login
                      details may be used in a manner not expressly authorised
                      by these Conditions or where it deems it necessary to do
                      so in its reasonable opinion. (c) The Company shall change
                      the username(s) and password(s) where the User requests
                      these to be changed and reserves the right to withdraw the
                      right to access the Program where in its opinion there are
                      reasonable grounds for believing the User has not complied
                      or is not complying with the Contract or if the Contract
                      or any part of it is terminated or suspended for any
                      reason.
                    </li>
                    <li>
                      The User shall use all reasonable endeavours to prevent
                      any unauthorised access to, or use of, the Program and/or
                      the Documentation and, in the event of any such
                      unauthorised access or use, promptly notify the Company.
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>Activities not permitted</h4>
                  <ol className="third-level-ol">
                    <li>
                      The User shall not access, store, distribute or transmit
                      any Viruses or any material during the course of its use
                      of the Program that is unlawful, harmful, threatening,
                      defamatory, obscene, infringing, harassing, discriminatory
                      or racially or ethnically offensive; (a) facilitates
                      illegal activity; or (b) is otherwise illegal or causes
                      damage or injury to any person or property and the Company
                      reserves the right, without liability or prejudice to its
                      other rights to the User, to disable the User’s access to
                      the Program or to any infringing material where the User
                      breaches this clause.
                    </li>
                    <li>
                      The User may not scrape data nor allow access anyone not a
                      member of the THE QUORUM and a User of THE QUORUM, to see,
                      read or access information that the User has gotten as a
                      result of his signed up privileges on the THE QUORUM
                      Platform.{" "}
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3>OTHER CONTENT, SITES AND APPS</h3>
              <p>
                By using the Services, you may encounter content or information
                that might be inaccurate, incomplete, delayed, misleading,
                illegal, offensive or otherwise harmful. THE QUORUM (Powered by
                INCRED APPLICATIONS PVT. LTD.) generally does not review content
                provided by our Members. You agree that we are not responsible
                for third parties' (including other Members') content or
                information or for any damages as result of your use of or
                reliance on it. You are responsible for deciding if you want to
                access or use third party apps or sites that link from our
                Services. Third party apps and sites have their own legal terms
                and privacy policies, and you may be giving others permission to
                use your information in ways we would not. Except to the limited
                extent it may be required by applicable THE THE QUORUM (Powered
                by INCRED APPLICATIONS PVT. LTD.) is not responsible for these
                other sites and apps. Use these at your own risk. Please refer
                to our Privacy Policy.
              </p>
            </li>
            <li>
              <h3>LIMITS</h3>
              <p>
                THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.). reserves
                the right to limit your use of the Services, including the
                number of your connections and your ability to contact other
                Members THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.)
                reserves the right to restrict, suspend, or terminate your
                account if THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.)
                believes that you may be in breach of this Agreement or law or
                are misusing the Services (e.g. violating any Do and Don'ts).
                Incred Applications Pvt Ltd reserves all of its intellectual
                property rights in the Services. For example, Company logos and
                other THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.)
                trademarks, service marks, graphics, and logos used in
                connection with THE QUORUM (Powered by INCRED APPLICATIONS PVT.
                LTD.) are trademarks or registered trademarks of Incred
                Applications Pvt. Ltd. Other trademarks and logos used in
                connection with the Services may be the trademarks of their
                respective owners.
              </p>
            </li>
            <li>
              <h3>DISCLAIMER AND LIMIT OF LIABILITY</h3>
              <p>
                The QUORUM User expressly agrees that use of the Apps is at the
                User's risk. Neither Incred Applications Pvt Ltd its affiliates
                nor any of their respective employees, agents, third party
                content providers or licensors warrant that the Site or Apps
                will be uninterrupted or error free. Nor do they make any
                warranty as to the results that may be obtained from use of the
                Site and Apps, or as to the accuracy, reliability or content of
                any information, service.
              </p>
              <p>
                This disclaimer of liability applies to any damages or injury,
                caused by any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communication line failure, theft
                or destruction or unauthorized access to, alteration of, or use
                of record, whether for breach of contract, negligence, or under
                any other cause of action. Subscriber specifically acknowledges
                that the Incred Applications Pvt Ltd (Company) is not liable for
                the defamatory, offensive or illegal conduct of other
                subscribers or third-parties in cases including but not limited
                to any interactive communication on or through the site and that
                the risk of injury from the foregoing rests entirely with the
                users.
              </p>
              <p>
                In no event will Incred Applications Pvt Ltd, or any person or
                entity involved in creating, producing or distributing the Site
                and Apps or its software, be liable for any damages, including,
                without limitation, direct, indirect, incidental, special,
                consequential or punitive damages arising out of the use of or
                inability to use. This section shall apply to all content. The
                Company shall have the right, but not the obligation, to monitor
                the content of the site, all interactive communication.
              </p>
              <p>
                Incred Applications Pvt Ltd shall have the right in its sole
                discretion to edit, refuse to post or remove any material
                submitted to or posted. Without limiting the foregoing, Incred
                Applications Pvt. Ltd. shall have the right to remove any
                material that Incred Applications Pvt. Ltd. in its sole
                discretion, finds to be in violation of the provisions hereof or
                otherwise objectionable.
              </p>

              <p>
                The QUORUM User agrees that in no event, will INCRED
                APPLICATIONS PVT LTD be responsible for any claims in relation
                to the products and services purchased from and supplied by
                third party vendors listed on THE QUORUM, and any such claim be
                made directly and only agaisnt the third party vendor from whom
                the QUORUM User avails the particular product or service.
              </p>
            </li>

            <li>
              <h3>TERMINATION</h3>
              <p>
                THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.) or you may
                terminate this Agreement at any time with notice to the other.
                On termination, you lose the right to access or use the
                Services. The following shall survive termination:
              </p>
              <ul>
                <li>Our rights to use and disclose your feedback</li>
                <li>
                  Members' and/or Visitors' rights to further re-share content
                  and information you shared through the Service to the extent
                  copied or re-shared prior to termination
                </li>
                <li>
                  The Disclaimer and Limit of Liability, Dispute Resolution &
                  General Terms Sections of this Agreement
                </li>
                <li>
                  Any amounts owed by either party prior to termination remain
                  owed after termination.
                </li>
              </ul>
            </li>

            <li>
              <h3>DISPUTE RESOLUTION</h3>
              <p>
                You agree that the laws of India, excluding its conflict of laws
                rules, shall exclusively govern any dispute relating to this
                Agreement and/or the Services. We both agree that all of these
                claims can only be litigated in the Central or State courts
                India, and we each agree to personal jurisdiction in those
                courts.
              </p>
            </li>
            <li>
              <h3>GENERAL TERMS</h3>
              <p>
                If a court with authority over this Agreement finds any part of
                it not enforceable, you and us agree that the court should
                modify the terms to make that part enforceable while still
                achieving its intent. If the court cannot do that, you and
                Incred Applications Pvt Ltd agree to ask the court to remove
                that unenforceable part and still enforce the rest of this
                Agreement. To the extent allowed by law, the English version of
                this Agreement is binding and other translations are for
                convenience only. This Agreement (including additional terms
                that may be provided by us when you engage with a feature of the
                Services) is the only agreement between us regarding the
                Services and supersedes all prior agreements for the Services.
              </p>
              <p>
                If we don't act to enforce a breach of this Agreement, that does
                not mean that Incred Applications Pvt Ltd has waived its right
                to enforce this Agreement. You may not assign or transfer this
                Agreement (or your membership or use of Services) to anyone
                without our consent. However, you agree that Incred Applications
                Pvt Ltd may assign this Agreement to its affiliates or a party
                that buys it without your consent. There are no third party
                beneficiaries to this Agreement.
              </p>
              <p>
                We reserve the right to change the terms of this Agreement and
                will provide you notice if we do and we agree that changes
                cannot be retroactive. If you don't agree to these changes, you
                must stop using the Services. You agree that the only way to
                provide us legal notice is at the addresses provided in Contact
                Us section of our Website.
              </p>
            </li>
            <li>
              <h3>TERMS OF BEHAVIOUR</h3>
              <ol className="tob-ol">
                <li>
                  {" "}
                  <h4>You agree that you will:</h4>
                  <p>
                    Comply with all applicable laws, including, without
                    limitation, privacy laws, intellectual property laws,
                    anti-spam laws, export control laws, tax laws, and
                    regulatory requirements protecting all videos, images,
                    graphical and written content on the site.
                  </p>
                  <p>Provide accurate information to us and keep it updated;</p>
                  <p>Use your real name on your profile;</p>
                  <p>Use the Services in a professional manner.</p>
                </li>
                <li>
                  <h3>You agree that you will not:</h3>
                  <p>
                    Act dishonestly or unprofessionally, including by posting
                    inappropriate, inaccurate, or objectionable content;
                  </p>
                  <p>
                    Add content that is not intended for, or inaccurate for, a
                    designated field (e.g. submitting a telephone number in the
                    “title” or any other field, or including telephone numbers,
                    email addresses, street addresses or any personally
                    identifiable information for which there is not a field
                    provided by THE QUORUM (Powered by INCRED APPLICATIONS PVT.
                    LTD.) );
                  </p>
                  <p>
                    Use an image that is not your likeness or a headshot photo
                    for your profile;
                  </p>
                  <p>
                    Create a false identity on THE QUORUM (Powered by INCRED
                    APPLICATIONS PVT. LTD.);
                  </p>
                  <p>
                    Misrepresent your current or previous positions and
                    qualifications;
                  </p>
                  <p>
                    Misrepresent your affiliations with a person or entity, past
                    or present;
                  </p>
                  <p>
                    Misrepresent your identity, including but not limited to the
                    use of a pseudonym;
                  </p>
                  <p>
                    Create a Member profile for anyone other than yourself (a
                    real person);
                  </p>
                  <p>Invite people you do not know to join your network;</p>
                  <p>Use or attempt to use another's account;</p>
                  <p>Harass, abuse or harm another person;</p>
                  <p>In a manner that is harmful to minors in any way;</p>
                  <p>
                    In a manner that is hateful or discriminatory based on race,
                    colour, sex, religion, nationality, ethnic or national
                    origin, marital status, disability, sexual orientation or
                    age or is otherwise objectionable;
                  </p>
                  <p>
                    To impersonate any other person, or falsely state or
                    otherwise misrepresent your affiliation with any person or
                    entity, or to obtain access to this Network without
                    authorization;
                  </p>
                  <p>
                    To interfere or attempt to interfere with the proper working
                    of this Network or prevent others from using this Network,
                    or in a manner that disrupts the normal flow of dialogue
                    with an excessive number of messages (flooding attack) to
                    this Network, or that otherwise negatively affects other
                    person's ability to use this Network;
                  </p>
                  <p>Send spam or other unwelcome communications to others;</p>
                  <p>
                    Scrape or copy profiles and information of others through
                    any means (including crawlers, browser plugins and add-ons,
                    and any other technology or manual work);
                  </p>
                  <p>
                    Act in an unlawful, libellous, abusive, obscene,
                    discriminatory or otherwise objectionable manner;
                  </p>
                  <p>
                    Disclose information that you do not have the right to
                    disclose (such as confidential information of others
                    (including your employer));
                  </p>
                  <p>
                    Violate intellectual property rights of others, including
                    patents, trademarks, trade secrets, copyrights or other
                    proprietary rights; Violate the intellectual property or
                    other rights of THE QUORUM (Powered by INCRED APPLICATIONS
                    PVT. LTD.) , including, without limitation, using the word “
                    THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.) ” or
                    our logos in any business name, email, or URL in anyway
                    implying our approval for usage or imitating us except if
                    expressly given our consent to do so.;
                  </p>
                  <p>
                    Post any unsolicited or unauthorized advertising, “junk
                    mail”, “spam”, “chain letters”, “pyramid schemes”, or any
                    other form of solicitation unauthorized by THE QUORUM
                    (Powered by INCRED APPLICATIONS PVT. LTD.) ;
                  </p>
                  <p>
                    Send messages to distribution lists, newsgroup aliases, or
                    group aliases;
                  </p>
                  <p>
                    Post anything that contains software viruses, worms, or any
                    other harmful code;
                  </p>
                  <p>
                    Manipulate identifiers in order to disguise the origin of
                    any message or post transmitted through the Services;
                  </p>
                  <p>
                    Create profiles or provide content that promotes escort
                    services or prostitution.
                  </p>
                  <p>
                    Creating or operate a pyramid scheme, fraud or other similar
                    practice;
                  </p>
                  <p>
                    Copy or use the information, content or data of others
                    available on the Services (except as expressly authorized);
                  </p>
                  <p>
                    Copy or use the information, content or data on THE QUORUM
                    (Powered by INCRED APPLICATIONS PVT. LTD.) in connection
                    with a competitive service (as determined by THE QUORUM
                    (Powered by INCRED APPLICATIONS PVT. LTD.)Copy, modify or
                    create derivative works of THE QUORUM (Powered by INCRED
                    APPLICATIONS PVT. LTD.) , the Services any related
                    technology (except as expressly authorized by THE QUORUM
                    (Powered by INCRED APPLICATIONS PVT. LTD.) );
                  </p>
                  <p>
                    Reverse engineer, decompile, disassemble, decipher or
                    otherwise attempt to derive the source code for the Services
                    or any related technology, or any part thereof;
                  </p>
                  <p>
                    Imply or state that you are affiliated with or endorsed by
                    THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.)
                    without our express consent;
                  </p>
                  <p>
                    Rent, lease, loan, trade, sell/re-sell access to the
                    Services or related any information or data; Sell, sponsor,
                    or otherwise monetize a THE QUORUM (Powered by INCRED
                    APPLICATIONS PVT. LTD.) Group or any other feature of the
                    Services, without THE QUORUM (Powered by INCRED APPLICATIONS
                    PVT. LTD.) s consent;
                  </p>
                  <p>
                    Deep-link to our Services for any purpose other than to
                    promote your profile or a Group or Project on THE QUORUM
                    (Powered by INCRED APPLICATIONS PVT. LTD.), without THE
                    QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.) or Incred
                    Applications Pvt. Ltd.’s consent;
                  </p>
                  <p>
                    Remove any copyright, trademark or other proprietary rights
                    notices contained in or on our Service;
                  </p>
                  <p>
                    Remove, cover or obscure any advertisement included on the
                    Services;
                  </p>
                  <p>
                    Collect, use, copy, or transfer any information obtained
                    from without the consent of THE QUORUM (Powered by INCRED
                    APPLICATIONS PVT. LTD.) or Incred Applications Pvt. Ltd.’s;
                  </p>
                  <p>
                    Share or disclose information of others without their
                    express consent;
                  </p>
                  <p>
                    Use manual or automated software, devices, scripts robots,
                    other means or processes to access, “scrape”, “crawl” or
                    “spider” the services or any related data or information;
                  </p>
                  <p>
                    Use bots or other automated methods to access the Services,
                    add or download contacts, send or redirect messages;
                  </p>
                  <p>
                    Monitor the Services availability, performance or
                    functionality for any competitive purpose;
                  </p>
                  <p>
                    Engage in “framing”, “mirroring”, or otherwise simulating
                    the appearance or function of the Services;
                  </p>
                  <p>
                    Access the Services except through the interfaces expressly
                    provided by THE QUORUM (Powered by INCRED APPLICATIONS PVT.
                    LTD.), such as its mobile applications, THE QUORUM (Powered
                    by INCRED APPLICATIONS PVT. LTD.);
                  </p>
                  <p>Override any security feature of the Services;</p>
                  <p>
                    Interfere with the operation of, or place an unreasonable
                    load on, the Services (e.g., spam, denial of service attack,
                    viruses, gaming algorithms);
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="date">Date of last revision: 21st Dec 2019</div>
        <div>
          <h3>PRIVACY POLICY</h3>
          <p className="bold-text">
            THIS PRIVACY POLICY APPLIES TO THE QUORUM WEB AND MOBILE APPLICATION
            POWERED BY INCRED APPLICATIONS PVT. LTD.(“APPLICATION”) AND TO THE
            BUSINESS THEREIN.
          </p>
          <p>
            Incred Applications Private Limited (“Company”) recognizes the
            importance of maintaining your privacy. This Privacy Policy applies
            to current and former visitors to the Application. By visiting
            and/or using the Application, you agree to this Privacy Policy.
          </p>
          <p>
            The Company recognizes the importance of maintaining your privacy.
            We value your privacy and appreciate your trust in us. This Policy
            describes how we treat user information we collect on the
            Application and other offline sources.
          </p>
        </div>
        <div>
          <h3>INFORMATION WE COLLECT</h3>
          <p>
            <span className="bold-text">Contact information:</span> We might
            collect your name, email, mobile number, phone number, street, city,
            state, pin code, country and IP address.
          </p>
          <p>
            <span className="bold-text">Information you post:</span> We collect
            information you post in a public space on the Application or on a
            third-party social media site belonging to the Company
          </p>
          <p>
            <span className="bold-text">Demographic information:</span> We may
            collect demographic information about you, events you like, events
            you intend to participate in, products you buy, or any other
            information provided by your during the use of our Application. We
            might collect this as a part of a survey also.
          </p>
          <p>
            <span className="bold-text">Other information:</span> If you use the
            Application, we may collect information about your IP address and
            the browser you're using. We might look at which site you came from,
            duration of time spent on our website, pages accessed or what site
            you visit when you leave us. We might also collect the type of
            mobile device you are using, or the version of the operating system
            your computer or device is running.
          </p>
          <p>
            We may disclose personal information if required to do so by law or
            in good faith that such disclosure is reasonably necessary to
            respond to subpoenas, court orders, or other legal process. We may
            disclose personal information to law enforcement offices, third
            party rights owners, or others in the good faith that such
            disclosure is reasonably necessary to: enforce our Privacy Policy;
            respond to claims that an advertisement posting or other content
            violates the rights of a third party; or protect the rights,
            property or personal safety of our users or the general public.
          </p>
        </div>
        <div>
          <h3>WAYS OF COLLECTING INFORMATION</h3>
          <p>
            <span className="bold-text">Directly through you:</span> We collect
            information directly from you when you register. We also collect
            information if you post a comment on our Application or ask us a
            question through phone or email.
          </p>
          <p>
            <span className="bold-text">
              Information sourced from third-parties:
            </span>{" "}
            If you use an integrated social media feature on our Application,
            the third-party social media site will give us certain information
            about you.{" "}
          </p>
        </div>
        <div>
          <h3>USE OF YOUR PERSONAL INFORMATION</h3>
          <p>
            <span className="bold-text">
              We use information to contact you:
            </span>{" "}
            We might use the information you provide to contact you for
            confirmation of a purchase on our website or for other promotional
            purposes.
          </p>
          <p>
            <span className="bold-text">
              We use information to respond to your requests or questions:
            </span>{" "}
            We might use your information to confirm your registration for an
            event or contest.
          </p>
          <p>
            <span className="bold-text">
              We use information for security purposes:
            </span>{" "}
            We may use information to protect our company, our customers, or our
            websites.
          </p>
          <p>
            <span>We use information for marketing purposes:</span> We might
            send you information about special promotions or offers. We might
            also tell you about new features or products. These might be our own
            offers or products, or third-party offers or products we think you
            might find interesting.{" "}
          </p>
          <p className="underline-text">
            PLEASE NOTE: In addition, we reserve the right to use and share your
            information in the event we believe doing so is necessary to operate
            this website or where such disclosure may be required by law, or to
            protect our right or the rights of others.
          </p>
        </div>
        <div>
          <h3>SHARING OF INFORMATION WITH THIRD-PARTIES</h3>
          <p>
            <span className="bold-text">
              We will share information with third parties who perform services
              on our behalf:
            </span>{" "}
            We share information with vendors who help us manage our online
            registration process or transactional message processors.
          </p>
          <p>
            <span className="bold-text">
              We may share information if we think we have to in order to comply
              with the law or to protect ourselves:
            </span>{" "}
            We will share information to respond to a court order or summons. We
            may also share it if a government agency or investigatory body
            requests. Or, we might also share information when we are
            investigating potential fraud.{" "}
          </p>
          <p>
            <span>
              We may share information with any successor to all or part of our
              business:
            </span>{" "}
            We and our affiliates will share / sell some or all of your personal
            information with another business entity should we (or our assets)
            plan to merge with, or be acquired by that business entity, or
            re-organization, amalgamation, restructuring of business.{" "}
          </p>
          <p>
            <span className="bold-text">
              We may share your information for reasons not described in this
              policy:
            </span>{" "}
            We will tell you before we do this.
          </p>
        </div>
        <div>
          <h3>COOKIE POLICY</h3>
          <p>
            A cookie is a small text file that we place on your mobile device to
            enable various features of the Application. "Cookies" are used to
            store user preferences and to track user trends, so as to enhance
            your interactive experience and generally improve our services to
            you. You can change your cookie settings to accept or not accept
            cookies in your browser settings. If you do accept a "cookie", you
            thereby agree to our use of any personal information collected by us
            using that Cookie. If you do accept a "cookie", you thereby agree to
            our use of any Personal Information collected by us using that
            Cookie.
          </p>
        </div>
        <div>
          <h3>GRIEVANCE OFFICER</h3>
          <p>
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below:
          </p>
          <p>Mr. Nakul Sood; Email: nakul.sood@incred.io</p>
          <p>
            If you have any questions about this Policy or other privacy
            concerns, you can also email us at nakul.sood@incred.io
          </p>
        </div>
        <div>
          <h3>UPDATES TO THIS POLICY</h3>
          <p>
            This Privacy Policy was last updated on 1st of Jan 2020. From time
            to time we may change our privacy practices. We may update this
            privacy policy by posting a new version on this website from time to
            time. You must check this page occasionally to ensure you are
            familiar with any changes in the policy.
          </p>
        </div>
        <div class="border-div">
          <h3>JURISDICTION</h3>
          <p>
            If you choose to visit the Application, your visit and any dispute
            over privacy is subject to this Policy and the Application’s terms
            of use. In addition to the foregoing, any disputes arising under
            this Policy shall be governed by the laws of India.
          </p>
        </div>

        <div>
          <h3>COPYRIGHT POLICY</h3>
          <p className="bold-text">
            VISITORS TO THIS WEB PLATFORM AND MOBILE APPLICATION, THE QUORUM
            (Powered by INCRED APPLICATIONS PVT. LTD.), ARE BOUND BY THE
            FOLLOWING COPYRIGHT POLICY. PLEASE READ THESE CAREFULLY BEFORE
            CONTINUING TO USE THIS APPLICATION.
          </p>
          <p className="underline-text">
            Incred Applications Private Limited (
            <span className="bold-text">“Company”</span>) reserves the right to
            modify/change the policies as it deems fit on a time-to-time basis.
          </p>
          <p>
            ll the works and other content displayed on this website and mobile
            app platform is the intellectual property of the Company. Nothing
            contained within the website should be construed as granting any
            license or the right to use any trademark/trade
            name/design/copyright without the prior written consent of the owner
            of the website.
          </p>
          <p>
            Any misuse of the intellectual property at the website will not be
            permitted and will be dealt as per the applicable laws of India. You
            acknowledge that your use of the content on this Website is for
            personal, non-commercial use.
          </p>
          <p>
            Incred Applications Private Limited and INCRED APPLICATIONS PVT.
            LTD. are trade names and/or trademarks of Incred Applications
            Private Limited and its affiliates.
          </p>
          <p>
            In addition, when you use any current or future service of the
            mobile application/website THE QUORUM (Powered by INCRED
            APPLICATIONS PVT. LTD.) ("Service"), you will also be subject to the
            terms, guidelines and conditions applicable to that App Service
            ("Terms").
          </p>
        </div>

        <div>
          <h3>GUIDELINES</h3>
          <ul class="guide-list ">
            <li>
              Copyright for the purpose of this Service shall always mean and
              include copyrights whether registered or not, and the right to
              register them, displaying rights, distribution rights, selling
              rights, abridged rights, translating rights, reproducing rights,
              performing rights, communicating rights, adapting rights,
              circulating rights, protected rights, joint rights, reciprocating
              rights, infringement rights.
            </li>
            <li>
              All content and the compilation of it thereto included in or made
              available through the Service, such as text, graphics, logos,
              button icons, images, audio clips, digital downloads, data
              compilations, and software is the property of the Company and/or
              its content suppliers and protected by the Indian copyright law.
            </li>
            <li>
              All rights arising as a result of domain names, internet or any
              other right available under applicable law shall vest in the
              domain of the Company as the owner of such domain name.
            </li>
            <li>
              Subject to your compliance and download, registration and/or view
              of Service, the Company and/or its content providers hereby grant
              you a limited, non-exclusive, non-transferable, non-sub licensable
              license to access and make personal and non-commercial use of the
              Services. This license does not include any resale or commercial
              use of any Service, or its contents; any collection and use of any
              descriptions, or prices; any derivative use of Service or its
              contents; any downloading, copying, or other use of account
              information for the benefit of any third party; or any use of data
              mining, robots, or similar data gathering and extraction tools.
            </li>
            <li>
              All rights not expressly granted to you are reserved and retained
              by the Company or its licensors, suppliers, publishers,
              rights-holders, or other content providers.
            </li>
            <li>
              No Service, nor any part of Service, may be reproduced,
              duplicated, copied, sold, resold, visited, or otherwise exploited
              for any commercial purpose without express written consent of the
              Company.
            </li>
            <li>
              You may not use any meta tags or any other "hidden text" utilizing
              the trade mark Incred, Incred Applications Pvt. Ltd., or other
              trademarks of the Company without the express written consent of
              the Company.
            </li>
            <li>
              You may not separate any individual component of the software for
              use other than in connection to the download, may not incorporate
              any portion of it into your own programs or compile any portion of
              it in combination with your own programs, may not transfer it for
              use with another service, or use it, or any portion of it, over a
              network and may not sell, rent, lease, lend, loan, distribute or
              sub-license the software or otherwise assign any rights to the
              software in whole or in part. We may discontinue some or all of
              any software we provide, and we may terminate your right to use
              any software at any time and in such event may modify it to make
              it inoperable.
            </li>
          </ul>
        </div>
        <div>
          <h3>POSTING NEW CONTENT</h3>
          <p>
            You may post reviews, comments, photos, videos, and other content
            and submit suggestions, ideas, comments, questions, or other
            information, so long as the content is not illegal, obscene,
            threatening, defamatory, invasive of privacy, infringing of
            intellectual property rights (including publicity rights), or
            otherwise injurious to third parties or objectionable, and does not
            consist of or contain software viruses, political campaigning,
            commercial solicitation, chain letters, mass mailings, or any form
            of "spam" or unsolicited commercial electronic messages.
          </p>
          <p>
            You may not use a false e-mail address, impersonate any person or
            entity, or otherwise mislead as to the origin of a card or other
            content, the Company reserves the right to remove or edit such
            content, or to regularly review posted content.
          </p>
          <p>
            If you do post content or submit material, and unless the Company
            indicate otherwise, you represent and warrant that you own or
            otherwise control all of the rights to the content that you post;
            that the content is accurate; that use of the content you supply
            does not violate this policy and will not cause injury to any person
            or entity; and that you will indemnify the Company for all claims
            resulting from content you supply.
          </p>
          <p>
            The Company has the right, but not the obligation, to monitor and
            edit or remove any activity or content. The Company takes no
            responsibility and assumes no liability for any content posted by
            you or any third party.
          </p>
        </div>
        <div>
          <h3>REPORTING INFRINGEMENT</h3>
          <p>
            A Rights Owner shall include any person who legitimately owns
            intellectual property rights in the product in question. To submit a
            notice of IP infringement, you must be the Rights Owner who owns the
            IP being reported or an agent with permission from the Rights Owner
            to submit notices on his or her behalf. If we receive proper
            notification of Copyright Infringement, our response to such notices
            will include removing or disabling access to material claimed to be
            the subject of infringing activity.
          </p>
          <p>
            The Right Owner or their authorised agent may notify the Company in
            writing upon their discovery of any unauthorized use or
            infringement. Infringing content found on the THE QUORUM (Powered by
            INCRED APPLICATIONS PVT. LTD.), mobile application/website may be
            reported by providing specific details of such infringement at
            nakul.sood@incred.io
          </p>
        </div>
        <div>
          <h3>REMOVAL OF INFRINGING CONTENT</h3>
          <p>
            When we receive a report from a Rights Owner or their authorized
            agent claiming content on THE QUORUM (Powered by INCRED APPLICATIONS
            PVT. LTD.), mobile application/website infringes their intellectual
            property rights, we may need to promptly remove that content from
            THE QUORUM (Powered by INCRED APPLICATIONS PVT. LTD.), mobile
            application/website without contacting you first.
          </p>
          <p>
            If we remove content you posted because of an intellectual property
            report, you’ll receive a notification from THE QUORUM (Powered by
            INCRED APPLICATIONS PVT. LTD.), mobile application/website that
            includes the name and email of the Rights Owner who made the report
            and/or the details of the report. If you believe the content
            shouldn’t have been removed, you can follow up with them directly to
            try to resolve the issue.
          </p>
        </div>
        <div>
          <h3>UPDATES TO THIS POLICY</h3>
          <p>
            This Copyright Policy was last updated on 1st of Jan 2020. From time
            to time we may change our privacy practices. We may update this
            privacy policy by posting a new version on this website from time to
            time. You must check this page occasionally to ensure you are
            familiar with any changes in the policy.
          </p>
          <p>
            The App requires the user's phone number and email id to enable
            access to allow Sign In on the application.
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
