import { Q_PAY_UPDATE_USER_DATA, Q_PAY_GET_TOKEN } from '../actions/constants';
import { getCookie } from '../services/session';

const INITIAL_STATE = {
  isQpayLogin: !getCookie('qpay-token') ? false : true,
  qPayUserId: getCookie('qpay-user-id',"") || null,
  qPayAuthData: {},
  qPaytoken:  getCookie('qpay-token',"") || ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Q_PAY_UPDATE_USER_DATA:
        if(action.data.apiError){
          return {...state, isQpayLogin: null}
        }
      return {
        ...state,
        isQpayLogin: action.data.isQpayLogin,
        qPayAuthData: action.data.qPayAuthData,
        qPayUserId: action.data.qPayUserId,
        qPaytoken: action.data.qPayUserId
      };
    case `REDUCER/${Q_PAY_GET_TOKEN}`: 
       if(!action.data.qPaytoken) return state;
       return {
        ...state, 
        qPaytoken: (action.data && action.data.qPaytoken ? action.data.qPaytoken: '') ,
        isQpayLogin: (action.data && action.data.qPaytoken ? true: false),
      } 
    default:
      return state;
  }
}
