import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPageData, resetPageStore } from '../actions/page';
import { Endpoint } from '../utils/constants';
import Loader from '../components/customFields/loader';
import NoRecord from '../components/customFields/noRecord';

class InviteGuestsSummary extends Component {
    constructor(props) {
        super(props);
        this.isSignedIn = false;
    }

    getIsoString = () =>{
        Date.prototype.toIsoString = function() {
            var tzo = -this.getTimezoneOffset(),
                dif = tzo >= 0 ? '+' : '-',
                pad = function(num) {
                    var norm = Math.floor(Math.abs(num));
                    return (norm < 10 ? '0' : '') + norm;
                };
            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes()) +
                ':' + pad(this.getSeconds()) +'.00'+
                dif + pad(tzo / 60) +
                ':' + pad(tzo % 60);
        }
    }
    loadCalenderApi = () =>{
        var self = this;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.async = true;
            js.defer = true;
            js.src = "//apis.google.com/js/api.js";
            fjs.parentNode.insertBefore(js, fjs);
            js.onload = self.handleClientLoad;
          }(document, 'script', 'calender-ssdk'));
    }

     initClient = () =>{
        var self = this;
        if(window.gapi)
        var CLIENT_ID = '32059725603-8dsjb2nflnsndq8bqsr62fpfoonhhluk.apps.googleusercontent.com';
        var API_KEY = 'AIzaSyAB2s-ipfF26XlfMCuiMUrKStieE0FUJN0';
        // Array of API discovery doc URLs for APIs used by the quickstart
        var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
  
        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        var SCOPES = "https://www.googleapis.com/auth/calendar.events";
        window.gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then(function () {
          // Listen for sign-in state changes.
          window.gapi.auth2.getAuthInstance().isSignedIn.listen(self.updateSigninStatus);
          self.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get(), true);
          document.getElementById('authorize_button').onclick = self.handleAuthClick;
        }, function(error) {
          //appendPre(JSON.stringify(error, null, 2));
        });
      }

       handleAuthClick = (event) =>{
        // if(this.isSignedIn){
        //     this.addEvents();
        //     return;
        // }
        window.gapi.auth2.getAuthInstance().signIn();
      }

       updateSigninStatus=(isSignedIn, isOnload = false) =>{
        this.isSignedIn = isSignedIn;
        if(!isOnload && isSignedIn){
            this.addEvents();
        }
       }

      addEvents() {
        let { list } = this.props;
        let start = new Date(list.date + ' ' + list.time);
        let end = new Date(list.date + ' ' + list.endTime);
          let event = {
            'summary': list.eventName || 'New Invitation',
            'location': list.address,
            'description': list.about || '',
            'start': {
              'dateTime': start.toIsoString(),
              'timeZone': 'Asia/Kolkata'
            },
            'end': {
              'dateTime': end.toISOString(),
              'timeZone': 'Asia/Kolkata'
            },
            // 'recurrence': [
            //   'RRULE:FREQ=DAILY;COUNT=2'
            // ],
            // 'attendees': [
            //   {'email': 'lpage@example.com'},
            //   {'email': 'sbrin@example.com'}
            // ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
              ]
            }
          };
        var request = window.gapi.client.calendar.events.insert({
          'calendarId': 'primary',
           'resource': event
        });

        request.execute(function(event) {
            console.log(event);
        });
      }

    handleClientLoad =()=> {
        if(window.gapi)
        window.gapi.load('client:auth2', this.initClient.bind(this));
    }

    componentDidMount() {
        this.getIsoString();
        this.loadCalenderApi();
        let { match: { params } } = this.props;
        this.props.getPageData({
            params: { gid: params.token },
            url: Endpoint.INIVITE_LINK
        })
    }

    componentWillUnmount(){
        this.props.resetPageStore('pageData');
    }

    getLatLang=(latlng)=>{
        console.log(latlng.replace(',','-'));
        return latlng.replace(',','-');
    }

    render() {
        let { list, isLoader } = this.props;
        console.log(list);
        if(isLoader){
            return <Loader />;
        }
        if(!isLoader && Object.keys(list).length === 0){
            return <NoRecord message="Invalid Invite Link" />;
        }
        return (
            <div className='inviteGuests inviteGuestSummary'>
                <div className='_title text-center mb10'>QUORUM INVITE</div>
                <div className='d-flex justify-content-center' >
                    <img src={list.qr} className='w100per snaptag' alt="b"/>
                </div>
                <p className='txt'>{list.gName} - {list.name}</p>
                <div className='row txt-details'>
                    <div className='col-6'>
                        <div className='mb20'>
                            <p>DATE</p>
                            <p>{list.date}</p>
                        </div>
                        <div className='mb20'>
                            <p>TIME</p>
                            <p>{list.time}</p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <p>LOCATION</p>
                        <p>{list.address}</p>
                    </div>
                </div>
                <div className='row txt-details'>
                    <div className='col-6'>
                        <p className='txt-color cursor-pointer' id="authorize_button">Add To Calendar</p>
                    </div>
                    <div className='col-6'>
                        {list.latlng && <p className='txt-color'><a href={`https://www.google.com.sa/maps/search/${list.latlng}?hl=en`} target="_blank">See Map</a></p>}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps({page: { pageData: { data = []}, isLoader = false }}) {
    return { list: data, isLoader }
}

export default connect(mapStateToProps, {getPageData, resetPageStore})(InviteGuestsSummary);
