/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPageStore, getIndividualItem } from "../../actions/page";
import { dateToStr } from "../../utils/formatter";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import moment from "moment";
import ModalButton from "../../components/modal/button";
import useReservation from "../../controller/reservation/reservation-controller";
import { toast } from "react-toastify";

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.maxGuest = 10;
    this.state = {
      date: dateToStr(null, "YYYY-MM-DD"),
      list: [],
      request: true,
      activeClub: "",
      isLoader: false,
    };
  }

  componentDidMount() {
    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      this.getListAuto();
      this.getList();
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("individualItem");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      if (this.props.clubID !== "" && prevProps.clubID !== this.props.clubID) {
        this.getListAuto();
      }
    }
    if (
      this?.props?.adminType !== "Super Admin" &&
      this?.props?.adminType !== "Admin" &&
      this?.props?.adminType !== "Developer"
    ) {
      if (
        prevProps !== this.props &&
        this.props.activeClub !== null &&
        this.props.activeClub.length !== 0
      ) {
        this.setState({ activeClub: this.props.activeClub[0]._id });
      }

      if (this.state.activeClub !== "" && this.state.request) {
        this.getList(this.state.date, "", "");
        this.setState({ request: false });
      }
    }

    if (
      prevProps?.reservationControllerData?.allReservations !==
        this?.props?.reservationControllerData?.allReservations &&
      this?.props?.permissions?.view
    ) {
      this.setState({
        list: this?.props?.reservationControllerData?.allReservations,
      });
    }

    if (
      prevProps?.reservationControllerData?.isLoading !==
      this?.props?.reservationControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.reservationControllerData?.isLoading,
      });
    }

    if (
      prevProps?.reservationControllerData?.errMsg !==
      this?.props?.reservationControllerData?.errMsg
    ) {
      if (this?.props?.reservationControllerData?.errMsg) {
        toast.error(this?.props?.reservationControllerData?.errMsg);
      }
    }

    if (
      prevProps?.reservationControllerData?.succMsg !==
      this?.props?.reservationControllerData?.succMsg
    ) {
      if (this?.props?.reservationControllerData?.succMsg) {
        toast.success(this?.props?.reservationControllerData?.succMsg);
      }
    }
  }

  getListAuto = () => {
    if (this.props.clubID) {
      const { getAllReservations } = this?.props?.reservationControllerData;
      const url = `${this.props.clubID}?startDateTime=${moment
        .utc(this.state.date)
        .unix()}`;

      getAllReservations(url);
    }
  };

  getList = () => {
    if (this.state.activeClub) {
      const { getAllReservations } = this?.props?.reservationControllerData;
      const url = `${this.state.activeClub}?startDateTime=${moment
        .utc(this.state.date)
        .unix()}`;

      getAllReservations(url);
    }
  };

  render() {
    let { list, isLoader } = this.state;

    if (Array.isArray(list) && list.length > 0 && list[0].startTime) {
      list.sort((a, b) => {
        var time1 = parseFloat(
          a.startTime.replace(":", ".").replace(/[^\d.-]/g, "")
        );
        var time2 = parseFloat(
          b.startTime.replace(":", ".").replace(/[^\d.-]/g, "")
        );
        if (a.startTime.match(/.*pm/)) time1 += 12;
        if (b.startTime.match(/.*pm/)) time2 += 12;
        if (time1 > time2) return 1;
        if (time1 < time2) return -1;
        return 0;
      });
    }

    return (
      <React.Fragment>
        {isLoader && <Loader />}
        {!isLoader && (!list || list.length === 0 || !list.length) && (
          <NoRecord message="No reservation found" />
        )}
        {!isLoader && list && list.length > 0 && (
          <table className="table table-borderless custom-table">
            <thead>
              <tr className="text-center">
                <th>Date</th>
                <th>Time</th>
                <th>Member's Name</th>
                <th>Guests</th>
                <th>Area</th>
                <th>Note</th>
                <th>From</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, i) => {
                return (
                  <tr
                    key={i}
                    style={{
                      color:
                        item.isApproved === 2
                          ? "#27ce27"
                          : item.isApproved === 3
                          ? "#fd2b22"
                          : "",
                    }}
                    className="text-center"
                  >
                    <td>
                      {moment.unix(item?.startDateTime).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {moment
                        .unix(item?.startDateTime, "hh:mm a")
                        .format("HH:mm")}{" "}
                      -{" "}
                      {moment
                        .unix(item?.endDateTime, "hh:mm a")
                        .format("HH:mm")}{" "}
                    </td>
                    <td className="capitalize-text">
                      {item?.memberId?.fName} {item?.memberId?.mName}{" "}
                      {item?.memberId?.lName}
                    </td>
                    <td className="text-center">{item?.noGuest}</td>
                    <td style={{ textTransform: "uppercase" }}>
                      {item?.spaceId?.name || item?.privateSpaceId?.name}
                    </td>
                    <td className="text-center">
                      <ModalButton
                        data={{
                          showCustomModal: true,
                          title: "Note",
                          path: "reservation/show-notes",
                          body: "",
                          data: {
                            ...item,
                            notes: item?.note,
                          },
                          id: item._id,
                          className:
                            "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                        }}
                      >
                        {item.note ? (
                          <span className="cursor-pointer color-green fs20">
                            <i className="fa fa-file-text"></i>
                          </span>
                        ) : (
                          <span className="cursor-pointer">
                            <i className="fa fa-file-text"></i>
                          </span>
                        )}
                      </ModalButton>
                    </td>
                    <td>{item?.doneFrom}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { authData }, clubs: { activeClub } }) {
  return {
    adminType: authData?.roleId?.roleName,
    activeClub: activeClub,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const reservationControllerData = useReservation();

    return (
      <Component
        {...props}
        reservationControllerData={reservationControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, { getIndividualItem, resetPageStore })(
  injectComp(Reservation)
);
