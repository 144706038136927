import React, { Component } from "react";
import { connect } from "react-redux";
import { validate } from "../utils/validation";
import { qPayGenerateOtp, qPayVerifyOtp, getQPayToken } from "../actions";
import { updateQpayData } from "../actions/qpay";
import { getCookie } from "../services/session";
import { Endpoint } from "./../utils/constants";
import QPayDashboard from "./../components/Qpay/q-pay-dashboard";
import TransacationHistory from "./../components/Qpay/transacation-history";
import MemberDeposite from "./../components/Qpay/member-deposite";
import QPayReports from "./../components/Qpay/q-pay-report";
import TermsAndConditions from "./../components/Qpay/terms-conditions";
import DepositIncentive from "./../components/Qpay/deposite-insentive";
import AccessControl from "./../components/Qpay/access-control";
import MemberWallets from "./../components/Qpay/member_wallets";
import "../style/newStyle.scss";

class QPay extends Component {
  constructor(props) {
    super(props);
    this.interval = 0;
    this.timer = 120;
    this.state = {
      menus: [],
      phone: "",
      email: "",
      otp: "",
      isValid: {},
      errorMessages: {},
      error: "",
      otpId: "",
      isActiveStep: 1,
      isQpayLogin: false,
      section: "phone",
      isOtpSend: false,
      isLoader: false,
    };
  }

  componentWillMount = () => {
    let token = getCookie("qpay-token");
    if (token && token != "undefined" && token != null && token != "") {
      this.setState({ isQpayLogin: true });
      this.getMenu();
    } else {
      this.setState({ isQpayLogin: false, isLoader: true });
    }
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return `0${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
  };

  startTimer = () => {
    this.interval = setInterval(() => {
      this.timer = this.timer - 1;
      let el = document.getElementById("_timer");
      el
        ? (el.innerHTML = this.secondsToTime(this.timer))
        : clearInterval(this.interval);
      if (this.timer == 0) {
        clearInterval(this.interval);
        this.timer = 120;
        this.setState({ isOtpSend: false });
      }
    }, 1000);
  };

  validate = (name, value) => {
    let { isValid, errorMessages } = this.state;
    let error = validate(name, value, { type: "text" });
    isValid[name] = error.isValid;
    error.isValid
      ? delete errorMessages[name]
      : (errorMessages[name] = error.errorMsg);
    this.setState({ isValid, errorMessages });
  };

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    if ((name === "phone" || name === "email") && this.state.isOtpSend) {
      return;
    }
    this.setState({ [name]: value }, () => {
      this.validate(name, value);
    });
  };

  onSubmit = (e, type = "") => {
    if ((e.key === "Enter" && !e.shiftKey) || type) {
      this.setState({ error: "" });
      e.preventDefault();
      let payload = {};
      let {
        target: { name, value },
      } = e;
      name = type ? type : name;
      this.validate(name, this.state[name]);
      if (name === "phone" && this.state.isValid[name] && !this.state.otp) {
        payload.mobile = this.state.phone;
      } else if (
        name === "email" &&
        this.state.isValid[name] &&
        !this.state.otp
      ) {
        payload.email = this.state.email;
      } else if (name === "otp" && this.state.isValid[name]) {
        payload.otpCode = this.state.otp;
      }
      if (type == "phone") {
        if (this.state.phone === "" || !this.state.phone) {
          return;
        } else {
          this.props.qPayGenerateOtp(
            { payload, token: this.props.token },
            (response) => {
              if (response?.status && response?.status !== "false") {
                this.setState({ error: "" });
                this.startTimer();
                this.setState({ isOtpSend: true, otpId: response?.otpId });
              } else {
                this.setState({ error: response?.msg });
              }
            }
          );
        }
      } else if (type == "email") {
        if (this.state.email === "" || !this.state.email) {
          return;
        } else {
          this.props.qPayGenerateOtp(
            { payload, token: this.props.token },
            (response) => {
              if (response?.status && response?.status !== "false") {
                this.setState({ error: "" });
                this.startTimer();
                this.setState({ isOtpSend: true, otpId: response?.otpId });
              } else {
                this.setState({ error: response?.msg });
              }
            }
          );
        }
      } else if (type == "otp") {
        payload.otpId = this.state.otpId;
        if (!this.state.otp || this.state.otp === "") {
          return;
        } else {
          this.props.qPayVerifyOtp(
            { payload, token: this.props.token },
            (response) => {
              if (response?.status && response?.status !== "false") {
                this.setState({ isQpayLogin: true, isLoader: false });
                this.getMenu();
              } else {
                this.setState({ error: "Invalid otp code" });
              }
            }
          );
        }
      }
    }
  };

  setSection = (e, section) => {
    e.preventDefault();
    this.setState({ section, isOtpSend: false, error: "" });
  };

  getMenu = () => {
    let roleId = getCookie("qpay-role-id");
    this.props.updateQpayData(
      {
        url: `${Endpoint.QPAY_ROLE}/${roleId}`,
        method: "GET",
      },
      (response) => {
        if (response?.status && response?.status !== "false") {
          this.setState({
            menus: response?.data?.accessAllowed,
            isLoader: true,
          });
        } else {
          this.setState({
            error: response?.message || response?.msg,
            isQpayLogin: false,
            isLoader: false,
          });
        }
      }
    );
  };

  render() {
    let {
      isActiveStep,
      isQpayLogin,
      phone,
      email,
      otp,
      isValid,
      errorMessages,
      error,
      isOtpSend,
      section,
      menus,
      isLoader,
    } = this.state;

    return (
      <React.Fragment>
        {!isQpayLogin && (
          <div className="q-pay-login">
            <div className="login-box">
              <div className="logo"></div>
              <p className="title">Q Pay Access</p>
              <p className="sub-title mb-2">
                {section == "phone" && "Sign in with Phone Number"}
                {section == "email" && "Sign in with E-Mail"}
              </p>
              <div className="login-form">
                <div>
                  {section == "phone" && (
                    <div>
                      <label className="form-label-login" htmlFor="phone">
                        Phone Number
                      </label>
                      <input
                        className="form_control"
                        type="number"
                        name="phone"
                        value={phone}
                        disabled={isOtpSend}
                        onChange={this.inputChange}
                        onKeyDown={this.onSubmit}
                      ></input>
                      {!isValid.phone && (
                        <div className="error_msg">{errorMessages.phone}</div>
                      )}
                    </div>
                  )}
                  {section == "email" && (
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label-login" htmlFor="email">
                          Email Id
                        </label>
                        <input
                          className="form_control"
                          name="email"
                          value={email}
                          disabled={isOtpSend}
                          onChange={this.inputChange}
                          onKeyDown={this.onSubmit}
                        ></input>
                        {!isValid.email && (
                          <div className="error_msg">{errorMessages.email}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {isOtpSend && (
                    <div>
                      <label className="form-label-login" htmlFor="otp">
                        OTP
                      </label>
                      <input
                        className="form_control mb5"
                        name="otp"
                        value={otp}
                        onChange={this.inputChange}
                        onKeyDown={this.onSubmit}
                      ></input>
                      {!isValid.otp && (
                        <div className="error_msg">{errorMessages.otp}</div>
                      )}
                      <a href="#" className="txt-common" type="number">
                        Resend OTP will be active in:{" "}
                        <span id="_timer">02:00</span>
                      </a>
                    </div>
                  )}
                  {error && (
                    <p className="error_msg" style={{ color: "red" }}>
                      {error}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-center mt30">
                  {section == "phone" && (
                    <div
                      className="btn-login text-center"
                      onClick={(e) =>
                        this.onSubmit(e, isOtpSend ? "otp" : "phone")
                      }
                    >
                      {!isOtpSend ? "Request OTP" : "Submit OTP"}
                    </div>
                  )}
                  {section == "email" && (
                    <div
                      className="btn-login text-center"
                      onClick={(e) =>
                        this.onSubmit(e, isOtpSend ? "otp" : "email")
                      }
                    >
                      {!isOtpSend ? "Request OTP" : "Submit OTP"}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center mt10 default-font">
                  {section == "phone" && (
                    <div
                      className="txt-common txt-underline"
                      onClick={(e) => this.setSection(e, "email")}
                    >
                      Log In With Email
                    </div>
                  )}
                  {section == "email" && (
                    <div
                      className="txt-common txt-underline"
                      onClick={(e) => this.setSection(e, "phone")}
                    >
                      Log In With Phone Number
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoader && isQpayLogin && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden q-pay">
            <div className="row">
              <div className="col-3" style={{ width: "20%" }}>
                <div className="d-flex mb-0 align-items-center  justify-content-between">
                  <div>
                    <h3 className="page-title mb-0">Q Pay</h3>
                  </div>
                </div>
                <div className="card bg-transparent mt-4 border-none h-100 overflow-x-hidden overflow-auto">
                  <div className="reservtion-tab overflow-card">
                    <ul
                      className="list-unstyled components"
                      style={{ border: "none" }}
                    >
                      {menus &&
                        menus.length > 0 &&
                        menus.map((element, index) => {
                          return (
                            element.view &&
                            element.menuBar && (
                              <li
                                key={index}
                                className={
                                  isActiveStep == element.moduleId.seqId
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.setState({
                                    isActiveStep: element.moduleId.seqId,
                                  })
                                }
                              >
                                <p>{element.moduleId.name}</p>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-9" style={{ width: "80%" }}>
                <div className="card h-100 overflow-hidden">
                  <div className="h-100 overflow-x-hidden overflow-auto">
                    <div className="container-fluid">
                      {isActiveStep === 1 && <QPayDashboard></QPayDashboard>}
                      {isActiveStep === 2 && (
                        <TransacationHistory></TransacationHistory>
                      )}
                      {isActiveStep === 3 && <MemberDeposite></MemberDeposite>}
                      {isActiveStep === 4 && (
                        <MemberWallets menus={menus}></MemberWallets>
                      )}
                      {isActiveStep === 6 && <QPayReports></QPayReports>}
                      {isActiveStep === 7 && (
                        <TermsAndConditions></TermsAndConditions>
                      )}
                      {isActiveStep === 8 && (
                        <DepositIncentive></DepositIncentive>
                      )}
                      {isActiveStep === 9 && (
                        <AccessControl getMenu={this.getMenu}></AccessControl>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ qpayAuth: { isQpayLogin } }) {
  return {
    isQpayLogin,
  };
}
export default connect(mapStateToProps, {
  updateQpayData,
  qPayGenerateOtp,
  qPayVerifyOtp,
  getQPayToken,
})(QPay);
