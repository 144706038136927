import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useQPerks from "../controller/q-perks/q-perks-controller";
import Uploader from "../components/customFields/uploader";
import Dropzone from "react-dropzone";
import { S3_BUCKET_CRED } from "../utils/constants";
import AWS from "aws-sdk";
import { toast } from "react-toastify";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class QperksSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isUpdate: false,
      isInit: true,
      categoriesList: [],
      mainImgData: {},
    };
  }

  componentDidMount() {
    this.getCategoriesList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.qPerksControllerData?.allCategoriesData !=
      this?.props?.qPerksControllerData?.allCategoriesData
    ) {
      this.setState({
        categoriesList: this?.props?.qPerksControllerData?.allCategoriesData,
      });
    }
    if (
      prevProps?.qPerksControllerData?.mainImg !=
      this?.props?.qPerksControllerData?.mainImg
    ) {
      this.setState({
        mainImgData: this?.props?.qPerksControllerData?.mainImg,
      });
    }
    if (
      prevProps?.qPerksControllerData?.isLoading !=
      this?.props?.qPerksControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.qPerksControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.qPerksControllerData?.allCategoriesData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.qPerksControllerData?.isUpdating !=
      this?.props?.qPerksControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.qPerksControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.qPerksControllerData?.succMsg !==
      this?.props?.qPerksControllerData?.succMsg
    ) {
      if (this?.props?.qPerksControllerData?.succMsg) {
        const {} = this?.props?.qPerksControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        this.getCategoriesList();
        toast.success(this?.props?.qPerksControllerData?.succMsg);
      }
    }
    if (
      prevProps?.qPerksControllerData?.errMsg !==
      this?.props?.qPerksControllerData?.errMsg
    ) {
      if (this?.props?.qPerksControllerData?.errMsg) {
        this.setState({
          error: this?.props?.qPerksControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.qPerksControllerData?.errMsg);
      }
    }
  }

  getCategoriesList = () => {
    const { getAllCategories } = this?.props?.qPerksControllerData;
    getAllCategories();
  };

  handleSwitchChange = (data) => {
    const { updateCategory } = this?.props?.qPerksControllerData;
    updateCategory({ _id: data?._id, isActive: !data?.isActive });
  };

  onDrop = (files, r) => {
    if (files.length) {
      this.setState({ bulkFiles: files });
      this.toggleUploader(true);
    } else if (r.length) {
      this.props.updateModalData({
        showCustomModal: true,
        title: "",
        body: "Only jpg, jpeg and png files are allowed",
      });
    }
  };

  toggleUploader = (isUploader) => {
    this.setState({ isUploader });
  };

  setBulkPhotos = (bulkCollection) => {
    let a = bulkCollection.filter((e) => e.type === "selected");
    if (a.length === bulkCollection.length) {
      for (let i = 0; i < bulkCollection.length; i++) {
        const file = bulkCollection[i];
        new Promise((resolve, reject) => {
          var fileName = bulkCollection[i].file.name.split(".").pop();
          AWS.config.update({
            accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
            secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
            region: S3_BUCKET_CRED.BUCKET_REGION,
          });
          var s3 = new AWS.S3();
          var params = {
            Bucket: S3_BUCKET_CRED.BUCKET_NAME,
            Key: "asset/" + Date.now() + "." + fileName,
            ContentType: file.file.type,
            Body: dataURLtoFile(file.thumbnail, file.fileName),
            ACL: "public-read",
          };

          s3.putObject(params, async (err, res) => {
            if (err) {
            } else {
              const { addMainImg } = this?.props?.qPerksControllerData;
              let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

              this.setState({
                isUploader: false,
                isImageAdded: true,
              });
              addMainImg({ url: link });

              try {
                resolve(link);
              } catch (e) {
                reject(e);
              }
            }
          });
        });
      }
    }
  };

  render() {
    let {
      isLoader,
      isUpdate,
      isInit,
      categoriesList,
      mainImgData,
      bulkFiles = [],
      isUploader,
    } = this.state;

    if (isUploader) {
      return (
        <div className="mt30 memberUploader">
          <Uploader
            files={bulkFiles}
            onComplete={this.setBulkPhotos}
            onBack={this.toggleUploader}
            folder={"qperks-setting"}
            uploadType="mainImage"
            isSideNav={false}
            not_upload={true}
            isPopup={true}
            config={{ aspectRatio: "l2" }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/qperks">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Q Perks Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="row mb-3">
                      <h4 className="col-8">Categories</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            getCategoriesList: this.getCategoriesList,
                            showCustomModal: true,
                            title: "Add Category",
                            path: "QPerksSetting/addCategory",
                            body: "",
                            className: "modal-small",
                          }}
                          disabled={isUpdate}
                        >
                          <h3
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: isUpdate ? "default" : "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {isInit && isLoader && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {!isLoader && categoriesList?.length === 0 && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <NoRecord />
                      </div>
                    )}
                    {categoriesList && categoriesList?.length > 0
                      ? categoriesList?.map((cont, i) => (
                          <div className="row cursor-pointer" key={i}>
                            <div className="col-8 mb-3">
                              <p style={{ textTransform: "capitalize" }}>
                                {cont?.title}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <ModalButton
                                  data={{
                                    itemData: cont,
                                    getCategoriesList: this.getCategoriesList,
                                    showCustomModal: true,
                                    title: "Edit Category",
                                    path: "QPerksSetting/addCategory",
                                    body: "",
                                    className: "modal-small",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginRight: 10,
                                      marginBottom: 0,
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                                <InputField
                                  value={cont.isActive}
                                  checked={cont.isActive}
                                  inputChange={() =>
                                    isUpdate
                                      ? {}
                                      : this.handleSwitchChange(cont)
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="col-4" style={{ paddingLeft: "3px" }}>
                    <h3 className="page-title mb-3">Q Perks Main Image</h3>
                    <Dropzone
                      style={{ position: "relative" }}
                      accept="image/jpeg, image/png, image/jpg"
                      multiple={false}
                      onDrop={(accepted, rejected) => {
                        this.onDrop(accepted, rejected);
                      }}
                    >
                      <div
                        className={"image-style upload-photo"}
                        style={{
                          backgroundImage: `url(${mainImgData?.url})`,
                          width: 300,
                        }}
                      >
                        <img
                          width={65}
                          src={"../../images/imagesize2_3.png"}
                          className="w100per"
                        />
                      </div>
                    </Dropzone>
                  </div>
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ page: { isLoader = false } }) {
  return { isLoader };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const qPerksControllerData = useQPerks();
    return <Component {...props} qPerksControllerData={qPerksControllerData} />;
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {})(injectComp(QperksSettings));
