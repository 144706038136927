/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPageStore } from "../../actions/page";
import { dateToStr } from "../../utils/formatter";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import ModalButton from "../../components/modal/button";
import moment from "moment";
import useCheckin from "../../controller/checkin/checkin-controller";
import { toast } from "react-toastify";

class Checkin extends Component {
  constructor(props) {
    super(props);
    this.maxGuest = 10;
    this.state = {
      date: dateToStr(null, "YYYY-MM-DD"),
      list: [],
      isLoader: false,
    };
  }

  componentDidMount(prevProps, prevState) {
    if (this.props.activeClub) {
      this.getList();
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("individualItem");
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.activeClub !== "" &&
      prevProps.activeClub !== this.props.activeClub
    ) {
      this.getList();
    }

    if (
      prevProps?.checkinControllerData?.allMemberCheckinData !==
        this?.props?.checkinControllerData?.allMemberCheckinData &&
      this?.props?.permissions?.view
    ) {
      this.setState({
        list: this?.props?.checkinControllerData?.allMemberCheckinData,
      });
    }

    if (
      prevProps?.checkinControllerData?.isLoading !==
      this?.props?.checkinControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.checkinControllerData?.isLoading,
      });
    }

    if (
      prevProps?.checkinControllerData?.errMsg !==
      this?.props?.checkinControllerData?.errMsg
    ) {
      if (this?.props?.checkinControllerData?.errMsg) {
        toast.error(this?.props?.checkinControllerData?.errMsg);
      }
    }

    if (
      prevProps?.checkinControllerData?.succMsg !==
      this?.props?.checkinControllerData?.succMsg
    ) {
      if (this?.props?.checkinControllerData?.succMsg) {
        toast.success(this?.props?.checkinControllerData?.succMsg);
      }
    }
  }

  getList = () => {
    const { getAllMemberCheckin } = this.props.checkinControllerData;
    const url = `${this.props.activeClub}?from=${
      this.state.date
        ? encodeURIComponent(moment(this.state.date).format("YYYY/MM/DD"))
        : ""
    }&end=${
      this.state.date
        ? encodeURIComponent(moment(this.state.date).format("YYYY/MM/DD"))
        : ""
    }`;

    getAllMemberCheckin(url);
  };

  render() {
    let { list, isLoader } = this.state;

    if (Array.isArray(list) && list.length > 0 && list[0].time) {
      list.sort((a, b) => b.time.localeCompare(a.time));
    }

    return (
      <React.Fragment>
        {isLoader && <Loader />}
        {!isLoader && (!list || list.length === 0) && (
          <NoRecord message="No checked in members found" />
        )}
        {!isLoader && list && list.length > 0 && (
          <table className="table table-borderless custom-table">
            <thead>
              <tr className="text-center">
                <th>Date</th>
                <th>Time</th>
                <th>Member's Name</th>
                <th>Guests</th>
                <th>M.No</th>
                <th>Note</th>
                <th>From</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, i) => {
                return (
                  <tr key={i} className="text-center">
                    <td>{moment.unix(item?.dateTime).format("DD-MM-YYYY")}</td>
                    <td className="w100">
                      {moment.unix(item?.dateTime, "hh:mm a").format("HH:mm")}
                    </td>
                    <td className="capitalize-text">
                      {item?.memberId?.fName} {item?.memberId?.mName}{" "}
                      {item?.memberId?.lName}
                    </td>
                    <td>{item?.guestCount}</td>
                    <td>{item?.memberId?.mNo}</td>
                    <td className="text-center">
                      <ModalButton
                        // className="ico-Box"
                        data={{
                          showCustomModal: true,
                          title: "Note",
                          path: "reservation/show-notes",
                          body: "",
                          data: {
                            ...item,
                            notes: item?.memberId?.notes,
                          },
                          id: item._id,
                          className:
                            "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                        }}
                      >
                        {item?.memberId?.notes ? (
                          <span className="cursor-pointer color-green fs20">
                            <i className="fa fa-file-text"></i>
                          </span>
                        ) : (
                          <span className="cursor-pointer">
                            <i className="fa fa-file-text"></i>
                          </span>
                        )}
                      </ModalButton>
                    </td>
                    <td>
                      {item?.checkInFrom === 1
                        ? "App"
                        : item?.checkInFrom === 2
                        ? "Cms"
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const checkinControllerData = useCheckin();

    return (
      <Component {...props} checkinControllerData={checkinControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(null, { resetPageStore })(injectComp(Checkin));
