import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ModalButton from "../../components/modal/button";
import {
  getPageData,
  fetchSuggestions,
  resetPageStore,
  updatePageData,
} from "../../actions/page";
import { listQpayData, updateQpayData } from "../../actions/qpay";
import { Endpoint } from "../../utils/constants";
import { isObjEmpty } from "../../utils";
import Loader from "../../components/customFields/loader";
import InputField from "../../components/customFields/inputField";
import NoRecord from "../../components/customFields/noRecord";
import { getClubs } from "../../actions/clubs";

class Qtraveller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      isinit: true,
      list: [],
      totalCount: "",
      qsCatList: ["F & B", "Notice Board", "Q PERKS", "Retail"],
      activeClub: "",
      clubs: [],
      req: true,
      isLoader: false,
    };
  }
  componentDidMount() {
    this.props.getClubs();

    if (this?.props?.clubs?.length > 0) {
      this.setState({
        clubs: this.props.clubs,
      });
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  getList = (flag) => {
    // this.props.getPageData({
    //   params: { token: this.props.token },
    //   url: Endpoint.Qtraveller + "?clubid=" + this.state.activeClub,
    // });
    this.setState({ isLoader: false });
    let payload = {};
    let params = {};
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.HOW_TO_T_LISTS}/${this.state.activeClub}`,
        method: "GET",
      },
      (res) => {
        if (res.status) {
          this.setState({ list: res.data, isLoader: true });
        }
      }
    );
  };

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list != prevState.list && prevState.isinit) {
      return {
        list: nextProps.list,
        totalCount: nextProps.count,
        isinit: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updatedData != prevProps.updatedData &&
      !isObjEmpty(this.props.updatedData) &&
      this.props.updatedData.page === "qtreaveller"
    ) {
      let { action, data } = this.props.updatedData;
      this.setState({ category: "" });
      this.getList(false);
      this.props.resetPageStore("updatedData");
    }
    if (prevProps.list != this.props.list) {
      this.setState({ list: this.props.list, totalCount: this.props.count });
    }
    if (
      this.state.clubs.length === 0 &&
      this.props.clubs !== null &&
      this.props.clubs.length !== 0
    ) {
      this.setState({ clubs: this.props.clubs });
    }
    if (
      this.state.activeClub === "" &&
      this.state.clubs.length !== 0 &&
      this.props.activeClub !== null &&
      this.props.activeClub.length !== 0
    ) {
      this.setState({ activeClub: this.props.activeClub[0]._id });
    }

    if (
      prevProps.activeClub !== null &&
      this.props.activeClub !== null &&
      prevProps.activeClub.length !== 0 &&
      this.props.activeClub.length !== 0 &&
      prevProps.activeClub[0]._id !== this.props.activeClub[0]._id
    ) {
      this.setState({ activeClub: this.props.activeClub[0]._id });
    }
    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.getList(true);
    }
  }

  onSelect = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value, isinit: true }, () => {});
  };

  delete = (e, closeModal, element) => {
    e.preventDefault();
    this.props.updateQpayData(
      {
        url: `${Endpoint.HOW_TO_T_DELETE}/${element.id}`,
        method: "DELETE",
      },
      (response) => {
        if (response.status) {
          closeModal();
          this.getList(true);
          this.setState({ error: "" });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  render() {
    let { list = {}, isLoader } = this.state;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundImage: `url(${item ? item.img : ""})`,
      };
      return (
        <div className="card news-card  p-0 h-100 ">
          <div className="card-body border-top p-0">
            <div className="absolute-top-right  card-absolute-header  news-header">
              <div className="d-flex align-items-center">
                <ModalButton
                  className="cursor-pointer"
                  data={{
                    showCustomModal: true,
                    title: "Edit The Q Traveller",
                    path: "Qtraveller/Qtraveller",
                    body: "",
                    className: "auto-width",
                    getList: this.getList,
                    clubid: this.state.activeClub,
                    id: item._id,
                  }}
                >
                  <i className="fa fa-edit" />
                </ModalButton>
                &nbsp;&nbsp;
                <ModalButton
                  className="cursor-pointer"
                  data={{
                    showCustomModal: true,
                    id: item._id,
                    title: "Alert",
                    footer: true,
                    onSubmit: this.delete,
                    submitArg: { id: item._id },
                    body: "Are you sure want to delete it ?",
                    className: "",
                  }}
                >
                  <i className="fa fa-trash" />
                </ModalButton>
              </div>
            </div>
            <div className="image-style" style={imagestyle}>
              <img
                src="../images/imagesize2_3.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">
                <Link to="/static-pages">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;The Q traveller
              </h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              <ModalButton
                className="btn btn-primary btn-primary-green"
                data={{
                  showCustomModal: true,
                  title: "Add The Q Traveller",
                  path: "Qtraveller/Qtraveller",
                  body: "",
                  className: "auto-width",
                  getList: this.getList,
                  clubid: this.state.activeClub,
                }}
              >
                Add new
              </ModalButton>
            </div>
          </div>
          <div className="card bg-transferent h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {!isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {isLoader && list && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {isLoader && list && list.length > 0 && (
                  <div className="row ">
                    {list.map((item, i) => {
                      return (
                        <div key={i} className="col-2  mb-3">
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    fetchSuggestions: (myParam) =>
      dispatch(fetchSuggestions(myParam, "qsCatList")),
    getPageData: (myParam) => dispatch(getPageData(myParam)),
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
    updatePageData: (myParam) => dispatch(updatePageData(myParam)),
    listQpayData: (myParam, cb) => dispatch(listQpayData(myParam, cb)),
    updateQpayData: (myParam, cb) => dispatch(updateQpayData(myParam, cb)),
  };
};
function mapStateToProps({
  auth: { token },
  page: { pageData, updatedData = {}, qsCatList },
  clubs: { activeClub, clubs },
}) {
  return {
    token,
    list: pageData,
    updatedData,
    qsCatList: qsCatList,
    activeClub,
    clubs,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Qtraveller);
