import React, { Component } from "react";
import { connect } from "react-redux";
import InputField from "../customFields/inputField";
import {
  getValue,
  dateToStr,
  handleReservationType,
} from "../../utils/formatter";
import { Endpoint } from "../../utils/constants";
import "../../style/newStyle.scss";
import { Button } from "reactstrap";
import { getClubs } from "../../actions/clubs";
import SearchInput from "../customFields/search-input";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = this.intialiseState();
  }
  intialiseState = (data = {}) => {
    return {
      memberId: getValue(data, "memberId", ""),
      keyword: getValue(data, "keyword", ""),
      chips: getValue(data, "chips", ""),
      date: getValue(data, "date", dateToStr(null, "YYYY-MM-DD")),
      optionList: [],
    };
  };

  componentDidMount() {
    this.props.getClubs();
    if (this?.props?.reservationTypeList) {
      this.setState({
        optionList: handleReservationType(this?.props?.reservationTypeList),
      });
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this?.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  search = (e) => {
    e.preventDefault();
    const { date, memberId, keyword, optionList } = this.state;
    this.props.refreshList(
      date,
      optionList[keyword] ? this?.props?.reservationTypeList[keyword] : {},
      memberId
    );
  };

  setChips = (value, name, item) => {
    if (item && item !== null) {
      this.setState({ memberId: item.id || "", chips: value });
    } else {
      this.setState({ memberId: "", chips: "" });
    }
  };

  setKeyword = (e) => {
    this.setState({ keyword: e.target.value });
  };

  render() {
    const { date, keyword, memberId, optionList } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <InputField
              name="date"
              label="Date"
              value={date}
              inputChange={this.inputChange}
              dom={{
                placeholder: "dd/mm/yyyy",
                type: "date",
                //maxDate: new Date(),
                dateFormat: "dd/MM/yyyy",
              }}
            />
          </div>
          <div className="col-12">
            <InputField
              name="keyword"
              label="Room"
              value={keyword}
              inputChange={this.setKeyword}
              options={optionList}
              dom={{ placeholder: "All Category", type: "select" }}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Member Details</label>
            <SearchInput
              endpoint={`${Endpoint.MEMBERS}${this?.props?.activeClub?.[0]?._id}/`}
              searchKey="searchKeyword"
              accessKey="members"
              name="memberId"
              inputClassName="form_control"
              inputProps={{
                autoFocus: true,
              }}
              placeholder="Search by First Name, Last Name or Member ID"
              onChange={(value, onChangeSearch) => {
                onChangeSearch(
                  `${value?.fName} ${value?.mName} ${value?.lName}`
                );
                this.inputChange({
                  target: { name: "memberId", value: value?._id },
                });
              }}
              onInputChange={(value) =>
                !value &&
                this.inputChange({
                  target: { name: "memberId", value: "" },
                })
              }
              renderItem={(item, i) => {
                return (
                  <React.Fragment key={"auto_" + i}>
                    <div
                      className="image-style"
                      style={{ backgroundImage: `url(${item?.photo})` }}
                    >
                      <img
                        src={"/images/imagesize2_3.png"}
                        alt={item?.fName}
                        className="w100per"
                      />
                    </div>

                    <div className="d-flex justify-content-between flex-grow-1">
                      <span
                        className="user-name"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.fName + " " + item?.mName + " " + item?.lName}
                      </span>
                      {item?.mNo && (
                        <span className="user-name1">{item?.mNo}</span>
                      )}
                    </div>
                  </React.Fragment>
                );
              }}
            />
          </div>
          <div className="col-12 mt-2 text-center">
            <Button
              style={{ borderColor: "transparent" }}
              onClick={this.search}
              className={`btn btn-primary btn-primary-green`}
            >
              Search
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ clubs: { activeClub } }) {
  return { activeClub };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    getClubs: () => dispatch(getClubs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
