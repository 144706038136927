import React, { Component } from "react";
import { connect } from "react-redux";
import { listQpayData } from "../../actions/qpay";
import { Endpoint } from "./../../utils/constants";
import { dateToStr, exportToCsv } from "./../../utils/formatter";
import InputField from "../customFields/inputField";
import moment from "moment";

class QPayReports extends Component {
  constructor(props) {
    super(props);
    this.keymapperMemberBalance = {
      header: [
        "FirstName",
        "LastName",
        "Wallet Balance",
        "Wallet Status",
        "M.No",
        "Email",
        "Phone",
        "Notes",
      ],
      keys: [
        "fName",
        "lName",
        "walletBalance",
        "walletStatus",
        "mNo",
        "email",
        "mobile",
        "note",
      ],
    };
    this.keymapperTransacation = {
      header: [
        "Date",
        "Time",
        "Member's Name",
        "M.No",
        "Spend",
        "From",
        "Email",
        "Phone",
        "Location",
        "Notes",
      ],
      keys: [
        "dateOfTxn",
        "timeOfTxn",
        "memberName",
        "mno",
        "withdraw",
        "sourceOfTxn",
        "email",
        "mobile",
        "location",
        "note",
      ],
    };
    this.keymapperDeposite = {
      header: [
        "Date",
        "Time",
        "Member's Name",
        "M.No",
        "Deposit",
        "Credit",
        "From",
        "Email",
        "Phone",
        "Notes",
      ],
      keys: [
        "dateOfTxn",
        "timeOfTxn",
        "memberName",
        "mno",
        "deposited",
        "credited",
        "sourceOfTxn",
        "email",
        "mobile",
        "note",
      ],
    };
    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      memberBalanceList: [],
      transactionList: [],
      depositeList: [],
      loading1: false,
      loading2: false,
      loading3: false,
    };
  }

  componentDidMount() {
    this.getList();
  }

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  getList = () => {
    this.getMemberBalanceList();
    this.getTransacationList();
    this.getDepositeList();
  };

  getMemberBalanceList = () => {
    let payload = {};
    let params = {
      excel: true,
    };
    this.setState({ loading3: true });
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_WALLEAT_MEMBERS}`,
        method: "GET",
      },
      (res) => {
        res?.data?.length > 0 &&
          res.data.map((res) => {
            res["walletStatus"] =
              res?.walletStatus == 0 ? "Inactive" : "Active";
            res["note"] = res.note ? res.note?.content : "";
          });
        this.setState({ memberBalanceList: res?.data, loading3: false });
      }
    );
  };

  getTransacationList = () => {
    let payload = {};
    let params = {
      type: 2,
      from: this.state.fromDate
        ? moment(this.state.fromDate).format("YYYY/MM/DD")
        : "",
      end: this.state.toDate
        ? moment(this.state.toDate).format("YYYY/MM/DD")
        : "",
    };
    this.setState({ loading1: true });
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_CMS_TXN_LIST}`,
        method: "GET",
      },
      (res) => {
        res?.data?.length > 0 &&
          res.data.map((res) => {
            res["memberName"] =
              res?.memberId?.fName + " " + res?.memberId?.lName;
            res["mno"] = res?.memberId?.mNo;
            res["email"] = res?.memberId?.email;
            res["mobile"] = res?.memberId?.mobile;
            res["note"] = res.note ? res.note?.content : "";
          });
        this.setState({ transactionList: res?.data, loading1: false });
      }
    );
  };

  getDepositeList = () => {
    let payload = {};
    let params = {
      type: 1,
      from: this.state.fromDate
        ? moment(this.state.fromDate).format("YYYY/MM/DD")
        : "",
      end: this.state.toDate
        ? moment(this.state.toDate).format("YYYY/MM/DD")
        : "",
    };
    this.setState({ loading2: true });
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_CMS_TXN_LIST}`,
        method: "GET",
      },
      (res) => {
        res?.data?.length > 0 &&
          res.data.map((res) => {
            res["memberName"] =
              res?.memberId?.fName + " " + res?.memberId?.lName;
            res["mno"] = res?.memberId?.mNo;
            res["email"] = res?.memberId?.email;
            res["mobile"] = res?.memberId?.mobile;
            res["note"] = res.note ? res.note?.content : "";
          });
        this.setState({ depositeList: res?.data, loading2: false });
      }
    );
  };

  render() {
    const {
      fromDate,
      toDate,
      memberBalanceList,
      transactionList,
      depositeList,
    } = this.state;

    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">Q Pay Reports</h3>
            </div>
            <div className="row align-items-center">
              <div
                className="col-auto center-date ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="fromDate"
                  groupClass="mbi-0"
                  value={fromDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "Start Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              <div
                className="col-auto center-date ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="toDate"
                  groupClass="mbi-0"
                  value={toDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "End Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    minDate: new Date(this.state.fromDate),
                  }}
                />
              </div>
              <div className="col-auto ps-0">
                <button
                  onClick={this.getList}
                  disabled={
                    this.state.loading1 ||
                    this.state.loading2 ||
                    this.state.loading3
                  }
                  className="btn btn-primary btn-primary-green me-2"
                >
                  Apply
                </button>
                <button
                  className="btn btn-primary btn-primary-green"
                  onClick={() => {
                    this.setState(
                      {
                        fromDate: new Date(),
                        toDate: new Date(),
                      },
                      () => {
                        this.getList();
                      }
                    );
                  }}
                >
                  <i className="fa fa-repeat"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row margin-top-15">
            <div className="col-3">
              <button
                className="qPayReportBox cursor-pointer d-flex justify-content-center align-items-center"
                disabled={this.state.loading3}
                onClick={() =>
                  exportToCsv(
                    memberBalanceList,
                    "Members_Balance_Data" +
                      moment().format("YYYY-MM-DD_HH.mm"),
                    this.keymapperMemberBalance
                  )
                }
              >
                <div className="val">Members Balance Data</div>
              </button>
            </div>
            <div className="col-3">
              <button
                className="qPayReportBox cursor-pointer d-flex justify-content-center align-items-center"
                disabled={this.state.loading1}
                onClick={() =>
                  exportToCsv(
                    transactionList,
                    "Transaction_Data" + moment().format("YYYY-MM-DD_HH.mm"),
                    this.keymapperTransacation
                  )
                }
              >
                <div className="val">Download Transaction Data</div>
              </button>
            </div>
            <div className="col-3">
              <button
                className="qPayReportBox cursor-pointer d-flex justify-content-center align-items-center"
                disabled={this.state.loading2}
                onClick={() =>
                  exportToCsv(
                    depositeList,
                    "Deposit_Data" + moment().format("YYYY-MM-DD_HH.mm"),
                    this.keymapperDeposite
                  )
                }
              >
                <div className="val">Download Deposit Data</div>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({}) {
  return {};
}
export default connect(mapStateToProps, { listQpayData })(QPayReports);
