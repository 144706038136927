import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../customFields/loader';
import { updatePageData, getIndividualItem, resetPageStore } from '../../actions/page';
import { Endpoint } from '../../utils/constants';

class MemberDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        let { match: { params } } = this.props;
        if (params.mid) {
            this.props.getIndividualItem({
                params: { token: this.props.token },
                url: `${Endpoint.MEMBER}/${params.mid}`
            });
        }
    }

    componentWillUnmount() {
        this.props.resetPageStore('individualItem');
    }

    render() {
        let { itemLoader, itemData } = this.props;
        return (
            <div className='member'>
                <p className='_title mb20'>Member details</p>
                {itemLoader && <Loader />}
                {!itemLoader && <React.Fragment>
                    <div className='row mb15'>
                        <div className='col-4'>
                            <div className='d-flex'>
                                <div className='image-style member-img' style={{ backgroundImage: `url(${itemData.image})` }}>
                                    <img src={'/images/imagesize2_3.png'} className='w100per' />
                                </div>
                                <div>
                                    <p className='color-white fs18 text-capitalize'>{`${itemData.firstName}${itemData.middleName ? " " + itemData.middleName + " " : " "}${itemData.lastName}`}</p>
                                    <p className='fs14 color-green'>M. No.- {itemData.mno}</p>
                                    <p className='color-gray fs14'>{itemData.jobTitle || '--'}</p>
                                    <p className='color-gray fs14'>{itemData.company || '--'}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-8'>
                            <div className='member-notes'>
                                <p className='color-white fs18 text-capitalize'>Notes on Member</p>
                                <p className='color-gray fs14'>{itemData.founderNote || '--'}</p>
                                <p className='color-white fs18 mt10 mb5'>Favourites</p>
                                <ul className='fav-list'>
                                    {itemData.hobby && itemData.hobby.length > 0 ?
                                        itemData.hobby.map((h, i) => {
                                            return <li key={isSecureContext} className='listItem'>{h}</li>
                                        })
                                        : '--'}

                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <div className className='last-order'>
                    <p className='_title mb10'>Last orders</p>
                    <ul className='orderList'>
                        <li className='listItem'>
                            <p className='color-gray fs18 mb5'>26 May, 2019</p>
                            <p className='color-white fs14'>margherita Pizza x1</p>
                            <p className='color-white fs14'>Mushroom Risotto x1</p>
                            <p className='color-white fs14'>Bordeaux wine</p>
                        </li>
                    </ul>
                </div> */}
                </React.Fragment>
                }
            </div>
        );
    }
}
function mapStateToProps({ auth: { token }, page: { individualItem: { data = {} }, itemLoader = false } }) {
    return { token, itemData: data, itemLoader }
}

export default connect(mapStateToProps, { updatePageData, getIndividualItem, resetPageStore })(MemberDetails);
