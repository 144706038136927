import { put, call, takeEvery } from "redux-saga/effects";
import { Endpoint } from "../utils/constants";
import { qPayFetchService } from "../utils/index";
import { setCookie, getCookie } from "../services/session";
import { Q_PAY_UPDATE_USER_DATA, Q_PAY_GENERATE_OTP, Q_PAY_VERIFY_OTP, Q_PAY_GET_TOKEN } from "../actions/constants";

function* qPayGenerateOtp({ data, cb }) {
    try {
        const response = yield call(qPayFetchService, {
            payload: data.payload,
            method: "POST",
            url: Endpoint.QPAY_SEND_OTP,
        });
        cb(response);
    } catch (e) {
        cb({
            errorMsg: e.msg || e,
        });
    }
}

function* qPayVerifyOtp({ data, cb }) {
    try {
        const response = yield call(qPayFetchService, {
            method: "POST",
            payload: data.payload,
            url: Endpoint.QPAY_VERIFY_OTP
        });
        if (response) {
            if (response.status) {
                setCookie("qpay-token", response.accessToken);
                setCookie("qpay-user-id", response.data.id);
                setCookie("qpay-role-id", response.data.roleId._id);
                setCookie("qpay-refresh-token", response.refreshToken);
                setCookie("qpay-menu", response.data);
            }
            yield put({
                type: Q_PAY_UPDATE_USER_DATA,
                data: {
                    isQpayLogin: true,
                    qPayAuthData: response.data,
                    qPayUserId: response.data.id,
                },
            });
        }
        cb(response);
    } catch (e) {
        cb({
            errorMsg: e.msg || e,
        });
    }
}

function* getQPayToken({ }) {
    try {
        const token = getCookie("qpay-token", "");
        yield put({
            type: `REDUCER/${Q_PAY_GET_TOKEN}`,
            data: token,
        });
    } catch (e) {
        yield put({
            type: `REDUCER/${Q_PAY_GET_TOKEN}`,
            data: { token: "", errorMsg: e.msg || e },
        });
    }
}

function* pageWatcher() {
    yield takeEvery("SAGA/" + Q_PAY_GET_TOKEN, getQPayToken);
    yield takeEvery(Q_PAY_GENERATE_OTP, qPayGenerateOtp);
    yield takeEvery(Q_PAY_VERIFY_OTP, qPayVerifyOtp);
}

export default pageWatcher;
