import requireAuth from "./hoc/requireAuth";
import App from "./App";
import Login from "./pages/login";
import MemberDashboard from "./pages/memberDashboard";
import Staffmember from "./components/member/createStaffMember";
import AddMember from "./components/member/createNewMember";
import Events from "./pages/event";
import EventsSettings from "./pages/events-settings";
import MemberDetails from "./components/member/memberDetails";
import Memberlist from "./components/member/memberlist";
import GlobalAccess from "./pages/globalaccess";
import GlobalAccessSettings from "./pages/globalaccess-settings";
import Qspecial from "./pages/Qspecial";
import QperksSettings from "./pages/qperks-settings";
import IndiaofIdeas from "./pages/indiaofideas";
import Qguests from "./pages/Qguest";
import InviteGuests from "./pages/inviteGuests";
import InviteGuestsSummary from "./pages/inviteGuestSummary";
import Roles from "./pages/roles";
import AllRoles from "./pages/all-roles";
import VideoSettings from "./pages/video-settings";
import Home from "./pages/home";
import CheckedinMembers from "./pages/checkedin";
import Reservation from "./pages/reservation";
import Spaces from "./pages/spaces";
import SpacesSettings from "./pages/spaces-settings";
import QPay from "./pages/q-pay";
import StaticDashboard from "./components/staticPages/staticDashboard";
import privacypolicy from "./utils/privacypolicy";
import SetPopNote from "./components/notes/setpopnote";
import SetPushNote from "./components/notes/setpushnote";
import Analytics from "./pages/analytics";
import AnalyticsFilter from "./pages/analytics-filter";
import Qtraveller from "./components/staticPages/q-traveller";
import HowToQ from "./components/staticPages/how-to-q";
import MemberSettings from "./pages/member-settings";
import MemberFilter from "./pages/member-filter";
import Feedback from "./components/staticPages/feedback";

export default [
  {
    component: App,
    routes: [
      {
        component: Login,
        path: "/login",
        exact: true,
      },
      {
        component: requireAuth(MemberDashboard),
        path: "/admin-access",
        page: "Admin Access",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(StaticDashboard),
        path: "/static-pages",
        page: "Admin Access",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Qtraveller),
        path: "/static-pages/the-q-traveller",
        auth: true,
        exact: true,
        page: "Admin Access",
      },
      {
        component: requireAuth(HowToQ),
        path: "/static-pages/how-to-q",
        auth: true,
        exact: true,
        page: "Admin Access",
      },
      {
        component: requireAuth(Feedback),
        path: "/static-pages/feedback",
        auth: true,
        exact: true,
        page: "Admin Access",
      },
      {
        auth: true,
        exact: true,
        path: "/add-member",
        component: requireAuth(AddMember),
        page: "Admin Access",
      },
      {
        path: "/add-staffmember",
        component: requireAuth(Staffmember),
        page: "Admin Access",
      },
      {
        auth: true,
        exact: true,
        path: "/edit-member/:mid",
        component: requireAuth(AddMember),
        page: "Members",
      },
      {
        path: "/edit-staffmember/:mid",
        component: requireAuth(Staffmember),
        page: "Admin Access",
      },
      {
        component: requireAuth(Events),
        path: "/events",
        page: "Events",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(EventsSettings),
        path: "/events-settings",
        page: "Events",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(MemberDetails),
        path: "/member-details/:mid",
        auth: true,
        exact: true,
        page: "Members",
      },
      {
        component: requireAuth(Memberlist),
        path: "/member-list/:mtype",
        page: "Members",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(GlobalAccess),
        path: "/global-access",
        page: "Global Access",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(GlobalAccessSettings),
        path: "/global-access-settings",
        page: "Global Access",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Qspecial),
        path: "/qperks",
        page: "Q Perks",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(QperksSettings),
        path: "/qperks-settings",
        page: "Q Perks",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(IndiaofIdeas),
        path: "/india-of-ideas",
        page: "An India Of Ideas",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Qguests),
        path: "/guest-check-in",
        page: "Guest Check-In",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Roles),
        path: "/roles",
        auth: true,
        page: "Admin Access",
        exact: true,
      },
      {
        component: requireAuth(AllRoles),
        path: "/all-roles",
        auth: true,
        page: "Members",
        exact: true,
      },
      {
        component: requireAuth(VideoSettings),
        path: "/video-settings",
        auth: true,
        page: "An India Of Ideas",
        exact: true,
      },
      {
        component: requireAuth(MemberSettings),
        path: "/member-settings",
        auth: true,
        page: "Members",
        exact: true,
      },
      {
        component: requireAuth(MemberFilter),
        path: "/member-filter",
        auth: true,
        page: "Members",
        exact: true,
      },
      {
        component: requireAuth(InviteGuests),
        path: "/invite-guests/:id?",
        auth: true,
        page: "Guest Check-In",
        exact: true,
      },
      {
        component: InviteGuestsSummary,
        path: "/invite-link/:token",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Home),
        path: "/home",
        page: "Dashboard",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Home),
        path: "/dashboard",
        page: "Dashboard",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Home),
        path: "/",
        page: "Dashboard",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(CheckedinMembers),
        path: "/check-in",
        page: "Check-In",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Reservation),
        path: "/reservations",
        page: "Reservations",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(Spaces),
        path: "/spaces",
        page: "Reservations",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(SpacesSettings),
        path: "/spaces-settings",
        page: "Reservations",
        auth: true,
        exact: true,
      },
      {
        component: requireAuth(QPay),
        path: "/qpay",
        auth: true,
        exact: true,
        page: "Q Pay",
      },
      {
        component: privacypolicy,
        path: "/privacy-policy",
        auth: false,
        exact: true,
      },
      {
        component: requireAuth(SetPopNote),
        path: "/pop-notification",
        auth: true,
        exact: true,
        page: "Admin Access",
      },
      {
        component: requireAuth(SetPushNote),
        path: "/push-notification",
        auth: true,
        exact: true,
        page: "Admin Access",
      },
      {
        auth: true,
        exact: true,
        path: "/analytics/:type",
        component: requireAuth(Analytics),
        page: "Admin Access",
      },
      {
        auth: true,
        exact: true,
        path: "/analytics",
        component: requireAuth(Analytics),
        page: "Admin Access",
      },
      {
        auth: true,
        exact: true,
        path: "/analytics-filter",
        component: requireAuth(AnalyticsFilter),
        page: "Admin Access",
      },
    ],
  },
];
