import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useStaff from "../controller/staff/staff-controller";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import { toast } from "react-toastify";

class AllRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRolesData: [],
      isLoader: false,
      isUpdate: false,
    };
  }

  componentDidMount() {
    this.handleGetAllRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.staffControllerData?.allRolesData !==
      this?.props?.staffControllerData?.allRolesData
    ) {
      this.setState({
        allRolesData: this?.props?.staffControllerData?.allRolesData,
      });
    }
    if (
      prevProps?.staffControllerData?.isLoading !==
      this?.props?.staffControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.staffControllerData?.isLoading,
      });
    }
    if (
      prevProps?.staffControllerData?.isUpdating !==
      this?.props?.staffControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.staffControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.staffControllerData?.succMsg !==
      this?.props?.staffControllerData?.succMsg
    ) {
      if (this?.props?.staffControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
        });
        // window.location.reload(false);
        this.handleGetAllRoles();
        toast.success(this?.props?.staffControllerData?.succMsg);
      }
    }
    if (
      prevProps?.staffControllerData?.errMsg !==
      this?.props?.staffControllerData?.errMsg
    ) {
      if (this?.props?.staffControllerData?.errMsg) {
        this.setState({
          error: this?.props?.staffControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.staffControllerData?.errMsg);
      }
    }
  }

  handleGetAllRoles = () => {
    const { getAllRoles } = this?.props?.staffControllerData;
    getAllRoles();
  };

  handleSwitchChange = (e, data) => {
    const { updateRole } = this?.props?.staffControllerData;
    updateRole({ _id: data?._id, isActive: !data?.isActive });
  };

  render() {
    let { allRolesData, isLoader, isUpdate } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/member-list/staf-member">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Set All Roles
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th style={{ width: 500 }}>
                        <h4>Staff Category</h4>
                      </th>
                      <th>
                        <ModalButton
                          data={{
                            showCustomModal: true,
                            title: "Add New Role",
                            path: "Roles/addRoles",
                            body: "",
                            className: "modal-small",
                            handleGetAllRoles: this.handleGetAllRoles,
                          }}
                        >
                          <h3
                            style={{
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoader && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {!isLoader && allRolesData?.length === 0 && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <NoRecord />
                      </div>
                    )}
                    {allRolesData &&
                      allRolesData.length > 0 &&
                      allRolesData?.map((role) => {
                        return (
                          <tr key={role._id}>
                            <td>{role?.roleName}</td>
                            <td className="d-flex align-items-center">
                              <ModalButton
                                data={{
                                  showCustomModal: true,
                                  title: `Update Role For ${role.roleName}`,
                                  roleId: role?._id,
                                  path: "Roles/editRoles",
                                  body: "",
                                  className: "modal-extra-large",
                                }}
                              >
                                <h3
                                  style={{
                                    marginRight: 10,
                                    marginBottom: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-edit"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                              <InputField
                                value={role.isActive}
                                checked={role.isActive}
                                inputChange={(e) =>
                                  isUpdate
                                    ? {}
                                    : this.handleSwitchChange(e, role)
                                }
                                dom={{
                                  type: "checkbox",
                                  ele: "switch",
                                  label: "rte",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ page: { isLoader = false } }) {
  return { isLoader };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staffControllerData = useStaff();
    return <Component {...props} staffControllerData={staffControllerData} />;
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {})(injectComp(AllRoles));
