import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetPageStore, updatePageData } from "../../actions/page";
import ModalButton from "../modal/button";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import useStaticPage from "../../controller/static-page/static-page-controller";
// import InputField from "../customFields/inputField";
import { Button } from "reactstrap";
import moment from "moment";
import { exportToCsv } from "../../utils/formatter";
import { toast } from "react-toastify";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.keymapperFeedback = {
      header: ["Name", "M No.", "Phone", "Email", "Feedback", "Sent On"],
      keys: ["fName", "mNo", "mobile", "email", "feedback", "created"],
    };
    this.state = {
      list: [],
      activeClub: "",
      isLoader: false,
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    if (this?.props?.activeClub?.[0]?._id || this.state.activeClub)
      this.getList();

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  getList = () => {
    const { getAllFeedback } = this?.props?.staticPageControllerData;
    getAllFeedback(this.state.activeClub || this?.props?.activeClub?.[0]?._id);
  };

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this?.state?.activeClub === "" &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
    if (prevProps?.activeClub?.[0]?._id !== this?.props?.activeClub?.[0]?._id) {
      this.setState({
        activeClub: this?.props?.activeClub?.[0]?._id,
      });
    }
    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.getList();
    }
    if (
      prevProps?.staticPageControllerData?.allFeedback !==
      this?.props?.staticPageControllerData?.allFeedback
    ) {
      this.setState({
        list: this?.props?.staticPageControllerData?.allFeedback,
      });
    }
    if (
      prevProps?.staticPageControllerData?.isLoading !==
      this?.props?.staticPageControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.staticPageControllerData?.isLoading,
      });
    }

    if (
      prevProps?.staticPageControllerData?.errMsg !==
      this?.props?.staticPageControllerData?.errMsg
    ) {
      if (this?.props?.staticPageControllerData?.errMsg) {
        toast.error(this?.props?.pushNotiControllerData?.errMsg);
      }
    }

    if (
      prevProps?.staticPageControllerData?.succMsg !==
      this?.props?.staticPageControllerData?.succMsg
    ) {
      if (this?.props?.staticPageControllerData?.succMsg) {
        toast.success(this?.props?.pushNotiControllerData?.succMsg);
      }
    }
  }

  render() {
    let { list = [], isLoader /*, startDate, endDate*/ } = this.state;
    const { permissions = {} } = this.props;

    const getCard = (item, i) => {
      return (
        <div className="card news-card p-0 h-100 ">
          <div className="card-body p-4">
            <div className="row">
              <div className="col-5">
                <p style={{ color: "rgb(108, 117, 125)" }}>
                  {moment.unix(item?.created).format("DD MMM, YYYY hh:mm")}
                </p>
                <p style={{ color: "rgb(108, 117, 125)" }}>
                  Name:{" "}
                  <span style={{ color: "#0d3231" }}>
                    {item?.memberId?.fName}
                  </span>
                </p>
                <p style={{ color: "rgb(108, 117, 125)" }}>
                  M No:{" "}
                  <span style={{ color: "#0d3231" }}>
                    {item?.memberId?.mNo}
                  </span>
                </p>
                <p style={{ color: "rgb(108, 117, 125)" }}>
                  Ph:{" "}
                  <span style={{ color: "#0d3231" }}>
                    {item?.memberId?.mobile}
                  </span>
                </p>
                <p style={{ color: "rgb(108, 117, 125)" }}>
                  E:{" "}
                  <span style={{ color: "#0d3231" }}>
                    {item?.memberId?.email}
                  </span>
                </p>
              </div>
              <div className="col-7">
                <p style={{ color: "rgb(108, 117, 125)" }}>Feedback</p>
                <p style={{ color: "#0d3231" }}>{item?.feedback}</p>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">
                <Link to="/static-pages">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;View Feedback
              </h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              {/* &nbsp;
              <div style={{ width: 130 }}>
                <InputField
                  name="date"
                  groupClass="mb-0"
                  value={startDate}
                  inputChange={(e) =>
                    this.setState({ startDate: e.target.value })
                  }
                  dom={{
                    placeholder: "Select Start Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    maxDate: new Date(),
                  }}
                />
              </div>
              &nbsp;
              <div style={{ width: 130 }}>
                <InputField
                  name="date"
                  groupClass="mbi-0"
                  value={endDate}
                  inputChange={(e) =>
                    this.setState({ endDate: e.target.value })
                  }
                  dom={{
                    placeholder: "Select End Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    maxDate: new Date(),
                  }}
                />
              </div>
              &nbsp;
              <Button
                className="btn btn-primary btn-primary-green"
                onClick={() => {}}
              >
                Apply
              </Button>
              &nbsp;
              <Button
                className="btn btn-primary btn-primary-green"
                onClick={() => {}}
              >
                <i className="fa fa-repeat"></i>
              </Button> */}
              &nbsp;
              <Button
                className="btn btn-primary btn-primary-green"
                onClick={() => {
                  const arr =
                    list?.length > 0
                      ? list?.map((dt) => ({
                          ...dt,
                          ...dt?.memberId,
                          created: moment
                            .unix(dt?.created)
                            .format("DD MMM, YYYY hh:mm"),
                        }))
                      : [];
                  exportToCsv(
                    arr,
                    "Feedback" + moment().format("YYYY-MM-DD_HH.mm"),
                    this.keymapperFeedback
                  );
                }}
              >
                <i className="fa fa-download"></i>
              </Button>
              &nbsp;
              {permissions?.create ? (
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    showCustomModal: true,
                    title: "Feedback Emails",
                    path: "Feedback/FeedbackEmail",
                    body: "",
                    className: "auto-width",
                    permissions: permissions,
                    clubid: this.state.activeClub,
                  }}
                >
                  <i className="fa fa-envelope"></i>
                </ModalButton>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && list && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {!isLoader && list && list.length > 0 && (
                  <div className="row">
                    {list.map((item, i) => {
                      return (
                        <div key={i} className="col-12 mb-3 mr-2">
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    updatePageData: (myParam) => dispatch(updatePageData(myParam)),
  };
};

function mapStateToProps({ clubs: { activeClub } }) {
  return {
    activeClub,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staticPageControllerData = useStaticPage();

    return (
      <Component
        {...props}
        staticPageControllerData={staticPageControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(Feedback));
