import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import { getClubs } from "../actions/clubs";
import useReservation from "../controller/reservation/reservation-controller";
import { toast } from "react-toastify";

class SpacesSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isUpdate: false,
      isInit: true,
      activeClub: "",
      emailsList: [],
      spacesList: [],
      categoryList: [],
    };
  }

  componentDidMount() {
    this.props.getClubs();
    this.handleGetAllResCategory();

    if (this?.props?.activeClub?.[0]?._id) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      this.handleGetAllEmails(this?.props?.activeClub?.[0]?._id);
      this.handleGetAllSpaces(this?.props?.activeClub?.[0]?._id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this?.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.handleGetAllEmails(this?.state?.activeClub);
      this.handleGetAllSpaces(this?.state?.activeClub);
    }

    if (
      prevProps?.reservationControllerData?.isLoading !==
      this?.props?.reservationControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.reservationControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        (prevProps?.reservationControllerData?.allEmails?.length !== 0 ||
          prevProps?.reservationControllerData?.allSpaces?.length !== 0)
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.reservationControllerData?.isUpdating !==
      this?.props?.reservationControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.reservationControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.reservationControllerData?.succMsg !==
      this?.props?.reservationControllerData?.succMsg
    ) {
      if (this?.props?.reservationControllerData?.succMsg) {
        const {} = this?.props?.reservationControllerData;
        this.setState({
          error: "",
        });
        this.handleGetAllEmails(
          this?.props?.activeClub?.[0]?._id || this?.state?.activeClub
        );
        this.handleGetAllSpaces(
          this?.props?.activeClub?.[0]?._id || this?.state?.activeClub
        );
        this.handleGetAllResCategory();
        toast.success(this?.props?.reservationControllerData?.succMsg);
      }
    }
    if (
      prevProps?.reservationControllerData?.errMsg !==
      this?.props?.reservationControllerData?.errMsg
    ) {
      if (this?.props?.reservationControllerData?.errMsg) {
        this.setState({
          error: this?.props?.reservationControllerData?.errMsg,
        });
        toast.error(this?.props?.reservationControllerData?.errMsg);
      }
    }

    if (
      prevProps?.reservationControllerData?.allEmails !==
      this?.props?.reservationControllerData?.allEmails
    ) {
      const arrData = this?.props?.reservationControllerData?.allEmails;
      this.setState({
        emailsList: arrData,
      });
    }

    if (
      prevProps?.reservationControllerData?.allSpaces !==
      this?.props?.reservationControllerData?.allSpaces
    ) {
      const arrData = this?.props?.reservationControllerData?.allSpaces;
      this.setState({
        spacesList: arrData,
      });
    }

    if (
      prevProps?.reservationControllerData?.allResCat !==
      this?.props?.reservationControllerData?.allResCat
    ) {
      const arrData = this?.props?.reservationControllerData?.allResCat;
      this.setState({
        categoryList: arrData,
      });
    }
  }

  handleGetAllEmails = (id) => {
    if (id) {
      const { getAllEmails } = this?.props?.reservationControllerData;
      getAllEmails(id);
    }
  };

  handleGetAllSpaces = (id) => {
    if (id) {
      const { getAllPHSpaces } = this?.props?.reservationControllerData;
      getAllPHSpaces(id);
    }
  };

  handleGetAllResCategory = () => {
    const { getAllResCategory } = this?.props?.reservationControllerData;
    getAllResCategory();
  };

  handleSwitchChange = (data, type) => {
    const {
      updateResCategory,
      updateEmail,
      updatePHSpace,
    } = this?.props?.reservationControllerData;

    type === "category"
      ? updateResCategory({ _id: data?._id, isActive: !data?.isActive })
      : type === "spaces"
      ? updatePHSpace({ _id: data?._id, isActive: !data?.isActive })
      : updateEmail({ _id: data?._id, isActive: !data?.isActive });
  };

  render() {
    let {
      isLoader,
      isUpdate,
      isInit,
      emailsList,
      spacesList,
      categoryList,
      activeClub,
    } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/spaces">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Spaces Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                {isInit && isLoader && (
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <Loader />
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <h4 className="col-8">Space Categories</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            activeClub: activeClub,
                            getCatList: this.handleGetAllResCategory,
                            showCustomModal: true,
                            title: "Add Category",
                            path: "SpacesSetting/addCategory",
                            body: "",
                            className: "modal-small",
                          }}
                          disabled={isUpdate}
                        >
                          <h3
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: isUpdate ? "default" : "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {!isLoader && categoryList?.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center position-relative">
                        <NoRecord />
                      </div>
                    )}
                    {categoryList && categoryList?.length > 0
                      ? categoryList?.map((cat, i) => (
                          <div className="row cursor-pointer" key={i}>
                            <div className="col-8 mb-3">
                              <p>{cat?.title}</p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <ModalButton
                                  data={{
                                    itemData: cat,
                                    getCatList: this.handleGetAllResCategory,
                                    showCustomModal: true,
                                    title: "Edit Category",
                                    path: "SpacesSetting/addCategory",
                                    body: "",
                                    className: "modal-small",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginRight: 10,
                                      marginBottom: 0,
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                                <InputField
                                  value={cat.isActive}
                                  checked={cat.isActive}
                                  inputChange={() =>
                                    isUpdate
                                      ? {}
                                      : this.handleSwitchChange(cat, "category")
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                    <div className="row mt-3">
                      <h4 className="col-8">Private Hire Email Ids</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            activeClub: activeClub,
                            getEmailsList: this.handleGetAllEmails,
                            showCustomModal: true,
                            title: "Add Email",
                            path: "SpacesSetting/addEmail",
                            body: "",
                            className: "modal-small",
                          }}
                          disabled={isUpdate}
                        >
                          <h3
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: isUpdate ? "default" : "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {!isLoader && emailsList?.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center position-relative">
                        <NoRecord />
                      </div>
                    )}
                    {emailsList && emailsList?.length > 0
                      ? emailsList?.map((em, i) => (
                          <div className="row cursor-pointer" key={i}>
                            <div className="col-8 mb-3">
                              <p>{em?.email}</p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <InputField
                                  value={em.isActive}
                                  checked={em.isActive}
                                  inputChange={() =>
                                    isUpdate
                                      ? {}
                                      : this.handleSwitchChange(em, "email")
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <h4 className="col-8">Private Hire Spaces</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            activeClub: activeClub,
                            getSpacesList: this.handleGetAllSpaces,
                            showCustomModal: true,
                            title: "Add Space",
                            path: "SpacesSetting/addSpace",
                            body: "",
                            className: "modal-extra-large",
                          }}
                          disabled={isUpdate}
                        >
                          <h3
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: isUpdate ? "default" : "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {!isLoader && spacesList?.length === 0 && (
                      <div className="d-flex h-100 justify-content-center align-items-center position-relative">
                        <NoRecord />
                      </div>
                    )}
                    {spacesList &&
                      spacesList.length > 0 &&
                      spacesList?.map((space) => {
                        return (
                          <div className="row" key={space._id}>
                            <div className="col-8 mb-3 d-flex align-items-center">
                              <img
                                src={space?.image}
                                alt="space-img"
                                width={70}
                              />
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  marginLeft: 10,
                                }}
                              >
                                {space?.name}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <ModalButton
                                  data={{
                                    itemData: space,
                                    activeClub: activeClub,
                                    getSpacesList: this.handleGetAllSpaces,
                                    showCustomModal: true,
                                    title: "Edit Space",
                                    path: "SpacesSetting/addSpace",
                                    body: "",
                                    className: "modal-extra-large",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginRight: 10,
                                      marginBottom: 0,
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                                <InputField
                                  value={space.isActive}
                                  checked={space.isActive}
                                  inputChange={() =>
                                    isUpdate
                                      ? {}
                                      : this.handleSwitchChange(space, "spaces")
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ clubs: { activeClub } }) {
  return {
    activeClub: activeClub,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const reservationControllerData = useReservation();
    return (
      <Component
        {...props}
        reservationControllerData={reservationControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, { getClubs })(
  injectComp(SpacesSettings)
);
