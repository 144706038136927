import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { Endpoint, Messages } from "../utils/constants";
import {
  USER_LOGIN,
  GENERATE_OTP,
  VERIFY_OTP,
  CHECK_AUTH,
  USER_LOGOUT,
  UPDATE_USER_DATA,
  RESET_PASSWORD,
  GET_TOKEN,
} from "../actions/constants";
import { fetchService } from "../utils/index";
import { setCookie, getCookie } from "../services/session";
import axiosInstance from "../services/axios-new";

export function* checkAuthorization({ data }) {
  try {
    const response = yield call(fetchService, {
      url: Endpoint.AUTH + "/" + data.token,
      method: "GET",
    });
    if (response.response && response.response.valid) {
      yield put({
        type: UPDATE_USER_DATA,
        data: {
          ...response.response,
          isLogin: response.response.isLogin,
        },
      });
    } else {
      if (response.apiError) {
        yield put({
          type: UPDATE_USER_DATA,
          data: {
            apiError: true,
          },
        });
        return;
      }
      yield put({
        type: UPDATE_USER_DATA,
        data: {
          ...response.response,
          isLogin: false,
        },
      });
      yield put({
        type: `REDUCER/${GET_TOKEN}`,
        data: response.response,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_USER_DATA,
      data: {
        isLogin: null,
        errorMsg: e.message,
      },
    });
  }
}

function* loginSubmit({ data, cb }) {
  try {
    const response = yield call(fetchService, {
      payload: data.payload,
      method: "POST",
      url: Endpoint.USER_LOGIN,
    });
    if (
      response.response.status &&
      response.response.data &&
      response.response.data.userid
    ) {
      yield put({
        type: UPDATE_USER_DATA,
        data: {
          ...response.response.data,
          isLogin: true,
          userId: response.response.data.userid,
        },
      });
    }
    cb(response.response);
  } catch (e) {
    cb({
      errorMsg: e.msg || e,
    });
  }
}

function* generateOtp({ data, cb }) {
  try {
    const response = yield call(fetchService, {
      payload: data.payload,
      method: "POST",
      url: Endpoint.USER_LOGIN,
    });
    cb(response);
  } catch (e) {
    cb({
      errorMsg: e.msg || e,
    });
  }
}

function* verifyOtp({ data, cb }) {
  try {
    const response = yield axiosInstance.post(
      `${Endpoint.AUTH_VERIFY_OTP}`,
      data
    );
    if (response?.data?.status && response?.data?.status !== "false") {
      localStorage.setItem(
        "quorum-refresh-token",
        `${response?.data?.refreshToken}`
      );
      setCookie("quorum-token", `Bearer ${response?.data?.accessToken}`);
      setCookie("userId", response?.data?.userData?.id);

      yield put({
        type: UPDATE_USER_DATA,
        data: {
          userData: response?.data?.userData,
          isLogin: true,
          userId: response?.data?.userData?.id,
        },
      });
      yield put({
        type: `REDUCER/${GET_TOKEN}`,
        data: response?.data?.accessToken,
      });
    }
    cb(response);
  } catch (e) {
    cb({
      errorMsg: e.msg || e,
    });
  }
}

function* getToken({}) {
  try {
    const token = getCookie("quorum-token", "");
    yield put({
      type: `REDUCER/${GET_TOKEN}`,
      data: token,
    });
  } catch (e) {
    yield put({
      type: `REDUCER/${GET_TOKEN}`,
      data: { token: "", errorMsg: e.msg || e },
    });
  }
}

function* resetPassword({ data, cb }) {
  try {
    const response = yield call(fetchService, {
      payload: data,
      method: "POST",
      url: Endpoint.RESET_PASSWORD + ".json",
    });
    cb(response);
  } catch (e) {
    cb({
      errorMsg: e.msg || e,
    });
  }
}

function* logout({ data, cb }) {
  try {
    const response = yield axiosInstance.post(`${Endpoint.AUTH_LOGOUT}`);
    cb(response);
    if (response?.data?.status && response?.data?.status !== "false") {
      yield put({
        type: UPDATE_USER_DATA,
        data: {
          isLogin: false,
        },
      });
    }
  } catch (e) {
    // adding bcz it is setting isLogin true bcz of error.
    console.log("e.message", e.message);
    yield put({
      type: UPDATE_USER_DATA,
      data: {
        isLogin: false,
      },
    });
    ///////
    cb({
      errorMsg: e.message || e,
    });
  }
}

function* pageWatcher() {
  yield takeEvery("SAGA/" + GET_TOKEN, getToken);
  yield takeEvery(USER_LOGIN, loginSubmit);
  yield takeEvery(GENERATE_OTP, generateOtp);
  yield takeEvery(VERIFY_OTP, verifyOtp);
  yield takeEvery("SAGA/" + CHECK_AUTH, checkAuthorization);
  yield takeEvery(USER_LOGOUT, logout);
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default pageWatcher;
