/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import { resetPageStore } from "../actions/page";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useGlobalAccess from "../controller/global-access/global-access-controller";
import { toast } from "react-toastify";

class GlobalAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      continentList: [],
      allReciprocalsList: [],
      activeReciprocalsList: [],
      totalCount: "",
      selected: "",
      selectedItem: {},
      isLoader: false,
    };
  }

  componentDidMount() {
    this.getContinentsList();
  }

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  getContinentsList = () => {
    const { getAllContinents } = this?.props?.globalAccessControllerData;
    getAllContinents();
  };

  getCountriesList = (id) => {
    const { getAllCountries } = this?.props?.globalAccessControllerData;
    getAllCountries(id);
  };

  getReciprocalsList = (id) => {
    const { getAllReciprocals } = this?.props?.globalAccessControllerData;
    getAllReciprocals(id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.globalAccessControllerData?.allContinents !==
      this?.props?.globalAccessControllerData?.allContinents
    ) {
      const arrData =
        this?.props?.globalAccessControllerData?.allContinents?.length > 0
          ? this?.props?.globalAccessControllerData?.allContinents?.filter(
              (dt) => dt.isActive
            )
          : [];
      this.setState({
        continentList: arrData,
        selected: arrData?.[0]?._id,
      });
      this.getCountriesList(arrData?.[0]?._id);
      this.getReciprocalsList(arrData?.[0]?._id);
    }

    if (
      prevProps?.globalAccessControllerData?.allCountries !==
      this?.props?.globalAccessControllerData?.allCountries
    ) {
      const arrData =
        this?.props?.globalAccessControllerData?.allCountries?.length > 0
          ? this?.props?.globalAccessControllerData?.allCountries?.filter(
              (dt) => dt.isActive
            )
          : [];
      this.setState(
        {
          list: arrData,
          totalCount: arrData.length,
        },
        () => {
          if (this?.state?.list?.length) {
            let item = !Object.keys(this?.state?.selectedItem)?.length
              ? this?.state?.list?.[0]
              : this.filterItem(this.state.list, this.state.selectedItem);
            this.selectCity(item, true);
          }
        }
      );
    }

    if (
      prevProps?.globalAccessControllerData?.allReciprocals !==
      this?.props?.globalAccessControllerData?.allReciprocals
    ) {
      this.setState({
        allReciprocalsList: this?.props?.globalAccessControllerData
          ?.allReciprocals,
      });
    }

    if (
      prevProps?.globalAccessControllerData?.isLoading !==
      this?.props?.globalAccessControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.globalAccessControllerData?.isLoading,
      });
    }

    if (
      prevProps?.globalAccessControllerData?.errMsg !==
      this?.props?.globalAccessControllerData?.errMsg
    ) {
      if (this?.props?.globalAccessControllerData?.errMsg) {
        toast.error(this?.props?.globalAccessControllerData?.errMsg);
      }
    }

    if (
      prevProps?.globalAccessControllerData?.succMsg !==
      this?.props?.globalAccessControllerData?.succMsg
    ) {
      if (this?.props?.globalAccessControllerData?.succMsg) {
        toast.success(this?.props?.globalAccessControllerData?.succMsg);
      }
    }

    if (
      prevState?.selectedItem !== this?.state?.selectedItem ||
      prevState?.allReciprocalsList !== this?.state?.allReciprocalsList
    ) {
      const arrData =
        this?.state?.allReciprocalsList?.length > 0
          ? this?.state?.allReciprocalsList?.filter(
              (dt) => this?.state?.selectedItem?._id === dt?.countryId?._id
            )
          : [];

      this.setState({
        activeReciprocalsList: arrData,
      });
    }
  }

  select = (cid) => {
    this.setState(
      { selected: cid, selectedItem: {}, activeReciprocalsList: [] },
      () => {
        this.getCountriesList(this.state.selected);
        this.getReciprocalsList(this.state.selected);
      }
    );
  };

  filterItem = (list, item) => {
    let f = list.filter((c, i) => {
      return item._id === c._id;
    });
    return f.length ? f[0] : list[0];
  };

  selectCity = (item, isReload = false) => {
    if (item.name === this.state.selectedItem.name && !isReload) {
      return;
    }
    this.setState({ selectedItem: item });
  };

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      return this.props.history.push("/global-access-settings");
    }
  };

  render() {
    let {
      list,
      selected,
      selectedItem,
      continentList,
      activeReciprocalsList,
      isLoader,
    } = this.state;
    let { permissions = {}, authData = {} } = this.props;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundSize: "contain",
        backgroundImage: `url(${
          item && item.logos && typeof item.logos[0] != "undefined"
            ? item.logos[0]
            : ""
        })`,
      };
      return (
        <div className="card news-card  p-0 h-100 ">
          <div className="card-header border-0 ps-2 pe-2 pt-2">
            <div className="col-12">
              <div className="default-font page-title-box">{item.clubName}</div>
            </div>
          </div>
          <div className="card-body p-0">
            {permissions?.update || permissions?.delete ? (
              <div className="absolute-top-right card-absolute-header news-header">
                <div className="d-flex align-items-center">
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      itemData: item,
                      countriesList: list,
                      continentList: continentList,
                      permissions: permissions,
                      getContinentsList: this.getContinentsList,
                      showCustomModal: true,
                      title: "Edit Club",
                      id: item._id,
                      path: "globalAccess/globalAccess",
                      body: "",
                      className: "modal-extra-large",
                    }}
                  >
                    <i className="fa fa-edit" />
                  </ModalButton>
                </div>
              </div>
            ) : null}
            <div className="image-style" style={imagestyle}>
              <img
                src="./images/imagesize16_9.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">Global Access</h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              {authData?.roleId?.roleName === "Developer" ? (
                <div onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp;&nbsp;
              {permissions?.create ? (
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    countriesList: list,
                    continentList: continentList,
                    permissions: permissions,
                    getContinentsList: this.getContinentsList,
                    showCustomModal: true,
                    title: "Add New Club",
                    path: "globalAccess/globalAccess",
                    body: "",
                    className: "modal-extra-large",
                  }}
                >
                  Add New
                </ModalButton>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent  h-100">
            <div className="row">
              <div className="col-4">
                <div className="reservtion-tab row">
                  <div className="col-6">
                    <ul
                      className="list-unstyled components"
                      style={{ border: "none" }}
                    >
                      {continentList &&
                        continentList.length > 0 &&
                        continentList.map((c, i) => {
                          return (
                            <li
                              key={i}
                              className={selected === c?._id ? "active" : ""}
                              onClick={() => this.select(c?._id)}
                            >
                              <p style={{ textTransform: "capitalize" }}>
                                {c?.name}
                              </p>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="col-6 overflow-card overflow-auto">
                    {isLoader && <Loader />}
                    {!isLoader && list.length === 0 && <NoRecord />}
                    <ul
                      className="list-unstyled components"
                      style={{ border: "none" }}
                    >
                      {!isLoader &&
                        list &&
                        list.length > 0 &&
                        list.map((item, i) => {
                          return (
                            <li
                              className={
                                selectedItem.name === item.name ? "active" : ""
                              }
                              key={i}
                              onClick={() => this.selectCity(item)}
                            >
                              <p>{item.name}</p>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-8 overflow-card overflow-auto">
                <div className=" py-0">
                  <div className="row ">
                    {!isLoader &&
                      list &&
                      list.length > 0 &&
                      activeReciprocalsList &&
                      activeReciprocalsList.length > 0 &&
                      activeReciprocalsList.map((item, i) => {
                        return (
                          <div key={i} className="col-4 mb-3">
                            {getCard(item, i)}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
  };
};

function mapStateToProps({ auth: { authData = {} } }) {
  return {
    authData,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const globalAccessControllerData = useGlobalAccess();
    return (
      <Component
        {...props}
        globalAccessControllerData={globalAccessControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(GlobalAccess));
