import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useStaticPage = () => {
  const [clubsData, setClubsData] = useState([]);
  const [allFeedback, setAllFeedback] = useState([]);
  const [allFeedbackEmail, setAllFeedbackEmail] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // const getStaticTiming = async (id) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `${Endpoint.STATIC_TIMINGS}${id}`
  //     );
  //     setStaffData(response?.data);
  //   } catch (error) {
  //     console.log("error--", error);
  //   }
  // };

  const updateStaticTiming = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_TIMINGS}/${data?._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  // const getStaticRules = async (id) => {
  //   try {
  //     const response = await axiosInstance.get(`${Endpoint.STATIC_RULES}${id}`);
  //     setStaffData(response?.data);
  //   } catch (error) {
  //     console.log("error--", error);
  //   }
  // };

  const updateStaticRules = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_RULES}/${data?._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  // const getStaticPolicy = async (id) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `${Endpoint.STATIC_POLICY}${id}`
  //     );
  //     setStaffData(response?.data);
  //   } catch (error) {
  //     console.log("error--", error);
  //   }
  // };

  const updateStaticPolicy = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_POLICY}/${data?._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getStaticNoticeBoard = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.STATIC_NOTICE_BOARD}/${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setClubsData(response?.data?.clubs)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addStaticNoticeBoard = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_NOTICE_BOARD}/${data?.clubId}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateStaticNoticeBoard = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.STATIC_NOTICE_BOARD}/${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const deleteStaticNoticeBoard = async (id, cb) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(
        `${Endpoint.STATIC_NOTICE_BOARD}/${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllFeedback = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.STATIC_FEEDBACK}/${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllFeedback(response?.data?.feedbacks)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addFeedback = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_FEEDBACK}/${data?.clubId}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAllFeedbackEmail = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.STATIC_FEEDBACK_EMAIL}/${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllFeedbackEmail(response?.data?.emailIDs)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addFeedbackEmail = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STATIC_FEEDBACK_EMAIL}/${data?.clubId}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateFeedbackEmail = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.STATIC_FEEDBACK_EMAIL}/${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    clubsData,
    allFeedback,
    allFeedbackEmail,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    // getStaticTiming,
    updateStaticTiming,
    // getStaticRules,
    updateStaticRules,
    // getStaticPolicy,
    updateStaticPolicy,
    getStaticNoticeBoard,
    addStaticNoticeBoard,
    updateStaticNoticeBoard,
    deleteStaticNoticeBoard,
    getAllFeedback,
    addFeedback,
    getAllFeedbackEmail,
    addFeedbackEmail,
    updateFeedbackEmail,
  };
};

export default useStaticPage;
