import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useEvents = () => {
  const [allEventsData, setAllEventsData] = useState([]);
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllEvents = async (cid) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.EVENTS}${cid}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setAllEventsData(response?.data?.events)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addEvent = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.EVENTS}${data?.cid}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateEvent = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.EVENTS}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const updateRsvp = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.EVENTS}${Endpoint.RSVP}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const deleteEvent = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(`${Endpoint.EVENTS}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllCategories = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.EVENTS}${Endpoint.CATEGORIES}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllCategoriesData(response?.data?.categories)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addCategory = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.EVENTS}${Endpoint.CATEGORY}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateCategory = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.EVENTS}${Endpoint.CATEGORY}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  return {
    allEventsData,
    allCategoriesData,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllEvents,
    addEvent,
    updateEvent,
    updateRsvp,
    deleteEvent,
    getAllCategories,
    addCategory,
    updateCategory,
  };
};

export default useEvents;
