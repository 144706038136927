/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPageStore } from "../actions/page";
import { Endpoint } from "../utils/constants";
import { dateToStr, exportToCsv, isToday } from "../utils/formatter";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import InputField from "../components/customFields/inputField";
import ModalButton from "../components/modal/button";
import moment from "moment";
import "../style/newStyle.scss";
import { Button } from "reactstrap";
import { getClubs } from "../actions/clubs";
import useCheckin from "../controller/checkin/checkin-controller";
import SearchInput from "../components/customFields/search-input";
import { toast } from "react-toastify";

class CheckedinMembers extends Component {
  constructor(props) {
    super(props);
    this.keymapper = {
      header: [
        "Check-in Time",
        "Member Name",
        "Member Id",
        "Guests",
        "From",
        "Notes",
      ],
      keys: [
        ["date", "time"],
        "memberName",
        "memberId",
        "guestCount",
        "checkInFrom",
        "notes",
      ],
      mapper: {
        checkInFrom: { 1: "App", 2: "Cms" },
      },
    };
    this.maxGuest = 10;
    this.state = {
      date: dateToStr(null, "YYYY-MM-DD"),
      list: [],
      memberData: {},
      guestCount: "",
      isSaving: false,
      editId: "",
      activeClub: "",
      request: true,
      isLoader: false,
      fromDate: new Date(),
      toDate: new Date(),
    };
  }

  componentDidMount() {
    this.props.getClubs();

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.checkinControllerData?.allMemberCheckinData !==
      this?.props?.checkinControllerData?.allMemberCheckinData
    ) {
      this.setState({
        list: this?.props?.checkinControllerData?.allMemberCheckinData,
      });
    }

    if (
      prevProps?.checkinControllerData?.isLoading !==
      this?.props?.checkinControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.checkinControllerData?.isLoading,
      });
    }

    if (
      prevProps?.checkinControllerData?.succMsg !==
      this?.props?.checkinControllerData?.succMsg
    ) {
      if (this?.props?.checkinControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
          guestCount: "",
          editId: "",
          memberData: {},
        });
        this.getList();
        this.setState((state) => {
          return {
            ...state,
            memberData: {},
            guestCount: "",
            isSaving: false,
            editId: "",
            activeClub: "",
            request: true,
            isLoader: false,
            reset: !state.reset,
          };
        });
        // window.location.reload();
        // this.props.toastmessage(response.message);
        toast.success(this?.props?.checkinControllerData?.succMsg);
      }
    }

    if (
      prevProps?.checkinControllerData?.errMsg !==
      this?.props?.checkinControllerData?.errMsg
    ) {
      if (this?.props?.checkinControllerData?.errMsg) {
        this.setState({
          error: this?.props?.checkinControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.checkinControllerData?.errMsg);
      }
    }

    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      if (
        prevProps !== this.props &&
        prevProps?.activeClub !== null &&
        prevProps?.activeClub?.length !== 0 &&
        this?.props?.activeClub !== null &&
        this?.props?.activeClub?.length !== 0
      ) {
        this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      }
      if (
        this.state.activeClub !== "" &&
        prevState.activeClub !== this.state.activeClub
      ) {
        this.getList();
      }
    }
    if (
      this?.props?.adminType !== "Super Admin" &&
      this?.props?.adminType !== "Admin" &&
      this?.props?.adminType !== "Developer"
    ) {
      if (
        prevProps !== this.props &&
        this.props.activeClub !== null &&
        this.props.activeClub.length !== 0
      ) {
        this.setState({ activeClub: this.props.activeClub[0]._id });
      }

      if (this.state.activeClub !== "" && this.state.request) {
        this.getList();
        this.setState({ request: false });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  getList = () => {
    if (this?.props?.activeClub?.[0]?._id) {
      const { getAllMemberCheckin } = this.props.checkinControllerData;
      const url = `${this?.props?.activeClub?.[0]?._id}?startDateTime=${
        this.state.fromDate
          ? moment(this.state.fromDate)
              .startOf("D")
              .format("X")
          : ""
      }&endDateTime=${
        this.state.toDate
          ? moment(this.state.toDate)
              .endOf("D")
              .format("X")
          : ""
      }`;

      getAllMemberCheckin(url);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps?.checkinControllerData?.allMemberCheckinData != prevState.list
    ) {
      return {
        list: nextProps?.checkinControllerData?.allMemberCheckinData,
      };
    }
    return null;
  }

  inputChange = (e, id) => {
    let {
      target: { name, value },
    } = e;
    if (name === "guestCount" && value && value > this.maxGuest) {
      this.setState({ error: `Maximum ${this.maxGuest} guests are allowed.` });
      return;
    }
    this.setState({ [name]: value, error: "" });
  };

  onSubmit = (e) => {
    const { permissions = {} } = this?.props;
    e.preventDefault();
    const {
      addMemberCheckin,
      updateMemberCheckin,
    } = this.props.checkinControllerData;
    let { guestCount, editId, memberData } = this.state;
    if (!memberData?._id) {
      this.setState({ error: "No member Selected" });
      return;
    }
    this.setState({ isSaving: true });

    let payload = {
      guestCount: `${guestCount ? guestCount : 0}`,
    };
    if (!editId) {
      payload = {
        ...payload,
        memberId: memberData?._id,
        checkInFrom: 2,
        cid: this.state.activeClub,
      };
    } else {
      payload = { ...payload, _id: editId };
    }

    !editId && permissions?.create && addMemberCheckin(payload);
    editId && permissions?.update && updateMemberCheckin(payload);
  };

  editData = (e, item = {}) => {
    this.setState({
      memberData: item?.memberId
        ? {
            name: `${item?.memberId?.fName} ${item?.memberId?.mName} ${item?.memberId?.lName}`,
            _id: item?.memberId?._id,
            mNo: item?.memberId?.mNo,
            photo: item?.photo,
          }
        : {},
      guestCount: item?.guestCount || 0,
      editId: item?._id || "",
    });
  };

  delete = (e, closeModal, item) => {
    e.preventDefault();
    const { deleteMemberCheckin } = this.props.checkinControllerData;
    deleteMemberCheckin(item.id, (response) => {
      if (response?.data?.status && response?.data?.status !== "false") {
        closeModal();
      }
    });
  };

  render() {
    let {
      list,
      memberData,
      guestCount,
      error,
      isSaving,
      date,
      editId,
      isLoader,
      fromDate,
      toDate,
    } = this.state;
    const { permissions = {} } = this?.props;

    if (Array.isArray(list) && list.length > 0 && list[0].time) {
      list.sort((a, b) => b.time.localeCompare(a.time));
    }

    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">Check In</h3>
            </div>
            <div className="row align-items-center checkin-date-filter">
              <div
                className="col-auto center-date ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="fromDate"
                  groupClass="mbi-0"
                  value={fromDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "Start Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              <div
                className="col-auto center-date ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="toDate"
                  groupClass="mbi-0"
                  value={toDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "End Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    minDate: new Date(this.state.fromDate),
                  }}
                />
              </div>
              <div className="col-auto ps-0">
                <button
                  onClick={(e) => {
                    this.editData(e, {});
                    this.getList();
                  }}
                  className="btn btn-primary btn-primary-green me-2"
                >
                  Apply
                </button>
                <button
                  className="btn btn-primary btn-primary-green"
                  onClick={() => {
                    this.setState(
                      {
                        fromDate: new Date(),
                        toDate: new Date(),
                      },
                      () => {
                        this.getList();
                      }
                    );
                  }}
                >
                  <i className="fa fa-repeat"></i>
                </button>
              </div>
              <div className="col-auto ps-0">
                {list && list.length > 0 && permissions?.view && (
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={() =>
                      exportToCsv(
                        list.map((dt) => ({
                          ...dt,
                          date: moment.unix(dt?.dateTime).format("DD-MM-YYYY"),
                          time: moment
                            .unix(dt?.dateTime, "hh:mm a")
                            .format("HH:mm"),
                          memberName: `${dt?.memberId?.fName || ""} ${dt
                            ?.memberId?.mName || ""} ${dt?.memberId?.lName ||
                            ""}`,
                          memberId: dt?.memberId?.mNo,
                          notes: dt?.memberId?.notes,
                        })),
                        "checkin_list_" +
                          fromDate +
                          toDate +
                          "_DLon_" +
                          moment().format("YYYY-MM-DD_HH.mm"),
                        this.keymapper
                      )
                    }
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3" style={{ width: "330px" }}>
              <div className="card margin-top-15">
                <div className="">
                  <div className="container-fluid">
                    <div className="row">
                      {editId && (
                        <div className="col-12 text-end">
                          <button
                            onClick={(e) => {
                              this.editData(e);
                            }}
                            class="btn btn-primary btn-primary-green"
                          >
                            Back
                          </button>
                        </div>
                      )}
                      <div className="col-12">
                        <label className="form-label">Check in A Member</label>
                        <SearchInput
                          key={this.state.reset}
                          endpoint={`searchMember/`}
                          searchKey="searchKeyword"
                          accessKey="members"
                          name="memberData"
                          inputClassName="form_control"
                          inputProps={{
                            autoFocus: true,
                            disabled: editId ? true : false,
                          }}
                          defaultValue={memberData?.name || ""}
                          placeholder="Search by First Name, Last Name or Member ID"
                          onChange={(value, onChangeSearch) => {
                            onChangeSearch(`${value?.fName} ${value?.lName}`);
                            this.inputChange({
                              target: { name: "memberData", value: value },
                            });
                          }}
                          renderItem={(item, i) => {
                            return (
                              <React.Fragment key={"auto_" + i}>
                                <div
                                  className="image-style"
                                  style={{
                                    backgroundImage: `url(${item?.photo})`,
                                  }}
                                >
                                  <img
                                    src={"/images/imagesize2_3.png"}
                                    alt={item?.fName}
                                    className="w100per"
                                  />
                                </div>

                                <div className="d-flex justify-content-between flex-grow-1">
                                  <span
                                    className="user-name"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item?.fName + " " + item?.lName}
                                  </span>
                                  {item?.mNo && (
                                    <span className="user-name1">
                                      {item?.mNo}
                                    </span>
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <InputField
                          name="guestCount"
                          label="Number Of Guests"
                          groupClass="text-right"
                          value={guestCount}
                          inputChange={this.inputChange}
                          dom={{ placeholder: "0", type: "number" }}
                        />
                      </div>
                      <div className="col-12">
                        {memberData?._id && (
                          <div className="member_img">
                            <div
                              className="image-style"
                              style={{
                                backgroundImage: `url(${memberData?.photo})`,
                              }}
                            >
                              <img
                                src={"./images/imagesize2_3.png"}
                                alt=""
                                className="w100per"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        {error && <p className="error_msg  "> {error}</p>}
                      </div>
                      {permissions?.update || permissions?.create ? (
                        <div className="col-12 mt-2 text-center">
                          <Button
                            className={`btn btn-primary btn-primary-green  ${
                              memberData?._id ? "active" : ""
                            } ${isSaving ? "btndisabled" : ""}`}
                            onClick={this.onSubmit}
                          >
                            {editId ? "update" : "check in"}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-9"
              style={{ paddingLeft: "3px", width: "calc(100% - 330px)" }}
            >
              <div className="card h-100 margin-top-15 overflow-hidden">
                <div className="overflow-x-hidden overflow-auto">
                  {isLoader && (
                    <div className="justify-content-center align-items-center">
                      <Loader />
                    </div>
                  )}
                  {!isLoader && (!list || list.length == 0) && (
                    <NoRecord message="No checked in members found" />
                  )}
                  <div className="overflow-card container-fluid">
                    {!isLoader && list && list.length > 0 && (
                      <table className="table table-borderless custom-table">
                        <thead>
                          <tr className="text-center">
                            <th>Date</th>
                            <th className="w100">Time</th>
                            <th>Member's Name</th>
                            <th>Guests</th>
                            <th>M.No</th>
                            <th>Note</th>
                            <th>From</th>
                            {isToday(date) && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((item, i) => {
                            return (
                              <tr key={i} className="text-center">
                                <td>
                                  {moment
                                    .unix(item?.dateTime)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td className="w100">
                                  {moment
                                    .unix(item?.dateTime, "hh:mm a")
                                    .format("HH:mm")}
                                </td>
                                <td className="capitalize-text">
                                  {item?.memberId?.fName}{" "}
                                  {item?.memberId?.mName}{" "}
                                  {item?.memberId?.lName}
                                </td>
                                <td>{item?.guestCount}</td>
                                <td>{item?.memberId?.mNo}</td>
                                <td className="text-center">
                                  <ModalButton
                                    data={{
                                      showCustomModal: true,
                                      title: "Note",
                                      path: "reservation/show-notes",
                                      body: "",
                                      data: {
                                        ...item,
                                        notes: item?.memberId?.notes,
                                      },
                                      id: item?._id,
                                      className:
                                        "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                                    }}
                                  >
                                    {item?.memberId?.notes ? (
                                      <span className="cursor-pointer color-green fs20">
                                        <i className="fa fa-file-text"></i>
                                      </span>
                                    ) : (
                                      <span className="cursor-pointer">
                                        <i className="fa fa-file-text"></i>
                                      </span>
                                    )}
                                  </ModalButton>
                                </td>
                                <td>
                                  {item?.checkInFrom === 1
                                    ? "App"
                                    : item?.checkInFrom === 2
                                    ? "Cms"
                                    : ""}
                                </td>
                                {isToday(date) && (
                                  <td>
                                    {permissions?.update && (
                                      <span
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                          this.editData(e, item);
                                        }}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </span>
                                    )}
                                    {permissions?.delete && (
                                      <ModalButton
                                        className="ms-2 cursor-pointer  fs18"
                                        tag="span"
                                        data={{
                                          showCustomModal: true,
                                          id: item?._id,
                                          title: "Alert",
                                          footer: true,
                                          onSubmit: this.delete,
                                          submitArg: { id: item?._id },
                                          body:
                                            "Are you sure want to delete it ?",
                                          className: "",
                                        }}
                                      >
                                        <i className="fa fa-remove"></i>
                                      </ModalButton>
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { authData }, clubs: { activeClub } }) {
  return {
    activeClub: activeClub,
    adminType: authData?.roleId?.roleName,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const checkinControllerData = useCheckin();

    return (
      <Component {...props} checkinControllerData={checkinControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  resetPageStore,
  getClubs,
})(injectComp(CheckedinMembers));
