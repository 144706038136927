/** @format */

import React, { Component } from "react";
import "./m_style.css";
import { Link } from "react-router-dom";
import InputField from "../customFields/inputField";
import { connect } from "react-redux";
import moment from "moment";
import { updateModalData } from "../../actions/page";
import "../../style/style.scss";
import { S3_BUCKET_CRED } from "../../utils/constants";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { getClubs } from "../../actions/clubs";
import usePushNotifications from "../../controller/push-notifications/push-notifications-controller";
import Dropzone from "react-dropzone";
import Uploader from "../customFields/uploader";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class SetPushNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      timeValue: "",
      dateValue: "",
      selectedMembersData: [],
      selectedFilterData: {},
      title: "",
      desc: "",
      imageUrl: "",
      moduleId: 0,
      linkUrl: "",
      dispalyValidation: false,
      imageValidateion: false,
      fileErr: "",
      sending: false,
      update: false,
      updateId: "",
      allModuleCodesList: [],
      allScheduledNotiList: [],
      allExecutedNotiList: [],
      isStep: 1,
      memberIdArr: [],
      filterArr: {},
    };
  }

  componentDidMount() {
    const {
      getModCode,
      getPushNotifications,
    } = this?.props?.pushNotiControllerData;

    this.props.getClubs();
    getModCode();
    getPushNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.pushNotiControllerData?.allModuleCodes !==
      this?.props?.pushNotiControllerData?.allModuleCodes
    ) {
      const optData = this?.props?.pushNotiControllerData?.allModuleCodes?.reduce(
        (accumulator, value) => {
          return {
            ...accumulator,
            [value.moduleId]: value.text,
          };
        },
        {}
      );

      this.setState({
        allModuleCodesList: optData,
      });
    }

    if (
      prevProps?.pushNotiControllerData?.allScheduledNoti !==
      this?.props?.pushNotiControllerData?.allScheduledNoti
    ) {
      this.setState({
        allScheduledNotiList: this?.props?.pushNotiControllerData
          ?.allScheduledNoti,
      });
    }

    if (
      prevProps?.pushNotiControllerData?.allExecutedNoti !==
      this?.props?.pushNotiControllerData?.allExecutedNoti
    ) {
      this.setState({
        allExecutedNotiList: this?.props?.pushNotiControllerData
          ?.allExecutedNoti,
      });
    }

    if (
      prevProps?.pushNotiControllerData?.isLoading !==
      this?.props?.pushNotiControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.pushNotiControllerData?.isLoading,
      });
    }

    if (
      prevProps?.pushNotiControllerData?.succMsg !==
      this?.props?.pushNotiControllerData?.succMsg
    ) {
      this.setState({
        type: 1,
        imageUrl: "",
        title: "",
        desc: "",
        selectedMembersData: [],
        selectedFilterData: {},
        timeValue: "",
        dateValue: "",
        moduleId: 0,
        linkUrl: "",
        dispalyValidation: false,
        imageValidateion: false,
        update: false,
        fileErr: "",
        sending: false,
        updateId: "",
        memberIdArr: [],
        filterArr: {},
      });

      if (this?.props?.pushNotiControllerData?.succMsg) {
        toast.success(this?.props?.pushNotiControllerData?.succMsg);
        const { getPushNotifications } = this?.props?.pushNotiControllerData;
        getPushNotifications();
      }
    }
    if (
      prevProps?.pushNotiControllerData?.errMsg !==
      this?.props?.pushNotiControllerData?.errMsg
    ) {
      if (this?.props?.pushNotiControllerData?.errMsg) {
        this.setState({ sending: false });
        toast.error(this?.props?.pushNotiControllerData?.errMsg);
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {
      addPushNotification,
      updatePushNotification,
    } = this?.props?.pushNotiControllerData;

    const {
      title,
      desc,
      timeValue,
      dateValue,
      imageUrl,
      linkUrl,
      moduleId,
      type,
      selectedMembersData,
      update,
      updateId,
    } = this.state;

    if (title === "" || desc === "" || timeValue === "" || dateValue === "") {
      return this.setState({ dispalyValidation: true });
    }
    if (imageUrl === "") {
      this.setState({ imageValidateion: true });
    }

    this.setState({ sending: true });

    const payload = {
      dateTime: moment(`${dateValue} ${timeValue}`).unix(),
      title: title,
      body: desc,
      imageUrl: imageUrl,
      webLink: linkUrl,
      moduleId: moduleId,
      type: type,
      memberId: type === 2 ? [...selectedMembersData] : [],
      clubId: type === 3 ? [...this?.state?.selectedFilterData?.clubId] : [],
      genderId:
        type === 3 ? [...this?.state?.selectedFilterData?.genderId] : [],
      intrestId:
        type === 3 ? [...this?.state?.selectedFilterData?.intrestId] : [],
      appUsage:
        type === 3 ? [...this?.state?.selectedFilterData?.appUsage] : [],
      age:
        type === 3 && this?.state?.selectedFilterData?.ageData?.[0]
          ? [...this?.state?.selectedFilterData?.ageData]
          : [],
      mType: type === 3 ? [...this?.state?.selectedFilterData?.mType] : [],
    };

    if (update) {
      payload._id = updateId;
      updatePushNotification(payload);
    } else {
      addPushNotification(payload);
    }
  };

  updateSelectedMemebers = (selectedMembers) => {
    const idArr =
      selectedMembers && selectedMembers.length > 0
        ? selectedMembers.map((d) => d?._id)
        : [];
    this.setState({
      selectedMembersData: idArr,
    });
  };

  updateFilter = (selectedFilter) => {
    this.setState({
      selectedFilterData: selectedFilter,
    });
  };

  inputChange = (e) => {
    if (e.target.type === "radio") {
      this.setState({
        type: Number(e.target.value),
      });
      if (Number(e.target.value) === 2) {
        this.props.updateModalData({
          showCustomModal: true,
          title: "Individual Members for notification",
          path: "notes/selectedMembers",
          className: "modal-extra-large",
          body: "",
          data: {
            memberIdArr: this.state.memberIdArr,
            updateMember: this.updateSelectedMemebers,
          },
        });
      } else if (Number(e.target.value) === 3) {
        this.props.updateModalData({
          showCustomModal: true,
          title: "Filters for notification",
          path: "notes/filters",
          className: "modal-extra-large",
          body: "",
          data: {
            filterArr: this.state.filterArr,
            clubs: this.props.clubs,
            updateFilter: this.updateFilter,
          },
        });
      }
    }
    if (e.target.name === "moduleId") {
      this.setState({ moduleId: e.target.value });
    }
    if (e.target.name === "date") {
      this.setState({ dateValue: e.target.value });
    }
    if (e.target.name === "time") {
      this.setState({ timeValue: e.target.value });
    }
    if (e.target.name === "title") {
      this.setState({ title: e.target.value });
    }
    if (e.target.name === "desc") {
      this.setState({ desc: e.target.value });
    }
    if (e.target.name === "link") {
      this.setState({ linkUrl: e.target.value });
    }
    if (e.target.type === "file") {
      if (e.target.files[0].size > 300000) {
        return this.setState({
          imageValidateion: true,
          fileErr: "Image size should be less than 300kb",
        });
      }
      if (e.target.files[0].size < 300000) {
        const file = e.target.files[0];
        new Promise((resolve, reject) => {
          var fileName = file.name.split(".").pop();

          AWS.config.update({
            accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
            secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
            region: S3_BUCKET_CRED.BUCKET_REGION,
          });
          var s3 = new AWS.S3();
          var params = {
            Bucket: S3_BUCKET_CRED.BUCKET_NAME,
            Key: "asset/" + Date.now() + "." + fileName,
            ContentType: file.type,
            Body: file,
            ACL: "public-read",
          };

          s3.putObject(params, async (err, res) => {
            if (err) {
            } else {
              let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

              this.setState({ imageUrl: link });
              try {
                resolve(link);
              } catch (e) {
                reject(e);
              }
            }
          });
        });
      }
    }
  };

  formReset = () => {
    this.setState({
      type: 1,
      imageUrl: "",
      title: "",
      desc: "",
      selectedMembersData: [],
      selectedFilterData: {},
      timeValue: "",
      dateValue: "",
      moduleId: 0,
      linkUrl: "",
      dispalyValidation: false,
      imageValidateion: false,
      memberIdArr: [],
      filterArr: {},
    });
  };

  deleteNotification = (id) => {
    const { deletePushNotification } = this?.props?.pushNotiControllerData;
    deletePushNotification(id);
  };

  askConfirm = (e, id) => {
    this.props.updateModalData({
      showCustomModal: true,
      title: "Confirmation",
      footer: true,
      body: "Are you sure you want to delete this notification ?",
      onSubmit: (e, closeModal) => {
        closeModal();
        this.deleteNotification(id);
      },
    });
  };

  updateLog = (log) => {
    this.setState({
      update: true,
      dateValue: log?.dateTime
        ? moment.unix(log?.dateTime).format("YYYY-MM-DD")
        : "",
      timeValue: log?.dateTime
        ? moment.unix(log?.dateTime, "hh:mm a").format("HH:mm")
        : "",
      title: log?.title,
      desc: log?.body,
      imageUrl: log?.imageUrl,
      linkUrl: log?.webLink,
      moduleId: log?.moduleId,
      type: log?.type,
      updateId: log?._id,
      memberIdArr: log?.memberId,
      filterArr: {
        clubId: log?.clubId ? log?.clubId : [],
        mType: log?.mType ? log?.mType : [],
        age: log?.age ? log?.age : [],
        genderId: log?.genderId ? log?.genderId : [],
        intrestId: log?.intrestId ? log?.intrestId : [],
        appUsage: log?.appUsage ? log?.appUsage : [],
      },
    });
  };

  toggleUploader = (isUploader) => {
    this.setState({ isUploader });
  };

  onDrop = (files, r) => {
    if (files.length) {
      this.setState({ bulkFiles: files });
      this.toggleUploader(true);
    } else if (r.length) {
      this.props.updateModalData({
        showCustomModal: true,
        title: "",
        body: "Only jpg, jpeg and png files are allowed",
      });
    }
  };

  setBulkPhotos = (bulkCollection) => {
    let a = bulkCollection.filter((e) => e.type === "selected");
    if (a.length === bulkCollection.length) {
      for (let i = 0; i < bulkCollection.length; i++) {
        const file = bulkCollection[i];
        new Promise((resolve, reject) => {
          var fileName = bulkCollection[i].file.name.split(".").pop();
          AWS.config.update({
            accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
            secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
            region: S3_BUCKET_CRED.BUCKET_REGION,
          });
          var s3 = new AWS.S3();
          var params = {
            Bucket: S3_BUCKET_CRED.BUCKET_NAME,
            Key: "asset/" + Date.now() + "." + fileName,
            ContentType: file.file.type,
            Body: dataURLtoFile(file.thumbnail, file.fileName),
            ACL: "public-read",
          };

          s3.putObject(params, async (err, res) => {
            if (err) {
            } else {
              let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

              this.setState({
                imageUrl: link,
                isUploader: false,
                isImageAdded: true,
              });

              try {
                resolve(link);
              } catch (e) {
                reject(e);
              }
            }
          });
        });
      }
    }
  };

  render() {
    const {
      update,
      title,
      desc,
      linkUrl,
      dateValue,
      timeValue,
      moduleId,
      imageUrl,
      imageValidateion,
      fileErr,
      sending,
      allModuleCodesList,
      isUploader,
      dispalyValidation,
      allScheduledNotiList,
      allExecutedNotiList,
      bulkFiles,
      type,
      isStep,
    } = this.state;

    if (isUploader) {
      return (
        <div className="mt30 memberUploader">
          <Uploader
            files={bulkFiles}
            onComplete={this.setBulkPhotos}
            onBack={this.toggleUploader}
            folder={"temp"}
            id={""}
            uploadType="userImage"
            isSideNav={false}
            not_upload={true}
            isPopup={true}
            config={{ aspectRatio: "p2" }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row">
            <div className="col">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h3 className="page-title mb-0">
                    <Link to="/admin-access">
                      <i className="fa fa-arrow-left cursor-pointer"></i>
                    </Link>
                    &nbsp;Push Notifications
                  </h3>
                </div>
              </div>

              <div className="noti-tab">
                <ul
                  className="list-unstyled components d-flex"
                  style={{ border: "none" }}
                >
                  <li
                    onClick={() => this.setState({ isStep: 1 })}
                    className={isStep === 1 ? "current-tab" : ""}
                  >
                    <div className="cursor-pointer">
                      <p style={{ textTransform: "capitalize" }}>Scheduled</p>
                    </div>
                  </li>
                  <li
                    onClick={() => this.setState({ isStep: 2 })}
                    className={isStep === 2 ? "current-tab" : ""}
                  >
                    <div className="cursor-pointer">
                      <p style={{ textTransform: "capitalize" }}>Executed</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="push_slider style-5">
                {isStep === 1 ? (
                  allScheduledNotiList && allScheduledNotiList?.length !== 0 ? (
                    <div className="owl-carousel">
                      {allScheduledNotiList?.map((log, i) => {
                        return (
                          <div className="box" key={i}>
                            <div
                              style={{ flexWrap: "nowrap" }}
                              className="boxcontainer"
                            >
                              <div className="img">
                                <img
                                  src={
                                    log?.imageUrl ||
                                    "./images/imagesize16_9.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="img_info">
                                {log?.title || log?.body ? (
                                  <>
                                    <p>{log?.title}</p>
                                    <p>{log?.body}</p>
                                  </>
                                ) : (
                                  <p>{log?.webLink}</p>
                                )}
                              </div>
                              <div className="action-btn">
                                <span>
                                  <i
                                    onClick={() => this.updateLog(log)}
                                    className="fa fa-edit cursor-pointer"
                                  ></i>
                                </span>
                                <span className="ms-2">
                                  <i
                                    onClick={(e) =>
                                      this.askConfirm(e, log?._id)
                                    }
                                    className="fa fa-trash cursor-pointer"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                {moment
                                  .unix(log?.dateTime, "hh:mm a")
                                  .format("HH:mm")}
                                &nbsp;&nbsp;
                                {moment
                                  .unix(log?.dateTime)
                                  .format("DD MMM, YYYY")}
                              </label>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                Scheduled By - {log?.scheduledBy?.fName}{" "}
                                {log?.scheduledBy?.lName}
                              </label>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                Schedule For - {log?.totalScheduledFor} Members
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="px-4" style={{ color: "black" }}>
                      No Logs Found
                    </p>
                  )
                ) : null}
                {isStep === 2 ? (
                  allExecutedNotiList && allExecutedNotiList?.length !== 0 ? (
                    <div className="owl-carousel">
                      {allExecutedNotiList?.map((log, i) => {
                        return (
                          <div className="box" key={i}>
                            <div
                              style={{ flexWrap: "nowrap" }}
                              className="boxcontainer"
                            >
                              <div className="img">
                                <img
                                  src={
                                    log?.imageUrl ||
                                    "./images/imagesize16_9.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="img_info">
                                {log?.title || log?.body ? (
                                  <>
                                    <p>{log?.title}</p>
                                    <p>{log?.body}</p>
                                  </>
                                ) : (
                                  <p>{log?.webLink}</p>
                                )}
                              </div>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                {moment
                                  .unix(log?.dateTime, "hh:mm a")
                                  .format("HH:mm")}
                                &nbsp;&nbsp;
                                {moment
                                  .unix(log?.dateTime)
                                  .format("DD MMM, YYYY")}
                              </label>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                Scheduled By - {log?.scheduledBy?.fName}{" "}
                                {log?.scheduledBy?.lName}
                              </label>
                            </div>
                            <div className="date-time-txt">
                              <label>
                                Viewed By - {log?.totalViewed} Members
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="px-4" style={{ color: "black" }}>
                      No Logs Found
                    </p>
                  )
                ) : null}
              </div>
            </div>
            <div style={{ width: "700px" }}>
              <form className="h-100 card">
                <div className="card-header bg-primary">
                  <div className="text-white">
                    {update ? "Edit" : "Create"} Push Notification
                  </div>
                </div>

                <div className="card-body overflow-auto-div">
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <InputField
                          name="title"
                          label="Title"
                          value={title}
                          inputChange={this.inputChange}
                          dom={{
                            type: "text",
                            placeholder: "",
                            maxLength: "40",
                          }}
                        />
                        {dispalyValidation && title === "" && (
                          <small className="validation-text">
                            Title is required
                          </small>
                        )}
                      </div>
                      <div>
                        <label className="form-label">Body</label>
                        <textarea
                          groupClass="form-control"
                          value={desc}
                          onChange={this.inputChange}
                          name="desc"
                          rows={5}
                          maxLength="120"
                        ></textarea>
                        {dispalyValidation && desc === "" && (
                          <small className="validation-text">
                            Body is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <InputField
                        label="Link"
                        name="link"
                        value={linkUrl}
                        inputChange={this.inputChange}
                        dom={{
                          type: "text",
                          placeholder: "Paste URL for Web Link",
                        }}
                      />
                      <div className="row">
                        <div
                          className="col-12"
                          style={
                            dispalyValidation &&
                            (dateValue === "" || timeValue === "")
                              ? { marginTop: 0 }
                              : {}
                          }
                        >
                          <InputField
                            label="Action Module"
                            value={moduleId}
                            options={allModuleCodesList}
                            name="moduleId"
                            inputChange={this.inputChange}
                            dom={{ placeholder: "Select", type: "select" }}
                          />
                        </div>
                        <div className="col-6">
                          <InputField
                            value={dateValue}
                            name="date"
                            inputChange={(e) => this.inputChange(e)}
                            dom={{
                              placeholder: "Date",
                              type: "date",
                              dateFormat: "dd/MM/yyyy",
                            }}
                          />
                          {dispalyValidation && dateValue === "" && (
                            <small className="validation-text">
                              Date is Required
                            </small>
                          )}
                        </div>
                        <div className="col-6">
                          <InputField
                            name="time"
                            groupClass="mbi-0"
                            value={timeValue}
                            inputChange={this.inputChange}
                            dom={{
                              placeholder: "Time",
                              type: "time",
                            }}
                          />
                          {dispalyValidation && timeValue === "" && (
                            <small className="validation-text">
                              Time is required
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2" style={{ marginTop: "10px" }}>
                      <div className="inputbox elative  bordered-input upl_img">
                        <Dropzone
                          style={{ position: "relative", cursor: "pointer" }}
                          accept="image/jpeg, image/png, image/jpg"
                          multiple={false}
                          onDrop={(accepted, rejected) => {
                            this.onDrop(accepted, rejected);
                          }}
                        >
                          {!imageUrl && (
                            <div className="absolute-center">Upload Photo</div>
                          )}
                          <label htmlFor="gig_upload">
                            <input
                              onChange={this.inputChange}
                              type="file"
                              id="gig_upload"
                            />
                            <img
                              src={imageUrl || "./images/imagesize16_9.png"}
                              id="upload_img"
                              alt=""
                            />
                          </label>
                        </Dropzone>
                      </div>
                    </div>
                    <div className="col-4" style={{ marginTop: "10px" }}>
                      <label className="form-label fs12">
                        40 X 40 <br /> pixels Image <br />
                        {"(Image Size <= 300KB)"}
                        <div>
                          {(imageValidateion || dispalyValidation) &&
                            imageUrl === "" && (
                              <small className="validation-text">
                                {fileErr}
                              </small>
                            )}
                        </div>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12 profile-radio-button">
                          <div className="form-check-inline">
                            <label className="form-check-label py-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={1}
                                onClick={this.inputChange}
                                checked={type === 1}
                              />
                              All Members
                            </label>
                          </div>
                          <div className="form-check-inline py-2">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={3}
                                onClick={this.inputChange}
                                checked={type === 3}
                              />
                              Use Filters
                            </label>
                          </div>
                          <div className="form-check-inline py-2">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={2}
                                onClick={this.inputChange}
                                checked={type === 2}
                              />
                              Individual Members
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 bg-white d-flex justify-content-end">
                  <button
                    className={`btn btn-primary `}
                    onClick={this.formReset}
                    type="reset"
                  >
                    Reset
                  </button>
                  &nbsp;
                  <button
                    className={`btn btn-primary btn-primary-green ${
                      sending ? "btndisabled" : ""
                    }`}
                    onClick={this.onSubmit}
                    type="submit"
                  >
                    {update ? "Update" : "Push Notify"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clubs: state?.clubs?.clubs,
});

function injectComp(Component) {
  const InjectedComp = function(props) {
    const pushNotiControllerData = usePushNotifications();

    return (
      <Component {...props} pushNotiControllerData={pushNotiControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  updateModalData,
  getClubs,
})(injectComp(SetPushNote));
