import axios from "axios";
import { API_BASE_PATH_NEW } from "../utils/constants";
import { forceLogout, getCookie } from "./session";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: API_BASE_PATH_NEW,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function(config) {
  const token = getCookie("quorum-token");
  config.headers.authorization = token ? token : "";

  return config;
});

axiosInstance.defaults.validateStatus = function(status) {
  if (status === 401) {
    toast.error("Session Expired");
    forceLogout();
  } else return true;
};

export default axiosInstance;
