import React, { Component } from "react";
import "../../style/paging.scss";
import Button from "../modal/button";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: {},
      flag: false,
    };
  }
  componentDidMount() {
    let paging = this.paging(this.props);
    this.setState({ paging });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.paging !== prevState.paging) {
      return { flag: true };
    } else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paging !== this.props.paging) {
      let paging = this.paging(this.props);
      this.setState({ paging });
    }
  }

  paging = (data) => {
    const { paging = {}, limit = 5 } = data;
    let { count = 0, size = 0, rows = 5, page = 1 } = paging;
    count = parseInt(count);
    size = parseInt(size);
    rows = parseInt(rows);
    page = parseInt(page);
    if (count > 0) {
      let totalPages = Math.ceil(count / rows);
      let lim = limit - 1;
      let start = page - lim / 2 > 1 ? page - lim / 2 : 1;
      let end = start + lim < totalPages ? start + lim : totalPages;
      if (end == totalPages)
        start = totalPages - lim > 0 ? totalPages - lim : start;
      let next = page + 1 <= totalPages ? page + 1 : false,
        prev = page - 1 >= 1 && page < totalPages ? page - 1 : false,
        first = totalPages > 0 && page > 1 ? 1 : false,
        last = totalPages > 1 && page < totalPages ? totalPages : false,
        pages = [];
      for (let i = start; i <= end; i++) {
        pages = [...pages, { active: page == i, page: i }];
      }
      let from = (page - 1) * rows + 1,
        to = from + rows <= count ? from + rows - 1 : count;
      return { pages, from, to, count, start, end, next, prev, first, last };
    }
  };

  setPage = (e, page) => {
    e.preventDefault();
    if (!page) {
      return;
    }
    if (this.props.onPageClick) this.props.onPageClick(page);
  };

  render() {
    let { paging } = this.state;
    if (!paging || !paging.count) {
      return <div />;
    } else {
      return (
        <nav
          aria-label="Page navigation example"
          className="paging d-flex justify-content-between align-items-center"
        >
          <div className="btn btn-primary btn-primary-green1">
            {paging.from} to {paging.to} of {paging.count}
          </div>
          <ul className="pagination d-flex align-items-center">
            <li className="page-item">
              <button
                onClick={(e) => this.setPage(e, paging.first)}
                disabled={!paging.first}
                className="btn btn-primary btn-primary-green1"
              >
                First
              </button>
            </li>
            {paging.pages.map((page) => {
              return (
                <li className="page-item" key={page.page}>
                  <a
                    href="#"
                    onClick={(e) => this.setPage(e, page.page)}
                    className={`btn  outline-none ${
                      page.active
                        ? "btn-primary btn-primary-green1"
                        : "bg-white"
                    }`}
                  >
                    {page.page}
                  </a>
                </li>
              );
            })}
            <button
              disabled={!paging.last}
              onClick={(e) => this.setPage(e, paging.last)}
              className="btn btn-primary btn-primary-green1"
              style={{ marginLeft: "2px" }}
            >
              Last
            </button>
          </ul>
        </nav>
      );
    }
  }
}

export default Pagination;
