import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPageData,
  fetchSuggestions,
  resetPageStore,
  updatePageData,
} from "../../actions/page";
import { listQpayData, updateQpayData } from "../../actions/qpay";
import { getClubs } from "../../actions/clubs";
import ModalButton from "../../components/modal/button";
import Loader from "../../components/customFields/loader";
import NoRecord from "../../components/customFields/noRecord";
import useStaticPage from "../../controller/static-page/static-page-controller";
import { toast } from "react-toastify";

class HowToQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      isinit: true,
      list: [],
      // totalCount: "",
      qsCatList: ["F & B", "Notice Board", "Q PERKS", "Retail"],
      activeClub: "",
      // clubs: [],
      req: true,
      isLoader: false,
    };
  }

  componentDidMount() {
    if (this?.props?.activeClub?.[0]?._id || this.state.activeClub)
      this.getList();

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  getList = () => {
    const { getStaticNoticeBoard } = this?.props?.staticPageControllerData;
    getStaticNoticeBoard(
      this.state.activeClub || this?.props?.activeClub?.[0]?._id
    );
  };

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list != prevState.list && prevState.isinit) {
      return {
        list: nextProps.list,
        // totalCount: nextProps.count,
        isinit: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.props.updatedData != prevProps.updatedData &&
    //   !isObjEmpty(this.props.updatedData) &&
    //   this.props.updatedData.page === "howToQ"
    // ) {
    //   let { action, data } = this.props.updatedData;
    //   this.setState({ category: "" });
    //   this.getList(false);
    //   this.props.resetPageStore("updatedData");
    // }
    if (
      this?.state?.activeClub === "" &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
    if (prevProps?.activeClub?.[0]?._id !== this?.props?.activeClub?.[0]?._id) {
      this.setState({
        activeClub: this?.props?.activeClub?.[0]?._id,
      });
    }
    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.getList();
    }
    if (
      prevProps?.staticPageControllerData?.clubsData !==
      this?.props?.staticPageControllerData?.clubsData
    ) {
      this.setState({
        list: this?.props?.staticPageControllerData?.clubsData,
      });
    }
    if (
      prevProps?.staticPageControllerData?.isLoading !==
      this?.props?.staticPageControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.staticPageControllerData?.isLoading,
      });
    }

    if (
      prevProps?.staticPageControllerData?.errMsg !==
      this?.props?.staticPageControllerData?.errMsg
    ) {
      if (this?.props?.staticPageControllerData?.errMsg) {
        toast.error(this?.props?.pushNotiControllerData?.errMsg);
      }
    }

    if (
      prevProps?.staticPageControllerData?.succMsg !==
      this?.props?.staticPageControllerData?.succMsg
    ) {
      if (this?.props?.staticPageControllerData?.succMsg) {
        toast.success(this?.props?.pushNotiControllerData?.succMsg);
      }
    }
  }

  // onSelect = (e) => {
  //   let {
  //     target: { name, value },
  //   } = e;
  //   this.setState({ [name]: value, isinit: true }, () => {
  //     this.getList(true);
  //   });
  // };

  delete = (e, closeModal, element) => {
    e.preventDefault();
    const { deleteStaticNoticeBoard } = this?.props?.staticPageControllerData;

    element.id &&
      deleteStaticNoticeBoard(element.id, (response) => {
        if (response?.data?.status && response?.data?.status !== "false") {
          closeModal();
          this.getList(true);
          this.setState({ error: "" });
        } else {
          this.setState({ error: response?.data?.msg });
        }
      });
  };

  render() {
    let { list = [], isLoader } = this.state;
    const { permissions = {} } = this.props;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundImage: `url(${item ? item.imageUrl : ""})`,
      };
      return (
        <div className="card news-card  p-0 h-100 ">
          <div className="card-body border-top p-0">
            <div className="absolute-top-right  card-absolute-header  news-header">
              <div className="d-flex align-items-center">
                {permissions?.update || permissions?.delete ? (
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      showCustomModal: true,
                      title: "Edit Noticeboard",
                      path: "HowToQ/howToQ",
                      body: "",
                      className: "auto-width",
                      getList: this.getList,
                      permissions: permissions,
                      clubid: this.state.activeClub,
                      cardData: item,
                      id: item._id,
                    }}
                  >
                    <i className="fa fa-edit" />
                  </ModalButton>
                ) : null}
                &nbsp;&nbsp;
                {permissions?.delete ? (
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      showCustomModal: true,
                      id: item._id,
                      title: "Alert",
                      footer: true,
                      onSubmit: this.delete,
                      submitArg: { id: item._id },
                      body: "Are you sure want to delete it ?",
                      className: "",
                    }}
                  >
                    <i className="fa fa-trash" />
                  </ModalButton>
                ) : null}
              </div>
            </div>
            <div className="image-style" style={imagestyle}>
              <img
                src="../images/imagesize2_3.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
          </div>
        </div>
      );
    };
    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">
                <Link to="/static-pages">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Noticeboard
              </h3>
            </div>
            {permissions?.create ? (
              <div className="d-flex col-auto align-items-center">
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    showCustomModal: true,
                    title: "Add Noticeboard",
                    path: "HowToQ/howToQ",
                    body: "",
                    className: "auto-width",
                    getList: this.getList,
                    permissions: permissions,
                    clubid: this.state.activeClub,
                  }}
                >
                  Add new
                </ModalButton>
              </div>
            ) : null}
          </div>
          <div className="card bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && list && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {!isLoader && list && list.length > 0 && (
                  <div className="row ">
                    {list.map((item, i) => {
                      return (
                        <div key={i} className="col-2 mb-3">
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    fetchSuggestions: (myParam) =>
      dispatch(fetchSuggestions(myParam, "qsCatList")),
    getPageData: (myParam) => dispatch(getPageData(myParam)),
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
    updatePageData: (myParam) => dispatch(updatePageData(myParam)),
    listQpayData: (myParam, cb) => dispatch(listQpayData(myParam, cb)),
    updateQpayData: (myParam, cb) => dispatch(updateQpayData(myParam, cb)),
  };
};

function mapStateToProps({
  auth: { token },
  page: { pageData, updatedData = {}, qsCatList },
  clubs: { activeClub },
}) {
  return {
    token,
    list: pageData,
    updatedData,
    qsCatList: qsCatList,
    activeClub,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staticPageControllerData = useStaticPage();

    return (
      <Component
        {...props}
        staticPageControllerData={staticPageControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, mapDispatchToProps)(injectComp(HowToQ));
