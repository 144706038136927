import Header from "./header";
import Sidenav from "./sidenav";
import Footer from "./footer";

const fields = {
  Footer,
  Header,
  Sidenav,
};

export default fields;
