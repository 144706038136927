/** @format */

import axios from "axios";
import { getCookie } from "../services/session";
import { API_BASE_PATH_NEW } from "../utils/constants";
import { GET_CLUBS, SET_ACTIVE_CLUB } from "./constants";

export const getClubs = () => async (dispatch) => {
  const accToken = getCookie("quorum-token");
  try {
    const res = await axios.get(`${API_BASE_PATH_NEW}clubs/`, {
      headers: {
        Authorization: accToken,
      },
    });
    dispatch({
      type: GET_CLUBS,
      payload: res.data.clubs,
    });
  } catch (error) {}
};

export const setActiveClub = (clubs, activeClub, role) => (dispatch) => {
  const activeClubObj = clubs?.filter((club) => {
    if (activeClub === club.clubName) {
      return club;
    }
  });

  role === "Super Admin" || role === "Admin" || role === "Developer"
    ? localStorage.setItem("activeClubData", JSON.stringify(activeClubObj))
    : localStorage.setItem("staffClubData", JSON.stringify(activeClubObj));

  dispatch({
    type: SET_ACTIVE_CLUB,
    payload: activeClubObj,
  });
};
