import React, { Component } from "react";
import { connect } from "react-redux";
import { listQpayData, updateQpayData, getQPayRoles } from "../../actions/qpay";
import { Endpoint } from "./../../utils/constants";
import ModalButton from "./../modal/button";
import Loader from "../customFields/loader";
import AdminLogs from "./logs";
import Pagination from "../pagination/pagination";
class AccessControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      error: "",
      isLog: false,
      isRoleAssign: false,
      roles: [],
      total: 0,
      page: 1,
    };
  }

  componentDidMount(prevProps, prevState) {
    this.getList();
  }

  getList = () => {
    let payload = {};
    this.props.listQpayData(
      {
        payload,
        url: `${Endpoint.QPAY_ADMINS}?page=${this.state.page}&limit=10`,
        method: "GET",
      },
      (res) => {
        this.setState({ isLoader: true, total: res?.totalRec });
      }
    );
  };
  getRoles = () => {
    this.props.getQPayRoles((response) => {
      if (response.status) {
        this.setState({ roles: response.data, isLoader: true });
      }
    });
  };

  delete = (e, closeModal, item) => {
    e.preventDefault();
    this.props.updateQpayData(
      {
        url: `${Endpoint.QPAY_ADMIN}/${item.id}`,
        method: "DELETE",
      },
      (response) => {
        if (response.status) {
          closeModal();
          this.getList();
          this.setState({ error: "" });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  render() {
    const { isLoader, isLog, isRoleAssign, roles, total } = this.state;
    console.log("qewdfew", total);
    let { list } = this.props;
    return (
      <React.Fragment>
        {!isLog && !isRoleAssign && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">Access Control</h3>
              </div>
              <div className="row align-items-center">
                <div className="col-auto">
                  <button
                    className="ms-2 btn btn-primary btn-primary-green"
                    onClick={() => {
                      this.getRoles();
                      this.setState({
                        isLoader: false,
                        isRoleAssign: true,
                      });
                    }}
                  >
                    Role Controls
                  </button>
                  <ModalButton
                    className="ms-2 btn btn-primary btn-primary-green"
                    data={{
                      showCustomModal: true,
                      title: "Add New Admin",
                      path: "Qpay/add-edit-admin",
                      body: "",
                      getList: this.getList,
                      className: "modal-large",
                    }}
                  >
                    Add
                  </ModalButton>
                  <button
                    className="ms-2 btn btn-primary btn-primary-green"
                    onClick={() => {
                      this.setState({ isLog: true });
                    }}
                  >
                    Logs
                  </button>
                </div>
              </div>
            </div>
            <div className="row h-100  margin-top-15 overflow-auto-div-pagination">
              <div className="col-12">
                {!isLoader && <Loader />}
                {isLoader && list && list.length > 0 && (
                  <div className="">
                    <table className="table  table-borderless custom-table">
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Admin's Name</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((element, key) => {
                          return (
                            <tr key={key} className="text-center">
                              <td>{key + 1}</td>
                              <td style={{ textTransform: "capitalize" }}>
                                {element.fullName}
                              </td>
                              <td>{element.roleId.roleName}</td>
                              <td>
                                {element.status == 1 ? "Active" : "Inactive"}
                              </td>
                              <td>
                                <ModalButton
                                  className="cursor-pointer"
                                  tag="span"
                                  data={{
                                    id: element.id,
                                    showCustomModal: true,
                                    title: "Edit Admin",
                                    path: "Qpay/add-edit-admin",
                                    getList: this.getList,
                                    body: "",
                                    className: "modal-large",
                                  }}
                                >
                                  <i className="fa fa-edit" />
                                </ModalButton>
                                <ModalButton
                                  className="ms-2 cursor-pointer color-red fs18"
                                  tag="span"
                                  data={{
                                    showCustomModal: true,
                                    id: element.id,
                                    title: "Alert",
                                    footer: true,
                                    onSubmit: this.delete,
                                    submitArg: { id: element.id },
                                    body: "Are you sure want to delete it ? ",
                                    className: "",
                                  }}
                                >
                                  <i className="fa fa-remove"></i>
                                </ModalButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div>
              {isLoader && this.state.total > 0 && (
                <div>
                  <Pagination
                    paging={{
                      count: this.state.total,
                      rows: 10,
                      page: this.state.page,
                    }}
                    onPageClick={(page) => {
                      this.setState({ page: page }, () => {
                        this.getList();
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {isLog && !isRoleAssign && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex mb-2 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  {" "}
                  <i
                    onClick={() => {
                      this.setState({ isLog: false });
                    }}
                    className="fa fa-arrow-left cursor-pointer me-2"
                  ></i>
                  Admin Logs
                </h3>
              </div>
            </div>
            <div>
              <AdminLogs></AdminLogs>
            </div>
          </div>
        )}
        {!isLog && isRoleAssign && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  {" "}
                  <i
                    onClick={() => {
                      this.setState({ isRoleAssign: false });
                    }}
                    className="fa fa-arrow-left cursor-pointer me-2"
                  ></i>
                  Admin Roles
                </h3>
              </div>
            </div>
            <div className="row margin-top-15">
              <div className="col-12">
                {!isLoader && <Loader />}
                {isLoader && roles && roles.length > 0 && (
                  <div className="overflow-auto-div">
                    <table className="table table-borderless custom-table">
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Role</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roles.map((element, key) => {
                          return (
                            <tr key={key} className="text-center">
                              <td>{key + 1}</td>
                              <td>{element.roleName}</td>
                              <td>
                                <ModalButton
                                  className="cursor-pointer"
                                  tag="span"
                                  data={{
                                    id: element._id,
                                    showCustomModal: true,
                                    title: "Manage Role Control",
                                    path: "Qpay/role-control",
                                    body: "",
                                    getMenu: this.props.getMenu,
                                    className: "modal-extra-large",
                                  }}
                                >
                                  <i className="fa fa-edit" />
                                </ModalButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ qpay: { list, isLoader } }) {
  return {
    list,
    isLoader,
  };
}
export default connect(mapStateToProps, {
  listQpayData,
  updateQpayData,
  getQPayRoles,
})(AccessControl);
