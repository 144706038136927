import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useSpaces = () => {
  const [allResSpaces, setAllResSpaces] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllReservationSpaces = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.RESERVATION}${Endpoint.ALLSPACES}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllResSpaces(response?.data?.spaces)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addReservationSpace = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.RESERVATION}${Endpoint.SPACE}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateReservationSpace = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.RESERVATION}${Endpoint.SPACE}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setIsUpdating(false);
      setErrMsg("Something went wrong");
    }
  };

  const deleteReservationSpace = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(
        `${Endpoint.RESERVATION}${Endpoint.SPACE}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allResSpaces,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllReservationSpaces,
    addReservationSpace,
    updateReservationSpace,
    deleteReservationSpace,
  };
};

export default useSpaces;
