import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useAuth = () => {
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpId, setOtpId] = useState("");
  // const [isLogin, setIsLogin] = useState(false);

  const sendOtp = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        `${Endpoint.AUTH_SEND_OTP}`,
        data
      );
      if (response?.data?.status && response?.data?.status !== "false") {
        setOtpId(response?.data?.otpId);
        setSuccMsg(response?.data?.msg);
      } else {
        setOtpId("");
        setErrMsg(response?.data?.msg);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  // const verifyOtp = async (data) => {
  //   setSuccMsg("");
  //   setErrMsg("");
  //   setIsLoading(true);

  //   try {
  //     const response = await axiosInstance.post(
  //       `${Endpoint.AUTH_VERIFY_OTP}`,
  //       data
  //     );
  //     console.log("response", response);
  //     if(response?.data?.status && response?.data?.status !== "false") {
  //       setCookie("quorum-token", response?.data?.accessToken);
  //       setCookie("userId", response?.data?.userData?.id);
  //       setIsLogin(true)
  //       setSuccMsg(response?.data?.msg)

  //       yield put({
  //         type: UPDATE_USER_DATA,
  //         data: {
  //           userData: response?.data?.userData,
  //           isLogin: true,
  //           userId: response?.data?.userData?.id,
  //         },
  //       });
  //       yield put({
  //         type: `REDUCER/${GET_TOKEN}`,
  //         data: response?.data?.accessToken,
  //       });
  //     } else {
  //       setIsLogin(false)
  //       setErrMsg("Invalid otp code")
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("error--", error);
  //     setErrMsg("Something went wrong");
  //     setIsLoading(false);
  //   }
  // };

  const getAccessToken = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        `${Endpoint.AUTH_ACCESS_TOKEN}`,
        data
      );
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  // const logout = async () => {
  //   setSuccMsg("");
  //   setErrMsg("");
  //   setIsLoading(true);

  //   try {
  //     const response = await axiosInstance.get(`${Endpoint.AUTH_LOGOUT}`);
  //     console.log("response", response);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("error--", error);
  //     setErrMsg("Something went wrong");
  //     setIsLoading(false);
  //   }
  // };

  return {
    succMsg,
    errMsg,
    isLoading,
    otpId,
    // isLogin,
    sendOtp,
    // verifyOtp,
    getAccessToken,
    // logout,
  };
};

export default useAuth;
