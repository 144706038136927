/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetPageStore } from "../actions/page";
import { dateToStr, exportToCsv } from "../utils/formatter";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import InputField from "../components/customFields/inputField";
import moment from "moment";
import { getClubs } from "../actions/clubs";
import useCheckin from "../controller/checkin/checkin-controller";
import { toast } from "react-toastify";

class Qguests extends Component {
  constructor(props) {
    super(props);

    this.keymapper = {
      header: [
        "DATE",
        "TIME",
        "MEMBER'S NAME",
        "MEMBER NO.",
        "GUEST'S NAME",
        "PHONE NO.",
        "EMAIL",
        "WITH",
        "FROM",
        "CHECKED IN",
      ],
      keys: [
        "invitationDate",
        "invitationTime",
        "memberName",
        "memberId",
        "guestName",
        "guestMobile",
        "guestEmail",
        "with",
        "checkInFrom",
        "checkInStatus",
      ],
      mapper: {
        checkInFrom: { 1: "App", 2: "Cms" },
      },
    };
    this.state = {
      invitationDate: dateToStr(null, "YYYY-MM-DD"),
      list: [],
      activeClub: "",
      request: true,
      isLoader: false,
    };
  }

  componentDidMount() {
    this.props.getClubs();

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({
        activeClub: this?.props?.activeClub?.[0]?._id,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.checkinControllerData?.allGuestCheckinData !==
      this?.props?.checkinControllerData?.allGuestCheckinData
    ) {
      this.setState({
        list: this?.props?.checkinControllerData?.allGuestCheckinData,
      });
    }

    if (
      prevProps?.checkinControllerData?.isLoading !==
      this?.props?.checkinControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.checkinControllerData?.isLoading,
      });
    }

    if (
      prevProps?.checkinControllerData?.succMsg !==
      this?.props?.checkinControllerData?.succMsg
    ) {
      if (this?.props?.checkinControllerData?.succMsg) {
        this.getList(this.state.invitationDate);
        this.setState({ error: "" });
        // this.props.toastmessage(response.message);
        toast.success(this?.props?.checkinControllerData?.succMsg);
      }
    }

    if (
      prevProps?.checkinControllerData?.errMsg !==
      this?.props?.checkinControllerData?.errMsg
    ) {
      if (this?.props?.checkinControllerData?.errMsg) {
        this.setState({ error: this?.props?.checkinControllerData?.errMsg });
        toast.error(this?.props?.checkinControllerData?.errMsg);
      }
    }

    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      if (
        prevProps !== this.props &&
        prevProps?.activeClub !== null &&
        prevProps?.activeClub?.length !== 0 &&
        this?.props?.activeClub !== null &&
        this?.props?.activeClub?.length !== 0
      ) {
        this.setState({
          activeClub: this?.props?.activeClub?.[0]?._id,
        });
      }
      if (
        this.state.activeClub !== "" &&
        prevState.activeClub !== this.state.activeClub
      ) {
        this.getList(this.state.invitationDate);
      }
    }

    if (
      this?.props?.adminType !== "Super Admin" &&
      this?.props?.adminType !== "Admin" &&
      this?.props?.adminType !== "Developer"
    ) {
      if (
        prevProps !== this.props &&
        this.props.activeClub !== null &&
        this.props.activeClub.length !== 0
      ) {
        this.setState({
          activeClub: this.props.activeClub[0]._id,
        });
      }

      if (this.state.activeClub !== "" && this.state.request) {
        this.getList(this.state.invitationDate);
        this.setState({
          request: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  getList = (date) => {
    if (this?.props?.activeClub?.[0]?._id && date) {
      const { getAllGuestCheckin } = this.props.checkinControllerData;
      const url = `${this?.props?.activeClub?.[0]?._id}?invitationDate=${moment(
        date
      ).format("DD/MM/YYYY")}`;
      getAllGuestCheckin(url);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps?.checkinControllerData?.allGuestCheckinData != prevState.list &&
      !nextProps.isLoading
    ) {
      return {
        list: nextProps?.checkinControllerData?.allGuestCheckinData,
      };
    }
    return null;
  }

  loadData = (e) => {
    let { value, name } = e.target;
    this.setState({ invitationDate: value }, () => {
      this.getList(value);
    });
  };

  changeStatus = (e, item, status) => {
    e.preventDefault();
    const { updateGuestCheckin } = this?.props?.checkinControllerData;
    const payload = {
      guestId: item?._id,
      checkInFrom: 2,
      checkInStatus: (!item.checkInStatus).toString(),
    };
    updateGuestCheckin(payload);
  };

  render() {
    let { list, invitationDate, isLoader } = this.state;
    const { permissions = {} } = this?.props;

    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="d-flex mb-0 align-items-center justify-content-between">
            <div>
              <h3 className="page-title mb-0">Guest Check In</h3>
            </div>
            <div className="row align-items-center">
              <div className="col-auto input-header" style={{ width: "130px" }}>
                <InputField
                  name="invitationDate"
                  groupClass="mbi-0"
                  value={invitationDate}
                  inputChange={this.loadData}
                  dom={{
                    placeholder: "Select Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              {list && list.length > 0 && permissions?.view && (
                <div className="col-auto" style={{ paddingLeft: "0px" }}>
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={() =>
                      exportToCsv(
                        list.map((dt) => ({
                          ...dt,
                          memberName: `${dt?.invitedBy?.fName || ""} ${dt
                            ?.invitedBy?.mName || ""} ${dt?.invitedBy?.lName ||
                            ""}`,
                          memberId: dt?.invitedBy?.mNo,
                          checkInStatus: dt?.checkInStatus ? "YES" : "NO",
                        })),
                        "checked_in_member_" +
                          "_DLon_" +
                          moment().format("YYYY-MM-DD_HH.mm"),
                        this.keymapper
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}
              {permissions?.create && (
                <div className="col-auto" style={{ paddingLeft: "0px" }}>
                  <Link
                    className="btn btn-primary btn-primary-green"
                    to="/invite-guests"
                  >
                    Invite Guest
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="row h-100 overflow-hidden">
            <div className="col-12 h-100">
              <div className="card mt-2 h-100 ">
                <div className="h-100 overflow-x-hidden overflow-hidden">
                  {isLoader && (
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <Loader />
                    </div>
                  )}
                  {!isLoader && (!list || list.length == 0) && (
                    <NoRecord message="No guest found" />
                  )}
                  <div
                    className="container-fluid pti-0 mt-3 h-100 overflow-auto"
                    style={{ paddingTop: "0px  !important" }}
                  >
                    {!isLoader && list && list.length > 0 && (
                      <table className="table table-borderless custom-table">
                        <thead>
                          <tr className="text-center">
                            <th>Date</th>
                            <th>Time</th>
                            <th>Member's Name</th>
                            <th>M.No</th>
                            <th>Guest's Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>With</th>
                            <th>From</th>
                            <th>Checked In</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((item, i) => {
                            return (
                              <tr key={i} className="text-center">
                                <td>{item?.invitationDate}</td>
                                <td>{item?.invitationTime}</td>
                                <td className="capitalize-text">{`${item
                                  ?.invitedBy?.fName || ""} ${item?.invitedBy
                                  ?.mName || ""} ${item?.invitedBy?.lName ||
                                  ""}`}</td>
                                <td>{item?.invitedBy?.mNo || "--"}</td>
                                <td>{item?.guestName}</td>
                                <td>{item?.guestMobile}</td>
                                <td>{item?.guestEmail}</td>
                                <td>{item?.with || 0}</td>
                                <td>
                                  {item?.checkInFrom === 1
                                    ? "App"
                                    : item?.checkInFrom === 2
                                    ? "Cms"
                                    : "NA"}
                                </td>
                                <td>{item?.checkInStatus ? "YES" : "NO"}</td>
                                <td>
                                  {permissions?.update && (
                                    <span
                                      className={`ml20 cursor-pointer fs20`}
                                      onClick={(e) =>
                                        this.changeStatus(e, item)
                                      }
                                    >
                                      <i
                                        className={
                                          item?.checkInStatus
                                            ? "fa fa-close"
                                            : "fa fa-check"
                                        }
                                      ></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { authData }, clubs: { activeClub } }) {
  return {
    activeClub: activeClub,
    adminType: authData?.roleId?.roleName,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const checkinControllerData = useCheckin();

    return (
      <Component {...props} checkinControllerData={checkinControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  resetPageStore,
  getClubs,
})(injectComp(Qguests));
