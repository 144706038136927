import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useIndiaIdea from "../controller/indiaIdea/indiaIdea-controller";
import { toast } from "react-toastify";

class VideoSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allVidCatsData: [],
      isLoader: false,
      isUpdate: false,
      isInit: true,
    };
  }

  componentDidMount() {
    this.handleGetAllVidCats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.indiaIdeaControllerData?.allVidCatsData !==
      this?.props?.indiaIdeaControllerData?.allVidCatsData
    ) {
      this.setState({
        allVidCatsData: this?.props?.indiaIdeaControllerData?.allVidCatsData,
      });
    }
    if (
      prevProps?.indiaIdeaControllerData?.isLoading !==
      this?.props?.indiaIdeaControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.indiaIdeaControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.indiaIdeaControllerData?.allVidCatsData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.indiaIdeaControllerData?.isUpdating !==
      this?.props?.indiaIdeaControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.indiaIdeaControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.indiaIdeaControllerData?.succMsg !==
      this?.props?.indiaIdeaControllerData?.succMsg
    ) {
      if (this?.props?.indiaIdeaControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
        });
        this.handleGetAllVidCats();
        toast.success(this?.props?.indiaIdeaControllerData?.succMsg);
      }
    }
    if (
      prevProps?.indiaIdeaControllerData?.errMsg !==
      this?.props?.indiaIdeaControllerData?.errMsg
    ) {
      if (this?.props?.indiaIdeaControllerData?.errMsg) {
        this.setState({
          error: this?.props?.indiaIdeaControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.indiaIdeaControllerData?.errMsg);
      }
    }
  }

  handleGetAllVidCats = () => {
    const { getAllVidCats } = this?.props?.indiaIdeaControllerData;
    getAllVidCats();
  };

  handleSwitchChange = (e, data) => {
    const { updateVidCat } = this?.props?.indiaIdeaControllerData;
    updateVidCat({ _id: data?._id, isActive: !data?.isActive });
  };

  render() {
    let { allVidCatsData, isLoader, isUpdate, isInit } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/india-of-ideas">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Video Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th style={{ width: 500 }}>
                        <h4>Video Categories - Drag to Reorder</h4>
                      </th>
                      <th>
                        <ModalButton
                          data={{
                            getAllVidCats: this.handleGetAllVidCats,
                            showCustomModal: true,
                            title: "Add New Video Category",
                            path: "VideoCategory/addVideoCategory",
                            body: "",
                            className: "modal-small",
                          }}
                        >
                          <h3
                            style={{
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isInit && isLoader && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {!isLoader && allVidCatsData?.length === 0 && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <NoRecord />
                      </div>
                    )}
                    {allVidCatsData &&
                      allVidCatsData.length > 0 &&
                      allVidCatsData?.map((cat) => {
                        return (
                          <tr key={cat?._id}>
                            <td>{cat?.title}</td>
                            <td className="d-flex align-items-center">
                              <ModalButton
                                data={{
                                  getAllVidCats: this.handleGetAllVidCats,
                                  showCustomModal: true,
                                  title: `Update Video Category For ${cat?.title}`,
                                  itemData: cat,
                                  path: "VideoCategory/addVideoCategory",
                                  body: "",
                                  className: "modal-small",
                                }}
                              >
                                <h3
                                  style={{
                                    marginRight: 10,
                                    marginBottom: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-edit"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                              <InputField
                                value={cat?.isActive}
                                checked={cat?.isActive}
                                inputChange={(e) =>
                                  isUpdate
                                    ? {}
                                    : this.handleSwitchChange(e, cat)
                                }
                                dom={{
                                  type: "checkbox",
                                  ele: "switch",
                                  label: "rte",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ page: { isLoader = false } }) {
  return { isLoader };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const indiaIdeaControllerData = useIndiaIdea();
    return (
      <Component {...props} indiaIdeaControllerData={indiaIdeaControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {})(injectComp(VideoSettings));
