import React, { Component } from 'react';
class Footer extends Component {
  render() {
    return (
      <footer className="page-footer font-small blue">
                <div className="footer-copyright text-center py-2">Platform Supported by:
                    <a href=""> Incred Applications Pvt. Ltd. ©</a>
                </div>
            </footer>
    )
  }
}

export default Footer;
