import React, { Component } from "react";
import { connect } from "react-redux";
import { Endpoint } from "./../../utils/constants";
import { updateQpayData } from "./../../actions/qpay";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import moment from "moment";

class TermsLogs extends Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }
  initializeState = () => {
    return {
      isLoader: false,
      list: [],
    };
  };
  componentDidMount() {
    this.getLogs();
  }
  getLogs() {
    this.props.updateQpayData(
      {
        url: `${Endpoint.QPAY_TERMS_LOGS}`,
        method: "GET",
      },
      (response) => {
        if (response.status) {
          this.setState({ list: [response.data], isLoader: true });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  }
  render() {
    let { isLoader, list } = this.state;
    return (
      <React.Fragment>
        {!isLoader && <Loader />}
        {isLoader && (!list || list.length == 0) && (
          <NoRecord message="No Logs Found" />
        )}
        {isLoader && list && list.length > 0 && (
          <div className="row margin-top-15">
            <div className="col-12">
              <div className="overflow-auto-div">
                <table className="table table-borderless custom-table">
                  <thead>
                    <tr className="text-center">
                      <th>S.No</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Type Of Action</th>
                      <th>Action By</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {list.map((element, key) => {
                        return (
                          <tr key={key} className="text-center">
                            <td>{key + 1}</td>
                            <td>
                              {moment(element.createdAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}
                            </td>
                            <td>
                              {moment(element.updatedAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}
                            </td>
                            <td>{element.typeOfAction}</td>
                            <td className="capitalize-text">{element?.actionBy?.fullName}</td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({}) {
  return {};
}
export default connect(mapStateToProps, { updateQpayData })(TermsLogs);
