import queryString from 'query-string';
export function historyPushstate(path) {
  if (window.history.pushState) {
    var newurl = window.location.protocol + '//' + window.location.host + path;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}
// export function historyReplacestate(path) {
//   if (window.history.replaceState) {
//     var newurl = window.location.protocol + '//' + window.location.host + path;
//     window.history.replaceState({ path: newurl }, '', newurl);
//   }
// }
export function historyReplacestate(url = '', q = '') {
  if (window.history.replaceState) {
      let i = window.location.pathname.indexOf('?')
      let path = url ? url : (i > -1 ? window.location.pathname.substring(0, i) + '' + q : window.location.pathname + '' + q);
      var newurl = window.location.protocol + "//" + window.location.host + path
      window.history.replaceState({ path: newurl }, '', newurl);
  }
  // history.pushState(null, '', '/en/step2');
}
export function getQueryParams() {
  return queryString.parse(window.location.search);
}
