import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useReservation = () => {
  const [allEmails, setAllEmails] = useState([]);
  const [allSpaces, setAllSpaces] = useState([]);
  const [allResCat, setAllResCat] = useState([]);
  const [allReservations, setAllReservations] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllEmails = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.PRIVATE_HIRE_EMAILS}${id}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllEmails(response?.data?.emailIDs)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addEmail = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.PRIVATE_HIRE_EMAIL}${data.cid}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateEmail = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.PRIVATE_HIRE_EMAIL}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setIsUpdating(false);
      setErrMsg("Something went wrong");
    }
  };

  const getAllPHSpaces = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.PRIVATE_HIRE_SPACE}${id}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllSpaces(response?.data?.spaces)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addPHSpace = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.PRIVATE_HIRE_SPACE}${data.cid}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updatePHSpace = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.PRIVATE_HIRE_SPACE}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setIsUpdating(false);
      setErrMsg("Something went wrong");
    }
  };

  const getAllResCategory = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.RESERVATION}${Endpoint.CATEGORIES}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllResCat(response?.data?.categories)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addResCategory = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.RESERVATION}${Endpoint.CATEGORY}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateResCategory = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.RESERVATION}${Endpoint.CATEGORY}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setIsUpdating(false);
      setErrMsg("Something went wrong");
    }
  };

  const getAllReservations = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.RESERV}${url}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setAllReservations(response?.data?.reservations)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addReservation = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${Endpoint.RESERV}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateReservation = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.RESERV}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setIsUpdating(false);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allEmails,
    allSpaces,
    allResCat,
    allReservations,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllEmails,
    addEmail,
    updateEmail,
    getAllPHSpaces,
    addPHSpace,
    updatePHSpace,
    getAllResCategory,
    addResCategory,
    updateResCategory,
    getAllReservations,
    addReservation,
    updateReservation,
  };
};

export default useReservation;
