import { all } from 'redux-saga/effects';

import auth from './auth';
import page from './page';
import qpayAuth from './qpay-auth';
import qpay from './qpay';

export default function* IndexSaga() {
  yield all([
    auth(), page(), qpayAuth(), qpay()
  ]);
}
