import 'whatwg-fetch';
import { setCookie, getCookie } from "../services/session";
import { ErrorCode, API_Q_PAY_BASE_PATH, Endpoint } from './constants';

function parseTextResponse(response) {
    try {
        return JSON.parse(response);
    } catch (e) {
        return response;
    }
}
function parseResponse(response) {
    if (response instanceof Response) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json();
        }
        return response.text().then(parseTextResponse);
    }
    return new Promise(function (resolve) {
        resolve(parseTextResponse(response));
    });
}
function checkStatus(response) {
    if (response instanceof Response) {
        if (response.status >= 200 && response.status < 500) {
            return response;
        }
        const error = new Error(response.statusText);
        error.responseCode = ErrorCode.SOMETHING_WRONG;
        error.response = response;
        throw error;
    }
    return new Promise(function (resolve) {
        resolve(response);
    });
}
function makeRequest(url, options, emptyHeader) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(options.method, url);
        if (!emptyHeader) {
            xhr.setRequestHeader(
                'Content-Type',
                options.headers && options.headers['Content-Type']
                    ? options.headers['Content-Type']
                    : 'application/json; charset=UTF-8"'
            );
            if (options.headers && Object.keys(options.headers).length > 1) {
                for (var header in options.headers) {
                    if (header !== 'Content-Type')
                        xhr.setRequestHeader(header, options.headers[header]);
                }
            }
        }
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 500) {
                if (this.status === 401) {
                    var data = JSON.stringify({
                        "refreshToken": getCookie("qpay-refresh-token", "")
                    });
                    var xhr1 = new XMLHttpRequest();

                    xhr1.open('POST', `${API_Q_PAY_BASE_PATH}${Endpoint.QPAY_GENERATE_TOKEN}`);
                    xhr1.addEventListener("readystatechange", function () {
                        if (this.readyState === 4) {
                            setCookie("qpay-token", JSON.parse(this.responseText).accessToken);
                            // Recall api
                            setTimeout(() => {
                                var xhrRecall = new XMLHttpRequest();
                                xhrRecall.open(options.method, url);
                                if (!emptyHeader) {
                                    options.headers['Authorization'] = 'Bearer ' + JSON.parse(this.responseText).accessToken;
                                    xhrRecall.setRequestHeader(
                                        'Content-Type',
                                        options.headers && options.headers['Content-Type']
                                            ? options.headers['Content-Type']
                                            : 'application/json; charset=UTF-8"'
                                    );
                                    if (options.headers && Object.keys(options.headers).length > 1) {
                                        for (var header in options.headers) {
                                            if (header !== 'Content-Type')
                                                xhrRecall.setRequestHeader(header, options.headers[header]);
                                        }
                                    }
                                }
                                options.body ? xhrRecall.send(options.body) : xhrRecall.send();
                                xhrRecall.onload = function () {
                                    if (this.status == 401) {
                                        reject({
                                            status: this.status,
                                            statusText: xhrRecall.statusText
                                        });
                                    } else {
                                        resolve(xhrRecall.response);
                                    }
                                }
                            }, 100);
                        }
                    });
                    xhr1.setRequestHeader("Content-Type", "application/json");
                    xhr1.send(data);
                } else {
                    resolve(xhr.response);
                }
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        options.body ? xhr.send(options.body) : xhr.send();
    });
}
function XHR(url, options, emptyHeader) {
    return new Promise(function (resolve, reject) {
        const xhr = makeRequest(url, options, emptyHeader);
        xhr.then(function (response) {
            resolve(response);
        }).catch(function () {
            reject();
        });
    });
}
export default function requestQpay(url, options, emptyHeader = false) {
    let ajaxApi = XHR(url, options, emptyHeader);
    return ajaxApi
        .then(checkStatus)
        .then(parseResponse)
        .catch(function (error) {
            return { apiError: true, error };
        });
}
