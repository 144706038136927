import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useCheckin = () => {
  const [allMemberCheckinData, setAllMemberCheckinData] = useState([]);
  const [allGuestCheckinData, setAllGuestCheckinData] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllMemberCheckin = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.MEMBER}${Endpoint.CHECKIN}${url}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllMemberCheckinData(response?.data?.checkins)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addMemberCheckin = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.MEMBER}${Endpoint.CHECKIN}${data?.cid}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateMemberCheckin = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.MEMBER}${Endpoint.CHECKIN}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const deleteMemberCheckin = async (id, cb) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(
        `${Endpoint.MEMBER}${Endpoint.CHECKIN}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllGuestCheckin = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.GUEST}${Endpoint.CHECKIN}${url}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllGuestCheckinData(response?.data?.checkins)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addGuestCheckin = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.GUESTINVITATION}${data.clubid}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateGuestCheckin = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.GUEST}${Endpoint.CHECKIN}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allMemberCheckinData,
    allGuestCheckinData,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllMemberCheckin,
    addMemberCheckin,
    updateMemberCheckin,
    deleteMemberCheckin,
    getAllGuestCheckin,
    addGuestCheckin,
    updateGuestCheckin,
  };
};

export default useCheckin;
