/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import { resetPageStore } from "../actions/page";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useIndiaIdea from "../controller/indiaIdea/indiaIdea-controller";
import InputField from "../components/customFields/inputField";
import { toast } from "react-toastify";
import Pagination from "../components/pagination/pagination";

class IndiaofIdeas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isinit: true,
      list: [],
      totalCount: "",
      category: "",
      isLoaded: false,
      isLoader: false,
      page: 1,
    };
  }
  componentDidMount() {
    const { getAllVidCats } = this?.props?.indiaIdeaControllerData;
    this.getList();
    getAllVidCats();
  }
  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  getList = (page = this.state.page) => {
    const { getAllIndiaIdea } = this?.props?.indiaIdeaControllerData;
    const url = this?.state?.category
      ? `?page=${page}&limit=20&videoCatId=${this?.state?.category}`
      : `?page=${page}&limit=20`;
    getAllIndiaIdea(url);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      JSON.stringify(
        nextProps?.indiaIdeaControllerData?.allIndiaIdeaData?.indiaIdeas
      ) !== JSON.stringify(prevState.list) &&
      prevState.isinit &&
      nextProps?.indiaIdeaControllerData?.allIndiaIdeaData?.indiaIdeas
        ?.length &&
      !prevState.isLoader
    ) {
      return {
        list: nextProps?.indiaIdeaControllerData?.allIndiaIdeaData?.indiaIdeas,
        totalCount:
          nextProps?.indiaIdeaControllerData?.allIndiaIdeaData?.totalRec,
        isinit: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.indiaIdeaControllerData?.allVidCatsData !=
      this?.props?.indiaIdeaControllerData?.allVidCatsData
    ) {
      const catOptData = this?.props?.indiaIdeaControllerData?.allVidCatsData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        catOpt: catOptData,
      });
    }

    if (
      prevProps?.indiaIdeaControllerData?.allIndiaIdeaData !==
      this?.props?.indiaIdeaControllerData?.allIndiaIdeaData
    ) {
      this.setState({
        list: this?.props?.indiaIdeaControllerData?.allIndiaIdeaData
          ?.indiaIdeas,
        totalCount: this?.props?.indiaIdeaControllerData?.allIndiaIdeaData
          ?.totalRec,
      });
    }

    if (
      prevProps?.indiaIdeaControllerData?.isLoading !==
      this?.props?.indiaIdeaControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.indiaIdeaControllerData?.isLoading,
      });
    }

    if (
      prevProps?.indiaIdeaControllerData?.errMsg !==
      this?.props?.indiaIdeaControllerData?.errMsg
    ) {
      if (this?.props?.indiaIdeaControllerData?.errMsg) {
        toast.error(this?.props?.indiaIdeaControllerData?.errMsg);
      }
    }

    if (
      prevProps?.indiaIdeaControllerData?.succMsg !==
      this?.props?.indiaIdeaControllerData?.succMsg
    ) {
      if (this?.props?.indiaIdeaControllerData?.succMsg) {
        toast.success(this?.props?.indiaIdeaControllerData?.succMsg);
      }
    }

    if (prevState?.category !== this?.state?.category) {
      this.setState(
        {
          page: 1,
        },
        () => {
          this.getList();
        }
      );
    }
  }

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      return this.props.history.push("/video-settings");
    }
  };

  render() {
    let { list, isLoader, catOpt, category } = this.state;
    let { authData, permissions = {} } = this.props;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundImage: `url(${item?.thumbnail})`,
      };
      return (
        <div className="card news-card  p-0 h-100 ">
          <div className=" card-header border-0  ps-2 pe-2 pt-2">
            <div className="col-12">
              <div className="default-font page-title-box">{item?.title}</div>
            </div>
          </div>
          <div className="card-body p-0">
            {permissions?.update || permissions?.delete ? (
              <div className="absolute-top-right  card-absolute-header  news-header">
                <div className="d-flex align-items-center">
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      showCustomModal: true,
                      id: item?._id,
                      itemData: item,
                      permissions,
                      catOpt,
                      title: "Edit An India Of Idea",
                      data: item,
                      path: "IndiaofIdeas/indiaofideas",
                      body: "",
                      className: "modal-small",
                    }}
                  >
                    <i className="fa fa-edit" />
                  </ModalButton>
                </div>
              </div>
            ) : null}
            <div className="image-style" style={imagestyle}>
              <img
                src="./images/imagesize16_9.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div></div>
            <div className="col-auto">
              <h3 className="page-title mb-0">An India Of Ideas</h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              <div style={{ width: 212 }}>
                <InputField
                  name="category"
                  value={category}
                  groupClass="mb-0"
                  inputChange={(e) =>
                    this.setState({ category: e.target.value })
                  }
                  options={catOpt}
                  dom={{ placeholder: "Select", type: "select" }}
                />
              </div>
              &nbsp; &nbsp;
              {authData?.roleId?.roleName === "Developer" ? (
                <div onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp; &nbsp;
              {permissions?.create ? (
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    showCustomModal: true,
                    permissions,
                    catOpt,
                    title: "Add An India Of Ideas",
                    path: "IndiaofIdeas/indiaofideas",
                    body: "",
                    className: "modal-small",
                  }}
                >
                  Add New
                </ModalButton>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className=" px-0">
                {!isLoader && list.length > 0 && (
                  <div className="row ">
                    {list.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-3  mb-3"
                          style={{ width: "20%", paddingRight: "3px" }}
                        >
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="border-top-0 pt-3">
              {!isLoader && list?.length !== 0 && (
                <Pagination
                  paging={{
                    count: this.state.totalCount,
                    rows: 20,
                    page: this.state.page,
                  }}
                  onPageClick={(page) => {
                    this.setState(
                      (prevState) => {
                        return {
                          ...prevState,
                          page: page,
                        };
                      },
                      () => {
                        this.getList(page);
                      }
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { authData = {} } }) {
  return { authData };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const indiaIdeaControllerData = useIndiaIdea();
    return (
      <Component {...props} indiaIdeaControllerData={indiaIdeaControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  resetPageStore,
})(injectComp(IndiaofIdeas));
