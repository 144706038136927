import { GET_QPAY_MENU, GET_QPAY_ROLES, Q_PAY_LIST_DATA, Q_PAY_UPDATE_DATA, Q_PAY_LISTS_DATA } from "../actions/constants";

const INITIAL_STATE = {
    menu: [],
    roles: [],
    list: [],
    lists: [],
    updatedData: [],
    isLoader: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case `SAGA/${GET_QPAY_MENU}`:
            return { ...state, menu: [], isLoader: false };
        case `REDUCER/${GET_QPAY_MENU}`:
            return { ...state, isLoader: true, menu: action.data };

        case `REDUCER/${GET_QPAY_ROLES}`:
            return { ...state, roles: action.data };

        case `SAGA/${Q_PAY_UPDATE_DATA}`:
            return { ...state, updatedData: {} };
        case `REDUCER/${Q_PAY_UPDATE_DATA}`:
            return { ...state, updatedData: action.data };

        case `SAGA/${Q_PAY_LIST_DATA}`:
            return { ...state, list: [] };
        case `REDUCER/${Q_PAY_LIST_DATA}`:
            return { ...state, list: action.data };

        case `SAGA/${Q_PAY_LISTS_DATA}`:
            return { ...state, lists: [] };
        case `REDUCER/${Q_PAY_LISTS_DATA}`:
            return { ...state, lists: action.data };

        default:
            return state;
    }
}
