import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useCompany = () => {
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAllCompany = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.COMPANY}${url ? url : ""}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllCompanyData(response?.data?.companies)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  return {
    allCompanyData,
    errMsg,
    isLoading,
    getAllCompany,
  };
};

export default useCompany;
