import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import AWS from "aws-sdk";
import InputField from "../customFields/inputField";
import { getValue } from "../../utils/formatter";
import { validate } from "../../utils/validation";
import { isObject, isObjEmpty } from "../../utils";
import { CLUB_NAME, GENDER, MEMBER_TITLE } from "../../utils/constants";
import { updateModalData, resetPageStore } from "../../actions/page";
import { S3_BUCKET_CRED } from "../../utils/constants";
import Uploader from "../customFields/uploader";
import "../../style/backup.scss";
import useStaff from "../../controller/staff/staff-controller";
import moment from "moment";
import { toast } from "react-toastify";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

class Staffmember extends Component {
  constructor(props) {
    super(props);
    this.state = this.intialiseState();
  }

  intialiseState = (data = {}, isinit = true) => {
    data = {
      ...data,
      joiningDate: data?.joiningDate
        ? moment.unix(data?.joiningDate).format("YYYY-MM-DD")
        : "",
      dob: data?.dob ? moment.unix(data?.dob).format("YYYY-MM-DD") : "",
    };
    return {
      roleId: data?.roleId ? getValue(data?.roleId, "_id", "") : "",
      salutation: getValue(data, "salutation", 1),
      photo: getValue(data, "photo", ""),
      fName: getValue(data, "fName", ""),
      mName: getValue(data, "mName", ""),
      lName: getValue(data, "lName", ""),
      mobile: getValue(data, "mobile", ""),
      gender: getValue(data, "gender", 1),
      email: getValue(data, "email", ""),
      dob: getValue(data, "dob", null),
      address: getValue(data, "address", ""),
      jobTitle: getValue(data, "jobTitle", ""),
      joiningDate: getValue(data, "joiningDate", null),
      note: getValue(data, "note", ""),
      roleName: data?.roleId ? getValue(data?.roleId, "roleName", "") : "",
      errorMessages: {},
      isValid: {},
      clubAccess:
        data?.clubAccess?.length > 0 ? data?.clubAccess?.map((d) => d._id) : [],
      isinit,
      isSaving: false,
    };
  };

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params.mid) {
      this.handleGetStaff(params.mid);
    }

    this.handleGetAllRoles();
  }

  componentWillUnmount() {
    this.props.resetPageStore("individualItem");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.staffControllerData?.staffData !=
        this?.props?.staffControllerData?.staffData &&
      prevState.isinit &&
      isObject(this?.props?.staffControllerData?.staffData) &&
      !isObjEmpty(this?.props?.staffControllerData?.staffData)
    ) {
      let stateD = this.intialiseState(
        this?.props?.staffControllerData?.staffData?.staff,
        false
      );
      this.setState(stateD);
    }
    if (
      prevProps?.staffControllerData?.allRolesData !==
      this?.props?.staffControllerData?.allRolesData
    ) {
      const rolesData = this?.props?.staffControllerData?.allRolesData;
      const obj = rolesData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: capitalize(value.roleName),
          };
        }, {});
      this.setState({
        CLEARANCE_LEVEL: obj,
      });
    }
    if (
      prevProps?.staffControllerData?.succMsg !==
      this?.props?.staffControllerData?.succMsg
    ) {
      if (this?.props?.staffControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
        });
        this.props.history.push("/member-list/staf-member");
        toast.success(this?.props?.staffControllerData?.succMsg);
      }
    }
    if (
      prevProps?.staffControllerData?.errMsg !==
      this?.props?.staffControllerData?.errMsg
    ) {
      if (this?.props?.staffControllerData?.errMsg) {
        this.setState({
          error: this?.props?.staffControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.staffControllerData?.errMsg);
      }
    }
  }

  handleGetAllRoles = () => {
    const { getAllRoles } = this?.props?.staffControllerData;
    getAllRoles();
  };

  handleGetStaff = (id) => {
    const { getStaff } = this?.props?.staffControllerData;
    getStaff(id);
  };

  validate = (name, value) => {
    let notr = ["mName", "photo"];
    if (notr.indexOf(name) > -1) {
      return;
    }
    let { isValid, errorMessages } = this.state;
    let error = validate(name, value, { type: "text" });
    isValid[name] = error.isValid;
    error.isValid
      ? delete errorMessages[name]
      : (errorMessages[name] = error.errorMsg);
    this.setState({ isValid, errorMessages });
  };

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    if (name === "fName" || name === "mName" || name === "lName") {
      value = value.replace(/[^A-Za-z]/gi, "");
    }
    if (name === "joiningDate" || name === "dob") {
      value = value;
    }
    this.setState({ [name]: value }, () => {
      this.validate(name, value);
    });
  };

  onSubmit = (e) => {
    [
      "fName",
      "mobile",
      "email",
      "jobTitle",
      "joiningDate",
      "gender",
      "clubAccess",
      "roleId",
    ].forEach((name) => {
      let value = this.state[name];
      this.validate(name, value);
    });
    if (Object.keys(this.state.errorMessages).length === 0) {
      this.setState({
        error: "",
        isSaving: true,
      });
      let payload = Object.assign({}, this.state);
      payload.joiningDate = moment(this.state.joiningDate).unix();
      payload.dob = moment(this.state.dob).unix();

      let {
        match: { params },
      } = this.props;
      const { addStaff, updateStaff } = this?.props?.staffControllerData;
      if (params.mid)
        payload._id =
          this?.props?.staffControllerData?.staffData?.staff?._id || "";
      delete payload.errorMessages;
      delete payload.isValid;
      delete payload.isUploader;
      delete payload.bulkFiles;
      delete payload.CLEARANCE_LEVEL;
      delete payload.CLUBS;
      // delete payload.clubAccess;
      delete payload.isImageAdded;
      delete payload.isinit;
      delete payload.isSaving;
      delete payload.error;
      !params.mid && delete payload._id;
      !params.mid && delete payload.isActive;
      params.mid ? updateStaff(payload) : addStaff(payload);
    }
  };

  onDrop = (files, r) => {
    if (files.length) {
      this.setState({ bulkFiles: files });
      this.toggleUploader(true);
    } else if (r.length) {
      this.props.updateModalData({
        showCustomModal: true,
        title: "",
        body: "Only jpg, jpeg and png files are allowed",
      });
    }
  };

  toggleUploader = (isUploader) => {
    this.setState({ isUploader });
  };
  setBulkPhotos = (bulkCollection) => {
    let a = bulkCollection.filter((e) => e.type === "selected");
    if (a.length === bulkCollection.length) {
      for (let i = 0; i < bulkCollection.length; i++) {
        const file = bulkCollection[i];
        new Promise((resolve, reject) => {
          var fileName = bulkCollection[i].file.name.split(".").pop();
          AWS.config.update({
            accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
            secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
            region: S3_BUCKET_CRED.BUCKET_REGION,
          });
          var s3 = new AWS.S3();
          var params = {
            Bucket: S3_BUCKET_CRED.BUCKET_NAME,
            Key: "asset/" + Date.now() + "." + fileName,
            ContentType: file.file.type,
            Body: dataURLtoFile(file.thumbnail, file.fileName),
            ACL: "public-read",
          };

          s3.putObject(params, async (err, res) => {
            if (err) {
            } else {
              let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

              this.setState({
                photo: link,
                isUploader: false,
                isImageAdded: true,
              });

              try {
                resolve(link);
              } catch (e) {
                reject(e);
              }
            }
          });
        });
      }
    }
  };

  delete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { deletestaff } = this?.props?.staffControllerData;
    deletestaff(id);
  };

  handleChangeClubs = (e) => {
    // var options = e.target.options;
    // var value = [];
    // for (var i = 0, l = options.length; i < l; i++) {
    //   if (options[i].selected && options[i].value !== "") {
    //     value.push(options[i].value);
    //   }
    // }
    var value = e?.length > 0 ? e.map((d) => d.value) : [];
    this.setState({ clubAccess: value });
  };

  render() {
    let {
      error,
      isUploader,
      bulkFiles = [],
      isSaving,
      photo,
      fName,
      mName,
      lName,
      mobile,
      email,
      dob,
      address,
      jobTitle,
      joiningDate,
      gender,
      note,
      errorMessages,
      isValid,
      CLEARANCE_LEVEL = {},
      salutation,
      roleName,
    } = this.state;
    let {
      match: { params },
    } = this.props;
    if (isUploader) {
      return (
        <div className="mt30 memberUploader">
          <Uploader
            files={bulkFiles}
            onComplete={this.setBulkPhotos}
            onBack={this.toggleUploader}
            folder={"temp"}
            id={""}
            uploadType="userImage"
            isSideNav={false}
            not_upload={true}
            isPopup={true}
            config={{ aspectRatio: "p2" }}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="d-flex pb-3 pt-1 mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">
                <Link
                  to={params.mid ? "/member-list/staf-member" : "/admin-access"}
                >
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;
                {params.mid ? "Edit Staff Member" : "Create A Staff Member"}
              </h3>
            </div>
          </div>
          <div className="card mt-2 h-100 overflow-x-hidden overflow-auto">
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-12 profile-radio-button">
                      <InputField
                        name="salutation"
                        groupClass="mbi-0"
                        value={salutation}
                        isValid={isValid.salutation}
                        errorMsg={errorMessages.salutation}
                        inputChange={this.inputChange}
                        options={MEMBER_TITLE}
                        dom={{ type: "radio-custom" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="fName"
                        label="First Name"
                        value={fName}
                        isValid={isValid.fName}
                        errorMsg={errorMessages.fName}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="mName"
                        label="Middle Name"
                        value={mName}
                        isValid={isValid.mName}
                        errorMsg={errorMessages.mName}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="lName"
                        label="Last Name"
                        value={lName}
                        isValid={isValid.lName}
                        errorMsg={errorMessages.lName}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="mobile"
                        label="Phone Number"
                        value={mobile}
                        isValid={isValid.mobile}
                        errorMsg={errorMessages.mobile}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "", type: "number" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="email"
                        label="Email Id"
                        value={email}
                        isValid={isValid.email}
                        errorMsg={errorMessages.email}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "", type: "email" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="dob"
                        label="DOB"
                        value={dob}
                        isValid={isValid.dob}
                        errorMsg={errorMessages.dob}
                        inputChange={this.inputChange}
                        dom={{
                          placeholder: "",
                          type: "date",
                          maxDate: new Date(),
                          dateFormat: "dd/MM/yyyy",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row">
                    <div className="col-5 staff-member">
                      <label className="form-label">Photo</label>
                      <Dropzone
                        style={{ position: "relative" }}
                        accept="image/jpeg, image/png, image/jpg"
                        multiple={false}
                        onDrop={(accepted, rejected) => {
                          this.onDrop(accepted, rejected);
                        }}
                      >
                        <div
                          className={
                            "image-style upload-photo " + (photo && "uploadtxt")
                          }
                          style={{ backgroundImage: `url(${photo})` }}
                        >
                          <img
                            width={65}
                            src={"../../images/imagesize2_3.png"}
                            className="w100per"
                          />
                        </div>
                      </Dropzone>
                    </div>
                    <div className="col-7">
                      <InputField
                        name="gender"
                        label="Gender"
                        groupClass="mbi-0"
                        value={gender}
                        isValid={isValid.gender}
                        errorMsg={errorMessages.gender}
                        inputChange={this.inputChange}
                        options={GENDER}
                        dom={{ type: "select" }}
                      />
                      <InputField
                        name="joiningDate"
                        label="Joining Date"
                        value={joiningDate}
                        isValid={isValid.joiningDate}
                        errorMsg={errorMessages.joiningDate}
                        inputChange={this.inputChange}
                        dom={{
                          placeholder: "",
                          type: "date",
                          dateFormat: "dd/MM/yyyy",
                        }}
                      />
                    </div>
                    <div className="col-12 mt-1">
                      <InputField
                        name="jobTitle"
                        label="Job Title"
                        value={jobTitle}
                        isValid={isValid.jobTitle}
                        errorMsg={errorMessages.jobTitle}
                        inputChange={this.inputChange}
                        dom={{ placeholder: "" }}
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        name="roleId"
                        label="Staff Category"
                        value={this.state.roleId}
                        isValid={isValid.roleId}
                        errorMsg={errorMessages.roleId}
                        inputChange={this.inputChange}
                        options={
                          roleName === "Developer"
                            ? { "637a2574878dc217854664e2": "Developer" }
                            : CLEARANCE_LEVEL
                        }
                        dom={{ placeholder: "Select", type: "select" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row">
                    <div className="col-12">
                      <label className="form-label">Address</label>
                      <textarea
                        className="form-control"
                        name="address"
                        rows="3"
                        value={address}
                        onChange={this.inputChange}
                      ></textarea>
                      {!isValid.address && (
                        <p className="error_msg"> {errorMessages.address}</p>
                      )}
                    </div>
                    <div className="col-12 mt-1">
                      <InputField
                        name="clubAccess"
                        label="Club Access"
                        value={this.state.clubAccess}
                        inputChange={this.handleChangeClubs}
                        options={CLUB_NAME}
                        dom={{
                          placeholder: "Select",
                          type: "select",
                          multiple: true,
                        }}
                        isValid={isValid.clubAccess}
                        errorMsg={errorMessages.clubAccess}
                      />
                    </div>
                    <div className="col-12">
                      <label className="form-label">Note</label>
                      <textarea
                        className="form-control"
                        name="note"
                        rows="8"
                        value={note}
                        onChange={this.inputChange}
                        placeholder=""
                      ></textarea>
                      {!isValid.note && (
                        <p className="error_msg"> {errorMessages.note}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer border-none bg-white">
              <div className="col-12">
                {error && <p className="error_msg"> {error}</p>}
                <div className="text-end">
                  {params.mid ? (
                    <Button
                      onClick={(e) =>
                        this.delete(
                          e,
                          this?.props?.staffControllerData?.staffData?.staff
                            ?._id
                        )
                      }
                      className={"btn btn-primary btn-primary-green"}
                      style={{ marginRight: "10px" }}
                    >
                      Delete
                    </Button>
                  ) : null}
                  <Button
                    className={`btn btn-primary btn-primary-green ${
                      isSaving ? "btndisabled" : ""
                    }`}
                    onClick={this.onSubmit}
                  >
                    {params.mid ? "Update" : "Save"}
                  </Button>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({}) {
  return {};
}
const mapDispatchToProps = function(dispatch) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    updateModalData: (myParam) => dispatch(updateModalData(myParam)),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staffControllerData = useStaff();
    return <Component {...props} staffControllerData={staffControllerData} />;
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(Staffmember));
