import React, { useEffect } from "react";
import useAppAnalytics from "../../controller/app-analytics/app-analytics-controller";
import { toast } from "react-toastify";

const AppAnalytics = (props) => {
  const { clubs } = props;
  const { appStats, getAppStatistics, errMsg } = useAppAnalytics();

  useEffect(() => {
    if (clubs?.length > 0) {
      const ids = clubs.map((d) => d?._id);
      const url = `?location=${ids.toString()}`;
      getAppStatistics(url);
    }
  }, [clubs]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  return (
    <>
      <div className="row card-analytics">
        <div className="col-3">
          <div className="box">
            <label>Unique Member Visits in Range</label>
            <div className="val_box">
              <span>{appStats?.uniqueMemberVisit || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Member Visits in Range</label>
            <div className="val_box">
              <span>{appStats?.totalMemberVisit || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Section Visits in Range</label>
            <div className="val_box">
              <span>{appStats?.totalSection || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total New Members Joining App in Range</label>
            <div className="val_box">
              <span>{appStats?.totalNewJoining || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Super Active Members in Last 60 Days</label>
            <div className="val_box">
              <span>{appStats?.totalSuperActive || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Active Members in Last 60 Days</label>
            <div className="val_box">
              <span>{appStats?.totalActive || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Passive Members in Last 60 Days</label>
            <div className="val_box">
              <span>{appStats?.totalPassive || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Dormant Members in Last 60 Days</label>
            <div className="val_box">
              <span>{appStats?.totalDormant || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Check ins Via App in Range</label>
            <div className="val_box">
              <span>{appStats?.totalCheckInApp || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Check ins Via CMS in Range</label>
            <div className="val_box">
              <span>{appStats?.totalCheckInCms || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total iOS Users in App</label>
            <div className="val_box">
              <span>{appStats?.totalIos || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Android Users in App</label>
            <div className="val_box">
              <span>{appStats?.totalAndroid || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Members Logged in App</label>
            <div className="val_box">
              <span>{appStats?.totalLoggedIn || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Members in DB</label>
            <div className="val_box">
              <span>{appStats?.totalMembersDB || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Active Members in DB</label>
            <div className="val_box">
              <span>{appStats?.totalActiveInDB || "0"}</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="box">
            <label>Total Inactive Members in DB</label>
            <div className="val_box">
              <span>{appStats?.totalInActiveDB || "0"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AppAnalytics;
