import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import { isObjEmpty } from "../utils";
import Loader from "../components/customFields/loader";
import InputField from "../components/customFields/inputField";
import NoRecord from "../components/customFields/noRecord";
import useQPerks from "../controller/q-perks/q-perks-controller";
import Pagination from "../components/pagination/pagination";
import { toast } from "react-toastify";

class Qspecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      isinit: true,
      list: [],
      totalCount: "",
      qsCatList: {},
      isLoader: true,
      page: 1,
    };
  }
  componentDidMount() {
    const { getAllCategories } = this?.props?.qPerksControllerData;
    getAllCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updatedData != prevProps.updatedData &&
      !isObjEmpty(this.props.updatedData) &&
      this.props.updatedData.page === "qspecial"
    ) {
      this.setState({ category: "" });
      this.props.resetPageStore("updatedData");
    }

    if (
      prevProps?.qPerksControllerData?.allQPerksData !==
      this?.props?.qPerksControllerData?.allQPerksData
    ) {
      this.setState({
        list: this?.props?.qPerksControllerData?.allQPerksData?.qperks,
        totalCount: this?.props?.qPerksControllerData?.allQPerksData?.total_rec,
      });
    }

    if (
      prevProps?.qPerksControllerData?.allCategoriesData !=
      this?.props?.qPerksControllerData?.allCategoriesData
    ) {
      const optData = this?.props?.qPerksControllerData?.allCategoriesData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState(
        {
          qsCatList: optData,
          page: 1,
        },
        () => {
          this.getList();
        }
      );
    }

    if (
      prevProps?.qPerksControllerData?.isLoading !==
      this?.props?.qPerksControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.qPerksControllerData?.isLoading,
      });
    }

    if (
      prevProps?.qPerksControllerData?.errMsg !==
      this?.props?.qPerksControllerData?.errMsg
    ) {
      if (this?.props?.qPerksControllerData?.errMsg) {
        toast.error(this?.props?.qPerksControllerData?.errMsg);
      }
    }

    if (
      prevProps?.qPerksControllerData?.succMsg !==
      this?.props?.qPerksControllerData?.succMsg
    ) {
      if (this?.props?.qPerksControllerData?.succMsg) {
        toast.success(this?.props?.qPerksControllerData?.succMsg);
      }
    }
  }

  getList = (page = this.state.page) => {
    const { getAllQPerks } = this?.props?.qPerksControllerData;
    page = page - 1;
    const url = `?page=${page}&limit=200&catId=${this.state.category}`;
    getAllQPerks(url);
  };

  onSelect = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value, isinit: true, page: 1 }, () => {
      this.getList();
    });
  };

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      return this.props.history.push("/qperks-settings");
    }
  };

  render() {
    let { isLoader, list, category, qsCatList, totalCount } = this.state;
    let { authData = {}, permissions = {} } = this.props;

    const getCard = (item, i) => {
      return (
        <div className="card news-card p-0 h-100 ">
          <div className="card-body p-3">
            {permissions?.update ? (
              <div className="absolute-top-right  card-absolute-header  news-header">
                <div className="d-flex align-items-center">
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      id: item._id,
                      itemData: item,
                      showCustomModal: true,
                      title: "Edit New At The Club",
                      path: "Qspecial/Qspecial",
                      body: "",
                      categoryData: qsCatList,
                      permissions: permissions,
                      getList: this.getList,
                      className: "modal-extra-large",
                    }}
                  >
                    <i className="fa fa-edit" />
                  </ModalButton>
                </div>
              </div>
            ) : null}
            <div>
              <p
                className="mb-2"
                style={{
                  textTransform: "capitalize",
                  textAlign: "end",
                }}
              >
                {item?.categoryId?.title?.toLowerCase()}
              </p>
              <p style={{ textTransform: "capitalize" }}>
                <span className="bold-text">{item?.title?.toLowerCase()}</span>
                ,&nbsp;
                <span
                  style={{
                    textTransform: "capitalize",
                    color: "rgb(108, 117, 125)",
                  }}
                >
                  {item?.subTitle?.toLowerCase()}
                </span>
              </p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">Q Perks</h3>
            </div>
            <div className="d-flex input-header col-auto align-items-center">
              <InputField
                name="category"
                groupClass="mbi-0 category-qspecial"
                value={category}
                inputChange={this.onSelect}
                options={qsCatList}
                dom={{ placeholder: "Select", type: "select" }}
              />
              {authData?.roleId?.roleName === "Developer" ? (
                <div onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp; &nbsp;
              {permissions?.create ? (
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    showCustomModal: true,
                    title: "Q Perks",
                    path: "Qspecial/Qspecial",
                    body: "",
                    categoryData: qsCatList,
                    permissions: permissions,
                    getList: this.getList,
                    className: "modal-extra-large",
                  }}
                >
                  Add new
                </ModalButton>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent p-0 h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}

              {!isLoader && list?.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="p-0">
                {!isLoader && list?.length > 0 && (
                  <div className="row pe-3">
                    {list?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-3  mb-3"
                          style={{
                            paddingBottom: "12px",
                            width: "20%",
                            paddingRight: "3px",
                          }}
                        >
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="border-top-0 pt-3">
              {!isLoader && list?.length !== 0 && (
                <Pagination
                  paging={{
                    count: totalCount,
                    rows: 20,
                    page: this.state.page,
                  }}
                  onPageClick={(page) => {
                    this.setState(
                      (prevState) => {
                        return {
                          ...prevState,
                          page: page,
                        };
                      },
                      () => {
                        this.getList(page);
                      }
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { authData = {} } }) {
  return {
    authData,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const qPerksControllerData = useQPerks();
    return <Component {...props} qPerksControllerData={qPerksControllerData} />;
  };
  return InjectedComp;
}

export default connect(mapStateToProps)(injectComp(Qspecial));
