import { combineReducers } from 'redux';
import auth from './auth';
import qpayAuth from './qpay-auth';
import qpay from './qpay';
import page from './page';
import global from './globalaccess';
import clubs from './clubs';

const appReducer = combineReducers({
  auth, page, global, clubs, qpayAuth, qpay
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
