import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { checkAuth } from "../actions";
import { getCookie } from "../services/session";
import Loader from "../components/customFields/loader";

function getCurrentModulePermission(name, data) {
  let cModule = data.filter((item) => {
    return item && item.moduleId && item.moduleId.moduleName === name;
  });

  return cModule.length
    ? {
        create: cModule[0].create || false,
        delete: cModule[0].delete || false,
        update: cModule[0].update || false,
        view: cModule[0].view || false,
      }
    : { create: false, delete: false, update: false, view: false };
}

function checkPermissions(page, pageRequiredPermission, url, authData) {
  const { roleId = {} } = authData;
  const { accessAllowed = [] } = roleId;

  let permissions = getCurrentModulePermission(page, accessAllowed);
  let total = 0;
  for (let i = 0; i < pageRequiredPermission.length; i++) {
    let p = pageRequiredPermission[i];
    if (permissions[p] === true) {
      total = total + 1;
    }
  }

  return (url && url.indexOf("/logs/") > -1) || window.location.pathname === "/"
    ? [true, { create: false, delete: false, update: false, view: true }]
    : [total === pageRequiredPermission.length, permissions];
}

export default (ChildCompnent, isLoader = true) => {
  class RequireAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLogin: null,
        permissions: [
          false,
          { create: false, delete: false, update: false, view: false },
        ],
      };
    }
    componentDidMount() {
      // this.props.checkAuth({token: this.props.token});
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.isLogin !== prevState.isLogin) {
        const {
          route: { page },
          match: { params, url },
          authData = {},
        } = nextProps;
        let pageRequiredPermission = ["view"];
        if (params.hasOwnProperty("id")) {
          pageRequiredPermission = [
            ...pageRequiredPermission,
            ...(params.id ? ["update"] : ["create"]),
          ];
        }

        const permissions = checkPermissions(
          page,
          pageRequiredPermission,
          url,
          authData
        );

        return {
          isLogin: nextProps.isLogin,
          permissions,
        };
      } else return null;
    }

    render() {
      switch (this.state.isLogin) {
        case false:
          return <Redirect to="/login" />;
        case null:
          return <Loader className={`${isLoader ? "loader" : ""}`} />;
        default:
          return (
            <ChildCompnent
              {...this.props}
              permissions={
                this.state.permissions[1] ? this.state.permissions[1] : {}
              }
            />
          );
      }
    }
  }

  function mapStateToProps({
    auth: { isLogin = false, token = "", authData = {} },
  }) {
    return { isLogin, token, authData };
  }

  return connect(mapStateToProps, { checkAuth })(RequireAuth);
};
