import React from "react";
import { Link } from "react-router-dom";

const Sidenav = ({
  authData,
  isOpen,
  closeDrawer,
  route: { path, page },
  sideBarList = [],
}) => {
  const renderMenu = (item, i) => {
    return (
      item?.moduleId?.moduleName && (
        <li
          key={i}
          className={
            page && page === item?.moduleId?.moduleName ? "active" : ""
          }
          data-link={item?.moduleId?.path}
        >
          {!item.accordian && (
            <Link to={item?.moduleId?.path}>
              <i className={item.icon}></i> &nbsp;{item?.moduleId?.moduleName}
            </Link>
          )}
          {item.accordian && item.childs.length > 0 && (
            <React.Fragment>
              <a
                href={"#" + item.id}
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className={item.icon}></i>
                {item.name}
              </a>
              <ul className="collapse list-unstyled" id={item.id}>
                {item.childs.map((child, ci) => {
                  return (
                    <li className="active" key={ci}>
                      <i className={item.icon}></i>
                      <Link to={child.path}>{child.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          )}
        </li>
      )
    );
  };

  return (
    <nav id="sidebar">
      <ul className="list-unstyled components py-3" style={{ border: "none" }}>
        {sideBarList.map((item, i) => {
          // if (
          //   item?.moduleId?.path == "/global-access" ||
          //   item?.moduleId?.path == "/india-of-ideas" ||
          //   item?.moduleId?.path == "/admin-access"
          // ) {
          //   if (
          //     authData?.roleId?.roleName === "Super Admin" ||
          //     authData?.roleId?.roleName === "Admin" ||
          //     authData?.roleId?.roleName === "Developer"
          //   ) {
          //     return renderMenu(item, i);
          //   }
          // } else {
          if (item?.moduleId?.path == "/members") {
            // if (
            //   authData?.roleId?.roleName === "Super Admin" ||
            //   authData?.roleId?.roleName === "Admin" ||
            //   authData?.roleId?.roleName === "Developer"
            // ) {
            return renderMenu(
              {
                ...item,
                moduleId: { ...item.moduleId, path: "/member-list/member" },
              },
              i
            );
            // }
          } else {
            return renderMenu(item, i);
          }
          // }
        })}
      </ul>
    </nav>
  );
};

export default Sidenav;
