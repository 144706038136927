import {
    CHECK_AUTH,
    USER_LOGIN,
    GENERATE_OTP,
    VERIFY_OTP,
    USER_LOGOUT,
    UPDATE_USER_DATA,
    RESET_PASSWORD,
    GET_TOKEN,
    Q_PAY_GENERATE_OTP,
    Q_PAY_VERIFY_OTP,
    Q_PAY_GET_TOKEN
  } from './constants';
  
  
  export function checkAuth(data={}) {
    return {
      type: 'SAGA/'+CHECK_AUTH,
      data
    };
  }

  export function getToken(data={}) {
    return {
      type: 'SAGA/'+GET_TOKEN,
      data
    };
  }
  
  export function loginSubmit(data, cb) {
    return {
      type: USER_LOGIN,
      data,
      cb
    };
  }

  export function generateOtp(data, cb) {
    return {
      type: GENERATE_OTP,
      data,
      cb
    };
  }

  export function verifyOtp(data, cb) {
    return {
      type: VERIFY_OTP,
      data,
      cb
    };
  }

  export function resetPassword(data, cb) {
    return {
      type: RESET_PASSWORD,
      data,
      cb
    };
  }
  
  export function logout(data, cb) {
    return {
      type: USER_LOGOUT,
      data,
      cb
    };
  }
  
  export function updateUserData(data) {
    return {
      type: UPDATE_USER_DATA,
      data
    };
  }

  export function qPayGenerateOtp(data, cb) {
    return {
      type: Q_PAY_GENERATE_OTP,
      data,
      cb
    };
  }

  export function qPayVerifyOtp(data, cb) {
    return {
      type: Q_PAY_VERIFY_OTP,
      data,
      cb
    };
  }
  
  export function getQPayToken(data={}) {
    return {
      type: 'SAGA/'+Q_PAY_GET_TOKEN,
      data
    };
  }