import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useStaff = () => {
  const [allStaffData, setAllStaffData] = useState([]);
  const [staffData, setStaffData] = useState({});
  const [allRolesData, setAllRolesData] = useState([]);
  const [roleData, setRoleData] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllStaff = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.STAF_MEMBERS}${url ? url : ""}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllStaffData(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getStaff = async (id) => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.STAF_MEMBER}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setStaffData(response?.data)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const addStaff = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(
        `${Endpoint.STAF_MEMBER}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateStaff = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.STAF_MEMBER}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const deletestaff = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(
        `${Endpoint.STAF_MEMBER}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllRoles = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.STAFF_ROLES}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setAllRolesData(response?.data?.roles)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getRole = async (id) => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.STAFF_ROLE}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setRoleData(response?.data?.role)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const addRole = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.STAFF_ROLE}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateRole = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.STAFF_ROLE}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  return {
    allStaffData,
    staffData,
    allRolesData,
    roleData,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllStaff,
    getStaff,
    addStaff,
    updateStaff,
    deletestaff,
    getAllRoles,
    getRole,
    addRole,
    updateRole,
  };
};

export default useStaff;
