import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateModalData } from "../actions/page";

class MemberDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  checkAdmin = (e, link) => {
    // if (this.props.adminType === 0) {
    return this.props.history.push(link);
    // }

    // this.props.updateModalData({
    //   showCustomModal: true,
    //   title: "",
    //   path: "member/NoPermision",
    //   className: "modal-large",
    //   body: "",
    //   data: {
    //     showButtons: false,
    //     msg: "You are not allowed to perform this action",
    //   },
    // });
  };

  render() {
    const { permissions = {} } = this.props;

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pt-2 pb-1 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">Admin Access</h3>
            </div>
          </div>
          <div className="card bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              <div className="">
                <div className="row">
                  {permissions?.view ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <Link
                        to="/member-list/staf-member"
                        className="w-100 text-center"
                      >
                        <div className="w-100 text-center">
                          <i className="fa fa-users"></i>
                        </div>
                        <div>
                          <p>View Staff Members</p>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {permissions?.create ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <div
                        onClick={(e) => {
                          this.checkAdmin(e, "/add-staffmember");
                        }}
                        to="/add-staffmember"
                        className="w-100 text-center"
                      >
                        <span>
                          <i className="fa fa-plus-circle"></i>
                        </span>
                        <p>Create Staff Member</p>
                      </div>
                    </div>
                  ) : null}
                  {permissions?.view ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <Link
                        to="/member-list/member"
                        className="w-100 text-center"
                      >
                        <div className="w-100 text-center">
                          <i className="fa fa-users"></i>
                        </div>
                        <div>
                          <p>View Club Members</p>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {permissions?.create ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <div
                        onClick={(e) => {
                          this.checkAdmin(e, "/add-member");
                        }}
                        className="w-100 text-center"
                      >
                        <span>
                          <i className="fa fa-plus-circle"></i>
                        </span>
                        <div>
                          <p>Create Club Member </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {permissions?.update || permissions?.create ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <Link to="/static-pages" className="w-100 text-center">
                        <div className="w-100 text-center">
                          <i className="fa fa-pencil-square-o"></i>
                        </div>
                        <div>
                          <p>Edit Static Pages</p>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                    <Link
                      to="/analytics/app-analytics"
                      className="w-100 text-center"
                    >
                      <div className="w-100 text-center">
                        <i className="fa fa-bar-chart"></i>
                      </div>
                      <div>
                        <p>App Analytics</p>
                      </div>
                    </Link>
                  </div>
                  {this?.props?.adminType === "Super Admin" ||
                  this?.props?.adminType === "Admin" ||
                  this?.props?.adminType === "Developer" ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <div
                        onClick={(e) => {
                          this.checkAdmin(e, "/roles");
                        }}
                        to="/roles"
                        className="w-100 text-center"
                      >
                        <span>
                          <i className="fa fa-cogs"></i>
                        </span>
                        <div>
                          <p>Set Admin Roles</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                    <Link to="/push-notification" className="w-100 text-center">
                      <div className="w-100 text-center">
                        <i className="fa fa-bell-o"></i>
                      </div>
                      <div>
                        <p>Push Notifications</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                    <Link to="/pop-notification" className="w-100 text-center">
                      <div className="w-100 text-center">
                        <i className="fa fa-snowflake-o"></i>
                      </div>
                      <div>
                        <p>Pop Notifications</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  adminType: state?.auth?.authData?.roleId?.roleName,
});

export default connect(mapStateToProps, { updateModalData })(MemberDashboard);
