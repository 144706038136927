export const GET_TOKEN = "GET_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_MODAL_DATA = "UPDATE_MODAL_DATA";
export const USER_LOGIN = "USER_LOGIN";
export const GENERATE_OTP = "GENERATE_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const CHECK_AUTH = "CHECK_AUTH";
export const USER_LOGOUT = "USER_LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PAGE_STATE = "RESET_PAGE_STATE";
export const FETCH_CONTINENT_LIST = "FETCH_CONTINENT_LIST";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_CITY_LIST = "FETCH_CITY_LIST";
export const UPDATE_PAGE_DATA = "UPDATE_PAGE_DATA";
export const GET_PAGE_DATA = "GET_PAGE_DATA";
export const GET_INDIVIDUAL_DATA = "GET_INDIVIDUAL_DATA";
export const FETCH_AUTO_SUGGESTIONS = "FETCH_AUTO_SUGGESTIONS";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const GET_CLUBS = "GET_CLUBS";
export const SET_ACTIVE_CLUB = "SET_ACTIVE_CLUB";

export const Q_PAY_GET_TOKEN = "Q_PAY_GET_TOKEN";
export const Q_PAY_GENERATE_OTP = "Q_PAY_GENERATE_OTP";
export const Q_PAY_VERIFY_OTP = "Q_PAY_VERIFY_OTP";
export const Q_PAY_UPDATE_USER_DATA = "Q_PAY_UPDATE_USER_DATA";
export const GET_QPAY_MENU = "GET_QPAY_MENU";
export const GET_QPAY_ROLES = "GET_QPAY_ROLES";
export const Q_PAY_LIST_DATA = "Q_PAY_LIST_DATA";
export const Q_PAY_LISTS_DATA = "Q_PAY_LISTS_DATA";
export const Q_PAY_UPDATE_DATA = "Q_PAY_UPDATE_DATA";
export const Q_PAY_DELETE_DATA = "Q_PAY_DELETE_DATA";

