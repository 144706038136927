import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useIndustry = () => {
  const [allIndustryData, setAllIndustryData] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllIndustry = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.INDUSTRY}${url ? url : ""}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllIndustryData(response?.data?.industry)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addIndustry = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.INDUSTRY}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateIndustry = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.INDUSTRY}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  return {
    allIndustryData,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllIndustry,
    addIndustry,
    updateIndustry,
  };
};

export default useIndustry;
