import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useAppAnalytics = () => {
  const [appStats, setAppStats] = useState({});
  const [checkins, setCheckins] = useState({});
  const [reservation, setReservation] = useState({});
  const [events, setEvents] = useState({});
  const [qperks, setQperks] = useState({});
  const [global, setGlobal] = useState({});
  const [qpay, setQpay] = useState({});
  const [videos, setVideos] = useState({});
  const [dine, setDine] = useState({});
  const [privateHire, setPrivateHire] = useState({});
  const [staticPage, setStaticPage] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAppStatistics = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.APP}${url}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAppStats(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsCheckins = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.CHECKIN}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setCheckins(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsReservations = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.RESERVATION}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setReservation(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsEvents = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.EVENT}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setEvents(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsQperks = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.QPERKS}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setQperks(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsGlobalAccess = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.GLOBAL}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setGlobal(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsQpay = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.QPAY}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setQpay(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsVideos = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.VIDEO}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setVideos(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsDine = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.DINE}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setDine(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsPrivateHire = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.PRIVATE}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setPrivateHire(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsStaticPage = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.STATIC}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setStaticPage(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  return {
    appStats,
    checkins,
    reservation,
    events,
    qperks,
    global,
    qpay,
    videos,
    dine,
    privateHire,
    staticPage,
    errMsg,
    isLoading,
    getAppStatistics,
    getAnalyticsCheckins,
    getAnalyticsReservations,
    getAnalyticsEvents,
    getAnalyticsQperks,
    getAnalyticsGlobalAccess,
    getAnalyticsQpay,
    getAnalyticsVideos,
    getAnalyticsDine,
    getAnalyticsPrivateHire,
    getAnalyticsStaticPage,
  };
};

export default useAppAnalytics;
