import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const usePushNotifications = () => {
  const [allModuleCodes, setAllModuleCodes] = useState([]);
  const [allScheduledNoti, setAllScheduledNoti] = useState([]);
  const [allExecutedNoti, setAllExecutedNoti] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getModCode = async () => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.PUSHNOTICODE}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setAllModuleCodes(response?.data?.codes)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getPushNotifications = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.PUSHNOTI}`);
      setIsLoading(false);
      if (response?.data?.status && response?.data?.status !== "false") {
        setAllScheduledNoti(response?.data?.scheduled);
        setAllExecutedNoti(response?.data?.executed);
      } else {
        setErrMsg(response?.data?.msg);
      }
    } catch (error) {
      console.log("error--", error);
      setIsLoading(false);
      setErrMsg("Something went wrong");
    }
  };

  const addPushNotification = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.PUSHNOTI}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updatePushNotification = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.PUSHNOTI}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const deletePushNotification = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(`${Endpoint.PUSHNOTI}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allModuleCodes,
    allScheduledNoti,
    allExecutedNoti,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getModCode,
    getPushNotifications,
    addPushNotification,
    updatePushNotification,
    deletePushNotification,
  };
};

export default usePushNotifications;
