import React, { Component } from "react";
import { connect } from "react-redux";
import InputField from "../customFields/inputField";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import { Endpoint } from "./../../utils/constants";
import {
  listQpayData,
  listsQpayData,
  updateQpayData,
} from "../../actions/qpay";
import { exportToCsv, currancyFormat } from "./../../utils/formatter";
import ModalButton from "./../modal/button";
import Pagination from "../pagination/pagination";
import Chips from "../customFields/chips";
import moment from "moment";
import SearchInput from "../customFields/search-input";
import { getClubs } from "../../actions/clubs";
import { toast } from "react-toastify";

class MemberWallets extends Component {
  constructor(props) {
    super(props);
    this.keymapperMemberLog = {
      header: [
        "Date",
        "Time",
        "Admin's Name",
        "Action",
        "Amount",
        "IP",
        "Device",
      ],
      keys: [
        "dateOfAction",
        "timeOfAction",
        "fullName",
        "typeOfAction",
        "amount",
        "ip",
        "device",
      ],
    };
    this.keymapperTransacation = {
      header: ["Date", "Time", "Member's Name", "M.No", "Spend", "From"],
      keys: [
        "dateOfTxn",
        "timeOfTxn",
        "memberName",
        "mno",
        "withdraw",
        "sourceOfTxn",
      ],
    };
    this.keymapperDeposite = {
      header: [
        "Date",
        "Time",
        "Member's Name",
        "M.No",
        "Deposit",
        "Credit",
        "From",
      ],
      keys: [
        "dateOfTxn",
        "timeOfTxn",
        "memberName",
        "mno",
        "deposited",
        "credited",
        "sourceOfTxn",
      ],
    };
    this.state = {
      isDetailView: false,
      isLogView: false,
      keyword: "",
      deposite: "",
      withdraw: "",
      isLoader: false,
      isLoaderLogs: false,
      isLoaderHistory: false,
      currentMember: {},
      membersLogs: [],
      transationHistory: [],
      depositeHistory: [],
      error: "",
      selectedTab: 1,
      is_insufficient_amount: false,
      isWalletDetail: false,
      isChipReload: true,
      totalCount: 0,
      page: 1,
      Memberpage: 1,
      location: "",
      clubs: {},
    };
  }

  componentDidMount() {
    this.getList();
    this.props.getClubs();
    if (this.props.menus && this.props.menus.length > 0) {
      let sel = this.props.menus.filter((d) => d.moduleId.alias === "MWD");
      if (sel && sel.length > 0) {
        sel[0].view && this.setState({ isWalletDetail: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.clubs !== prevProps.clubs) {
      if (this.props.clubs && this.props.clubs.length) {
        let temp = {};
        this.props.clubs.forEach((d) => {
          temp[d.clubCity] = d?.clubCity?.toUpperCase();
        });
        this.setState({
          location: this.props.clubs[0].clubCity,
          clubs: { ...temp },
        });
      }
    }
  }

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    if (name == "withdraw" || name == "deposite") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        if (value !== "") {
          if (parseInt(value) > 0) {
            if (name == "withdraw") {
              if (parseInt(value) > this.state.currentMember.walletBalance) {
                this.setState({ is_insufficient_amount: true });
              } else {
                this.setState({ is_insufficient_amount: false });
              }
            }
            this.setState({ [name]: value });
          }
        } else {
          this.setState({ [name]: value });
        }
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  setChips = (value, name, item = {}) => {
    this.setState(
      { keyword: value && value.length ? value[0].name : "", Memberpage: 1 },
      () => {
        this.getList();
      }
    );
  };

  onChipsChanges = (event) => {
    this.setState({ keyword: event }, () => {
      this.setState({ isChipReload: true });
      this.getList();
    });
  };

  getList = () => {
    this.setState({ isLoader: false });
    let payload = {};
    let params = {
      searchKeyword: this.state.keyword,
      page: this.state.Memberpage || 1,
      limit: 10,
    };
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_WALLEAT_MEMBERS}`,
        method: "GET",
      },
      (res) => {
        this.setState({ isLoader: true, MembertotalCount: res.totalRec });
      }
    );
  };

  getMembersLogs = () => {
    let payload = {};
    let params = {
      limit: 10,
      page: this.state.page,
    };
    this.props.listsQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_MEMBERS_LOGS}/${this.state.currentMember?._id}`,
        method: "GET",
      },
      (res) => {
        res.data.map((el) => {
          el["fullName"] = el.actionBy?.fullName;
        });
        this.setState({
          membersLogs: res.data,
          isLoaderLogs: true,
          totalCount: res.totalRec,
        });
      }
    );
  };

  changeWalleatStatus = (e, closeModal) => {
    e.preventDefault();
    this.props.updateQpayData(
      {
        url: `${Endpoint.QPAY_CHANGE_WALLEAT_STATUS}/${this.state.currentMember?._id}`,
        method: "PATCH",
      },
      (response) => {
        if (response?.status && response?.status !== "false") {
          closeModal();
          this.getList();
          this.setState({
            isDetailView: false,
            currentMember: null,
          });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  addDeposite = (e, closeModal) => {
    if (this.state?.deposite === "") {
      return;
    }
    e.preventDefault();
    let payload = {
      firstName: this.state.currentMember.fName,
      lastName: this.state.currentMember.lName,
      mNo: this.state.currentMember.mNo,
      memberId: this.state.currentMember._id,
      deposit: parseInt(this.state.deposite),
    };
    this.props.updateQpayData(
      {
        payload,
        url: `${Endpoint.QPAY_DEPOSITE}`,
        method: "POST",
      },
      (response) => {
        if (response?.status && response?.status !== "false") {
          closeModal();
          this.getList();
          this.setState({
            deposite: "",
            isDetailView: false,
            currentMember: null,
          });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  withdrawAmount = (e, closeModal) => {
    if (this.state?.location === "") {
      toast.error("Please Select Withdraw Location");
    } else {
      if (this.state?.withdraw === "") {
        return;
      }
      e.preventDefault();
      let payload = {
        firstName: this.state.currentMember.fName,
        lastName: this.state.currentMember.lName,
        mNo: this.state.currentMember.mNo,
        memberId: this.state.currentMember._id,
        withdraw: parseInt(this.state.withdraw),
        location: this.state.location.toUpperCase(),
      };
      this.props.updateQpayData(
        {
          payload,
          url: `${Endpoint.QPAY_WITHDRAW}`,
          method: "POST",
        },
        (response) => {
          if (response?.status && response?.status !== "false") {
            closeModal();
            this.getList();
            this.setState({
              withdraw: "",
              isDetailView: false,
              currentMember: null,
            });
          } else {
            this.setState({ error: response.message });
          }
        }
      );
    }
  };

  getTransactionHistory = () => {
    let payload = {};
    let params = {
      type: 2,
      memberId: this.state.currentMember?._id,
    };
    this.props.listsQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_CMS_TXN_LIST}`,
        method: "GET",
      },
      (res) => {
        res.data.map((res) => {
          res["memberName"] = res.memberId.fName + " " + res.memberId.lName;
          res["mno"] = res.memberId.mNo;
        });
        this.setState({
          transationHistory: res.data,
          isLoaderHistory: true,
        });
      }
    );
  };

  getDepositeHistory = () => {
    let payload = {};
    let params = {
      type: 1,
      memberId: this.state.currentMember?._id,
    };
    this.props.listsQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_CMS_TXN_LIST}`,
        method: "GET",
      },
      (res) => {
        res.data.map((res) => {
          res["memberName"] = res.memberId.fName + " " + res.memberId.lName;
          res["mno"] = res.memberId.mNo;
        });
        this.setState({
          depositeHistory: res.data,
          isLoaderHistory: true,
        });
      }
    );
  };

  render() {
    const {
      isDetailView,
      isLogView,
      keyword,
      isLoader,
      deposite,
      withdraw,
      isLoaderLogs,
      currentMember,
      membersLogs,
      transationHistory,
      depositeHistory,
      isLoaderHistory,
      error,
      selectedTab,
      is_insufficient_amount,
      isWalletDetail,
      isChipReload,
      totalCount,
      page,
      clubs,
      location,
    } = this.state;
    let { list } = this.props;

    return (
      <React.Fragment>
        {!isDetailView && !isLogView && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">Member Wallets</h3>
              </div>
              <div className="row align-items-center">
                <div className="col-auto ps-0 me-2 pe-0 d-flex">
                  {isChipReload && (
                    <div style={{ width: 247 }}>
                      <SearchInput
                        endpoint={`searchMember/`}
                        searchKey="searchKeyword"
                        accessKey="members"
                        name="memberId"
                        inputClassName="form_control form-control"
                        optClassName="mt-0"
                        inputProps={{
                          autoFocus: true,
                        }}
                        placeholder="Search by First Name, Last Name or Member ID"
                        onChange={(value, onChangeSearch) => {
                          onChangeSearch(`${value?.fName} ${value?.lName}`);
                          this.onChipsChanges(
                            `${value?.fName} ${value?.lName}`
                          );
                        }}
                        renderItem={(item, i) => {
                          return (
                            <React.Fragment key={"auto_" + i}>
                              <div
                                className="image-style"
                                style={{
                                  backgroundImage: `url(${item?.photo})`,
                                }}
                              >
                                <img
                                  src={"/images/imagesize2_3.png"}
                                  alt={item?.fName}
                                  className="w100per"
                                />
                              </div>

                              <div className="d-flex justify-content-between flex-grow-1">
                                <span className="user-name capitalize-text">
                                  {item?.fName + " " + item?.lName}
                                </span>
                                {item?.mNo && (
                                  <span className="user-name1">
                                    {item?.mNo}
                                  </span>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        }}
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={this.getList}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div className="col-auto ps-0">
                  <button
                    className="btn btn-primary btn-primary-green"
                    style={{ paddingTop: "7px", paddingBottom: "7px" }}
                    onClick={() => {
                      this.setState(
                        {
                          keyword: "",
                          isChipReload: false,
                        },
                        () => {
                          this.setState({ isChipReload: true });
                          this.getList();
                        }
                      );
                    }}
                  >
                    <i className="fa fa-repeat"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" px-1">
                {!isLoader && <Loader />}
                {isLoader && list && list?.length === 0 && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "60vh", width: "100%", color: "black" }}
                  >
                    No data found
                  </div>
                )}

                {isLoader && list && list.length > 0 && (
                  <>
                    <div className="overflow-auto-div-pagination1 margin-top-15">
                      <div className="row">
                        {list.map((element, key) => {
                          return (
                            <div className="col-4" key={key}>
                              <div
                                className={
                                  element.walletStatus == 1
                                    ? "wallets-box cursor-pointer"
                                    : "wallets-box blocked-wallets cursor-pointer"
                                }
                                onClick={() => {
                                  if (isWalletDetail) {
                                    this.setState({
                                      currentMember: element,
                                      isDetailView: true,
                                    });
                                    setTimeout(() => {
                                      this.getTransactionHistory();
                                    }, 100);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    backgroundImage: `url(${element.photo ||
                                      ""})`,
                                  }}
                                  className="img-block image-style"
                                >
                                  <img
                                    className="img-fluid"
                                    width={65}
                                    style={{
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                    src={"./../images/imagesize2_3.png"}
                                  />
                                </div>
                                <div className="detail-block">
                                  <div>
                                    {element.fName + " " + element.lName}
                                  </div>
                                  <div>M.No - {element.mNo}</div>
                                  <div>
                                    Bal - Rs{" "}
                                    {currancyFormat(element.walletBalance)}{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <Pagination
                        paging={{
                          count: this.state?.MembertotalCount,
                          rows: 10,
                          page: this.state?.Memberpage,
                        }}
                        onPageClick={(page) => {
                          this.setState({ Memberpage: page }, () => {
                            this.getList();
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {isDetailView && !isLogView && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden wallets-details">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <i
                  onClick={() => {
                    this.setState({
                      isDetailView: false,
                      currentMember: null,
                      transationHistory: [],
                      depositeHistory: [],
                      selectedTab: 1,
                    });
                  }}
                  className="fa fa-arrow-left cursor-pointer"
                ></i>
                <div className="wallets-details-box">
                  <div
                    style={{
                      backgroundImage: `url(${currentMember.photo || ""})`,
                    }}
                    className="img-block image-style"
                  >
                    <img
                      className="img-fluid"
                      width={65}
                      style={{ height: "100%", objectFit: "contain" }}
                      src={"./../images/imagesize2_3.png"}
                    />
                  </div>
                  <div className="detail-block">
                    <div>
                      {currentMember?.fName + " " + currentMember?.lName} - M.No
                      - {currentMember?.mNo}
                    </div>
                    <div>
                      Current Wallet Balance - Rs.{" "}
                      {currentMember?.walletBalance}{" "}
                    </div>
                    <div>
                      Status -{" "}
                      {currentMember?.walletStatus == 1 ? "Active" : "Blocked"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-auto">
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={() => {
                      if (selectedTab == 1) {
                        exportToCsv(
                          transationHistory,
                          "Transaction_Data" +
                            moment().format("YYYY-MM-DD_HH.mm"),
                          this.keymapperTransacation
                        );
                      } else {
                        exportToCsv(
                          depositeHistory,
                          "Deposite_Data" + moment().format("YYYY-MM-DD_HH.mm"),
                          this.keymapperDeposite
                        );
                      }
                    }}
                  >
                    <i className="fa fa-download"></i>
                  </button>
                  <button
                    className="btn btn-primary btn-primary-green ms-2"
                    onClick={() => {
                      this.getMembersLogs();
                      this.setState({
                        isDetailView: false,
                        isLogView: true,
                        isLoaderLogs: false,
                      });
                    }}
                  >
                    Logs
                  </button>
                  <ModalButton
                    className={
                      currentMember?.walletStatus == 1
                        ? "btn btn-primary btn-block ms-2"
                        : "btn btn-primary btn-blocked ms-2"
                    }
                    data={{
                      showCustomModal: true,
                      title: "Confirmation",
                      footer: true,
                      onSubmit: this.changeWalleatStatus,
                      body: `Are you sure you would like to ${
                        currentMember?.walletStatus == 1 ? "Blocked" : "Unblock"
                      } ${currentMember?.fName +
                        " " +
                        currentMember?.lName}'s Q Pay Account?`,
                      className: "modal-large",
                    }}
                  >
                    {currentMember?.walletStatus == 1 ? "Block" : "Blocked"}
                  </ModalButton>
                </div>
              </div>
            </div>
            <div className="w-100 text-center">
              {error && <p className="error_msg"> {error}</p>}
            </div>
            <div className="row" style={{ marginTop: "60px" }}>
              <div className="col-3 pe-0">
                <div
                  onClick={() => {
                    this.setState({ selectedTab: 1, isLoaderHistory: false });
                    setTimeout(() => {
                      this.getTransactionHistory();
                    }, 100);
                  }}
                  className={`wallets-tab ${selectedTab == 1 &&
                    "wallets-tab-selected"}`}
                >
                  View Transaction
                </div>
              </div>
              <div className="col-3 pe-0">
                <div
                  onClick={() => {
                    this.setState({ selectedTab: 2, isLoaderHistory: false });
                    setTimeout(() => {
                      this.getDepositeHistory();
                    }, 100);
                  }}
                  className={`wallets-tab ${selectedTab == 2 &&
                    "wallets-tab-selected"}`}
                >
                  View Deposit
                </div>
              </div>
              <div className="col-3 pe-0">
                <div className="d-flex w-100">
                  <div className="w-100">
                    <InputField
                      name="deposite"
                      groupClass="mb-0 q-pay-input-custom-border "
                      value={deposite}
                      inputChange={this.inputChange}
                      dom={{
                        placeholder: ``,
                      }}
                    />
                  </div>
                  <ModalButton
                    className={`btn btn-primary deposit-btn q-pay-button-custom-border ${deposite ==
                      "" && ""}`}
                    disabled={deposite == ""}
                    data={{
                      showCustomModal: true,
                      title: "Confirmation",
                      footer: true,
                      onSubmit: this.addDeposite,
                      allowSubmitDisable: true,
                      allowCancelDisable: true,
                      body: `Are you sure you would like to Deposit Rs ${deposite} into ${currentMember?.fName +
                        " " +
                        currentMember?.lName}'s Q Pay Account?`,
                      className: "modal-large",
                    }}
                  >
                    Deposit&nbsp;&nbsp;&nbsp;
                  </ModalButton>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="w-100">
                    <InputField
                      name="withdraw"
                      groupClass="mb-0 q-pay-input-custom-border q-pay-input-custom-border-withdraw pr-4"
                      value={withdraw}
                      inputChange={this.inputChange}
                      dom={{
                        placeholder: ``,
                        disabled: currentMember?.walletBalance == 0,
                      }}
                      postFix={() => (
                        <div className="fit-content position-absolute end-0">
                          <InputField
                            name="location"
                            groupClass="mb-0"
                            value={location}
                            inputChange={(e) =>
                              this.setState({
                                location: e.target.value,
                              })
                            }
                            options={clubs}
                            dom={{
                              type: "select",
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <ModalButton
                    className={`btn btn-primary btn-primary-green withdraw-btn q-pay-button-custom-border q-pay-button-custom-border-color ${(is_insufficient_amount ||
                      currentMember?.walletBalance == 0 ||
                      withdraw == "") &&
                      ""}`}
                    disabled={withdraw == ""}
                    data={{
                      showCustomModal: true,
                      title: "Confirmation",
                      footer: true,
                      disabledBtn: withdraw == "",
                      onSubmit: this.withdrawAmount,
                      allowSubmitDisable: true,
                      allowCancelDisable: true,
                      body: `Are you sure you would like to Withdraw Rs ${withdraw} from ${currentMember?.fName +
                        " " +
                        currentMember?.lName}'s Q Pay Account?`,
                      className: "modal-large",
                    }}
                  >
                    Withdraw
                  </ModalButton>
                </div>
                {is_insufficient_amount && (
                  <p className="error_msg">
                    Sorry, your wallet has an insufficient balance. Kindly
                    deposit funds to complete this transaction
                  </p>
                )}
              </div>
            </div>
            <div className="row margin-top-15">
              <div className="col-12">
                <div className="overflow-auto-div-wallet">
                  {selectedTab == 1 && (
                    <>
                      {!isLoaderHistory && <Loader />}
                      {isLoaderHistory &&
                        (!transationHistory ||
                          transationHistory.length == 0) && (
                          <NoRecord message="No Transaction History Found" />
                        )}
                      {isLoaderHistory &&
                        transationHistory &&
                        transationHistory.length > 0 && (
                          <table className="table table-borderless custom-table">
                            <thead>
                              <tr className="text-center">
                                <th>Date</th>
                                <th>Time</th>
                                <th>Member's Name</th>
                                <th>M.No</th>
                                <th>Spend</th>
                                <th>From</th>
                                <th>Location</th>
                                <th>Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {transationHistory.map((element, key) => {
                                  return (
                                    <tr key={key} className="text-center">
                                      <td>{element.dateOfTxn}</td>
                                      <td>{element.timeOfTxn}</td>
                                      <td className="capitalize-text">
                                        {element.memberId.fName +
                                          " " +
                                          element.memberId.lName}
                                      </td>
                                      <td>{element.memberId.mNo}</td>
                                      <td>{element.withdraw}</td>
                                      <td>{element.sourceOfTxn}</td>
                                      <td>{element.location}</td>
                                      <td>
                                        <ModalButton
                                          data={{
                                            showCustomModal: true,
                                            title: "Note",
                                            path: "Qpay/notes",
                                            body: "",
                                            data: element,
                                            id: element.id,
                                            getList: this.getTransactionHistory,
                                            className:
                                              "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                                          }}
                                        >
                                          {element.note &&
                                          element.note.isNote ? (
                                            <span className="ml20 cursor-pointer color-green fs20">
                                              <i className="fa fa-file-text"></i>
                                            </span>
                                          ) : (
                                            <span className="ml20 cursor-pointer">
                                              <i className="fa fa-file-text"></i>
                                            </span>
                                          )}
                                        </ModalButton>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            </tbody>
                          </table>
                        )}
                    </>
                  )}
                  {selectedTab == 2 && (
                    <>
                      {!isLoaderHistory && <Loader />}
                      {isLoaderHistory &&
                        (!depositeHistory || depositeHistory.length == 0) && (
                          <NoRecord message="No Deposit History Found" />
                        )}
                      {isLoaderHistory &&
                        depositeHistory &&
                        depositeHistory.length > 0 && (
                          <table className="table table-borderless custom-table">
                            <thead>
                              <tr className="text-center">
                                <th>Date</th>
                                <th>Time</th>
                                <th>Member's Name</th>
                                <th>M.No</th>
                                <th>Deposit</th>
                                <th>Credit</th>
                                <th>From</th>
                                <th>Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {depositeHistory.map((element, key) => {
                                  return (
                                    <tr key={key} className="text-center">
                                      <td>{element.dateOfTxn}</td>
                                      <td>{element.timeOfTxn}</td>
                                      <td className="capitalize-text">
                                        {element.memberId.fName +
                                          " " +
                                          element.memberId.lName}
                                      </td>
                                      <td>{element.memberId.mNo}</td>
                                      <td>{element.deposited}</td>
                                      <td>{element.credited}</td>
                                      <td>{element.sourceOfTxn}</td>
                                      <td>
                                        <ModalButton
                                          data={{
                                            showCustomModal: true,
                                            title: "Note",
                                            path: "Qpay/notes",
                                            body: "",
                                            data: element,
                                            id: element.id,
                                            getList: this.getDepositeHistory,
                                            className:
                                              "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                                          }}
                                        >
                                          {element.note &&
                                          element.note.isNote ? (
                                            <span className="ml20 cursor-pointer color-green fs20">
                                              <i className="fa fa-file-text"></i>
                                            </span>
                                          ) : (
                                            <span className="ml20 cursor-pointer">
                                              <i className="fa fa-file-text"></i>
                                            </span>
                                          )}
                                        </ModalButton>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            </tbody>
                          </table>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isLogView && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden wallets-details">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  Logs - {currentMember?.fName + " " + currentMember?.lName} -
                  M.No - BG48678F
                </h3>
              </div>
              <div className="row align-items-center">
                <div className="col-auto">
                  <i
                    className="fa fa-download me-2 cursor-pointer"
                    onClick={() =>
                      exportToCsv(
                        membersLogs,
                        "Member_Logs_Data" +
                          moment().format("YYYY-MM-DD_HH.mm"),
                        this.keymapperMemberLog
                      )
                    }
                  ></i>
                  <i
                    onClick={() => {
                      this.setState({ isDetailView: true, isLogView: false });
                    }}
                    className="fa fa-times cursor-pointer"
                  ></i>
                </div>
              </div>
            </div>
            <div className="row margin-top-15">
              <div className="col-12">
                {!isLoaderLogs && <Loader />}
                {isLoaderLogs && (!membersLogs || membersLogs.length == 0) && (
                  <NoRecord message="No Logs Found" />
                )}
                {isLoaderLogs && membersLogs && membersLogs.length > 0 && (
                  <>
                    <div className="overflow-auto-div-pagination1">
                      <table className="table table-borderless custom-table">
                        <thead>
                          <tr className="text-center">
                            <th>Date</th>
                            <th>Time</th>
                            <th>Admin's Name</th>
                            <th>Action</th>
                            <th>Amount</th>
                            <th>IP</th>
                            <th>Device</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {membersLogs.map((element, key) => {
                              return (
                                <tr key={key} className="text-center">
                                  <td>{element.dateOfAction}</td>
                                  <td>{element.timeOfAction}</td>
                                  <td className="capitalize-text">
                                    {element?.actionBy?.fullName}
                                  </td>
                                  <td>{element.typeOfAction}</td>
                                  <td>{element.amount}</td>
                                  <td>{element.ip}</td>
                                  <td>{element.device}</td>
                                </tr>
                              );
                            })}
                          </>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Pagination
                        paging={{
                          count: totalCount,
                          rows: 10,
                          page: page,
                        }}
                        onPageClick={(page) => {
                          this.setState({ page: page }, () => {
                            this.getMembersLogs();
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ qpay: { list }, clubs: { clubs } }) {
  return {
    list,
    clubs,
  };
}
export default connect(mapStateToProps, {
  listQpayData,
  listsQpayData,
  updateQpayData,
  getClubs,
})(MemberWallets);
