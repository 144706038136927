import React, { Component } from "react";
import { connect } from "react-redux";
import { Endpoint } from "./../../utils/constants";
import { currancyFormat, dateToStr } from "./../../utils/formatter";
import { listsQpayData } from "../../actions/qpay";
import ApexCharts from "react-apexcharts";
import InputField from "../customFields/inputField";
import Loader from "../customFields/loader";
import moment from "moment";
import chunk from "lodash.chunk";
class QPayDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      toDate: new Date(),
      totalBal: 0,
      totalDepo: 0,
      totalSpent: 0,
      topMembers: [],
      isLoader: false,
      series: [],
      options: {},
    };
  }

  componentDidMount() {
    this.getDashboardState();
  }

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  getDashboardState = () => {
    let payload = {};
    let params = {
      from: this.state.fromDate
        ? moment(this.state.fromDate).format("YYYY/MM/DD")
        : "",
      end: this.state.toDate
        ? moment(this.state.toDate).format("YYYY/MM/DD")
        : "",
    };
    this.props.listsQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_DASHBAORD_STATE}`,
        method: "GET",
      },
      (res) => {
        if (res.status) {
          let deposited = [];
          let withdraw = [];
          let date = [];
          res.graphData.map((element) => {
            deposited.push(element.item[0].deposited);
            withdraw.push(element.item[0].withdraw);
            date.push(element._id.dateWise);
          });
          let series = [
            {
              name: "Deposit",
              data: deposited,
            },
            {
              name: "Withdraw",
              data: withdraw,
            },
          ];
          let options = {
            chart: {
              height: 350,
              type: "area",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              type: "date",
              categories: date,
              labels: {
                show: false,
                formatter: function(value) {
                  return moment.unix(value).format("DD-MM-YYYY  h:mm a");
                },
                rotate: -45,
                rotateAlways: true,
              },
            },
            tooltip: {
              x: {
                format: "dd/MM/yy HH:mm",
              },
            },
            noData: {
              text: "No Data Found",
              align: "center",
              verticalAlign: "middle",
              offsetX: 0,
              offsetY: -22,
              style: {
                color: "black",
                fontSize: "14px",
                fontFamily: "Poppins",
              },
            },
          };
          this.setState({
            isLoader: true,
            totalBal: res.totalBal,
            totalDepo: res.totalDepo,
            totalSpent: res.totalSpent,
            topMembers: res.topMembers,
            series: series,
            options: options,
          });
        } else {
          this.setState({
            isLoader: true,
            totalBal: 0,
            totalDepo: 0,
            totalSpent: 0,
            topMembers: [],
            series: [],
            options: {},
          });
        }
      }
    );
  };

  render() {
    const {
      fromDate,
      toDate,
      isLoader,
      totalBal,
      totalDepo,
      totalSpent,
      topMembers,
    } = this.state;
    let count = 0;
    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">Q Pay Dashboard</h3>
            </div>
            <div className="row align-items-center">
              <div
                className="col-auto ps-0 center-date"
                style={{ width: "130px" }}
              >
                <InputField
                  name="fromDate"
                  groupClass="mbi-0"
                  value={fromDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "Start Date",
                    type: "date",
                    style: { display: "flex", justifyContent: "center" },
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              <div
                className="col-auto ps-0 center-date"
                style={{ width: "130px" }}
              >
                <InputField
                  name="toDate"
                  groupClass="mbi-0"
                  value={toDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "End Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    minDate: new Date(this.state.fromDate),
                  }}
                />
              </div>
              <div className="col-auto ps-0">
                <button
                  onClick={this.getDashboardState}
                  className="btn btn-primary btn-primary-green me-2"
                >
                  Apply
                </button>
                <button
                  className="btn btn-primary btn-primary-green"
                  onClick={() => {
                    this.setState(
                      {
                        fromDate: new Date(),
                        toDate: new Date(),
                      },
                      () => {
                        this.getDashboardState();
                      }
                    );
                  }}
                >
                  <i className="fa fa-repeat"></i>
                </button>
              </div>
            </div>
          </div>
          {!isLoader && <Loader />}
          {isLoader && (
            <div className="row mt-2">
              <div className="col-3">
                <div className="text-center">
                  <label className="form-label text-secondary">
                    Q PayTotal Balance
                  </label>
                </div>
                <div className="balance-box">
                  <div className="val">₹ {currancyFormat(totalBal)}</div>
                </div>
                <div className="text-center">
                  <label className="form-label text-secondary">
                    Date Range Deposit
                  </label>
                </div>
                <div className="balance-box">
                  <div className="val">₹ {currancyFormat(totalDepo)}</div>
                </div>
                <div className="text-center">
                  <label className="form-label text-secondary">
                    Date Range Spend
                  </label>
                </div>
                <div className="balance-box">
                  <div className="val">₹ {currancyFormat(totalSpent)}</div>
                </div>
              </div>
              <div className="col-9">
                <div id="chart">
                  <ApexCharts
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={300}
                  />
                </div>
              </div>
              <div className="col-12 top-members">
                <div className="list-title">Top Member Balances</div>
                <div className="row">
                  {topMembers && topMembers.length > 0 && (
                    <>
                      {chunk(topMembers, 5).map((member, key) => {
                        return (
                          <div className="col-3 pe-0" key={key}>
                            {member?.map((element, i) => {
                              count = count + 1;
                              return (
                                <div className="val flex-wrap" key={i}>
                                  {count}. {element.fName + " "}
                                  {element.lName} - ₹{" "}
                                  {currancyFormat(element.walletBalance)}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({}) {
  return {};
}
export default connect(mapStateToProps, { listsQpayData })(QPayDashboard);
