import React, { Component } from "react";
import { connect } from "react-redux";
import { listQpayData, updateQpayData } from '../../actions/qpay';
import { Endpoint } from "./../../utils/constants";
import InputField from "../customFields/inputField";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import ModalButton from "./../modal/button";
import Pagination from "../pagination/pagination";
import moment from "moment";

class DepositIncentive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLog: false,
            logs: [],
            isLoader: false,
            error: "",
            incentivesList: [],
            totalCount: 0,
            page: 1
        };
    }

    componentDidMount() {
        this.getIncentiveList();
    }

    inputChange = (e, key) => {
        let { target: { name, value } } = e;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            let copyData = [...this.state.incentivesList];
            if (name == 'percentage' && value !== '' && (parseInt(value) > 0 && parseInt(value) <= 100)) {
                copyData[key] = { ...copyData[key], [name]: value };
                this.setState({ incentivesList: copyData });
            }
            if (name == 'deposit' && value !== '' && parseInt(value) > 0) {
                copyData[key] = { ...copyData[key], [name]: value };
                this.setState({ incentivesList: copyData });
            }
            if ((name == 'percentage' || name == 'deposit') && value == '') {
                copyData[key] = { ...copyData[key], [name]: value };
                this.setState({ incentivesList: copyData });
            }
        }
    };

    getLogs = () => {
        let payload = {};
        let params = {
            limit: 10,
            page: this.state.page,
        };
        this.props.listQpayData({
            payload,
            params,
            url: `${Endpoint.QPAY_INCENTIVE_LOGS}`,
            method: 'GET'
        }, (res) => {
            if (res.status) {
                this.setState({ logs: res.data, isLoader: true, totalCount: res.totalRec });
            }
        });
    };

    getIncentiveList = () => {
        let payload = {};
        let params = {
            limit: 10,
            page: this.state.page,
        };
        this.props.listQpayData({
            payload,
            params,
            url: `${Endpoint.QPAY_GET_INCENTIVE}`,
            method: 'GET'
        }, (res) => {
            if (res.status) {
                this.setState({ incentivesList: res.data, isLoader: true, totalCount: res.totalRec });
            }
        });
    };

    update = (element, action) => {
        let payload = {
            status: action
        }
        this.props.updateQpayData({
            payload,
            url: `${Endpoint.QPAY_INCENTIVE}/${element._id}`,
            method: "PATCH",
        }, (response) => {
            if (response.status) {
                this.getIncentiveList();
                this.setState({ error: '' });
            } else {
                this.setState({ error: response.message });
            }
        });
    };

    delete = (e, closeModal, element) => {
        e.preventDefault();
        this.props.updateQpayData(
            {
                url: `${Endpoint.QPAY_INCENTIVE}/${element.id}`,
                method: "DELETE",
            },
            (response) => {
                if (response.status) {
                    closeModal();
                    this.getIncentiveList();
                    this.setState({ error: '' });
                } else {
                    this.setState({ error: response.message });
                }
            }
        );
    };

    render() {
        const {
            isLog,
            isLoader,
            logs,
            incentivesList,
            error,
            totalCount,
            page
        } = this.state;

        return (
            <React.Fragment>
                {
                    !isLog &&
                    <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
                        <div className="d-flex mb-0 align-items-center  justify-content-between">
                            <div>
                                <h3 className="page-title mb-0">Deposit Incentives</h3>
                            </div>
                            {error && <p className="error_msg"> {error}</p>}
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <ModalButton
                                        className='btn btn-primary btn-primary-green'
                                        data={{
                                            showCustomModal: true,
                                            title: "Add Deposit Incentive",
                                            path: "Qpay/add-insentive",
                                            body: "",
                                            getList: this.getIncentiveList,
                                            className: "modal-large"
                                        }}
                                    >
                                        Add
                                    </ModalButton>
                                </div>
                                <div className="col-auto ps-0">
                                    <button className="btn btn-primary btn-primary-green" onClick={() => {
                                        this.setState({ isLog: true, isLoader: false });
                                        this.getLogs();
                                    }}>
                                        Logs
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row margin-top-15">
                            <div className="col-12">
                                {
                                    !isLoader && <Loader />
                                }
                                {isLoader && (!incentivesList || incentivesList.length == 0) && (
                                    <NoRecord message="No Deposit Incentives Found" />
                                )}
                                {
                                    isLoader && incentivesList && incentivesList.length > 0 &&
                                    <>
                                        <div className="overflow-auto-div-pagination">
                                            {
                                                incentivesList.map((element, key) => {
                                                    return (
                                                        <div className="row mt-2" key={key}>
                                                            <div className="col-1">
                                                                <input
                                                                    name={'status'}
                                                                    type='checkbox'
                                                                    className="q-pay-role-checkbox form-check-input"
                                                                    checked={element.status}
                                                                    value={element.status}
                                                                    onChange={(e) => {
                                                                        let copyData = [...incentivesList];
                                                                        copyData[key] = { ...copyData[key], 'status': e.target.checked };
                                                                        this.setState({ incentivesList: copyData });
                                                                        this.update(element, e.target.checked)
                                                                    }}
                                                                    style={{ height: '19px', marginTop:  key == 0 ? '34px' : '8px' }} />
                                                            </div>
                                                            <div className="col-3">
                                                                <InputField
                                                                    name={'deposit'}
                                                                    label={key == 0 && 'Deposit Amount'}
                                                                    value={element.deposit}
                                                                    dom={{ placeholder: '', disabled: true }}
                                                                    inputChange={(e) => this.inputChange(e, key)}
                                                                />
                                                            </div>
                                                            <div className="col-2" style={{ position: 'relative' }}>
                                                                <InputField
                                                                    name={'percentage'}
                                                                    label={key == 0 && 'Percentage'}
                                                                    value={element.percentage}
                                                                    dom={{ placeholder: '', disabled: true }}
                                                                    inputChange={(e) => this.inputChange(e, key)}
                                                                />
                                                                <span className="percentage_sign" style={{top: key != 0 && '9px'}}>%</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <InputField
                                                                    name={'credit'}
                                                                    label={key == 0 && 'Credit Amount'}
                                                                    value={element.credit}
                                                                    dom={{ placeholder: '', disabled: true }}
                                                                    inputChange={(e) => this.inputChange(e, key)}
                                                                />
                                                            </div>
                                                            <div className="col-3">
                                                                <ModalButton
                                                                    className='btn btn-primary btn-primary-green'
                                                                    style={{ marginTop: key == 0 && '26px' }}
                                                                    data={{
                                                                        showCustomModal: true,
                                                                        title: "Edit Deposit Incentive",
                                                                        path: "Qpay/add-insentive",
                                                                        body: "",
                                                                        data: element,
                                                                        id: element._id,
                                                                        getList: this.getIncentiveList,
                                                                        className: "modal-large"
                                                                    }}
                                                                >
                                                                    Edit
                                                                </ModalButton>

                                                                <ModalButton
                                                                    className='btn btn-primary btn-primary-green ms-2'
                                                                    style={{ marginTop: key == 0 && '26px' }}
                                                                    data={{
                                                                        showCustomModal: true,
                                                                        id: element._id,
                                                                        title: "Alert",
                                                                        footer: true,
                                                                        onSubmit: this.delete,
                                                                        submitArg: { id: element._id },
                                                                        body:
                                                                            "Are you sure want to delete it ?",
                                                                        className: "",
                                                                    }}
                                                                >
                                                                    Delete
                                                                </ModalButton>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            <Pagination
                                                paging={{
                                                    count: totalCount,
                                                    rows: 10,
                                                    page: page,
                                                }}
                                                onPageClick={(page) => {
                                                    this.setState({ page: page }, () => {
                                                        this.getIncentiveList();
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    isLog &&
                    <div className="h-100 w-100 d-flex flex-column overflow-hidden wallets-details">
                        <div className="d-flex align-items-center  justify-content-between">
                            <div>
                                <h3 className="page-title mb-0"><i onClick={() => {
                                    this.setState({ isLog: false })
                                }} className="fa fa-arrow-left cursor-pointer"></i> Incentives Logs</h3>
                            </div>
                        </div>
                        <div className="row margin-top-15">
                            <div className="col-12">
                                {
                                    !isLoader && <Loader />
                                }
                                {isLoader && (!logs || logs.length == 0) && (
                                    <NoRecord message="No Logs Found" />
                                )}
                                {isLoader && logs && logs.length > 0
                                    &&
                                    <>
                                        <div className="overflow-auto-div-pagination1">
                                            <table className="table table-borderless custom-table">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th>Credit</th>
                                                        <th>Deposit</th>
                                                        <th>Percentage</th>
                                                        <th>Type Of Action</th>
                                                        <th>Action By</th>
                                                        <th>Updated At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        logs.map((element, key) => {
                                                            return (
                                                                <tr key={key} className="text-center">
                                                                    <td>{element.credit}</td>
                                                                    <td>{element.deposit}</td>
                                                                    <td>{element.percentage}</td>
                                                                    <td>{element.typeOfAction}</td>
                                                                    <td style={{ textTransform: 'capitalize' }}>{element?.actionBy?.fullName}</td>
                                                                    <td>{moment(element.updatedAt).format('DD/MM/YYYY hh:mm a')}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <Pagination
                                                paging={{
                                                    count: totalCount,
                                                    rows: 10,
                                                    page: page,
                                                }}
                                                onPageClick={(page) => {
                                                    this.setState({ page: page }, () => {
                                                        this.getLogs();
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }

            </React.Fragment >
        );
    }
}

function mapStateToProps({ }) {
    return {}
}
export default connect(mapStateToProps, { listQpayData, updateQpayData })(DepositIncentive);