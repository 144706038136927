import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { validate } from "../utils/validation";
import { loginSubmit, generateOtp, verifyOtp } from "../actions";
import bg from "../assets/bg.mp4";
import useAuth from "../controller/auth/auth-controller";

class Login extends Component {
  constructor(props) {
    super(props);
    this.interval = 0;
    this.timer = 120;
    this.state = {
      phone: "",
      email: "",
      username: "",
      otp: "",
      password: "",
      isValid: {},
      errorMessages: {},
      error: "",
      otpId: "",
      isOtpSend: false,
      section: "phone",
    };
  }

  componentDidMount() {
    document.body.classList.add("bg-login");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.authControllerData?.otpId !=
      this?.props?.authControllerData?.otpId
    ) {
      this.setState({
        error: "",
        isOtpSend: true,
        otpId: this?.props?.authControllerData?.otpId,
      });
      this.startTimer();
    }
    if (
      prevProps?.authControllerData?.isLogin !=
      this?.props?.authControllerData?.isLogin
    ) {
      this.setState({
        error: "",
      });
      this.props.history.push("/home");
    }
    if (
      prevProps?.authControllerData?.succMsg !==
      this?.props?.authControllerData?.succMsg
    ) {
      if (this?.props?.authControllerData?.succMsg) {
        this.setState({
          error: "",
        });
      }
    }
    if (
      prevProps?.authControllerData?.errMsg !==
      this?.props?.authControllerData?.errMsg
    ) {
      if (this?.props?.authControllerData?.errMsg) {
        this.setState({
          error: this?.props?.authControllerData?.errMsg,
        });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-login");
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return `0${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
  };
  startTimer = () => {
    this.interval = setInterval(() => {
      this.timer = this.timer - 1;
      let el = document.getElementById("_timer");
      el
        ? (el.innerHTML = this.secondsToTime(this.timer))
        : clearInterval(this.interval);
      if (this.timer == 0) {
        clearInterval(this.interval);
        this.timer = 120;
        this.setState({ isOtpSend: false });
      }
    }, 1000);
  };

  validate = (name, value) => {
    let { isValid, errorMessages } = this.state;
    let error = validate(name, value, { type: "text" });
    isValid[name] = error.isValid;
    error.isValid
      ? delete errorMessages[name]
      : (errorMessages[name] = error.errorMsg);
    this.setState({ isValid, errorMessages });
  };

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    if (name === "phone" && this.state.isOtpSend) {
      return;
    }
    if (name === "email" && this.state.isOtpSend) {
      return;
    }
    this.setState({ [name]: value }, () => {
      this.validate(name, value);
    });
  };

  onSubmit = (e, type = "") => {
    const { sendOtp /*, verifyOtp*/ } = this?.props?.authControllerData;

    if ((e.key === "Enter" && !e.shiftKey) || type) {
      this.setState({ error: "" });
      e.preventDefault();
      let payload = {};
      let {
        target: { name, value },
      } = e;
      name = type ? type : name;
      if (name === "username" || name == "password") {
        this.validate("username", this.state.username);
        this.validate("password", this.state.password);
      } else {
        this.validate(name, this.state[name]);
      }
      if (name === "phone" && this.state.isValid[name] && !this.state.otp) {
        payload.data = this.state.phone;
        payload.mode = "phone";
        payload.userType = "admin";
      } else if (name === "otp" && this.state.isValid[name]) {
        payload.otpCode = this.state.otp;
        payload.userType = "admin";
      } else if (
        (name === "username" || name == "password") &&
        this.state.isValid.username &&
        this.state.isValid.password
      ) {
        payload.username = this.state.username;
        payload.password = this.state.password;
        payload.type = "username";
      }
      if (type == "phone") {
        if (this.state.phone === "" || !this.state.phone) {
          return;
        } else {
          sendOtp({ phone: this.state.phone, requestFrom: "cms" });
        }
      } else if (type == "email") {
        if (this.state.email === "" || !this.state.email) {
          return;
        } else {
          sendOtp({ email: this.state.email, requestFrom: "cms" });
        }
      } else if (type == "otp") {
        payload.otpId = this.state.otpId;
        payload.userType = "admin";
        if (!this.state.otp || this.state.otp === "") {
          return;
        } else {
          this.props.verifyOtp(
            {
              otpId: this.state.otpId,
              otpCode: Number(this.state.otp),
            },
            (response) => {
              if (
                response?.data?.status &&
                response?.data?.status !== "false"
              ) {
                this.props.history.push("/home");
              } else {
                this.setState({ error: "Invalid otp code" });
              }
            }
          );
        }
      } else {
        if (this.state.isValid.username && this.state.isValid.password) {
          this.props.loginSubmit(
            { payload, token: this.props.token },
            (response) => {
              if (response.status) {
                this.setState({ error: "" });
                if (type !== "phone") {
                  this.props.history.push("/home");
                } else {
                  this.startTimer();
                  this.setState({ isOtpSend: true });
                }
              } else {
                this.setState({ error: response.message });
              }
            }
          );
        }
      }
      // payload.token = this.props.token;
    }
  };

  resendOtp = (e) => {
    e.preventDefault();
    let payload = {
      phone: this.state.phone,
      type: "phone",
      token: this.props.token,
    };
    this.props.loginSubmit({ payload, token: this.props.token }, (response) => {
      if (response.status) {
        this.setState({ error: "", isOtpSend: true });
      } else {
        this.setState({ error: response.message, isOtpSend: false });
      }
    });
  };

  setSection = (e, section) => {
    e.preventDefault();
    this.setState({ section, isOtpSend: false, error: "" });
  };

  render() {
    let {
      phone,
      email,
      username,
      otp,
      password,
      isValid,
      errorMessages,
      error,
      isOtpSend,
      section,
    } = this.state;
    if (this.props.isLogin) {
      return <Redirect to="/home" />;
    }
    return (
      <div className="">
        <div className="login ">
          <div className="row mx-0 h-100">
            <div className="col-6 green-dark-color p-0 h-100 login-divider-right">
              <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
                <video
                  className="rounded"
                  muted
                  autoPlay
                  loop
                  height={"85%"}
                  controls={false}
                >
                  <source src={bg} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-6 h-100 p-0 login-divider-left">
              <div className="right-content">
                <div className="home">
                  <div className="logo"></div>
                  <p className="title">The Quorum Club</p>
                  {section === "email" && (
                    <p
                      className="sub-title mb-2 cursor-pointer"
                      onClick={(e) => this.setSection(e, "phone")}
                    >
                      Sign in with Phone Number
                    </p>
                  )}
                  {section === "phone" && (
                    <p
                      className="sub-title mb-2 cursor-pointer"
                      onClick={(e) => this.setSection(e, "email")}
                    >
                      Sign in with E-Mail
                    </p>
                  )}
                  <div className="login-form">
                    <div>
                      <div className="d-flex align-items-end">
                        {section === "phone" && (
                          <div style={{ width: 270 }}>
                            <label className="form-label-login" htmlFor="phone">
                              Phone Number
                            </label>
                            <input
                              className="form_control"
                              type="number"
                              name="phone"
                              value={phone}
                              disabled={isOtpSend}
                              onChange={this.inputChange}
                              onKeyDown={this.onSubmit}
                            ></input>
                          </div>
                        )}
                        {section === "email" && (
                          <div style={{ width: 270 }}>
                            <label className="form-label-login" htmlFor="email">
                              Email
                            </label>
                            <input
                              className="form_control"
                              type="email"
                              name="email"
                              value={email}
                              disabled={isOtpSend}
                              onChange={this.inputChange}
                              onKeyDown={this.onSubmit}
                            ></input>
                          </div>
                        )}
                        <div>
                          <div
                            style={{
                              height: 40,
                              marginLeft: 20,
                              whiteSpace: "nowrap",
                              opacity: isOtpSend ? "0.6" : "1",
                            }}
                            className="btn-login text-center d-flex align-items-center"
                            onClick={(e) =>
                              !isOtpSend &&
                              this.onSubmit(e, isOtpSend ? "otp" : section)
                            }
                          >
                            <div style={{ width: 100 }}>Send OTP</div>
                          </div>
                        </div>
                      </div>
                      {section === "phone" && !isValid.phone && (
                        <div className="error_msg">{errorMessages.phone}</div>
                      )}
                      {section === "email" && !isValid.email && (
                        <div className="error_msg">{errorMessages.email}</div>
                      )}
                    </div>
                    <div>
                      <div className="d-flex align-items-end">
                        {isOtpSend && (
                          <div style={{ width: 270 }}>
                            <label className="form-label-login" htmlFor="otp">
                              OTP
                            </label>
                            <input
                              className="form_control mb5"
                              name="otp"
                              value={otp}
                              onChange={this.inputChange}
                              onKeyDown={this.onSubmit}
                            ></input>
                          </div>
                        )}
                        {isOtpSend && (
                          <div>
                            <div
                              style={{
                                height: 40,
                                margin: 0,
                                marginLeft: 20,
                                marginBottom: 4,
                                whiteSpace: "nowrap",
                              }}
                              className="btn-login text-center d-flex align-items-center"
                              onClick={(e) =>
                                this.onSubmit(e, isOtpSend ? "otp" : "phone")
                              }
                            >
                              <div style={{ width: 100 }}>Validate</div>
                            </div>
                          </div>
                        )}
                        {/* {section == "email" && (
                          <div className="row">
                            <div className="col-12">
                              <label
                                className="form-label-login"
                                htmlFor="password"
                              >
                                Password
                              </label>
                              <input
                                className="form_control mb5"
                                type="password"
                                name="password"
                                value={password}
                                onChange={this.inputChange}
                                onKeyDown={this.onSubmit}
                              ></input>
                              {!isValid.password && (
                                <div className="error_msg">
                                  {errorMessages.password}
                                </div>
                              )}
                            </div>
                          </div>
                        )} */}
                      </div>
                      {!isValid.otp && (
                        <div className="error_msg">{errorMessages.otp}</div>
                      )}
                      {error && (
                        <p className="error_msg" style={{ color: "red" }}>
                          {error}
                        </p>
                      )}
                      {isOtpSend && (
                        <a href="#" className="txt-common" type="number">
                          Resend OTP will be active in:{" "}
                          <span id="_timer">02:00</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ auth: { token = "", isLogin } }) {
  return { token, isLogin };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const authControllerData = useAuth();
    return <Component {...props} authControllerData={authControllerData} />;
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  loginSubmit,
  generateOtp,
  verifyOtp,
})(injectComp(Login));
