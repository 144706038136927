import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";

const useGlobalAccess = () => {
  const [allReciprocals, setAllReciprocals] = useState([]);
  const [allContinents, setAllContinents] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [bookNow, setBookNow] = useState([]);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllReciprocals = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.GLOBAL_ACCESS}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllReciprocals(response?.data?.reciprocals)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addReciprocals = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${Endpoint.GLOBAL_ACCESS}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateReciprocal = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.GLOBAL_ACCESS}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const deleteReciprocal = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(
        `${Endpoint.GLOBAL_ACCESS}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllContinents = async () => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.CONTINENTS}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllContinents(response?.data?.continents)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const addContinent = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${Endpoint.CONTINENT}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateContinent = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.CONTINENT}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllCountries = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.COUNTRIES}${id}?addInactive=true`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllCountries(response?.data?.countries)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const addCountry = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${Endpoint.COUNTRY}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateCountry = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.COUNTRY}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getBookNow = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.BOOKNOW}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setBookNow(response?.data?.bookNowUrl)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const updateBookNow = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.BOOKNOW}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  return {
    allReciprocals,
    allContinents,
    allCountries,
    bookNow,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllReciprocals,
    addReciprocals,
    updateReciprocal,
    deleteReciprocal,
    getAllContinents,
    addContinent,
    updateContinent,
    getAllCountries,
    addCountry,
    updateCountry,
    getBookNow,
    updateBookNow,
  };
};

export default useGlobalAccess;
