import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useGlobalAccess from "../controller/global-access/global-access-controller";
import { getClubs } from "../actions/clubs";
import { toast } from "react-toastify";

class GlobalAccessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isUpdate: false,
      isInit: true,
      activeCont: "",
      activeClub: "",
      continentList: [],
      countriesList: [],
      bookNowUrl: "",
    };
  }

  componentDidMount() {
    this.props.getClubs();
    this.getContinentsList();
    if (this?.props?.activeClub?.[0]?._id) {
      this.getBookNow(this?.props?.activeClub?.[0]?._id);
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this?.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.getBookNow(this?.state?.activeClub);
    }

    if (
      prevProps?.globalAccessControllerData?.isLoading !=
      this?.props?.globalAccessControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.globalAccessControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.globalAccessControllerData?.allCountries?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.globalAccessControllerData?.isUpdating !=
      this?.props?.globalAccessControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.globalAccessControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.globalAccessControllerData?.succMsg !==
      this?.props?.globalAccessControllerData?.succMsg
    ) {
      if (this?.props?.globalAccessControllerData?.succMsg) {
        const {} = this?.props?.globalAccessControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        this.getContinentsList();
        this.getBookNow(
          this?.props?.activeClub?.[0]?._id || this?.state?.activeClub
        );
        toast.success(this?.props?.globalAccessControllerData?.succMsg);
      }
    }
    if (
      prevProps?.globalAccessControllerData?.errMsg !==
      this?.props?.globalAccessControllerData?.errMsg
    ) {
      if (this?.props?.globalAccessControllerData?.errMsg) {
        this.setState({
          error: this?.props?.globalAccessControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.globalAccessControllerData?.errMsg);
      }
    }

    if (
      prevProps?.globalAccessControllerData?.allContinents !==
      this?.props?.globalAccessControllerData?.allContinents
    ) {
      const arrData = this?.props?.globalAccessControllerData?.allContinents;
      this.setState(
        {
          continentList: arrData,
          activeCont: this?.state?.activeCont
            ? this?.state?.activeCont
            : arrData?.length > 0
            ? arrData[0]?._id
            : "",
        },
        () => {
          if (this?.state?.activeCont) {
            this.getCountriesList(this?.state?.activeCont);
          }
        }
      );
    }

    if (
      prevProps?.globalAccessControllerData?.allCountries !==
      this?.props?.globalAccessControllerData?.allCountries
    ) {
      const arrData = this?.props?.globalAccessControllerData?.allCountries;
      this.setState({
        countriesList: arrData,
      });
    }

    if (
      prevProps?.globalAccessControllerData?.bookNow !==
      this?.props?.globalAccessControllerData?.bookNow
    ) {
      const url = this?.props?.globalAccessControllerData?.bookNow?.url;
      this.setState({
        bookNowUrl: url,
      });
    }

    if (prevState?.activeCont !== this?.state?.activeCont) {
      this.getCountriesList(this?.state?.activeCont);
    }
  }

  getContinentsList = () => {
    const { getAllContinents } = this?.props?.globalAccessControllerData;
    getAllContinents();
  };

  getCountriesList = (id) => {
    const { getAllCountries } = this?.props?.globalAccessControllerData;
    getAllCountries(id);
  };

  getBookNow = (id) => {
    if (id) {
      const { getBookNow } = this?.props?.globalAccessControllerData;
      getBookNow(id);
    }
  };

  handleSwitchChange = (data, type) => {
    const {
      updateContinent,
      updateCountry,
    } = this?.props?.globalAccessControllerData;

    type === "continent"
      ? updateContinent({ _id: data?._id, isActive: !data?.isActive })
      : updateCountry({ _id: data?._id, isActive: !data?.isActive });
  };

  changeStep = (step) => {
    if (!this.state.isLoader) this.setState({ activeCont: step });
  };

  onAddUrl = () => {
    const { updateBookNow } = this?.props?.globalAccessControllerData;
    const { activeClub, bookNowUrl } = this?.state;
    this.setState({ isSaving: true });
    updateBookNow({ _id: activeClub, url: bookNowUrl });
  };

  render() {
    let {
      isLoader,
      isUpdate,
      isInit,
      continentList,
      countriesList,
      bookNowUrl,
      isSaving,
    } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/global-access">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Global Access Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="row mb-3">
                      <h4 className="col-8">Continent Categories</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            getContinentsList: this.getContinentsList,
                            showCustomModal: true,
                            title: "Add Continent",
                            path: "GlobalAccessSetting/addContinent",
                            body: "",
                            className: "modal-small",
                          }}
                          disabled={isUpdate}
                        >
                          <h3
                            style={{
                              marginLeft: 10,
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: isUpdate ? "default" : "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {continentList && continentList?.length > 0
                      ? continentList?.map((cont, i) => (
                          <div
                            className="row cursor-pointer"
                            key={i}
                            onClick={() =>
                              this.setState({ activeCont: cont?._id })
                            }
                          >
                            <div className="col-8 mb-3">
                              <p style={{ textTransform: "capitalize" }}>
                                {cont?.name}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <ModalButton
                                  data={{
                                    itemData: cont,
                                    getContinentsList: this.getContinentsList,
                                    showCustomModal: true,
                                    title: "Edit Continent",
                                    path: "GlobalAccessSetting/addContinent",
                                    body: "",
                                    className: "modal-small",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginRight: 10,
                                      marginBottom: 0,
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                                <InputField
                                  value={cont.isActive}
                                  checked={cont.isActive}
                                  inputChange={() =>
                                    isUpdate
                                      ? {}
                                      : this.handleSwitchChange(
                                          cont,
                                          "continent"
                                        )
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                                <ModalButton
                                  data={{
                                    continentId: cont?._id,
                                    getCountriesList: this.getCountriesList,
                                    showCustomModal: true,
                                    title: "Add Country",
                                    path: "GlobalAccessSetting/addCountry",
                                    body: "",
                                    className: "modal-small",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginLeft: 10,
                                      marginBottom: 0,
                                      color: "#1c6765",
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}

                    <div className="mb-3 mt-3">
                      <h4 className="">Book Now Url</h4>
                      <div className="d-flex">
                        <InputField
                          name="url"
                          groupClass="mb-0"
                          value={bookNowUrl}
                          inputChange={(e) =>
                            this.setState({ bookNowUrl: e.target.value })
                          }
                          dom={{ placeholder: "", type: "text" }}
                        />
                        <button
                          className={`btn btn-primary btn-primary-green ${
                            isSaving ? "btndisabled" : ""
                          }`}
                          onClick={this.onAddUrl}
                          style={{ marginLeft: 10 }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ paddingLeft: "3px" }}>
                    <div className="card h-100">
                      <div className="container-fluid p-0">
                        {isInit && isLoader && (
                          <div className="d-flex h-100 justify-content-center align-items-center">
                            <Loader />
                          </div>
                        )}
                        {!isLoader && countriesList?.length === 0 && (
                          <div className="d-flex h-100 justify-content-center align-items-center">
                            <NoRecord />
                          </div>
                        )}

                        {countriesList &&
                          countriesList.length > 0 &&
                          countriesList?.map((data) => {
                            return (
                              <div className="row" key={data._id}>
                                <div className="col-6 mb-3">
                                  <p style={{ textTransform: "capitalize" }}>
                                    {data?.name}
                                  </p>
                                </div>
                                <div className="col-6">
                                  <div className="d-flex align-items-center">
                                    <ModalButton
                                      data={{
                                        continentId: data?.continentId,
                                        itemData: data,
                                        getCountriesList: this.getCountriesList,
                                        showCustomModal: true,
                                        title: "Edit Country",
                                        path: "GlobalAccessSetting/addCountry",
                                        body: "",
                                        className: "modal-small",
                                      }}
                                      disabled={isUpdate}
                                    >
                                      <h3
                                        style={{
                                          marginRight: 10,
                                          marginBottom: 0,
                                          cursor: isUpdate
                                            ? "default"
                                            : "pointer",
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit"
                                          style={{ fontSize: 20 }}
                                        ></i>
                                      </h3>
                                    </ModalButton>
                                    <InputField
                                      value={data.isActive}
                                      checked={data.isActive}
                                      inputChange={() =>
                                        isUpdate
                                          ? {}
                                          : this.handleSwitchChange(
                                              data,
                                              "countries"
                                            )
                                      }
                                      dom={{
                                        type: "checkbox",
                                        ele: "switch",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ clubs: { activeClub } }) {
  return {
    activeClub: activeClub,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const globalAccessControllerData = useGlobalAccess();
    return (
      <Component
        {...props}
        globalAccessControllerData={globalAccessControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, { getClubs })(
  injectComp(GlobalAccessSettings)
);
