import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonGroup,
  Button,
  CustomInput,
  FormGroup,
  Label,
} from "reactstrap";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { uploadFile, uploadFileS3 } from "../../actions/page";
import { Endpoint } from "../../utils/constants";
import "rc-time-picker/assets/index.css";
import "../../style/react-datepicker.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

class InputField extends Component {
  handleDateChange = (date) => {
    const { name, inputChange, format = "YYYY-MM-DD" } = this.props;
    let finalDate = date ? moment(date).format(format) : "";
    inputChange({ target: { name, value: finalDate } });
  };
  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  handleTime = (time) => {
    const { name, inputChange } = this.props;
    let finalDate = time ? time.format("hh:mm a") : "";
    inputChange({ target: { name, value: finalDate } });
  };
  onDrop = (a, r) => {
    let { multiple, name, inputChange, section, token } = this.props;
    if (r.length > 0) {
      alert("File Not allowed");
      return;
    }
    if (a.length) {
      let payload = {
        // token,
        file: a[0],
        // , type: 'file', section
      };
      this.props.uploadFile(
        {
          payload,
          url: `user/member/upload`,
          method: "POST",
        },
        (response) => {
          // console.log("response :; ",response)
          if (response.status) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
          // if (response !== "error") {
          //     inputChange({ target: { name, value: response, imageUrl: response } })
          // }
        }
      );
    }
  };

  onDropS3 = (a, r) => {
    let { multiple, name, inputChange, section, token } = this.props;
    if (r.length > 0) {
      alert("File Not allowed");
      return;
    }
    if (a.length) {
      let payload = {
        token,
        image: a[0],
        type: "file",
        section,
      };
      this.props.uploadFile(
        {
          payload,
          india: true,
        },
        (response) => {
          if (response !== "error") {
            inputChange({
              target: { name, value: response, imageUrl: response },
            });
          }
        }
      );
    }
  };
  render() {
    const {
      groupClass = "",
      dom = {},
      name,
      label,
      labelAttr = {},
      value,
      inputChange,
      isValid = true,
      errorMsg,
      options = {},
      children,
      multiple = false,
      accept = "image/jpeg, image/png",
      postFix,
    } = this.props;
    let style = dom.type === "radio" ? { display: "block" } : {};

    return (
      <React.Fragment>
        {label && dom.type !== "checkbox" && (
          <div>
            <Label
              for={name}
              {...labelAttr}
              style={style}
              {...(dom.type && dom.type === "checkbox" ? { check: true } : {})}
            >
              {label} {dom.required && <small className="text-danger">*</small>}
            </Label>
          </div>
        )}

        {(!dom ||
          (dom.type !== "file" &&
            [
              "toggle",
              "time",
              "date",
              "select",
              "radio",
              "radio-custom",
              "checkbox",
            ].indexOf(dom.type) === -1)) && (
          <div className="position-relative d-flex align-items-center">
            <input
              className={`form_control ${!isValid &&
                "error-input"} ${groupClass}`}
              style={this.props.style}
              name={name}
              value={value}
              // min="1"
              onChange={(event) => {
                inputChange(event);
              }}
              {...dom}
            />
            {postFix && postFix()}
          </div>
        )}
        {dom && dom.type === "select" && !dom.multiple && (
          <Input
            className={`form_control ${!isValid &&
              "error-input"} ${groupClass}`}
            type="select"
            multiple={dom?.multiple ? dom?.multiple : false}
            value={value}
            onChange={(event) => {
              inputChange(event);
            }}
            name={name}
            {...dom}
            // invalid={!isValid}
            style={{ display: "inline" }}
          >
            {dom.placeholder && <option value="">{dom.placeholder}</option>}
            {Object.keys(options).map((key, index) => {
              return (
                <option key={key} value={key} className="select-input">
                  {options[key]}
                </option>
              );
            })}
          </Input>
        )}
        {dom && dom.type === "select" && dom.multiple && (
          <Select
            isMulti={true}
            isClearable={true}
            isSearchable={false}
            options={
              Object.keys(options)?.map((key) => ({
                value: key,
                label: options[key],
              })) || []
            }
            name={name}
            onChange={(event) => {
              inputChange(event);
            }}
            value={Object.keys(options)
              ?.map((key) => {
                if (value?.includes(key)) {
                  return {
                    value: key,
                    label: options[key],
                  };
                }
              })
              ?.filter((x) => x)}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "5px",
                border: "1px solid #d9d9d8",
                background: "#fff",
                fontSize: "0.875rem",
                color: "#000",
                width: "100%",
                minHeight: "38px",
                marginBottom: !isValid ? 0 : 10,

                "&:hover": {
                  border: "1px solid #d9d9d8",
                },
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: "38px",
                "input[readonly]": {
                  position: "absolute",
                },
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: 2,
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight: 200,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                padding: "5px 10px",
              }),
            }}
            theme={(theme) => {
              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "transparent",
                },
              };
            }}
          />
        )}
        {dom && dom.type === "checkbox" && (
          // <Label className="mb-0" check={value}>
          <>
            {dom.ele === "switch" ? (
              <label className="switch">
                <Input
                  type="checkbox"
                  size="md"
                  value={value}
                  onChange={(event) => {
                    inputChange(event);
                  }}
                  checked={value}
                  id={name}
                  name={name}
                  {...dom}
                  invalid={!isValid}
                />
                <span className="slider round"></span>
              </label>
            ) : (
              <>
                <Input
                  type="checkbox"
                  size="md"
                  value={value}
                  onChange={(event) => {
                    inputChange(event);
                  }}
                  checked={value}
                  id={name}
                  name={name}
                  {...dom}
                  invalid={!isValid}
                />
                <span className="checkbox-label">{label || ""}</span>
              </>
            )}
          </>
          // </Label>
        )}
        {dom &&
          dom.type === "radio" &&
          Object.keys(options).map((key, index) => {
            return (
              <div
                className={`custom-control ${!isValid &&
                  "error"} ${groupClass}`}
                key={key}
              >
                <input
                  type="radio"
                  name={name}
                  id={key}
                  value={key}
                  checked={value == key}
                  onChange={(event) => {
                    inputChange(event);
                  }}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor={key}>
                  {options[key]}
                </label>
              </div>
            );
          })}
        {dom &&
          dom.type === "radio-custom" &&
          Object.keys(options).map((key, index) => {
            return (
              <div className="form-check-inline" key={index}>
                <label className="form-check-label" htmlFor={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={key}
                    value={key}
                    checked={value == key}
                    onChange={(event) => {
                      inputChange(event);
                    }}
                  />
                  {options[key]}
                </label>
              </div>
            );
          })}
        {dom && dom.type === "toggle" && (
          <ButtonGroup className={groupClass}>
            {Object.keys(options).map((key, index) => {
              return (
                <Button
                  key={key}
                  value={key}
                  name={name}
                  onClick={(event) => {
                    if (key == value) {
                      return;
                    }
                    inputChange(event);
                  }}
                  className={key == value ? "active" : ""}
                >
                  {options[key]}
                </Button>
              );
            })}
          </ButtonGroup>
        )}
        {dom && dom.type === "date" && (
          <DatePicker
            popperClassName="custom-date"
            popperPlacement={dom.placement ? dom.placement : ""}
            placeholderText={dom.placeholder}
            className={`${!isValid && "error-input"} ${
              groupClass ? "date-input" : "form-control input-style"
            }`}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            //maxDate={moment()}
            //dateFormat="DD-MM-YYYY"
            {...dom}
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
            selected={
              value && this.isValidDate(new Date(value))
                ? new Date(value)
                : null
            }
            onChange={this.handleDateChange}
          />
        )}
        {dom && dom.type === "time" && (
          <TimePicker
            placeholder={dom.placeholder}
            className={
              groupClass
                ? "date-input"
                : `form-control input-style ${!isValid && "error-input"}`
            }
            showSecond={false}
            value={value ? moment(value, "h:mm a") : null}
            onChange={this.handleTime}
            format="h:mm a"
            minuteStep={1}
            use12Hours={true}
            inputReadOnly
          />
        )}
        {dom && dom.type === "file" && (
          <Dropzone
            style={{ position: "relative" }}
            accept={accept}
            multiple={multiple}
            onDrop={(accepted, rejected) => {
              dom.india
                ? this.onDropS3(accepted, rejected)
                : this.onDrop(accepted, rejected);
            }}
          >
            {children}
          </Dropzone>
        )}
        {!isValid && <p className="error_msg"> {errorMsg}</p>}
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { token } }) {
  return { token };
}
export default connect(mapStateToProps, { uploadFile })(InputField);
