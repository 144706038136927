import { useState } from "react";
import { Endpoint } from "../../utils/constants";
import axiosInstance from "../../services/axios-new";
import { toast } from "react-toastify";

const useMember = () => {
  const [allMemberData, setAllMemberData] = useState([]);
  const [allFilterMemberData, setAllFilterMemberData] = useState([]);
  const [memberAnalytics, setMemberAnalytics] = useState({});
  const [memberAllLogs, setMemberAllLogs] = useState({});
  const [analyticsCode, setAnalyticsCode] = useState([]);
  const [memberData, setMemberData] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllMembers = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.MEMBERS}${url ? url : ""}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAllMemberData(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getMember = async (id) => {
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.MEMBER}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setMemberData(response?.data)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const addMember = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.MEMBER}`, data);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const updateMember = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsUpdating(true);
    try {
      const response = await axiosInstance.patch(
        `${Endpoint.MEMBER}${data._id}`,
        data
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
      setIsUpdating(false);
      cb && cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsUpdating(false);
    }
  };

  const deleteMember = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.delete(`${Endpoint.MEMBER}${id}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setSuccMsg(response?.data?.msg)
        : setErrMsg(response?.data?.msg);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const logoutMember = async (id, cb) => {
    setSuccMsg("");
    setErrMsg("");
    try {
      const response = await axiosInstance.post(`${Endpoint.MEMBER_LOGOUT}`, {
        memberId: id,
      });
      cb(response);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
    }
  };

  const getAllFilterMembers = async (url, page) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.FILTER_MEMBER}${url ? url : ""}`
      );

      if (page == 1 && response?.data?.members?.length == 0) {
        toast.error("No Records Found");
      }

      response?.data?.status && response?.data?.status !== "false"
        ? setAllFilterMemberData(response?.data)
        : setErrMsg(response?.data?.msg);

      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getMemberAnalytics = async (id) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.MEMBER}${id}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setMemberAnalytics(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getMemberLog = async (url) => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(`${Endpoint.LOGS}${url}`);
      response?.data?.status && response?.data?.status !== "false"
        ? setMemberAllLogs(response?.data)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  const getAnalyticsCode = async () => {
    setIsLoading(true);
    setErrMsg("");
    try {
      const response = await axiosInstance.get(
        `${Endpoint.ANALYTICS}${Endpoint.CODE}`
      );
      response?.data?.status && response?.data?.status !== "false"
        ? setAnalyticsCode(response?.data?.codes)
        : setErrMsg(response?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg("Something went wrong");
      setIsLoading(false);
    }
  };

  return {
    allMemberData,
    allFilterMemberData,
    memberAnalytics,
    memberAllLogs,
    analyticsCode,
    memberData,
    succMsg,
    errMsg,
    isLoading,
    isUpdating,
    getAllMembers,
    getMember,
    addMember,
    updateMember,
    deleteMember,
    logoutMember,
    getAllFilterMembers,
    getMemberAnalytics,
    getMemberLog,
    getAnalyticsCode,
  };
};

export default useMember;
