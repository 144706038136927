import React, { useEffect, useState } from "react";
import useAppAnalytics from "../../controller/app-analytics/app-analytics-controller";
import { toast } from "react-toastify";

const SectionStatistics = (props) => {
  const { sectionType, options } = props;
  const [title, setTitle] = useState("");
  const {
    checkins,
    reservation,
    events,
    qperks,
    global,
    qpay,
    videos,
    dine,
    privateHire,
    staticPage,
    errMsg,
    getAnalyticsCheckins,
    getAnalyticsReservations,
    getAnalyticsEvents,
    getAnalyticsQperks,
    getAnalyticsGlobalAccess,
    getAnalyticsQpay,
    getAnalyticsVideos,
    getAnalyticsDine,
    getAnalyticsPrivateHire,
    getAnalyticsStaticPage,
  } = useAppAnalytics();

  useEffect(() => {
    getAnalyticsCheckins();
    getAnalyticsReservations();
    getAnalyticsEvents();
    getAnalyticsQperks();
    getAnalyticsGlobalAccess();
    getAnalyticsQpay();
    getAnalyticsVideos();
    getAnalyticsDine();
    getAnalyticsPrivateHire();
    getAnalyticsStaticPage();
  }, []);

  useEffect(() => {
    if (sectionType || sectionType === 0) {
      const obj = options.find((d) => d.value === sectionType);
      obj && Object.keys(obj).length > 0 ? setTitle(obj?.label) : setTitle("");
    }
  }, [sectionType]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  const getCardData = () => {
    switch (sectionType) {
      case 0:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Check Ins Via App in Range</label>
                <div className="val_box">
                  <span>{checkins?.totalMemberViaApp || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Check Ins Via Cms in Range</label>
                <div className="val_box">
                  <span>{checkins?.totalMemberViaCms || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Check Ins</label>
                <div className="val_box">
                  <span>{checkins?.totalMemberCheckIn || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Check Ins Via QR Code</label>
                <div className="val_box">
                  <span>{checkins?.totalGuestViaApp || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Check Ins Via CMS</label>
                <div className="val_box">
                  <span>{checkins?.totalGuestViaCms || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Check Ins</label>
                <div className="val_box">
                  <span>{checkins?.totalGuestCheckIn || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Invites</label>
                <div className="val_box">
                  <span>{checkins?.totalGuestInvited || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{reservation?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{reservation?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Reservations Made</label>
                <div className="val_box">
                  <span>{reservation?.totalReservationMade || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Reservations Made From App</label>
                <div className="val_box">
                  <span>{reservation?.totalReserMadeApp || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Reservations Made From CMS</label>
                <div className="val_box">
                  <span>{reservation?.totalReserMadeCms || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Invites from Reservation</label>
                <div className="val_box">
                  <span>{reservation?.totalGuestInvited || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{events?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{events?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total RSVP Made</label>
                <div className="val_box">
                  <span>{events?.totalRsvp || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Guest Invited</label>
                <div className="val_box">
                  <span>{events?.totalGuestInvited || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Ticket Purchase Click</label>
                <div className="val_box">
                  <span>{events?.totalTicket || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{qperks?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{qperks?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Inquiries Generated</label>
                <div className="val_box">
                  <span>{qperks?.totalInquiries || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total views/clicks on Lifestyle</label>
                <div className="val_box">
                  <span>{qperks?.totalLifestyle || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total views/clicks on Travel</label>
                <div className="val_box">
                  <span>{qperks?.totalTravel || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total views/clicks on Stays</label>
                <div className="val_box">
                  <span>{qperks?.totalStays || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{global?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{global?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Book My Visit Clicks</label>
                <div className="val_box">
                  <span>{global?.totalBookClicks || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Sonato Clicks</label>
                <div className="val_box">
                  <span>{global?.totalSonatoClicks || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{qpay?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{qpay?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{videos?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{videos?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Video Clicks</label>
                <div className="val_box">
                  <span>{videos?.totalVideoClicks || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 7:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{dine?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{dine?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 8:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Unique Member Visits</label>
                <div className="val_box">
                  <span>{privateHire?.totalUniqVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits</label>
                <div className="val_box">
                  <span>{privateHire?.totalVisit || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Enquiries</label>
                <div className="val_box">
                  <span>{privateHire?.totalEnquiry || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      case 9:
        return (
          <>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits to Club Rules</label>
                <div className="val_box">
                  <span>{staticPage?.totalClubRule || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits to Club Timings</label>
                <div className="val_box">
                  <span>{staticPage?.totalClubTime || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits to Edit Profile</label>
                <div className="val_box">
                  <span>{staticPage?.totalClubEditProf || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Member Visits to Feedback</label>
                <div className="val_box">
                  <span>{staticPage?.totalFeed || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Total Feedbacks Sent</label>
                <div className="val_box">
                  <span>{staticPage?.totalFeedSent || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>Guest Policy</label>
                <div className="val_box">
                  <span>{staticPage?.totalGuestPolicy || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <label>ContactUs/Location</label>
                <div className="val_box">
                  <span>{staticPage?.totalLocation || 0}</span>
                </div>
              </div>
            </div>
            {/* <div className="col-4">
              <div className="box">
                <label>Privacy Policy</label>
                <div className="val_box">
                  <span>{staticPage?. || 0}</span>
                </div>
              </div>
            </div> */}
            <div className="col-4">
              <div className="box">
                <label>Notice Board</label>
                <div className="val_box">
                  <span>{staticPage?.totalNotice || 0}</span>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      {sectionType !== "" && (
        <>
          <h4 className="ms-4 mb-2">{title}</h4>
          <div className="row card-analytics">{getCardData()}</div>
        </>
      )}
    </>
  );
};
export default SectionStatistics;
