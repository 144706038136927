import React, { Component } from "react";
import Loader from "../components/customFields/loader";
import { Link } from "react-router-dom";
import useStaff from "../controller/staff/staff-controller";
import ModalButton from "../components/modal/button";
import NoRecord from "../components/customFields/noRecord";
import { toast } from "react-toastify";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRolesData: [],
      isLoader: false,
    };
  }

  componentDidMount() {
    const { getAllRoles } = this?.props?.staffControllerData;
    getAllRoles();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.staffControllerData?.allRolesData !==
      this?.props?.staffControllerData?.allRolesData
    ) {
      const optData = this?.props?.staffControllerData?.allRolesData?.filter(
        (d) => d.isActive
      );
      this.setState({
        allRolesData: optData,
      });
    }
    if (
      prevProps?.staffControllerData?.isLoading !==
      this?.props?.staffControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.staffControllerData?.isLoading,
      });
    }

    if (
      prevProps?.staffControllerData?.errMsg !==
      this?.props?.staffControllerData?.errMsg
    ) {
      if (this?.props?.staffControllerData?.errMsg) {
        toast.error(this?.props?.staffControllerData?.errMsg);
      }
    }

    if (
      prevProps?.staffControllerData?.succMsg !==
      this?.props?.staffControllerData?.succMsg
    ) {
      if (this?.props?.staffControllerData?.succMsg) {
        toast.success(this?.props?.staffControllerData?.succMsg);
      }
    }
  }

  render() {
    let { allRolesData, isLoader } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center">
              <h3 className="page-title mb-0">
                <Link to="/admin-access">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Set Admin Roles
              </h3>
            </div>
            <div className="card roles-card h-100 overflow-hidden">
              <div className="card-body overflow-auto">
                {isLoader && (
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <Loader />
                  </div>
                )}
                {!isLoader && allRolesData.length === 0 && (
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <NoRecord />
                  </div>
                )}
                {!isLoader && allRolesData.length > 0 && (
                  <table className="table table-borderless custom-table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">S. No.</th>
                        <th className="text-center">Role</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allRolesData.map((role, i) => {
                        return (
                          <tr key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center">{role?.roleName}</td>
                            <td className="text-center">
                              {role?.isActive ? "Active" : "Deactive"}
                            </td>
                            <td className="text-center">
                              <ModalButton
                                data={{
                                  showCustomModal: true,
                                  title: `Update Role For ${role?.roleName}`,
                                  roleId: role?._id,
                                  path: "Roles/editRoles",
                                  body: "",
                                  className: "modal-extra-large",
                                }}
                              >
                                <h3
                                  style={{
                                    marginRight: 10,
                                    marginBottom: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-edit"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staffControllerData = useStaff();
    return <Component {...props} staffControllerData={staffControllerData} />;
  };
  return InjectedComp;
}

export default injectComp(Roles);
