import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useAltContact from "../controller/member/altContact-controller";
import useDesignation from "../controller/member/designation-controller";
import useGender from "../controller/member/gender-controller";
import useIndustry from "../controller/member/industry-controller";
import useMIntrest from "../controller/member/mIntrest-controller";
import useMStatus from "../controller/member/mStatus-controller";
import useMType from "../controller/member/mType-controller";
import useEduInst from "../controller/member/eduInst-controller";
import { toast } from "react-toastify";

class MemberSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isUpdate: false,
      isInit: true,
      isStep: 1,
      genderType: [],
      desgType: [],
      industryType: [],
      memberType: [],
      maritalStatus: [],
      altContType: [],
      memberInterest: [],
      eduInstType: [],
    };
  }

  componentDidMount() {
    this.handleGetAllSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.mTypeControllerData?.isLoading !=
        this?.props?.mTypeControllerData?.isLoading ||
      prevProps?.mStatusControllerData?.isLoading !=
        this?.props?.mStatusControllerData?.isLoading ||
      prevProps?.mIntrestControllerData?.isLoading !=
        this?.props?.mIntrestControllerData?.isLoading ||
      prevProps?.industryControllerData?.isLoading !=
        this?.props?.industryControllerData?.isLoading ||
      prevProps?.genderControllerData?.isLoading !=
        this?.props?.genderControllerData?.isLoading ||
      prevProps?.designationControllerData?.isLoading !=
        this?.props?.designationControllerData?.isLoading ||
      prevProps?.altContactControllerData?.isLoading !=
        this?.props?.altContactControllerData?.isLoading ||
      prevProps?.eduInstControllerData?.isLoading !=
        this?.props?.eduInstControllerData?.isLoading
    ) {
      this.setState({
        isLoader:
          this?.props?.mTypeControllerData?.isLoading ||
          this?.props?.mStatusControllerData?.isLoading ||
          this?.props?.mIntrestControllerData?.isLoading ||
          this?.props?.industryControllerData?.isLoading ||
          this?.props?.genderControllerData?.isLoading ||
          this?.props?.designationControllerData?.isLoading ||
          this?.props?.altContactControllerData?.isLoading ||
          this?.props?.eduInstControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.genderControllerData?.allGenderData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.mTypeControllerData?.isUpdating !=
        this?.props?.mTypeControllerData?.isUpdating ||
      prevProps?.mStatusControllerData?.isUpdating !=
        this?.props?.mStatusControllerData?.isUpdating ||
      prevProps?.mIntrestControllerData?.isUpdating !=
        this?.props?.mIntrestControllerData?.isUpdating ||
      prevProps?.industryControllerData?.isUpdating !=
        this?.props?.industryControllerData?.isUpdating ||
      prevProps?.genderControllerData?.isUpdating !=
        this?.props?.genderControllerData?.isUpdating ||
      prevProps?.designationControllerData?.isUpdating !=
        this?.props?.designationControllerData?.isUpdating ||
      prevProps?.altContactControllerData?.isUpdating !=
        this?.props?.altContactControllerData?.isUpdating ||
      prevProps?.eduInstControllerData?.isUpdating !=
        this?.props?.eduInstControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate:
          this?.props?.mTypeControllerData?.isUpdating ||
          this?.props?.mStatusControllerData?.isUpdating ||
          this?.props?.mIntrestControllerData?.isUpdating ||
          this?.props?.industryControllerData?.isUpdating ||
          this?.props?.genderControllerData?.isUpdating ||
          this?.props?.designationControllerData?.isUpdating ||
          this?.props?.altContactControllerData?.isUpdating ||
          this?.props?.eduInstControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.mTypeControllerData?.succMsg !==
      this?.props?.mTypeControllerData?.succMsg
    ) {
      if (this?.props?.mTypeControllerData?.succMsg) {
        const { getAllMType } = this?.props?.mTypeControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllMType();
        toast.success(this?.props?.mTypeControllerData?.succMsg);
      }
    }
    if (
      prevProps?.mStatusControllerData?.succMsg !==
      this?.props?.mStatusControllerData?.succMsg
    ) {
      if (this?.props?.mStatusControllerData?.succMsg) {
        const { getAllMStatus } = this?.props?.mStatusControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllMStatus();
        toast.success(this?.props?.mStatusControllerData?.succMsg);
      }
    }
    if (
      prevProps?.mIntrestControllerData?.succMsg !==
      this?.props?.mIntrestControllerData?.succMsg
    ) {
      if (this?.props?.mIntrestControllerData?.succMsg) {
        const { getAllMIntrest } = this?.props?.mIntrestControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllMIntrest();
        toast.success(this?.props?.mIntrestControllerData?.succMsg);
      }
    }
    if (
      prevProps?.industryControllerData?.succMsg !==
      this?.props?.industryControllerData?.succMsg
    ) {
      if (this?.props?.industryControllerData?.succMsg) {
        const { getAllIndustry } = this?.props?.industryControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllIndustry();
        toast.success(this?.props?.industryControllerData?.succMsg);
      }
    }
    if (
      prevProps?.genderControllerData?.succMsg !==
      this?.props?.genderControllerData?.succMsg
    ) {
      if (this?.props?.genderControllerData?.succMsg) {
        const { getAllGender } = this?.props?.genderControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllGender();
        toast.success(this?.props?.genderControllerData?.succMsg);
      }
    }
    if (
      prevProps?.designationControllerData?.succMsg !==
      this?.props?.designationControllerData?.succMsg
    ) {
      if (this?.props?.designationControllerData?.succMsg) {
        const { getAllDesignation } = this?.props?.designationControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllDesignation();
        toast.success(this?.props?.designationControllerData?.succMsg);
      }
    }
    if (
      prevProps?.altContactControllerData?.succMsg !==
      this?.props?.altContactControllerData?.succMsg
    ) {
      if (this?.props?.altContactControllerData?.succMsg) {
        const { getAllAltContact } = this?.props?.altContactControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllAltContact();
        toast.success(this?.props?.altContactControllerData?.succMsg);
      }
    }
    if (
      prevProps?.eduInstControllerData?.succMsg !==
      this?.props?.eduInstControllerData?.succMsg
    ) {
      if (this?.props?.eduInstControllerData?.succMsg) {
        const { getAllEduInst } = this?.props?.eduInstControllerData;
        this.setState({
          error: "",
          isSaving: false,
        });
        getAllEduInst();
        toast.success(this?.props?.eduInstControllerData?.succMsg);
      }
    }
    if (
      prevProps?.mTypeControllerData?.errMsg !==
        this?.props?.mTypeControllerData?.errMsg ||
      prevProps?.mStatusControllerData?.errMsg !==
        this?.props?.mStatusControllerData?.errMsg ||
      prevProps?.mIntrestControllerData?.errMsg !==
        this?.props?.mIntrestControllerData?.errMsg ||
      prevProps?.industryControllerData?.errMsg !==
        this?.props?.industryControllerData?.errMsg ||
      prevProps?.genderControllerData?.errMsg !==
        this?.props?.genderControllerData?.errMsg ||
      prevProps?.designationControllerData?.errMsg !==
        this?.props?.designationControllerData?.errMsg ||
      prevProps?.altContactControllerData?.errMsg !==
        this?.props?.altContactControllerData?.errMsg ||
      prevProps?.eduInstControllerData?.errMsg !==
        this?.props?.eduInstControllerData?.errMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.errMsg ||
        this?.props?.mStatusControllerData?.errMsg ||
        this?.props?.mIntrestControllerData?.errMsg ||
        this?.props?.industryControllerData?.errMsg ||
        this?.props?.genderControllerData?.errMsg ||
        this?.props?.designationControllerData?.errMsg ||
        this?.props?.altContactControllerData?.errMsg ||
        this?.props?.eduInstControllerData?.errMsg
      ) {
        this.setState({
          error:
            this?.props?.mTypeControllerData?.errMsg ||
            this?.props?.mStatusControllerData?.errMsg ||
            this?.props?.mIntrestControllerData?.errMsg ||
            this?.props?.industryControllerData?.errMsg ||
            this?.props?.genderControllerData?.errMsg ||
            this?.props?.designationControllerData?.errMsg ||
            this?.props?.altContactControllerData?.errMsg ||
            this?.props?.eduInstControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(
          this?.props?.mTypeControllerData?.errMsg ||
            this?.props?.mStatusControllerData?.errMsg ||
            this?.props?.mIntrestControllerData?.errMsg ||
            this?.props?.industryControllerData?.errMsg ||
            this?.props?.genderControllerData?.errMsg ||
            this?.props?.designationControllerData?.errMsg ||
            this?.props?.altContactControllerData?.errMsg ||
            this?.props?.eduInstControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.mTypeControllerData?.allMTypeData !=
      this?.props?.mTypeControllerData?.allMTypeData
    ) {
      this.setState({
        memberType: this?.props?.mTypeControllerData?.allMTypeData,
      });
    }
    if (
      prevProps?.mStatusControllerData?.allMStatusData !=
      this?.props?.mStatusControllerData?.allMStatusData
    ) {
      this.setState({
        maritalStatus: this?.props?.mStatusControllerData?.allMStatusData,
      });
    }
    if (
      prevProps?.mIntrestControllerData?.allMIntrestData !=
      this?.props?.mIntrestControllerData?.allMIntrestData
    ) {
      this.setState({
        memberInterest: this?.props?.mIntrestControllerData?.allMIntrestData,
      });
    }
    if (
      prevProps?.industryControllerData?.allIndustryData !=
      this?.props?.industryControllerData?.allIndustryData
    ) {
      this.setState({
        industryType: this?.props?.industryControllerData?.allIndustryData,
      });
    }
    if (
      prevProps?.genderControllerData?.allGenderData !=
      this?.props?.genderControllerData?.allGenderData
    ) {
      this.setState({
        genderType: this?.props?.genderControllerData?.allGenderData,
      });
    }
    if (
      prevProps?.designationControllerData?.allDesignationData !=
      this?.props?.designationControllerData?.allDesignationData
    ) {
      this.setState({
        desgType: this?.props?.designationControllerData?.allDesignationData,
      });
    }
    if (
      prevProps?.altContactControllerData?.allAltContactData !=
      this?.props?.altContactControllerData?.allAltContactData
    ) {
      this.setState({
        altContType: this?.props?.altContactControllerData?.allAltContactData,
      });
    }
    if (
      prevProps?.eduInstControllerData?.allEduInstData !=
      this?.props?.eduInstControllerData?.allEduInstData
    ) {
      this.setState({
        eduInstType: this?.props?.eduInstControllerData?.allEduInstData,
      });
    }
  }

  handleGetAllSettings = () => {
    const { getAllMType } = this?.props?.mTypeControllerData;
    const { getAllMStatus } = this?.props?.mStatusControllerData;
    const { getAllMIntrest } = this?.props?.mIntrestControllerData;
    const { getAllIndustry } = this?.props?.industryControllerData;
    const { getAllGender } = this?.props?.genderControllerData;
    const { getAllDesignation } = this?.props?.designationControllerData;
    const { getAllAltContact } = this?.props?.altContactControllerData;
    const { getAllEduInst } = this?.props?.eduInstControllerData;

    getAllMType();
    getAllMStatus();
    getAllMIntrest();
    getAllIndustry();
    getAllGender();
    getAllDesignation();
    getAllAltContact();
    getAllEduInst();
  };

  handleSwitchChange = (data) => {
    const { isStep } = this.state;
    const { updateGender } = this?.props?.genderControllerData;
    const { updateDesignation } = this?.props?.designationControllerData;
    const { updateIndustry } = this?.props?.industryControllerData;
    const { updateMType } = this?.props?.mTypeControllerData;
    const { updateMStatus } = this?.props?.mStatusControllerData;
    const { updateAltContact } = this?.props?.altContactControllerData;
    const { updateMIntrest } = this?.props?.mIntrestControllerData;
    const { updateEduInst } = this?.props?.eduInstControllerData;

    isStep === 1 && updateGender({ _id: data?._id, isActive: !data?.isActive });
    isStep === 2 &&
      updateDesignation({ _id: data?._id, isActive: !data?.isActive });
    isStep === 3 &&
      updateIndustry({ _id: data?._id, isActive: !data?.isActive });
    isStep === 4 && updateMType({ _id: data?._id, isActive: !data?.isActive });
    isStep === 5 &&
      updateMStatus({ _id: data?._id, isActive: !data?.isActive });
    isStep === 6 &&
      updateAltContact({ _id: data?._id, isActive: !data?.isActive });
    isStep === 7 &&
      updateMIntrest({ _id: data?._id, isActive: !data?.isActive });
    isStep === 8 &&
      updateEduInst({ _id: data?._id, isActive: !data?.isActive });
  };

  changeStep = (step) => {
    if (!this.state.isLoader) this.setState({ isStep: step });
  };

  render() {
    let {
      isLoader,
      isUpdate,
      isInit,
      isStep,
      genderType,
      desgType,
      industryType,
      memberType,
      maritalStatus,
      altContType,
      eduInstType,
      memberInterest,
    } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/member-list/member">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Member Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="card h-100">
                      <div className="container-fluid">
                        <div className="d-flex align-items-center mb-3">
                          <h4
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            Member Category
                          </h4>
                        </div>
                        <div className="row" onClick={() => this.changeStep(1)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 1 ? "600" : 500,
                              }}
                            >
                              Gender
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(2)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 2 ? "600" : 500,
                              }}
                            >
                              Designation
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(3)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 3 ? "600" : 500,
                              }}
                            >
                              Industries
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(4)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 4 ? "600" : 500,
                              }}
                            >
                              Membership Types
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(5)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 5 ? "600" : 500,
                              }}
                            >
                              Marital Status
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(6)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 6 ? "600" : 500,
                              }}
                            >
                              Alternate Contact
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(7)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 7 ? "600" : 500,
                              }}
                            >
                              Member Interests
                            </p>
                          </div>
                        </div>
                        <div className="row" onClick={() => this.changeStep(8)}>
                          <div className="col mb-3 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === 8 ? "600" : 500,
                              }}
                            >
                              Educational Institute
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col"
                    style={{
                      paddingLeft: "3px",
                      height: "calc(100vh - 170px)",
                      overflowY: "auto",
                    }}
                  >
                    <div className="card h-100">
                      <div className="container-fluid">
                        {isInit && isLoader && (
                          <div className="d-flex h-100 justify-content-center align-items-center">
                            <Loader />
                          </div>
                        )}
                        {isStep === 1 ? (
                          <>
                            {!isLoader && genderType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Gender
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Gender",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {genderType &&
                              genderType.length > 0 &&
                              genderType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Gender",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 2 ? (
                          <>
                            {!isLoader && desgType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Designation
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Designation",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {desgType &&
                              desgType.length > 0 &&
                              desgType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Designation",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 3 ? (
                          <>
                            {!isLoader && industryType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Industries
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Industries",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {industryType &&
                              industryType.length > 0 &&
                              industryType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 p-2">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Industries",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 4 ? (
                          <>
                            {!isLoader && memberType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Membership Types
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Membership Type",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {memberType &&
                              memberType.length > 0 &&
                              memberType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Membership Type",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 5 ? (
                          <>
                            {!isLoader && maritalStatus?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Marital Status
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Marital Status",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {maritalStatus &&
                              maritalStatus.length > 0 &&
                              maritalStatus?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Marital Status",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 6 ? (
                          <>
                            {!isLoader && altContType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Alternate Contact
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Alternate Contact",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {altContType &&
                              altContType.length > 0 &&
                              altContType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Alternate Contact",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 7 ? (
                          <>
                            {!isLoader && memberInterest?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Member Interests
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Member Interests",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {memberInterest &&
                              memberInterest.length > 0 &&
                              memberInterest?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Member Interests",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}

                        {isStep === 8 ? (
                          <>
                            {!isLoader && eduInstType?.length === 0 && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <NoRecord />
                              </div>
                            )}
                            <div className="d-flex align-items-center mb-3">
                              <h4
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Educational Institute
                              </h4>
                              <ModalButton
                                data={{
                                  activeStep: this.state.isStep,
                                  getAllSettings: this.handleGetAllSettings,
                                  showCustomModal: true,
                                  title: "Add Educational Institute",
                                  path: "MemberSetting/addSetting",
                                  body: "",
                                  className: "modal-small",
                                }}
                                disabled={isUpdate}
                              >
                                <h3
                                  style={{
                                    marginBottom: 0,
                                    color: "#1c6765",
                                    cursor: isUpdate ? "default" : "pointer",
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: 20 }}
                                  ></i>
                                </h3>
                              </ModalButton>
                            </div>
                            {eduInstType &&
                              eduInstType.length > 0 &&
                              eduInstType?.map((data) => {
                                return (
                                  <div className="row" key={data._id}>
                                    <div className="col-6 mb-3">
                                      <p
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.title}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="d-flex align-items-center">
                                        <ModalButton
                                          data={{
                                            itemData: data,
                                            activeStep: this.state.isStep,
                                            getAllSettings: this
                                              .handleGetAllSettings,
                                            showCustomModal: true,
                                            title: "Edit Educational Institute",
                                            path: "MemberSetting/addSetting",
                                            body: "",
                                            className: "modal-small",
                                          }}
                                          disabled={isUpdate}
                                        >
                                          <h3
                                            style={{
                                              marginRight: 10,
                                              marginBottom: 0,
                                              cursor: isUpdate
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          </h3>
                                        </ModalButton>
                                        <InputField
                                          value={data.isActive}
                                          checked={data.isActive}
                                          inputChange={() =>
                                            isUpdate
                                              ? {}
                                              : this.handleSwitchChange(data)
                                          }
                                          dom={{
                                            type: "checkbox",
                                            ele: "switch",
                                            label: "rte",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ page: { isLoader = false } }) {
  return { isLoader };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const altContactControllerData = useAltContact();
    const designationControllerData = useDesignation();
    const genderControllerData = useGender();
    const industryControllerData = useIndustry();
    const mIntrestControllerData = useMIntrest();
    const mStatusControllerData = useMStatus();
    const mTypeControllerData = useMType();
    const eduInstControllerData = useEduInst();

    return (
      <Component
        {...props}
        altContactControllerData={altContactControllerData}
        designationControllerData={designationControllerData}
        genderControllerData={genderControllerData}
        industryControllerData={industryControllerData}
        mIntrestControllerData={mIntrestControllerData}
        mStatusControllerData={mStatusControllerData}
        mTypeControllerData={mTypeControllerData}
        eduInstControllerData={eduInstControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {})(injectComp(MemberSettings));
