/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import ReactTooltip from "react-tooltip";
import { resetPageStore } from "../actions/page";
import { dateToStr, exportToCsv } from "../utils/formatter";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import BookReservation from "../components/reservation/bookReservation";
import Filters from "../components/reservation/filter";
import InputField from "../components/customFields/inputField";
import "../style/newStyle.scss";
import moment from "moment";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getClubs } from "../actions/clubs";
import useReservation from "../controller/reservation/reservation-controller";
import useSpaces from "../controller/spaces/spaces-controller";
import { toast } from "react-toastify";

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.list = [];
    this.keymapper = {
      header: [
        "Start Date",
        "Start Time",
        "End Time",
        "M.No",
        "Member Name",
        "Total Guest",
        "Area",
        "Is Approved",
        "From",
        "Notes",
      ],
      keys: [
        "date",
        "startDateTime",
        "endDateTime",
        "mNo",
        "member",
        "noGuest",
        "area",
        "isApproved",
        "doneFrom",
        "note",
      ],
      mapper: { isApproved: { 1: "Pending", 2: "Approved", 3: "Disapproved" } },
    };
    this.state = {
      isinit: true,
      list: [],
      error: "",
      selectedRoom: null,
      date: dateToStr(null, "YYYY-MM-DD"),
      isFilter: false,
      type: "all",
      singleData: {},
      loadingSelected: false,
      activeClub: "",
      request: true,
      isAdd: false,
      isLoader: false,
      reservationTypeList: [],
    };
  }

  componentDidMount() {
    this.props.getClubs();
    this.setState({
      reservationTypeList: [],
    });

    if (this?.props?.activeClub?.[0]?._id) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      this.getList(this.state.date);
      this.getResSpaces();
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  getResSpaces = () => {
    const { getAllReservationSpaces } = this?.props?.spacesControllerData;
    getAllReservationSpaces(this?.props?.activeClub?.[0]?._id);
  };

  getList = (date, space = "", member = "", isPrivate = false) => {
    if (this?.props?.activeClub?.[0]?._id) {
      const { getAllReservations } = this?.props?.reservationControllerData;
      const url = `${
        this?.props?.activeClub?.[0]?._id
      }?startDateTime=${moment.utc(date).unix()}${
        isPrivate ? "&privateSpaceId=" : "&spaceId="
      }${space}&memberId=${member}`;

      getAllReservations(url);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps?.reservationControllerData?.allReservations != prevState.list &&
      prevState.isinit &&
      nextProps?.reservationControllerData?.isLoading
    ) {
      return {
        list:
          nextProps?.reservationControllerData?.allReservations?.length > 0
            ? nextProps?.reservationControllerData?.allReservations
            : [],
        isinit: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.spacesControllerData?.allResSpaces !==
      this?.props?.spacesControllerData?.allResSpaces
    ) {
      this.setState({
        reservationTypeList: this?.props?.spacesControllerData?.allResSpaces,
      });
    }

    if (
      prevProps?.reservationControllerData?.allReservations !==
      this?.props?.reservationControllerData?.allReservations
    ) {
      this.setState({
        list: this?.props?.reservationControllerData?.allReservations,
      });
    }

    if (
      prevProps?.reservationControllerData?.isLoading !==
      this?.props?.reservationControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.reservationControllerData?.isLoading,
      });
    }

    if (
      prevProps?.spacesControllerData?.succMsg !==
        this?.props?.spacesControllerData?.succMsg ||
      prevProps?.reservationControllerData?.succMsg !==
        this?.props?.reservationControllerData?.succMsg
    ) {
      if (
        this?.props?.spacesControllerData?.succMsg ||
        this?.props?.reservationControllerData?.succMsg
      ) {
        // this.getList(this.state.date, this.state.selectedRoom?._id);
        // // this.props.toastmessage(response.message);
        // this.getListWithFilter(item._id, status);
        // this.setState({ error: "" });
        toast.success(
          this?.props?.spacesControllerData?.succMsg ||
            this?.props?.reservationControllerData?.succMsg
        );
      }
    }

    if (
      prevProps?.reservationControllerData?.errMsg !==
      this?.props?.reservationControllerData?.errMsg
    ) {
      if (this?.props?.reservationControllerData?.errMsg) {
        this.setState({
          error: this?.props?.reservationControllerData?.errMsg,
        });
        toast.error(this?.props?.reservationControllerData?.errMsg);
      }
    }

    if (
      prevProps?.spacesControllerData?.errMsg !==
      this?.props?.spacesControllerData?.errMsg
    ) {
      if (this?.props?.spacesControllerData?.errMsg) {
        toast.error(this?.props?.spacesControllerData?.errMsg);
      }
    }

    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      if (
        prevProps !== this.props &&
        prevProps?.activeClub !== null &&
        prevProps?.activeClub?.length > 0 &&
        this?.props?.activeClub !== null &&
        this?.props?.activeClub?.length > 0
      ) {
        this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      }
      if (
        this.state.activeClub !== "" &&
        prevState.activeClub !== this.state.activeClub
      ) {
        this.getList(this.state.date);
        this.getResSpaces();
      }
    }

    if (
      this?.props?.adminType !== "Super Admin" &&
      this?.props?.adminType !== "Admin" &&
      this?.props?.adminType !== "Developer"
    ) {
      if (
        prevProps !== this.props &&
        this?.props?.activeClub !== null &&
        this?.props?.activeClub?.length > 0
      ) {
        this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      }
      if (this.state.activeClub !== "" && this.state.request) {
        this.getList(this.state.date);
        this.getResSpaces();
        this.setState({ request: false });
      }
    }
  }

  setRoom = (data) => {
    this.setState({
      selectedRoom: data,
      isAdd: false,
    });
    this.getList(this.state.date, data?._id, "", data?.privateHire);
  };

  refreshList = (date, area, member) => {
    this.setState({ selectedRoom: area, date: date });
    this.getList(date, area?._id, member, area?.privateHire);
  };

  goBack = () => {
    this.setState(
      {
        isAdd: false,
      },
      () => {
        this.getList(
          this.state.date,
          this.state.selectedRoom?._id,
          "",
          this.state.selectedRoom?.privateHire
        );
      }
    );
  };

  edit = (item) => {
    let { reservationTypeList } = this.state;
    this.setState({ singleData: item });
    let group = reservationTypeList ? Object.keys(reservationTypeList) : [];
    let selectedRoom = null;
    for (let i = 0; i < group.length; i++) {
      if (
        reservationTypeList[i]?._id === item?.spaceId?._id ||
        reservationTypeList[i]?._id === item?.privateSpaceId?._id
      ) {
        selectedRoom = reservationTypeList[i];
      }
    }
    this.setState({
      selectedRoom,
      isAdd: true,
    });
  };

  editInSub = (item) => {
    let { reservationTypeList } = this.state;
    this.setState({ loadingSelected: true, singleData: item });
    let group = reservationTypeList ? Object.keys(reservationTypeList) : [];
    let selectedRoom = null;
    for (let i = 0; i < group.length; i++) {
      if (
        reservationTypeList[i]?._id === item?.spaceId?._id ||
        reservationTypeList[i]?._id === item?.privateSpaceId?._id
      ) {
        selectedRoom = reservationTypeList[i];
      }
    }
    setTimeout(() => {
      this.setState({
        loadingSelected: false,
        selectedRoom,
        isAdd: true,
      });
    }, 100);
  };

  changeStatus = (e, status, item) => {
    const { updateReservation } = this?.props?.reservationControllerData;
    e.preventDefault();
    let payload = { ...item, isApproved: status };
    updateReservation(payload, (response) => {
      if (response?.data?.status && response?.data?.status !== "false") {
        this.getListWithFilter(item._id, status);
        this.setState({ error: "" });
        //this.props.toastmessage(response.message);
      }
    });
  };

  getListWithFilter = (id, status) => {
    const { list } = this.state;
    let a = list.filter((e) => e._id === id);
    a[0].isApproved = status;
    this.setState({ list });
  };

  goToFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        selectedRoom: null,
        date: dateToStr(null, "YYYY-MM-DD"),
        isFilter: true,
      },
      () => {
        this.getList(this.state.date);
      }
    );
  };

  loadData = (e) => {
    let { value } = e.target;
    this.setState({ date: value });
    this.getList(
      value,
      this.state.selectedRoom && this.state.selectedRoom !== null
        ? this.state.selectedRoom?._id
        : "",
      "",
      this?.state?.selectedRoom?.privateHire
    );
  };

  checkAdmin = () => {
    if (this?.props?.adminType === "Developer") {
      return this.props.history.push("/spaces");
    }
  };

  render() {
    const {
      list,
      selectedRoom,
      date,
      isinit,
      isFilter,
      isAdd,
      isLoader,
      reservationTypeList,
    } = this.state;
    let { adminType = "", permissions = {} } = this.props;
    let group = reservationTypeList ? Object.keys(reservationTypeList) : [];

    if (Array.isArray(list) && list.length > 0 && list[0].startDateTime) {
      list.sort((a, b) => {
        // var time1 = parseFloat(
        //   a.startDateTime.replace(":", ".").replace(/[^\d.-]/g, "")
        // );
        // var time2 = parseFloat(
        //   b.startDateTime.replace(":", ".").replace(/[^\d.-]/g, "")
        // );
        var time1 = a?.startDateTime;
        var time2 = b?.startDateTime;
        // if (a.startDateTime.match(/.*pm/)) time1 += 12;
        // if (b.startDateTime.match(/.*pm/)) time2 += 12;
        if (time1 > time2) return 1;
        if (time1 < time2) return -1;
        return 0;
      });
    }

    return (
      <React.Fragment>
        {!isFilter ? (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
            <div className="d-flex mb-0 align-items-center justify-content-between">
              <div>
                <h3 className="page-title mb-0">Reservation</h3>
              </div>
              <div className="row align-items-center reservation-page">
                {adminType === "Developer" ? (
                  <div className="col-auto p-0" onClick={this.checkAdmin}>
                    <h3
                      style={{
                        marginBottom: 0,
                        color: "#1c6765",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-gear"></i>
                    </h3>
                  </div>
                ) : null}
                &nbsp;&nbsp;&nbsp;
                <div className="col-auto p-0" style={{ width: "130px" }}>
                  <InputField
                    name="date"
                    groupClass="mbi-0"
                    value={date}
                    inputChange={this.loadData}
                    dom={{
                      placeholder: "Select Date",
                      type: "date",
                      dateFormat: "dd/MM/yyyy",
                      placement: "bottom",
                    }}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className={`col-auto p-0`}>
                  {group && group.length > 0 && (
                    <div className="search_tab">
                      <Button
                        onClick={this.goToFilters}
                        style={{ borderColor: "transparent" }}
                        className="btn btn-primary btn-primary-green"
                      >
                        <p>Search a Reservation</p>
                      </Button>
                    </div>
                  )}
                </div>
                &nbsp;&nbsp;&nbsp;
                {list.length !== undefined &&
                  list.length > 0 &&
                  (selectedRoom?._id?.length > 0
                    ? list.filter(
                        (e) =>
                          e?.spaceId?._id === selectedRoom?._id ||
                          e?.privateSpaceId?._id === selectedRoom?._id
                      ).length > 0
                    : list.length > 0) &&
                  permissions?.view && (
                    <div className="col-auto p-0">
                      <button
                        className="btn btn-primary btn-primary-green"
                        onClick={() =>
                          exportToCsv(
                            selectedRoom?._id?.length > 0
                              ? list
                                  .filter(
                                    (e) =>
                                      e?.spaceId?._id === selectedRoom?._id ||
                                      e?.privateSpaceId?._id ===
                                        selectedRoom?._id
                                  )
                                  .map((dt) => ({
                                    ...dt,
                                    date: moment
                                      .unix(dt?.startDateTime)
                                      .format("DD-MM-YYYY"),
                                    startDateTime: moment
                                      .unix(dt?.startDateTime, "hh:mm a")
                                      .format("HH:mm"),
                                    endDateTime: moment
                                      .unix(dt?.endDateTime, "hh:mm a")
                                      .format("HH:mm"),
                                    mNo: dt?.memberId?.mNo,
                                    member: `${dt?.memberId?.fName || ""} ${dt
                                      ?.memberId?.mName || ""} ${dt?.memberId
                                      ?.lName || ""}`,
                                    area:
                                      dt?.spaceId?.name ||
                                      dt?.privateSpaceId?.name,
                                    isApproved: dt?.isApproved,
                                  }))
                              : list?.map((dt) => ({
                                  ...dt,
                                  date: moment
                                    .unix(dt?.startDateTime)
                                    .format("DD-MM-YYYY"),
                                  startDateTime: moment
                                    .unix(dt?.startDateTime, "hh:mm a")
                                    .format("HH:mm"),
                                  endDateTime: moment
                                    .unix(dt?.endDateTime, "hh:mm a")
                                    .format("HH:mm"),
                                  mNo: dt?.memberId?.mNo,
                                  member: `${dt?.memberId?.fName || ""} ${dt
                                    ?.memberId?.mName || ""} ${dt?.memberId
                                    ?.lName || ""}`,
                                  area:
                                    dt?.spaceId?.name ||
                                    dt?.privateSpaceId?.name,
                                  isApproved: dt?.isApproved,
                                })),
                            "reservation_list_" +
                              date +
                              "_DLon_" +
                              moment().format("YYYY-MM-DD_HH.mm"),
                            this.keymapper
                          )
                        }
                      >
                        Download
                      </button>
                    </div>
                  )}
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div className="row h-100">
              <div className="col-3" style={{ width: "20%" }}>
                <div className="card bg-transparent margin-top-15 border-none h-100 overflow-x-hidden overflow-auto">
                  <div className="reservtion-tab overflow-card">
                    <ul
                      className="list-unstyled components"
                      style={{ border: "none" }}
                    >
                      <li
                        className={!selectedRoom?._id ? "active" : ""}
                        onClick={() => {
                          this.setState({
                            selectedRoom: null,
                            isAdd: false,
                          });
                          this.getList(this.state.date);
                        }}
                      >
                        <p>ALL SPACES</p>
                      </li>
                      {group &&
                        group.length > 0 &&
                        group.map((gr, i) => {
                          let itemid = reservationTypeList?.[gr]?._id;
                          return (
                            <li
                              key={i}
                              className={
                                selectedRoom?._id === itemid ? "active" : ""
                              }
                              onClick={() =>
                                this.setRoom(reservationTypeList?.[gr])
                              }
                            >
                              <p>
                                {reservationTypeList[gr].privateHire
                                  ? `Private Hire - ${reservationTypeList?.[gr]?.name}`
                                  : reservationTypeList?.[gr]?.name}
                              </p>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-9 h-100"
                style={{ width: "80%", paddingLeft: "3px" }}
              >
                <div className="card  margin-top-15 h-100 overflow-hidden">
                  <div className="h-100 overflow-x-hidden overflow-auto">
                    {isLoader && (
                      <div className="justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {/* {!isLoader && (!list || list.length === 0) && (
                      <NoRecord message="No checked in members found" />
                    )} */}
                    <div className="container-fluid">
                      {isinit && <Loader />}
                      {!isinit && (
                        <div
                          className={`reservation ${
                            selectedRoom?._id || isFilter ? "checkedin" : ""
                          }`}
                        >
                          {selectedRoom?._id && permissions?.create && (
                            <div className="row">
                              <div className="col-12 text-end margin-bottom-15">
                                <Button
                                  onClick={() => {
                                    this.setState(
                                      {
                                        singleData: {},
                                      },
                                      () => {
                                        this.setState({
                                          isAdd: true,
                                        });
                                      }
                                    );
                                  }}
                                  style={{ borderColor: "transparent" }}
                                  className="btn btn-primary btn-primary-green"
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12">
                              {!isLoader &&
                                (!list ||
                                  list.length === 0 ||
                                  !list.length) && (
                                  <NoRecord message="No reservations found" />
                                )}
                              {!isLoader &&
                              list &&
                              list.length > 0 &&
                              selectedRoom?._id &&
                              list.filter(
                                (e) =>
                                  e?.spaceId?._id === selectedRoom?._id ||
                                  e?.privateSpaceId?._id === selectedRoom?._id
                              ).length === 0 ? (
                                <NoRecord message="No reservations found" />
                              ) : (
                                ""
                              )}
                              {isLoader && <Loader />}
                              {!isLoader && list && list.length > 0 && (
                                <table className="table table-borderless custom-table">
                                  {(selectedRoom?._id !== "" &&
                                    list.filter(
                                      (e) =>
                                        e?.spaceId?._id === selectedRoom?._id ||
                                        e?.privateSpaceId?._id ===
                                          selectedRoom?._id
                                    ).length > 0) ||
                                  (list &&
                                    !selectedRoom?._id &&
                                    list.length > 0) ? (
                                    <thead>
                                      <tr className="text-center">
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Member's Name</th>
                                        <th>Guests</th>
                                        <th>Area</th>
                                        <th>Note</th>
                                        <th>From</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                  ) : (
                                    ""
                                  )}
                                  <tbody>
                                    {!selectedRoom?._id &&
                                      list.map((item, i) => {
                                        return (
                                          <tr
                                            key={
                                              item?._id +
                                              this.state.selectedRoom?._id
                                            }
                                            style={{
                                              color:
                                                item?.isApproved === 2
                                                  ? "#27ce27"
                                                  : item?.isApproved === 3
                                                  ? "#fd2b22"
                                                  : "",
                                            }}
                                            className="text-center"
                                          >
                                            <td>
                                              {moment
                                                .unix(item?.startDateTime)
                                                .format("DD-MM-YYYY")}
                                            </td>
                                            <td>
                                              {moment
                                                .unix(
                                                  item?.startDateTime,
                                                  "hh:mm a"
                                                )
                                                .format("HH:mm")}{" "}
                                              -{" "}
                                              {moment
                                                .unix(
                                                  item?.endDateTime,
                                                  "hh:mm a"
                                                )
                                                .format("HH:mm")}{" "}
                                            </td>
                                            <td>
                                              <div
                                                data-for={"main" + i}
                                                data-tip={item?.memberId?.mNo}
                                                data-iscapture="true"
                                                className="capitalize-text"
                                              >
                                                {item?.memberId?.fName}{" "}
                                                {item?.memberId?.mName}{" "}
                                                {item?.memberId?.lName}
                                              </div>
                                              <ReactTooltip
                                                id={"main" + i}
                                                place={"bottom"}
                                                type={"light"}
                                                effect={"solid"}
                                                multiline={true}
                                                backgroundColor="#fff"
                                              />
                                            </td>
                                            <td className="text-center">
                                              {item?.noGuest}
                                            </td>
                                            <td
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              {item?.spaceId?.name ||
                                                item?.privateSpaceId?.name}
                                            </td>
                                            <td className="text-center">
                                              <ModalButton
                                                data={{
                                                  showCustomModal: true,
                                                  title: "Note",
                                                  path: "reservation/notes",
                                                  body: "",
                                                  data: item,
                                                  id: item?._id,
                                                  className:
                                                    "modal-large modal-dialog-centered",
                                                  getList: () =>
                                                    this.getList(
                                                      this.state.date
                                                    ),
                                                }}
                                              >
                                                {item?.note && (
                                                  <span className="cursor-pointer color-green fs20">
                                                    <i className="fa fa-file-text"></i>
                                                  </span>
                                                )}

                                                {!item?.note && (
                                                  <span className="cursor-pointer">
                                                    <i className="fa fa-file-text"></i>
                                                  </span>
                                                )}
                                              </ModalButton>
                                            </td>
                                            <td>{item?.doneFrom}</td>
                                            <td>
                                              <div>
                                                {(item?.isApproved === 1 ||
                                                  item?.isApproved === 3) &&
                                                  permissions?.update && (
                                                    <span
                                                      className="cursor-pointer fs20 color-green"
                                                      onClick={(e) =>
                                                        this.changeStatus(
                                                          e,
                                                          2,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-check"></i>
                                                    </span>
                                                  )}
                                                {permissions?.update && (
                                                  <span
                                                    className="ml20  cursor-pointer"
                                                    style={{ fontSize: "17px" }}
                                                    onClick={() =>
                                                      this.edit(item)
                                                    }
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                  </span>
                                                )}
                                                {(item?.isApproved === 1 ||
                                                  item?.isApproved === 2) &&
                                                  permissions?.update && (
                                                    <span
                                                      className="ml20 cursor-pointer fs18 color-red"
                                                      onClick={(e) =>
                                                        this.changeStatus(
                                                          e,
                                                          3,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-close"></i>
                                                    </span>
                                                  )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    {selectedRoom?._id &&
                                      list.filter(
                                        (e) =>
                                          e?.spaceId?._id ===
                                            selectedRoom?._id ||
                                          e?.privateSpaceId?._id ===
                                            selectedRoom?._id
                                      ).length > 0 &&
                                      list
                                        .filter(
                                          (e) =>
                                            e?.spaceId?._id ===
                                              selectedRoom?._id ||
                                            e?.privateSpaceId?._id ===
                                              selectedRoom?._id
                                        )
                                        .map((item, i) => {
                                          return (
                                            <tr
                                              key={i}
                                              style={{
                                                color:
                                                  item?.isApproved === 2
                                                    ? "#27ce27"
                                                    : item?.isApproved === 3
                                                    ? "#fd2b22"
                                                    : "",
                                              }}
                                              className="text-center"
                                            >
                                              <td>
                                                {moment
                                                  .unix(item?.startDateTime)
                                                  .format("DD-MM-YYYY")}
                                              </td>
                                              <td>
                                                {moment
                                                  .unix(
                                                    item?.startDateTime,
                                                    "hh:mm a"
                                                  )
                                                  .format("HH:mm")}{" "}
                                                -{" "}
                                                {moment
                                                  .unix(
                                                    item?.endDateTime,
                                                    "hh:mm a"
                                                  )
                                                  .format("HH:mm")}{" "}
                                              </td>
                                              <td className="capitalize-text">
                                                {item?.memberId?.fName}{" "}
                                                {item?.memberId?.mName}{" "}
                                                {item?.memberId?.lName}
                                              </td>
                                              <td className="text-center">
                                                {item?.noGuest}
                                              </td>
                                              <td>
                                                {item?.spaceId?.name ||
                                                  item?.privateSpaceId?.name}
                                              </td>
                                              <td className="text-center">
                                                <ModalButton
                                                  data={{
                                                    showCustomModal: true,
                                                    title: "Note",
                                                    path: "reservation/notes",
                                                    body: "",
                                                    data: item,
                                                    id: item?._id,
                                                    className:
                                                      "modal-large modal-dialog-centered",
                                                    getList: () =>
                                                      this.getList(
                                                        this.state.date
                                                      ),
                                                  }}
                                                >
                                                  {item?.note && (
                                                    <span className="cursor-pointer color-green fs20">
                                                      <i className="fa fa-file-text"></i>
                                                    </span>
                                                  )}

                                                  {!item?.note && (
                                                    <span className="cursor-pointer">
                                                      <i className="fa fa-file-text"></i>
                                                    </span>
                                                  )}
                                                </ModalButton>
                                              </td>
                                              <td>{item?.doneFrom}</td>
                                              <td>
                                                <div>
                                                  {(item?.isApproved === 1 ||
                                                    item?.isApproved === 3) &&
                                                    permissions?.update && (
                                                      <span
                                                        className="cursor-pointer color-green fs20"
                                                        onClick={(e) =>
                                                          this.changeStatus(
                                                            e,
                                                            2,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-check"></i>
                                                      </span>
                                                    )}
                                                  {permissions?.update && (
                                                    <span
                                                      className="ml20 cursor-pointer"
                                                      onClick={() =>
                                                        this.editInSub(item)
                                                      }
                                                    >
                                                      <i className="fa fa-edit"></i>
                                                    </span>
                                                  )}
                                                  {(item?.isApproved === 1 ||
                                                    item?.isApproved === 2) &&
                                                    permissions?.update && (
                                                      <span
                                                        className="ml20 cursor-pointer color-red fs18"
                                                        onClick={(e) =>
                                                          this.changeStatus(
                                                            e,
                                                            3,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-close"></i>
                                                      </span>
                                                    )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <i
                    onClick={() => {
                      this.setState({
                        selectedRoom: null,
                        isAdd: false,
                        isFilter: false,
                      });
                      this.getList(this.state.date);
                    }}
                    className="fa fa-arrow-left cursor-pointer"
                  ></i>
                  &nbsp;Search A Reservation
                </h3>
              </div>
              <div className="row align-items-center">
                <div
                  className="col-auto reservation-page"
                  style={{ width: "130px", paddingLeft: "0px" }}
                >
                  <InputField
                    name="date"
                    groupClass="mbi-0"
                    value={date}
                    inputChange={this.loadData}
                    dom={{
                      placeholder: "Select Date",
                      type: "date",
                      dateFormat: "dd/MM/yyyy",
                    }}
                  />
                </div>
                <div className="col-auto" style={{ paddingLeft: "0px" }}>
                  {list?.length !== undefined &&
                    list?.length > 0 &&
                    (selectedRoom?._id?.length > 0
                      ? list.filter(
                          (e) =>
                            e?.spaceId?._id === selectedRoom?._id ||
                            e?.privateSpaceId?._id === selectedRoom?._id
                        ).length > 0
                      : list.length > 0) &&
                    permissions?.view && (
                      <button
                        className="btn btn-primary btn-primary-green"
                        onClick={() =>
                          exportToCsv(
                            selectedRoom?._id?.length > 0
                              ? list
                                  .filter(
                                    (e) =>
                                      e?.spaceId?._id === selectedRoom?._id ||
                                      e?.privateSpaceId?._id ===
                                        selectedRoom?._id
                                  )
                                  .map((dt) => ({
                                    ...dt,
                                    date: moment
                                      .unix(dt?.startDateTime)
                                      .format("DD-MM-YYYY"),
                                    startDateTime: moment
                                      .unix(dt?.startDateTime, "hh:mm a")
                                      .format("HH:mm"),
                                    endDateTime: moment
                                      .unix(dt?.endDateTime, "hh:mm a")
                                      .format("HH:mm"),
                                    mNo: dt?.memberId?.mNo,
                                    member: `${dt?.memberId?.fName || ""} ${dt
                                      ?.memberId?.mName || ""} ${dt?.memberId
                                      ?.lName || ""}`,
                                    area:
                                      dt?.spaceId?.name ||
                                      dt?.privateSpaceId?.name,
                                    isApproved: dt?.isApproved,
                                  }))
                              : list?.map((dt) => ({
                                  ...dt,
                                  date: moment
                                    .unix(dt?.startDateTime)
                                    .format("DD-MM-YYYY"),
                                  startDateTime: moment
                                    .unix(dt?.startDateTime, "hh:mm a")
                                    .format("HH:mm"),
                                  endDateTime: moment
                                    .unix(dt?.endDateTime, "hh:mm a")
                                    .format("HH:mm"),
                                  mNo: dt?.memberId?.mNo,
                                  member: `${dt?.memberId?.fName || ""} ${dt
                                    ?.memberId?.mName || ""} ${dt?.memberId
                                    ?.lName || ""}`,
                                  area:
                                    dt?.spaceId?.name ||
                                    dt?.privateSpaceId?.name,
                                  isApproved: dt?.isApproved,
                                })),
                            "reservation_list_" +
                              date +
                              "_DLon_" +
                              moment().format("YYYY-MM-DD_HH.mm"),
                            this.keymapper
                          )
                        }
                      >
                        Download
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="row h-100">
              <div className="col-3" style={{ width: "351px" }}>
                <div className="card mt-2 ">
                  <div className=" h-100">
                    <div className="container-fluid mt-3">
                      <Filters
                        reservationTypeList={reservationTypeList}
                        refreshList={this.refreshList}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9" style={{ width: "calc(100% - 351px)" }}>
                <div className="card mt-2 h-100 overflow-hidden">
                  <div className=" h-100 overflow-x-hidden overflow-auto">
                    {isLoader && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {/* {!isLoader && (!list || list.length === 0) && (
                      <NoRecord message="No checked in members found" />
                    )} */}
                    <div className="container-fluid mt-3">
                      {isinit && <Loader />}
                      {!isinit && (
                        <div className="row">
                          <div className="col-12">
                            {!isLoader &&
                              (!list || list.length === 0 || !list.length) && (
                                <NoRecord message="No reservations found" />
                              )}
                            {!isLoader &&
                            list &&
                            list.length > 0 &&
                            selectedRoom?._id &&
                            list.filter(
                              (e) =>
                                e?.spaceId?._id === selectedRoom?._id ||
                                e?.privateSpaceId?._id === selectedRoom?._id
                            ).length === 0 ? (
                              <NoRecord message="No reservations found" />
                            ) : (
                              ""
                            )}
                            {isLoader && <Loader />}
                            {!isLoader && list && list.length > 0 && (
                              <table className="table table-borderless custom-table">
                                {(selectedRoom?._id !== "" &&
                                  list.filter(
                                    (e) =>
                                      e?.spaceId?._id === selectedRoom?._id ||
                                      e?.privateSpaceId?._id ===
                                        selectedRoom?._id
                                  ).length > 0) ||
                                (list &&
                                  !selectedRoom?._id &&
                                  list.length > 0) ? (
                                  <thead>
                                    <tr className="text-center">
                                      <th>Date</th>
                                      <th>Time</th>
                                      <th>Member's Name</th>
                                      <th>Guests</th>
                                      <th>Area</th>
                                      <th>Note</th>
                                      <th>From</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                ) : (
                                  ""
                                )}
                                <tbody>
                                  {// !selectedRoom?._id &&
                                  list.map((item, i) => {
                                    return (
                                      <tr
                                        key={
                                          item?._id
                                          // + this.state.selectedRoom?._id
                                        }
                                        style={{
                                          color:
                                            item?.isApproved === 2
                                              ? "#27ce27"
                                              : item?.isApproved === 3
                                              ? "#fd2b22"
                                              : "",
                                        }}
                                        className="text-center"
                                      >
                                        <td>
                                          {moment
                                            .unix(item?.startDateTime)
                                            .format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {moment
                                            .unix(
                                              item?.startDateTime,
                                              "hh:mm a"
                                            )
                                            .format("HH:mm")}{" "}
                                          -{" "}
                                          {moment
                                            .unix(item?.endDateTime, "hh:mm a")
                                            .format("HH:mm")}{" "}
                                        </td>
                                        <td>
                                          <div
                                            data-for={"main" + i}
                                            data-tip={item?.memberId?.mNo}
                                            data-iscapture="true"
                                            className="capitalize-text"
                                          >
                                            {item?.memberId?.fName}{" "}
                                            {item?.memberId?.mName}{" "}
                                            {item?.memberId?.lName}
                                          </div>
                                          <ReactTooltip
                                            id={"main" + i}
                                            place={"bottom"}
                                            type={"light"}
                                            effect={"solid"}
                                            multiline={true}
                                            backgroundColor="#fff"
                                          />
                                        </td>
                                        <td className="text-center">
                                          {item?.noGuest}
                                        </td>
                                        <td
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {item?.spaceId?.name ||
                                            item?.privateSpaceId?.name}
                                        </td>
                                        <td>
                                          <ModalButton
                                            style={{ width: "fit-content" }}
                                            data={{
                                              showCustomModal: true,
                                              title: "Note",
                                              path: "reservation/notes",
                                              body: "",
                                              data: item,
                                              id: item?._id,
                                              className:
                                                "modal-large modal-dialog-centered",
                                              getList: () =>
                                                this.getList(this.state.date),
                                            }}
                                          >
                                            {item?.note && (
                                              <span className="ml20 cursor-pointer color-green fs20">
                                                <i className="fa fa-file-text"></i>
                                              </span>
                                            )}

                                            {!item?.note && (
                                              <span className="ml20 cursor-pointer">
                                                <i className="fa fa-file-text"></i>
                                              </span>
                                            )}
                                          </ModalButton>
                                        </td>
                                        <td>{item?.doneFrom}</td>
                                        <td>
                                          <div>
                                            {(item?.isApproved === 1 ||
                                              item?.isApproved === 3) &&
                                              permissions?.update && (
                                                <span
                                                  className="ml20 cursor-pointer color-green fs20"
                                                  onClick={(e) =>
                                                    this.changeStatus(
                                                      e,
                                                      2,
                                                      item
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-check"></i>
                                                </span>
                                              )}
                                            {permissions?.update && (
                                              <span
                                                className="ml20 cursor-pointer"
                                                onClick={() => this.edit(item)}
                                              >
                                                <i className="fa fa-edit"></i>
                                              </span>
                                            )}
                                            {(item?.isApproved === 1 ||
                                              item?.isApproved === 2) &&
                                              permissions?.update && (
                                                <span
                                                  className="ml20 cursor-pointer color-red fs18"
                                                  onClick={(e) =>
                                                    this.changeStatus(
                                                      e,
                                                      3,
                                                      item
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-close"></i>
                                                </span>
                                              )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  {/* {selectedRoom?._id !== "" &&
                                    list.filter(
                                      (e) =>
                                        e?.spaceId?._id === selectedRoom?._id ||
                                        e?.privateSpaceId?._id ===
                                          selectedRoom?._id
                                    ).length > 0 &&
                                    list
                                      .filter(
                                        (e) =>
                                          e?.spaceId?._id ===
                                            selectedRoom?._id ||
                                          e?.privateSpaceId?._id ===
                                            selectedRoom?._id
                                      )
                                      .map((item, i) => {
                                        return (
                                          <tr
                                            key={i}
                                            style={{
                                              color:
                                                item?.isApproved === 2
                                                  ? "#27ce27"
                                                  : item?.isApproved === 3
                                                  ? "#fd2b22"
                                                  : "",
                                            }}
                                            className="text-center"
                                          >
                                            <td>
                                              {moment
                                                .unix(
                                                  item?.startDateTime,
                                                  "hh:mm a"
                                                )
                                                .format("HH:mm")}{" "}
                                              -{" "}
                                              {moment
                                                .unix(
                                                  item?.endDateTime,
                                                  "hh:mm a"
                                                )
                                                .format("HH:mm")}{" "}
                                            </td>
                                            <td>
                                              {moment
                                                .unix(item?.startDateTime)
                                                .format("DD-MM-YYYY")}
                                            </td>
                                            <td>
                                              {item?.memberId?.fName}{" "}
                                              {item?.memberId?.mName}{" "}
                                              {item?.memberId?.lName}
                                            </td>
                                            <td className="text-center">
                                              {item?.noGuest}
                                            </td>
                                            <td>
                                              {item?.spaceId?.name ||
                                                item?.privateSpaceId?.name}
                                            </td>
                                            <td>
                                              <ModalButton
                                                style={{ width: "fit-content" }}
                                                data={{
                                                  showCustomModal: true,
                                                  title: "Note",
                                                  path: "reservation/notes",
                                                  body: "",
                                                  data: item,
                                                  id: item._id,
                                                  className:
                                                    "modal-large modal-dialog-centered",
                                                  getList: () => this.getList(this.state.date)
                                                }}
                                              >
                                                {item?.note && (
                                                  <span className="ml20 cursor-pointer color-green fs20">
                                                    <i className="fa fa-file-text"></i>
                                                  </span>
                                                )}

                                                {!item?.note && (
                                                  <span className="ml20 cursor-pointer">
                                                    <i className="fa fa-file-text"></i>
                                                  </span>
                                                )}
                                              </ModalButton>
                                            </td>
                                            <td>{item?.doneFrom}</td>
                                            <td>
                                              <div>
                                                {(item?.isApproved === 1 ||
                                                  item?.isApproved === 3) &&
                                                  permissions?.update && (
                                                    <span
                                                      className="ml20 cursor-pointer color-green fs20"
                                                      onClick={(e) =>
                                                        this.changeStatus(
                                                          e,
                                                          2,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-check"></i>
                                                    </span>
                                                  )}
                                                {permissions?.update && (
                                                  <span
                                                    className="ml20 cursor-pointer"
                                                    onClick={() =>
                                                      this.editInSub(item)
                                                    }
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                  </span>
                                                )}
                                                {(item?.isApproved === 1 ||
                                                  item?.isApproved === 2) &&
                                                  permissions?.update && (
                                                    <span
                                                      className="ml20 cursor-pointer color-red fs18"
                                                      onClick={(e) =>
                                                        this.changeStatus(
                                                          e,
                                                          3,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-close"></i>
                                                    </span>
                                                  )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })} */}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={isAdd && selectedRoom && Object.keys(selectedRoom).length > 0}
          backdrop="static"
        >
          <ModalHeader>
            {selectedRoom?.name}
            <a href="#" className="close-icon" onClick={this.goBack}>
              <i className="fa fa-times"></i>
            </a>
          </ModalHeader>
          <ModalBody id="scrollRef" className="default-font">
            {isAdd && selectedRoom && Object.keys(selectedRoom).length > 0 && (
              <BookReservation
                loadingSelected={this.state.loadingSelected}
                singleData={this.state.singleData}
                goBack={this.goBack}
                date={this.state.date}
                toastmessage={this.props.toastmessage}
                selectedRoom={selectedRoom}
              />
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { authData }, clubs: { activeClub } }) {
  return {
    activeClub: activeClub,
    adminType: authData?.roleId?.roleName,
  };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const reservationControllerData = useReservation();
    const spacesControllerData = useSpaces();

    return (
      <Component
        {...props}
        reservationControllerData={reservationControllerData}
        spacesControllerData={spacesControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(Reservation));
