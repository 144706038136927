/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPageStore } from "../../actions/page";
import { getValue } from "../../utils/formatter";
import Loader from "../customFields/loader";
import NoRecord from "../customFields/noRecord";
import { historyReplacestate, getQueryParams } from "../../utils/history";
import InputField from "../customFields/inputField";
import { getClubs } from "../../actions/clubs";
import Pagination from "../pagination/pagination";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import useStaff from "../../controller/staff/staff-controller";
import useMember from "../../controller/member/member-controller";
import { toast } from "react-toastify";

class Memberlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      memberId: "",
      memberId_name: [],
      isinit: true,
      totalCount: "",
      page: 1,
      activeClub: "",
      allStaffData: [],
      isLoader: false,
    };
  }
  syncQstring() {
    let qSting = getQueryParams();
    return {
      keyword: getValue(qSting, "keyword", ""),
      memberId: getValue(qSting, "memberId", ""),
      memberId_name: getValue(qSting, "memberId", "")
        ? [
            {
              id: getValue(qSting, "memberId", ""),
              name: getValue(qSting, "name", ""),
            },
          ]
        : [],
    };
  }
  componentDidMount() {
    this.props.getClubs();
    if (this?.props?.activeClub?.[0]?._id) this.handleGetAllStaff();

    let {
      match: { params },
    } = this.props;
    if (
      !params.mtype ||
      ["member", "staf-member"].indexOf(params.mtype) === -1
    ) {
      this.props.history.push("/dashboard");
    } else {
      let { keyword, memberId, memberId_name } = this.syncQstring();
      this.setState({ keyword, memberId, memberId_name }, () => {
        // this.handleGetAllStaff(params.mtype);
      });
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  handleGetAllStaff = (type, isSearch = false, page = this.state.page) => {
    if (this?.props?.activeClub?.[0]?._id) {
      let {
        keyword,
        // memberId
      } = this.state;
      page = page - 1;
      const { getAllStaff } = this?.props?.staffControllerData;
      const { getAllMembers } = this?.props?.memberControllerData;
      const url = `${this?.props?.activeClub?.[0]?._id}?page=${page}&limit=200&searchKeyword=${keyword}`;

      type == "member" ? getAllMembers(url) : getAllStaff(url);
      if (isSearch) this.setQueryParams();
    }
  };

  setQueryParams = () => {
    let { keyword, memberId, memberId_name } = this.state;
    let q = `?keyword=${memberId ? "" : keyword}&memberId=${memberId}&name=${
      memberId ? memberId_name[0].name : ""
    }&limit=200`;
    historyReplacestate("", q);
  };

  reset = (type) => {
    if (!this.state.keyword /*|| !this.state.memberId*/) {
      return;
    }
    this.inputChange({ target: { name: "keyword", value: "" } });
    this.setState({ keyword: "", memberId: "", memberId_name: [] }, () => {
      this.setQueryParams();
      this.handleGetAllStaff(type, false, 1);
    });
  };

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this?.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      // this.getList(this.props.match.params.mtype);
      this.handleGetAllStaff(this.props.match.params.mtype);
    }

    let {
      match: { params },
    } = this.props;
    if (params.mtype !== prevProps.match.params.mtype) {
      if (["member", "staf-member"].indexOf(params.mtype) == -1) {
        this.props.history.push("/dashboard");
      } else {
        // this.getList(params.mtype);
        this.handleGetAllStaff(params.mtype);
      }
    }

    if (
      this.props.match.params.mtype !== "member" &&
      prevProps?.staffControllerData?.allStaffData !==
        this?.props?.staffControllerData?.allStaffData
    ) {
      this.setState({
        allStaffData: this?.props?.staffControllerData?.allStaffData?.staff,
        totalCount: this?.props?.staffControllerData?.allStaffData?.totalRec,
      });
    }

    if (
      this.props.match.params.mtype === "member" &&
      prevProps?.memberControllerData?.allMemberData !==
        this?.props?.memberControllerData?.allMemberData
    ) {
      this.setState({
        allStaffData: this?.props?.memberControllerData?.allMemberData?.members,
        totalCount: this?.props?.memberControllerData?.allMemberData?.totalRec,
      });
    }

    if (
      prevProps?.staffControllerData?.isLoading !==
      this?.props?.staffControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.staffControllerData?.isLoading,
      });
    }

    if (
      prevProps?.memberControllerData?.isLoading !==
      this?.props?.memberControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.memberControllerData?.isLoading,
      });
    }

    if (
      prevProps?.staffControllerData?.errMsg !==
        this?.props?.staffControllerData?.errMsg ||
      prevProps?.memberControllerData?.errMsg !==
        this?.props?.memberControllerData?.errMsg
    ) {
      if (
        this?.props?.staffControllerData?.errMsg ||
        this?.props?.memberControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.staffControllerData?.errMsg ||
            this?.props?.memberControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.staffControllerData?.succMsg !==
        this?.props?.staffControllerData?.succMsg ||
      prevProps?.memberControllerData?.succMsg !==
        this?.props?.memberControllerData?.succMsg
    ) {
      if (
        this?.props?.staffControllerData?.succMsg ||
        this?.props?.memberControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.pushNotiControllerData?.succMsg ||
            this?.props?.memberControllerData?.succMsg
        );
      }
    }
  }

  inputChange = (e) => {
    let {
      match: { params },
    } = this.props;
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value, isinit: true }, () => {
      // this.getList(params.mtype, true, 1);
      if (this.state.keyword.length > 2 || this.state.keyword.length === 0)
        this.handleGetAllStaff(params.mtype, true, 1);
    });
  };

  goto = (item) => {
    let {
      match: { params },
    } = this.props;
    if (params.mtype === "member") {
      this.props.history.push(`/edit-member/${item._id}`);
    } else if (params.mtype === "staf-member") {
      this.props.history.push(`/edit-staffmember/${item.id}`);
    }
  };

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      if (this.props.match.params.mtype === "member") {
        return this.props.history.push("/member-settings");
      } else {
        return this.props.history.push("/all-roles");
      }
    }
  };

  checkFilter = () => {
    return this.props.history.push("/member-filter");
  };

  render() {
    let { allStaffData, keyword, totalCount, isLoader } = this.state;
    let {
      match: { params },
      authData = {},
      permissions = {},
    } = this.props;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundImage: `url(${
          item && item.photo && typeof item.photo != "undefined"
            ? item.photo
            : ""
        })`,
      };
      return (
        <div
          className="card news-card h-100 bgcolor-green-drak"
          onClick={(e) => {
            e.preventDefault();
            permissions?.update && this.goto(item);
          }}
        >
          <div className="card-body p-3 pb-2">
            <div className="image-style" style={imagestyle}>
              <img
                src="./../images/imagesize2_3.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
          </div>
          <div className="card-footer border-0  pt-0">
            <div className="row">
              <div className="col-12">
                <p className="mb-0 caps text-overflow-block fs14">
                  {item.fName} {item.lName}
                </p>
                {params.mtype === "member" && (
                  <p className="mb-1 fs14">M.No.- {item.mNo}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden member-card-list">
          <div className="row pb-3 align-items-center justify-content-between">
            <div></div>
            <div className="col-auto">
              <h3 className="page-title mb-0">
                {params.mtype !== "member" && (
                  <>
                    <Link to="/admin-access">
                      <i className="fa fa-arrow-left cursor-pointer"></i>
                    </Link>
                    &nbsp;
                  </>
                )}
                {params.mtype === "member" ? "Members" : "Staff Members"}
              </h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              {params.mtype === "member" ? (
                <div onClick={this.checkFilter} className="position-relative">
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-filter"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp;&nbsp;
              {authData?.roleId?.roleName === "Developer" ? (
                <div onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp;&nbsp;
              <div style={{ width: 212 }}>
                <InputField
                  name="keyword"
                  groupClass="mb-0"
                  value={keyword}
                  inputChange={this.inputChange}
                  dom={{
                    style: { marginBottom: "0px" },
                    placeholder: `Search by ${
                      params.mtype === "member" ? "name, member no." : "name"
                    }`,
                  }}
                />
              </div>
              <Button
                className="btn btn-primary btn-primary-green"
                onClick={() => {
                  if (!this.state.keyword) {
                    return;
                  }
                  this.handleGetAllStaff(params.mtype, true, 1);
                }}
              >
                <i className="fa fa-search"></i>
              </Button>
              &nbsp;
              <div>
                <Button
                  className="btn btn-primary btn-primary-green"
                  onClick={() => {
                    this.reset(params.mtype);
                  }}
                >
                  <i className="fa fa-repeat"></i>
                </Button>
              </div>
            </div>
          </div>
          <div className="card bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && allStaffData.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {!isLoader && allStaffData.length > 0 && (
                  <div className="row">
                    {allStaffData?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-2 mb-3 pe-0"
                          style={{ width: "14%" }}
                        >
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="border-top-0 pt-3">
              {" "}
              {!isLoader && allStaffData.length !== 0 && (
                <Pagination
                  paging={{
                    count: totalCount,
                    rows: 20,
                    page: this.state.page,
                  }}
                  onPageClick={(page) => {
                    this.setState(
                      (prevState) => {
                        return {
                          ...prevState,
                          page: page,
                        };
                      },
                      () => {
                        this.handleGetAllStaff(
                          this.props.match.params.mtype,
                          page
                        );
                      }
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ clubs: { activeClub }, auth: { authData = {} } }) {
  return {
    activeClub: activeClub,
    authData,
  };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const staffControllerData = useStaff();
    const memberControllerData = useMember();

    return (
      <Component
        {...props}
        staffControllerData={staffControllerData}
        memberControllerData={memberControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  resetPageStore,
  getClubs,
})(injectComp(Memberlist));
