import React, { Component } from "react";
import { connect } from "react-redux";
import { listQpayData, updateQpayData } from "../../actions/qpay";
import { Endpoint } from "./../../utils/constants";
import { dateToStr } from "./../../utils/formatter";
import ModalButton from "./../modal/button";
import InputField from "../customFields/inputField";
import NoRecord from "../customFields/noRecord";
import Loader from "../customFields/loader";
import Chips from "../customFields/chips";
import moment from "moment";
import SearchInput from "../customFields/search-input";

class TransacationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      keyword: "",
      isLoader: false,
      memberData: [],
      isChipReload: true,
    };
  }

  componentDidMount(prevProps, prevState) {
    this.getList();
  }

  inputChange = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  setChips = (value, name, item = {}) => {
    this.setState(
      { keyword: value && value.length ? value[0].mNo : "" },
      () => {
        this.getList();
      }
    );
  };

  onChipsChanges = (event) => {
    this.setState({ keyword: event }, () => {
      this.getList();
    });
  };

  getList = () => {
    this.setState({ isLoader: false });
    let payload = {};
    let params = {
      type: 2,
      searchKeyword: this.state.keyword,
      from: this.state.fromDate
        ? moment(this.state.fromDate).format("YYYY/MM/DD")
        : "",
      end: this.state.toDate
        ? moment(this.state.toDate).format("YYYY/MM/DD")
        : "",
    };
    this.props.listQpayData(
      {
        payload,
        params,
        url: `${Endpoint.QPAY_CMS_TXN_LIST}`,
        method: "GET",
      },
      (res) => {
        this.setState({ isLoader: true });
      }
    );
  };

  render() {
    const {
      fromDate,
      toDate,
      keyword,
      isLoader,
      memberData,
      isChipReload,
    } = this.state;
    let { list } = this.props;
    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">Transaction History</h3>
            </div>
            <div className="row align-items-center">
              <div className="col-auto ps-0 d-flex">
                {isChipReload && (
                  <div style={{ width: 247 }}>
                    <SearchInput
                      endpoint={`searchMember/`}
                      searchKey="searchKeyword"
                      accessKey="members"
                      name="memberId"
                      inputClassName="form_control form-control"
                      optClassName="mt-0 ui-datepicker"
                      inputProps={{
                        autoFocus: true,
                      }}
                      placeholder="Search by First Name, Last Name or Member ID"
                      onChange={(value, onChangeSearch) => {
                        onChangeSearch(`${value?.fName} ${value?.lName}`);

                        this.onChipsChanges(`${value?.mNo}`);
                      }}
                      renderItem={(item, i) => {
                        return (
                          <React.Fragment key={"auto_" + i}>
                            <div
                              className="image-style"
                              style={{
                                backgroundImage: `url(${item?.photo})`,
                              }}
                            >
                              <img
                                src={"/images/imagesize2_3.png"}
                                alt={item?.fName}
                                className="w100per"
                              />
                            </div>

                            <div className="d-flex justify-content-between flex-grow-1">
                              <span
                                className="user-name"
                                style={{ textTransform: "capitalize" }}
                              >
                                {item?.fName + " " + item?.lName}
                              </span>
                              {item?.mNo && (
                                <span className="user-name1">{item?.mNo}</span>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      }}
                    />
                  </div>
                )}

                <button
                  className="btn btn-primary btn-primary-green"
                  onClick={this.getList}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <div
                className="col-auto  center-date  ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="fromDate"
                  groupClass="mbi-0"
                  value={fromDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "Start Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              <div
                className="col-auto  center-date  ps-0"
                style={{ width: "130px" }}
              >
                <InputField
                  name="toDate"
                  groupClass="mbi-0"
                  value={toDate}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "End Date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    minDate: new Date(this.state.fromDate),
                  }}
                />
              </div>
              <div className="col-auto ps-0">
                <button
                  onClick={this.getList}
                  className="btn btn-primary btn-primary-green me-2"
                >
                  Apply
                </button>
                <button
                  className="btn btn-primary btn-primary-green"
                  onClick={() => {
                    this.setState(
                      {
                        fromDate: new Date(),
                        toDate: new Date(),
                        keyword: "",
                        isChipReload: false,
                      },
                      () => {
                        this.setState({ isChipReload: true });
                        this.getList();
                      }
                    );
                  }}
                >
                  <i className="fa fa-repeat"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row margin-top-15">
            <div className="col-12">
              {!isLoader && <Loader />}
              {isLoader && (!list || list.length == 0) && (
                <div className="overflow-auto-div">
                  <NoRecord message="No Transaction History Found" />
                </div>
              )}
              {isLoader && list && list.length > 0 && (
                <div className="overflow-auto-div">
                  <table className="table table-borderless custom-table">
                    <thead>
                      <tr className="text-center">
                        <th>Date</th>
                        <th>Time</th>
                        <th>Member's Name</th>
                        <th>M.No</th>
                        <th>Spend</th>
                        <th>From</th>
                        <th>Location</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {list.map((element, key) => {
                          return (
                            <tr key={key} className="text-center">
                              <td>{element?.dateOfTxn}</td>
                              <td>{element?.timeOfTxn}</td>
                              <td className="capitalize-text">
                                {element?.memberId?.fName +
                                  " " +
                                  element?.memberId?.lName}
                              </td>
                              <td>{element?.memberId?.mNo}</td>
                              <td>{element?.withdraw}</td>
                              <td>{element?.sourceOfTxn}</td>
                              <td>{element?.location}</td>
                              <td className="text-center">
                                <ModalButton
                                  data={{
                                    showCustomModal: true,
                                    title: "Note",
                                    path: "Qpay/notes",
                                    body: "",
                                    data: element,
                                    id: element?.id,
                                    getList: this.getList,
                                    className:
                                      "modal-small notes-dialog modal-dialog-centered mt-0 mx-auto",
                                  }}
                                >
                                  {element?.note && element?.note?.isNote ? (
                                    <span className="cursor-pointer color-green fs20">
                                      <i className="fa fa-file-text"></i>
                                    </span>
                                  ) : (
                                    <span className="cursor-pointer">
                                      <i className="fa fa-file-text"></i>
                                    </span>
                                  )}
                                </ModalButton>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ qpay: { list } }) {
  return {
    list,
  };
}
export default connect(mapStateToProps, { listQpayData, updateQpayData })(
  TransacationHistory
);
