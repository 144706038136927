import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { updateQpayData } from "../../actions/qpay";
import { Endpoint } from "./../../utils/constants";
import { toast } from "react-toastify";
import RichTextEditor from "react-rte";
import TermsLogs from "./terms-logs";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
      isLoader: false,
      id: "",
      isLog: false,
    };
  }

  componentDidMount() {
    this.getTerms();
  }

  onChange = (e) => {
    this.setState({ value: e });
  };

  getTerms() {
    this.props.updateQpayData(
      {
        url: `${Endpoint.QPAY_TERMS}`,
        method: "GET",
      },
      (response) => {
        if (response.status && response.data && response.data._id) {
          this.setState({
            value: RichTextEditor.createValueFromString(
              response.data.content,
              "html"
            ),
            isLoader: true,
            id: response.data._id,
          });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  }

  updateTerms = () => {
    let payload = {
      content: this.state.value.toString("html"),
    };
    let url = Endpoint.QPAY_TERMS;
    if (this.state.id != "") {
      url = Endpoint.QPAY_TERMS + "/" + this.state.id;
    }
    this.props.updateQpayData(
      {
        payload: payload,
        url: `${url}`,
        method: this.state.id == "" ? "POST" : "PATCH",
      },
      (response) => {
        if (response.status) {
          toast.success("Update Success");
          this.setState({
            value: RichTextEditor.createValueFromString(
              response.data.content,
              "html"
            ),
            isLoader: true,
          });
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  render() {
    const { value, isLoader, isLog } = this.state;

    return (
      <React.Fragment>
        {!isLog && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden pageTop">
            <div className="d-flex mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">Terms And Conditions</h3>
              </div>
              <div className="row align-items-center">
                <div className="col-auto">
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={() => {
                      this.setState({ isLog: true });
                    }}
                  >
                    Logs
                  </button>
                </div>
              </div>
            </div>
            {isLoader && (
              <div className="row margin-top-15">
                <div className="col-12">
                  <RichTextEditor value={value} onChange={this.onChange} />
                </div>
                <div className="col-12 text-end margin-top-15">
                  <button
                    className="btn btn-primary btn-primary-green"
                    onClick={this.updateTerms}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {isLog && (
          <div className="h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  {" "}
                  <i
                    onClick={() => {
                      this.setState({ isLog: false });
                    }}
                    className="fa fa-arrow-left cursor-pointer me-2"
                  ></i>
                  Terms Logs
                </h3>
              </div>
            </div>
            <div>
              <TermsLogs></TermsLogs>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({}) {
  return {};
}
export default connect(mapStateToProps, { updateQpayData })(TermsAndConditions);
