import React, { useEffect, useState } from "react";
import { lastDateOperation } from "../../utils/formatter";
import { Button } from "reactstrap";
import { Endpoint } from "../../utils/constants";
import SearchInput from "../customFields/search-input";
import useMember from "../../controller/member/member-controller";
import moment from "moment";
import Pagination from "../pagination/pagination";
import { toast } from "react-toastify";

const APP_USAGE = {
  1: "Dormant",
  2: "Passive",
  3: "Active",
  4: "Super Active",
};

const DEVICE_TYPE = {
  1: "iOS",
  2: "Android",
};

const AppMembers = (props) => {
  const { clearnUpFilter } = props;
  const [memberData, setMemberData] = useState({});
  const [memberId, setMemberId] = useState();
  const [showTable, setShowTable] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [analyticsCodeList, setAnalyticsCodeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const {
    memberAnalytics,
    memberAllLogs,
    analyticsCode,
    getMemberAnalytics,
    getMemberLog,
    getAnalyticsCode,
    errMsg,
    succMsg,
  } = useMember();

  useEffect(() => {
    if (memberId) handleGetMemberAnalytics();

    if (showTable && memberId) {
      handleGetLogs();
    }
  }, [page, showTable, memberId]);

  useEffect(() => {
    return () => {
      clearnUpFilter();
    };
  }, [clearnUpFilter]);

  useEffect(() => {
    if (Object.keys(memberAnalytics).length > 0) {
      const data = { ...memberAnalytics };
      delete data.status;
      delete data.basicDetails;

      setMemberData({
        userData: memberAnalytics?.basicDetails,
        analyticRecord: data,
      });
    }
  }, [memberAnalytics]);

  useEffect(() => {
    setTableList(memberAllLogs?.logs);
    setTotalCount(memberAllLogs?.totalRec);
  }, [memberAllLogs]);

  useEffect(() => {
    setAnalyticsCodeList(analyticsCode);
  }, [analyticsCode]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    if (succMsg) {
      toast.success(succMsg);
    }
  }, [succMsg]);

  const handleGetLogs = () => {
    const url = `${memberId}?page=${page}&limit=20`;
    getMemberLog(url);
    getAnalyticsCode();
  };

  const handleGetMemberAnalytics = () => {
    getMemberAnalytics(memberId);
  };

  const { userData, analyticRecord } = memberData;

  return (
    <div className="px-4 py-3">
      <div className="row">
        <div className="col-6 mb-2">
          <div className="row">
            <div className="col-auto" style={{ width: "350px" }}>
              <SearchInput
                endpoint={`${Endpoint.MEMBERS}${props?.activeClub}/`}
                searchKey="searchKeyword"
                accessKey="members"
                name="memberId"
                inputClassName="form_control"
                inputProps={{
                  autoFocus: true,
                }}
                placeholder="Search by First Name, Last Name or Member ID"
                defaultValue={
                  userData && Object.keys(userData).length > 0
                    ? `${userData?.fName || ""} ${userData?.mName ||
                        ""} ${userData?.lName || ""}`
                    : ""
                }
                onChange={(value, onChangeSearch) => {
                  onChangeSearch(
                    `${value?.fName} ${value?.mName} ${value?.lName}`
                  );
                  setMemberId(value?._id);
                }}
                renderItem={(item, i) => {
                  return (
                    <React.Fragment key={"auto_" + i}>
                      <div
                        className="image-style"
                        style={{ backgroundImage: `url(${item?.photo})` }}
                      >
                        <img
                          src={"/images/imagesize2_3.png"}
                          alt={item?.fName}
                          className="w100per"
                        />
                      </div>

                      <div className="d-flex justify-content-between flex-grow-1">
                        <span
                          className="user-name"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.fName + " " + item?.mName + " " + item?.lName}
                        </span>
                        {item?.mNo && (
                          <span className="user-name1">{item?.mNo}</span>
                        )}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            </div>
            <div
              className="col-auto px-0"
              onClick={() => {
                setMemberData({});
                setMemberId("");
              }}
            >
              <button className="btn btn-primary btn-primary-green">
                <i className="fa fa-refresh"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-6 mb-2">
          {memberId ? (
            <div className="d-flex align-items-center justify-content-end">
              {showTable ? (
                <Button
                  className={`btn btn-primary btn-primary-green`}
                  onClick={() => setShowTable(false)}
                >
                  Back
                </Button>
              ) : (
                <Button
                  className={`btn btn-primary btn-primary-green`}
                  onClick={() => setShowTable(true)}
                >
                  View Log
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="app_an_area section app_memb">
        {!userData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "60vh", width: "100%", color: "black" }}
          >
            Please select member
          </div>
        ) : userData && Object.values(userData).length <= 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "60h", width: "100%", color: "black" }}
          >
            No data found
          </div>
        ) : (
          <div className="row mx--15">
            <div className="col-md-3">
              <div className="box l app-members">
                <div className="bk">
                  <ul className="row list-unstyled">
                    <li className="mb-3 col-md-12 bg">
                      <div className="col-10 rounded bg-white">
                        {userData.photo && (
                          <img
                            className="h-100 w-100 object-fit"
                            src={userData.photo}
                          ></img>
                        )}
                      </div>
                    </li>
                    <li className="mb-3 col-md-12">
                      <label className="mb-0">
                        {userData?.fName} {userData?.mName} {userData?.lName}
                      </label>
                      <div className="val_box">
                        <span className="text-secondary fs15">
                          {" "}
                          M. No. : {userData.mNo}
                        </span>
                      </div>
                    </li>
                    <li className=" mb-3 col-md-12">
                      <label className="mb-0">Last Seen On</label>
                      <div className="val_box">
                        <span className="text-secondary fs15">
                          {userData.lastSeen
                            ? lastDateOperation(userData.lastSeen)
                            : "Not Logged In Yet"}
                        </span>
                      </div>
                    </li>
                    <li className="  mb-3 col-md-12">
                      <label className="mb-0">Device</label>
                      <div className="val_box">
                        <span className="text-secondary fs15">
                          {userData?.deviceType
                            ? DEVICE_TYPE[userData?.deviceType]
                            : ""}
                        </span>
                      </div>
                    </li>
                    <li className="  mb-3 col-md-12">
                      <label className="mb-0">App Usage</label>
                      <div className="val_box">
                        <span className="text-secondary fs15">
                          {userData?.appUsage
                            ? APP_USAGE[userData?.appUsage]
                            : ""}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div
                className={`${
                  !showTable ? "overflow-auto-div-pagination2" : ""
                }`}
              >
                {!showTable ? (
                  <div className="bk">
                    <ul className="list-unstyled app-members row">
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalVisits}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Section Views</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalSection}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Check Ins from App</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalCheckInApp}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Check Ins from CMS</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalCheckInCms}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Guest Invites</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalGuestInvites}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Q Perks Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalQperk}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Q Perks Inquiries</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalQperkEnquiry}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Reservation Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalReserVisit}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Reservation Made</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalReserMade}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Events Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalEvents}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total RSVPX</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalRsvp}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Events Ticket Clicks</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalEventsTicket}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Q Pay Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalQpay}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Global Access Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalGlobal}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Book My Visit</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalBook}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Sonato Clicks</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalSonato}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 mb-4 text-center">
                        <label>Total Video Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalVideoPlayed}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 text-center">
                        <label>Total Video Clicks</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalVideoClicks}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 text-center">
                        <label>Total Dine Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalDine}
                            </h3>
                          </div>
                        </div>
                      </li>
                      <li className="col-md-4 text-center">
                        <label>Total Static Page Visits</label>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="mb-0 text-center">
                              {analyticRecord?.totalStatic}
                            </h3>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="col-12">
                    {(!tableList ||
                      tableList.length === 0 ||
                      !tableList.length) && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "60h", width: "100%", color: "black" }}
                      >
                        No data found
                      </div>
                    )}
                    {tableList && tableList.length > 0 && (
                      <div>
                        <div className="overflow-auto-div-pagination3">
                          <table className="table table-borderless custom-table">
                            <thead>
                              <tr className="text-center">
                                <th>Date</th>
                                <th>Time</th>
                                <th>Activity</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableList.map((item, i) => {
                                return (
                                  <tr key={i} className="text-center">
                                    <td className="text-center">
                                      {moment
                                        .unix(item?.dateTime)
                                        .format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {moment
                                        .unix(item?.dateTime, "hh:mm a")
                                        .format("HH:mm")}
                                    </td>
                                    <td className="text-center">
                                      {analyticsCodeList?.length > 0
                                        ? analyticsCodeList?.find(
                                            (d) =>
                                              d?.activityDone ===
                                                item?.activityDone &&
                                              d?.moduleName === item?.moduleName
                                          )?.text
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <Pagination
                            paging={{
                              count: totalCount,
                              rows: 20,
                              page: page,
                            }}
                            onPageClick={(page) => {
                              setPage(page);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AppMembers;
