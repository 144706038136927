const CONFIG = {
  "localhost:3000": {
    HOST: "localhost:3000",
    //quorumqcapi
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-dev.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
  dev: {
    HOST: "localhost:3001",
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-dev.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
  "quorum.kalakar.io": {
    HOST: "quorum.kalakar.io",
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-live.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
  "dev-thequorum.incred.io": {
    HOST: "quorum.kalakar.io",
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-dev.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
  "live-thequorum.incred.io": {
    HOST: "live-thequorum.incred.io",
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-live.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
  "thequorum.incred.io": {
    HOST: "thequorum.incred.io",
    HEADER_TEXT: "The Quorum",
    LOGO: "/images/logo.png",
    API_BASE_PATH: "https://api-live.incred.io/",
    API_BASE_PATH_NEW: "https://new-live-quorum-api.incred.io/v1/",
    API_Q_PAY_BASE_PATH: "https://new-live-quorum-api.incred.io/v1/",
  },
};

export function getHostConfig(key = "") {
  let host = window.location.host;
  let config = CONFIG[host] ? CONFIG[host] : CONFIG["dev"];
  return key ? config[key] : config;
}

export const SECTION_STATISTICS = {
  1: { label: "Check Ins", inviteGuest: true },

  2: { label: "Guest Invites" },

  3: { label: "Reservations" },

  4: { label: "Events", RSVP: true, inviteGuest: true },

  5: { label: "Specials" },

  6: { label: "Reciprocals" },
  0: { label: "Q Connect" },

  7: { label: "Dine In" },

  8: { label: "Dine Out" },

  9: { label: "Videos" },
  10: { label: "Private Hire", requestReservation: true },

  11: { label: "Edit Profile" },
  12: { label: "All Static Pages" },
};
