import React, { useState } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import AppAnalytics from "../components/analytics/app-analytics";
import AppMembers from "../components/analytics/app-members";
import SectionStatistics from "../components/analytics/section-statistics";
import { analytics_types } from "../utils/constants";
import "../style/analytics.scss";
import InputField from "../components/customFields/inputField";
import { subDay } from "../utils/formatter";
import moment from "moment";
import { connect } from "react-redux";

const Analytics = (props) => {
  const date = {
    startDate: moment(subDay(0))
      .subtract(3, "months")
      .format("YYYY-MM-DD"),
    endDate: subDay(0),
  };
  const [sectionType, setSectionType] = useState(0);
  const { match = {} } = props;
  const { params = {} } = match;
  const { type } = params;
  const options = [
    { label: "Check Ins", value: 0 },
    { label: "Reservations", value: 1 },
    { label: "Events", value: 2 },
    { label: "Q Perks", value: 3 },
    { label: "Global Access", value: 4 },
    { label: "Q Pay", value: 5 },
    { label: "Videos", value: 6 },
    { label: "Dine", value: 7 },
    { label: "Private Hire", value: 8 },
    { label: "All Static Pages", value: 9 },
  ];

  const getPages = () => {
    switch (type) {
      case analytics_types.section_statistics:
        return (
          <div className="col-9 mt-4">
            <SectionStatistics
              sectionType={sectionType}
              options={options}
            ></SectionStatistics>
          </div>
        );
      case analytics_types.app_members:
        return (
          <div className="col-12 m-2">
            <AppMembers
              clearnUpFilter={clearnUpFilter}
              activeClub={props?.activeClub}
            ></AppMembers>
          </div>
        );
      default:
        return (
          <div className="col-12  mt-4 mb-2">
            <AppAnalytics clubs={props?.clubs}></AppAnalytics>
          </div>
        );
    }
  };

  const getFilter = () => {
    switch (type) {
      case analytics_types.section_statistics:
        return (
          <div className="col-auto p-4">
            <div className="fit-content px-2">
              <div className="card">
                <div className="card-body">
                  <ul className="sections-menu">
                    {options &&
                      options.length > 0 &&
                      options.map((gr, i) => {
                        return (
                          <li
                            key={i}
                            className={sectionType === gr.value ? "active" : ""}
                            onClick={(e) => {
                              setSectionType(gr.value);
                            }}
                          >
                            <p>{gr.label}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const clearnUpFilter = () => {
    setSectionType(0);
  };

  const getTabs = (tab, i) => {
    return (
      <NavLink key={i} to={tab.link} activeClassName="acitve-class">
        <li>
          <p>{tab.name}</p>
        </li>
      </NavLink>
    );
  };

  const checkFilter = () => {
    return props.history.push("/analytics-filter");
  };

  return (
    <React.Fragment>
      <div className="h-100 w-100 d-flex flex-column overflow-hidden app-analytics-section">
        <div className="d-flex pb-3 input-header mb-0 align-items-center  justify-content-between">
          <div>
            <h3 className="page-title mb-0">
              <Link to="/admin-access">
                <i className="fa fa-arrow-left cursor-pointer"></i>
              </Link>
              &nbsp;App Analytics
            </h3>
          </div>
          <div className="row align-items-center">
            {type !== analytics_types.section_statistics &&
            type !== analytics_types.app_members ? (
              <div className="col-auto pe-0" onClick={checkFilter}>
                <h3
                  style={{
                    marginBottom: 0,
                    color: "#1c6765",
                    cursor: "pointer",
                  }}
                >
                  <i className="fa fa-filter"></i>
                </h3>
              </div>
            ) : null}
            <div className="col-auto pe-0" style={{ width: "130px" }}>
              <InputField
                name="startDate"
                groupClass="mbi-0 text-center"
                value={date["startDate"]}
                dom={{
                  placeholder: "Start date",
                  type: "date",
                  dateFormat: "dd/MM/yyyy",
                  disabled: true,
                }}
              />
            </div>
            <div className="col-auto pe-0" style={{ width: "130px" }}>
              <InputField
                name="endDate"
                groupClass="mbi-0"
                value={date["endDate"]}
                dom={{
                  placeholder: "End date",
                  type: "date",
                  dateFormat: "dd/MM/yyyy",
                  disabled: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="row h-100 overflow-hidden">
          <div className="col-3" style={{ width: "20%" }}>
            <div className="reservtion-tab h-100 overflow-x-hidden overflow-auto">
              <ul
                className="list-unstyled components"
                style={{ border: "none" }}
              >
                {[
                  { name: "App Statistics", link: "/analytics/app-analytics" },
                  {
                    name: "Section Statistics",
                    link: "/analytics/section-statistics",
                  },
                  { name: "Member History", link: "/analytics/app-members" },
                ].map((_, index) => {
                  return getTabs(_, index);
                })}
              </ul>
            </div>
          </div>
          <div className="col h-100">
            <div className="card bg-transparent h-100 overflow-hidden">
              <div className="h-100  overflow-hidden">
                <div className="row h-100">
                  {getFilter()}
                  {getPages()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps({ clubs }) {
  return {
    clubs: clubs?.clubs,
    activeClub: clubs?.activeClub?.[0]?._id,
  };
}

export default connect(mapStateToProps)(withRouter(Analytics));
