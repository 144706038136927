import { takeEvery, put, call } from "redux-saga/effects";
import {
  GET_QPAY_MENU,
  GET_QPAY_ROLES,
  Q_PAY_LIST_DATA,
  Q_PAY_UPDATE_DATA,
  Q_PAY_LISTS_DATA,
} from "../actions/constants";
import { qPayFetchService } from "../utils/index";
import { getCookie } from "../services/session";
import { Endpoint } from "../utils/constants";

export function* getQPayMenu({ data }) {
  const response = getCookie("qpay-menu");
  yield put({
    type: `REDUCER/${GET_QPAY_MENU}`,
    data: response ? JSON.parse(response).roleId.accessAllowed : [],
  });
}

export function* getQPayRoles({ cb }) {
  const response = yield call(qPayFetchService, {
    method: "GET",
    url: Endpoint.QPAY_GET_ALL_ROLES,
  });
  cb && cb(response);
  yield put({
    type: `REDUCER/${GET_QPAY_ROLES}`,
    data: response.data,
  });
}

export function* updateQpayData({ data, cb }) {
  try {
    const response = yield call(qPayFetchService, {
      payload: data.payload ? data.payload : {},
      params: data.params ? data.params : {},
      method: data.method,
      url: data.url,
    });
    cb && cb(response);
    if (response && response.status && data.action && data.page) {
      yield put({
        type: `REDUCER/${Q_PAY_UPDATE_DATA}`,
        data: response.data,
      });
    }
  } catch (e) {
    cb && cb({ status: false, errorMsg: e.msg || e });
  }
}

export function* listQpayData({ data, cb }) {
  try {
    const response = yield call(qPayFetchService, {
      payload: data.payload ? data.payload : {},
      params: data.params ? data.params : {},
      method: data.method,
      url: data.url,
    });
    cb && cb(response);
    if (response && response.status) {
      yield put({
        type: `REDUCER/${Q_PAY_LIST_DATA}`,
        data: response.data,
      });
    }
  } catch (e) {
    cb && cb({ status: false, errorMsg: e.msg || e });
  }
}

export function* listsQpayData({ data, cb }) {
  try {
    const response = yield call(qPayFetchService, {
      payload: data.payload ? data.payload : {},
      params: data.params ? data.params : {},
      method: data.method,
      url: data.url,
    });
    cb && cb(response);
  } catch (e) {
    cb && cb({ status: false, errorMsg: e.msg || e });
  }
}

function* pageWatcher() {
  yield takeEvery(`SAGA/${GET_QPAY_MENU}`, getQPayMenu);
  yield takeEvery(`SAGA/${GET_QPAY_ROLES}`, getQPayRoles);
  yield takeEvery(`SAGA/${Q_PAY_UPDATE_DATA}`, updateQpayData);
  yield takeEvery(`SAGA/${Q_PAY_LIST_DATA}`, listQpayData);
  yield takeEvery(`SAGA/${Q_PAY_LISTS_DATA}`, listsQpayData);
}

export default pageWatcher;
