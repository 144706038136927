import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import InputField from "../customFields/inputField";
import Loader from "../customFields/loader";
import AWS from "aws-sdk";
import { getValue, lastDateOperation } from "../../utils/formatter";
import { isObject, isObjEmpty } from "../../utils";
import { validate } from "../../utils/validation";
import {
  updatePageData,
  getIndividualItem,
  resetPageStore,
  fetchSuggestions,
  updateModalData,
} from "../../actions/page";
import Uploader from "../customFields/uploader";
import { Endpoint, S3_BUCKET_CRED } from "../../utils/constants";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import useMember from "../../controller/member/member-controller";
import useAltContact from "../../controller/member/altContact-controller";
import useDesignation from "../../controller/member/designation-controller";
import useGender from "../../controller/member/gender-controller";
import useIndustry from "../../controller/member/industry-controller";
import useMIntrest from "../../controller/member/mIntrest-controller";
import useMStatus from "../../controller/member/mStatus-controller";
import useMType from "../../controller/member/mType-controller";
import moment from "moment";
import SearchInput from "../customFields/search-input";
import useEduInst from "../../controller/member/eduInst-controller";
import NoRecord from "../customFields/noRecord";
import Pagination from "../pagination/pagination";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function getKeyByValue(object, value) {
  return (
    object &&
    Object.keys(object).length !== 0 &&
    Object.keys(object).find((key) => object[key] === value)
  );
}

const APP_USAGE = {
  1: "Dormant",
  2: "Passive",
  3: "Active",
  4: "Super Active",
};

const DEVICE_TYPE = {
  1: "iOS",
  2: "Android",
};

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.rf = [
      "mobile",
      "email",
      "dob",
      "age",
      "fName",
      "designation",
      "industry",
      "mNo",
      "mType",
      "clubBelongsTo",
      "gender",
      "altContactType",
      "maritalStatus",
    ];
    this.state = this.intialiseState();
  }

  intialiseState = (data = {}, isinit = true) => {
    data = {
      ...data,
      gender: data?.gender?._id,
      industry: data?.industry?._id,
      designation: data?.designation?._id,
      clubBelongsTo: data?.clubBelongsTo?._id,
      mType: data?.mType?._id,
      maritalStatus: data?.maritalStatus?._id,
      altContactType: data?.altContactType?._id,
      dob: data?.dob ? moment.unix(data?.dob).format("YYYY-MM-DD") : "",
      startDate: data?.startDate
        ? moment.unix(data?.startDate).format("YYYY-MM-DD")
        : "",
      endDate: data?.endDate
        ? moment.unix(data?.endDate).format("YYYY-MM-DD")
        : "",
      cDob: data?.cDob ? moment.unix(data?.cDob).format("YYYY-MM-DD") : "",
      spouseDOB: data?.spouseDOB
        ? moment.unix(data?.spouseDOB).format("YYYY-MM-DD")
        : "",
      spouseAnni: data?.spouseAnni
        ? moment.unix(data?.spouseAnni).format("YYYY-MM-DD")
        : "",
    };

    let childrenInfo = data.childrenInfo
      ? typeof data.childrenInfo === "string"
        ? JSON.parse(data.childrenInfo)
        : data.childrenInfo
      : [{ cName: "", cDob: "" }];

    const intrestData = data?.intrest?.map((d) => d._id);
    const eduData = data?.educationInsti?.map((d) => d._id);

    return {
      clubBelongsTo: getValue(data, "clubBelongsTo", ""),
      photo: getValue(data, "photo", ""),
      mobile: getValue(data, "mobile", ""),
      email: getValue(data, "email", ""),
      dob: getValue(data, "dob", null),
      age: getValue(data, "age", null),
      gender: getValue(data, "gender", ""),
      fName: getValue(data, "fName", ""),
      mName: getValue(data, "mName", ""),
      lName: getValue(data, "lName", ""),
      address: getValue(data, "address", ""),
      educationInstiName: getValue(data, "educationInstiName", ""),
      company: getValue(data, "company", ""),
      industry: getValue(data, "industry", ""),
      educationInsti: eduData?.length > 0 ? eduData : [],
      selEducationInsti: "",
      designation: getValue(data, "designation", ""),
      mNo: getValue(data, "mNo", ""),
      mType: getValue(data, "mType", ""),
      startDate: getValue(data, "startDate", ""),
      endDate: getValue(data, "endDate", ""),
      altContactType: getValue(data, "altContactType", ""),
      altFname: getValue(data, "altFname", ""),
      altLname: getValue(data, "altLname", ""),
      altEmail: getValue(data, "altEmail", ""),
      altPhone: getValue(data, "altPhone", ""),
      maritalStatus: getValue(data, "maritalStatus", ""),
      spouseFname: getValue(data, "spouseFname", ""),
      spouseLname: getValue(data, "spouseLname", ""),
      spouseEmail: getValue(data, "spouseEmail", ""),
      spousePhone: getValue(data, "spousePhone", ""),
      spouseDOB: getValue(data, "spouseDOB", ""),
      spouseAnni: getValue(data, "spouseAnni", ""),
      reference1: getValue(data, "reference1", ""),
      reference2: getValue(data, "reference2", ""),
      notes: getValue(data, "notes", ""),
      drink: getValue(data, "drink", ""),
      film: getValue(data, "film", ""),
      fitness: getValue(data, "fitness", ""),
      herb: getValue(data, "herb", ""),
      intrest: intrestData,
      status: getValue(data, "status", 1),
      ctype: getValue(data, "ctype", data.isChildren ? "y" : "n"),
      totalChildren: childrenInfo.length ? childrenInfo.length : 0,
      childrenInfo: childrenInfo.length
        ? childrenInfo?.map((child) => {
            return {
              ...child,
              cDob: child?.cDob
                ? moment.unix(child?.cDob).format("YYYY-MM-DD")
                : "",
            };
          })
        : [
            {
              cName: "",
              cDob: "",
            },
          ],
      errorMessages: {},
      isValid: {},
      isinit,
      isSaving: false,
      isStep: 1,
      isStep1Done: true,
      isStep2Done: false,
      isStep3Done: false,
      isStep4Done: false,
      tableList: [],
      analyticsCodeList: [],
      basicDetails: {},
      analyticRecord: {},
      showTable: false,
      isLoader: false,
      totalCount: 0,
      page: 1,
    };
  };
  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    const { getAllMType } = this?.props?.mTypeControllerData;
    const { getAllMStatus } = this?.props?.mStatusControllerData;
    const { getAllMIntrest } = this?.props?.mIntrestControllerData;
    const { getAllIndustry } = this?.props?.industryControllerData;
    const { getAllGender } = this?.props?.genderControllerData;
    const { getAllDesignation } = this?.props?.designationControllerData;
    const { getAllAltContact } = this?.props?.altContactControllerData;
    const { getAllEduInst } = this?.props?.eduInstControllerData;

    getAllMType();
    getAllMStatus();
    getAllMIntrest();
    getAllIndustry();
    getAllGender();
    getAllDesignation();
    getAllAltContact();
    getAllEduInst();

    if (params.mid) {
      this.handleGetMember(params.mid);
      this.handleGetMemberAnalytics(params.mid);
    }

    if (this?.props?.clubs?.length > 0) {
      const clubOtpData = this?.props?.clubs?.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value._id]: value.clubName,
        };
      }, {});

      this.setState({ clubOpt: clubOtpData });
    }
  }
  componentWillUnmount() {
    this.props.resetPageStore(["individualItem", "suggesions"]);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.memberControllerData?.memberData !=
        this?.props?.memberControllerData?.memberData &&
      prevState.isinit &&
      isObject(this?.props?.memberControllerData?.memberData) &&
      !isObjEmpty(this?.props?.memberControllerData?.memberData)
    ) {
      let stateD = this.intialiseState(
        this?.props?.memberControllerData?.memberData?.member,
        false
      );
      this.setState(stateD);
    }

    if (
      prevProps?.memberControllerData?.memberAnalytics !=
      this?.props?.memberControllerData?.memberAnalytics
    ) {
      const data = { ...this?.props?.memberControllerData?.memberAnalytics };
      delete data.status;
      delete data.basicDetails;

      this.setState({
        basicDetails: this?.props?.memberControllerData?.memberAnalytics
          ?.basicDetails,
        analyticRecord: data,
      });
    }

    if (
      prevProps?.memberControllerData?.memberAllLogs !=
      this?.props?.memberControllerData?.memberAllLogs
    ) {
      this.setState({
        tableList: this?.props?.memberControllerData?.memberAllLogs?.logs,
        totalCount: this?.props?.memberControllerData?.memberAllLogs?.totalRec,
      });
    }

    if (
      prevProps?.memberControllerData?.analyticsCode !=
      this?.props?.memberControllerData?.analyticsCode
    ) {
      this.setState({
        analyticsCodeList: this?.props?.memberControllerData?.analyticsCode,
      });
    }

    if (
      prevProps?.memberControllerData?.isLoading !==
      this?.props?.memberControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.memberControllerData?.isLoading,
      });
    }

    if (
      prevProps?.memberControllerData?.succMsg !==
      this?.props?.memberControllerData?.succMsg
    ) {
      if (this?.props?.memberControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
        });
        this.props.history.push("/member-list/member");
        toast.success(this?.props?.memberControllerData?.succMsg);
      }
    }
    if (
      prevProps?.memberControllerData?.errMsg !==
      this?.props?.memberControllerData?.errMsg
    ) {
      if (this?.props?.memberControllerData?.errMsg) {
        this.setState({
          error: this?.props?.memberControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.memberControllerData?.errMsg);
      }
    }
    if (prevProps?.clubs !== this?.props?.clubs) {
      const clubOtpData = this?.props?.clubs?.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value._id]: value.clubName,
        };
      }, {});

      this.setState({ clubOpt: clubOtpData });
    }

    if (
      prevProps?.mTypeControllerData?.errMsg !==
        this?.props?.mTypeControllerData?.errMsg ||
      prevProps?.mStatusControllerData?.errMsg !==
        this?.props?.mStatusControllerData?.errMsg ||
      prevProps?.mIntrestControllerData?.errMsg !==
        this?.props?.mIntrestControllerData?.errMsg ||
      prevProps?.industryControllerData?.errMsg !==
        this?.props?.industryControllerData?.errMsg ||
      prevProps?.genderControllerData?.errMsg !==
        this?.props?.genderControllerData?.errMsg ||
      prevProps?.designationControllerData?.errMsg !==
        this?.props?.designationControllerData?.errMsg ||
      prevProps?.altContactControllerData?.errMsg !==
        this?.props?.altContactControllerData?.errMsg ||
      prevProps?.eduInstControllerData?.errMsg !==
        this?.props?.eduInstControllerData?.errMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.errMsg ||
        this?.props?.mStatusControllerData?.errMsg ||
        this?.props?.mIntrestControllerData?.errMsg ||
        this?.props?.industryControllerData?.errMsg ||
        this?.props?.genderControllerData?.errMsg ||
        this?.props?.designationControllerData?.errMsg ||
        this?.props?.altContactControllerData?.errMsg ||
        this?.props?.eduInstControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.mTypeControllerData?.errMsg ||
            this?.props?.mStatusControllerData?.errMsg ||
            this?.props?.mIntrestControllerData?.errMsg ||
            this?.props?.industryControllerData?.errMsg ||
            this?.props?.genderControllerData?.errMsg ||
            this?.props?.designationControllerData?.errMsg ||
            this?.props?.altContactControllerData?.errMsg ||
            this?.props?.eduInstControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.mTypeControllerData?.succMsg !==
        this?.props?.mTypeControllerData?.succMsg ||
      prevProps?.mStatusControllerData?.succMsg !==
        this?.props?.mStatusControllerData?.succMsg ||
      prevProps?.mIntrestControllerData?.succMsg !==
        this?.props?.mIntrestControllerData?.succMsg ||
      prevProps?.industryControllerData?.succMsg !==
        this?.props?.industryControllerData?.succMsg ||
      prevProps?.genderControllerData?.succMsg !==
        this?.props?.genderControllerData?.succMsg ||
      prevProps?.designationControllerData?.succMsg !==
        this?.props?.designationControllerData?.succMsg ||
      prevProps?.altContactControllerData?.succMsg !==
        this?.props?.altContactControllerData?.succMsg ||
      prevProps?.eduInstControllerData?.succMsg !==
        this?.props?.eduInstControllerData?.succMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.succMsg ||
        this?.props?.mStatusControllerData?.succMsg ||
        this?.props?.mIntrestControllerData?.succMsg ||
        this?.props?.industryControllerData?.succMsg ||
        this?.props?.genderControllerData?.succMsg ||
        this?.props?.designationControllerData?.succMsg ||
        this?.props?.altContactControllerData?.succMsg ||
        this?.props?.eduInstControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.mTypeControllerData?.succMsg ||
            this?.props?.mStatusControllerData?.succMsg ||
            this?.props?.mIntrestControllerData?.succMsg ||
            this?.props?.industryControllerData?.succMsg ||
            this?.props?.genderControllerData?.succMsg ||
            this?.props?.designationControllerData?.succMsg ||
            this?.props?.altContactControllerData?.succMsg ||
            this?.props?.eduInstControllerData?.succMsg
        );
      }
    }

    if (
      prevProps?.mTypeControllerData?.allMTypeData !=
      this?.props?.mTypeControllerData?.allMTypeData
    ) {
      const memberTypeData = this?.props?.mTypeControllerData?.allMTypeData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({ MEMBERTYPE: memberTypeData });
    }
    if (
      prevProps?.mStatusControllerData?.allMStatusData !=
      this?.props?.mStatusControllerData?.allMStatusData
    ) {
      const maritalStatusOtpData = this?.props?.mStatusControllerData?.allMStatusData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        maritalStatusOtp: maritalStatusOtpData,
      });
    }
    if (
      prevProps?.mIntrestControllerData?.allMIntrestData !=
      this?.props?.mIntrestControllerData?.allMIntrestData
    ) {
      this.setState({
        HOBBIES: this?.props?.mIntrestControllerData?.allMIntrestData?.filter(
          (d) => d.isActive
        ),
      });
    }
    if (
      prevProps?.industryControllerData?.allIndustryData !=
      this?.props?.industryControllerData?.allIndustryData
    ) {
      const industryOptData = this?.props?.industryControllerData?.allIndustryData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        industryOpt: industryOptData,
      });
    }
    if (
      prevProps?.genderControllerData?.allGenderData !==
      this?.props?.genderControllerData?.allGenderData
    ) {
      const genderOtpData = this?.props?.genderControllerData?.allGenderData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        genderOpt: genderOtpData,
      });
    }
    if (
      prevProps?.designationControllerData?.allDesignationData !=
      this?.props?.designationControllerData?.allDesignationData
    ) {
      const jobTitleOptData = this?.props?.designationControllerData?.allDesignationData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        jobTitleOpt: jobTitleOptData,
      });
    }
    if (
      prevProps?.altContactControllerData?.allAltContactData !=
      this?.props?.altContactControllerData?.allAltContactData
    ) {
      const actOptData = this?.props?.altContactControllerData?.allAltContactData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});
      this.setState({
        actOpt: actOptData,
      });
    }
    if (
      prevProps?.eduInstControllerData?.allEduInstData !=
      this?.props?.eduInstControllerData?.allEduInstData
    ) {
      const actOptData = this?.props?.eduInstControllerData?.allEduInstData
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        eduInstOpt: actOptData,
      });
    }
    if (
      this.state.company &&
      prevState.company &&
      this.state.company !== "" &&
      prevState.company !== "" &&
      prevState.company !== this.state.company
    ) {
      if (this.state.company.length < 3)
        document.getElementById("companyId").focus();
    }

    if (prevState.showTable !== this.state.showTable) {
      if (this.state.showTable) {
        this.handleGetLogs();
      }
    }
  }

  handleGetMember = (id) => {
    const { getMember } = this?.props?.memberControllerData;
    getMember(id);
  };

  handleGetMemberAnalytics = (id) => {
    const { getMemberAnalytics } = this?.props?.memberControllerData;
    getMemberAnalytics(id);
  };

  handleGetLogs = () => {
    let {
      match: { params },
    } = this.props;
    const {
      getMemberLog,
      getAnalyticsCode,
    } = this?.props?.memberControllerData;
    const url = `${params.mid}?page=${this.state.page}&limit=20`;
    getMemberLog(url);
    getAnalyticsCode();
  };

  validate = (name, value) => {
    if (this.rf.indexOf(name) > -1 || value) {
      let { isValid, errorMessages } = this.state;
      let error = validate(name, value, {
        type: "text",
        regexType:
          ["email", "aemail", "memail"].indexOf(name) > -1 ? "email" : "noop",
      });

      if (
        (name === "startDate" || name === "endDate") &&
        this.state.startDate &&
        this.state.endDate
      ) {
        let s = new Date(this.state.startDate).getTime();
        let e = new Date(this.state.endDate).getTime();
        error.isValid = e >= s;
        error.errorMsg =
          name === "startDate"
            ? "start date must be less than end date"
            : "end date must be greater than end date";
      }
      isValid[name] = error.isValid;
      error.isValid
        ? delete errorMessages[name]
        : (errorMessages[name] = error.errorMsg);
      this.setState({ isValid, errorMessages });
    } else {
      let { isValid, errorMessages } = this.state;
      isValid[name] = true;
      delete errorMessages[name];
      this.setState({ isValid, errorMessages });
    }
  };
  inputChange = (e, i, collection) => {
    let {
      target: { name, value },
    } = e;

    if (name === "fName" || name === "mName" || name === "lName") {
      value = value.replace(/[^A-Za-z]/gi, "");
    }
    if (
      name === "dob" ||
      name === "startDate" ||
      name === "endDate" ||
      name === "cDob" ||
      name === "spouseDOB" ||
      name === "spouseAnni"
    ) {
      value = value;
    }
    if ((i || i === 0) && collection) {
      let coll = this.state[collection];
      coll[i][name] = value;
      this.setState({ [collection]: coll });
      return;
    }

    if (name === "dob") {
      this.setState({ age: moment().diff(value, "years") });
    }
    let so = { [name]: value };
    this.setState(so, () => {
      this.validate(name, value);
    });
  };
  inputChangeClub = (e) => {
    this.setState({ clubBelongsTo: e.target.value });
  };
  selectHobby = (e) => {
    e.preventDefault();
    let value = e.target.getAttribute("value");

    let intrest = this.state.intrest ? this.state.intrest : [];
    if (intrest.length > 0 && intrest.includes(value)) {
      var index = intrest.indexOf(value);
      intrest.splice(index, 1);
    } else {
      intrest.push(value);
    }
    this.setState({ intrest });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let {
      match: { params },
    } = this.props;
    const { addMember, updateMember } = this?.props?.memberControllerData;
    // let rf = [...this.rf, "email", "altEmail", "spouseEmail"];

    // rf.forEach((name) => {
    //   let value = this.state[name];
    //   if (
    //     (name === "altFname" || name === "altPhone") &&
    //     this.state.altContactType === getKeyByValue(this?.state?.actOpt, "None")
    //   ) {
    //     return;
    //     value = 1;
    //   } else if (
    //     (name === "spouseFname" || name === "spousePhone") &&
    //     this.state.maritalStatus ===
    //       getKeyByValue(this?.state?.maritalStatusOtp, "Single")
    //   ) {
    //     value = 1;
    //   }
    //   this.validate(name, value);
    // });

    if (true) {
      this.setState({ error: "", isSaving: true });
      let payload = Object.assign({}, this.state);

      if (payload.childrenInfo?.length > 0) {
        payload.childrenInfo = payload.childrenInfo?.map((child) => {
          return {
            cName: child?.cName,
            cDob: moment(child?.cDob).unix(),
          };
        });
      }
      payload.dob = moment(this?.state?.dob).unix();
      payload.startDate = moment(this?.state?.startDate).unix();
      payload.endDate = moment(this?.state?.endDate).unix();
      payload.cDob = moment(this?.state?.cDob).unix();
      payload.spouseDOB = moment(this?.state?.spouseDOB).unix();
      payload.spouseAnni = moment(this?.state?.spouseAnni).unix();

      payload.id = this.props.itemData.id || "";
      payload._id = params.mid || "";
      delete payload.errorMessages;
      delete payload.isValid;
      delete payload.MEMBERTYPE;
      delete payload.HOBBIES;
      delete payload.genderOpt;
      delete payload.jobTitleOpt;
      delete payload.industryOpt;
      delete payload.eduInstOpt;
      delete payload.selEducationInsti;
      delete payload.clubOpt;
      delete payload.maritalStatusOtp;
      delete payload.actOpt;
      delete payload.isUploader;
      delete payload.bulkFiles;
      delete payload.isStep;
      delete payload.isStep1Done;
      delete payload.isStep2Done;
      delete payload.isStep3Done;
      delete payload.isStep4Done;
      delete payload.showTable;
      delete payload.isLoader;
      delete payload.basicDetails;
      delete payload.analyticRecord;
      delete payload.tableList;
      delete payload.analyticsCodeList;
      delete payload.totalCount;
      delete payload.page;

      if (
        payload.maritalStatus ===
        getKeyByValue(this?.state?.maritalStatusOtp, "Single")
      ) {
        payload.spouseFname = "";
        payload.spouseLname = "";
        payload.spouseEmail = "";
        payload.spousePhone = "";
        payload.spouseDOB = "";
        payload.spouseAnni = "";
      }
      if (
        payload.altContactType === getKeyByValue(this?.state?.actOpt, "None")
      ) {
        payload.altFname = "";
        payload.altLname = "";
        payload.altEmail = "";
        payload.altPhone = "";
      }
      if (payload.ctype === "n") {
        payload.childrenInfo = [];
      }
      if (
        !this.state.educationInsti.includes(
          getKeyByValue(this?.state?.eduInstOpt, "Tier 1")
        )
      ) {
        delete payload.educationInstiName;
      }
      payload.isChildren = payload.childrenInfo.length > 0 ? true : false;
      delete payload.ctype;
      delete payload.isImageAdded;
      delete payload.isSaving;
      delete payload.isinit;
      delete payload.mtype;
      delete payload.totalChildren;
      delete payload.error;
      !params.mid && delete payload.id;
      !params.mid && delete payload._id;

      params.mid ? updateMember(payload) : addMember(payload);
    }
  };
  deleteItem = (e) => {
    let {
      match: { params },
    } = this.props;
    const { deleteMember } = this?.props?.memberControllerData;
    deleteMember(params.mid);
  };
  logoutFromApp = () => {
    let {
      match: { params },
    } = this.props;
    const { logoutMember } = this?.props?.memberControllerData;

    logoutMember(params.mid, (response) => {
      if (response?.data?.status && response?.data?.status !== "false") {
        toast.success(response?.data?.msg);
      } else {
        toast.error(response?.data?.msg);
      }
    });
  };
  onDrop = (files, r) => {
    if (files.length) {
      this.setState({ bulkFiles: files });
      this.toggleUploader(true);
    } else if (r.length) {
      this.props.updateModalData({
        showCustomModal: true,
        title: "",
        body: "Only jpg, jpeg and png files are allowed",
      });
    }
  };
  statusChange = (id) => {
    const { updateMember } = this?.props?.memberControllerData;
    const payload = { _id: id, status: this.state.status === 1 ? 2 : 1 };

    updateMember(payload, (response) => {
      if (response?.data?.status && response?.data?.status !== "false") {
        this.setState({ status: this.state.status === 1 ? 2 : 1 });
      }
    });
  };
  toggleUploader = (isUploader) => {
    this.setState({ isUploader });
  };
  setBulkPhotos = (bulkCollection) => {
    let a = bulkCollection.filter((e) => e.type === "selected");
    if (a.length === bulkCollection.length) {
      for (let i = 0; i < bulkCollection.length; i++) {
        const file = bulkCollection[i];
        new Promise((resolve, reject) => {
          var fileName = bulkCollection[i].file.name.split(".").pop();
          AWS.config.update({
            accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
            secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
            region: S3_BUCKET_CRED.BUCKET_REGION,
          });
          var s3 = new AWS.S3();
          var params = {
            Bucket: S3_BUCKET_CRED.BUCKET_NAME,
            Key: "asset/" + Date.now() + "." + fileName,
            ContentType: file.file.type,
            Body: dataURLtoFile(file.thumbnail, file.fileName),
            ACL: "public-read",
          };

          s3.putObject(params, async (err, res) => {
            if (err) {
            } else {
              let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

              this.setState({
                photo: link,
                isUploader: false,
                isImageAdded: true,
              });

              try {
                resolve(link);
              } catch (e) {
                reject(e);
              }
            }
          });
        });
      }
    }
  };
  addChildren = (totalChild) => {
    if (totalChild && totalChild <= 4) {
      let childrenInfo = [];
      for (let i = 0; i < totalChild; i++) {
        let child = this.state.childrenInfo[i]
          ? this.state.childrenInfo[i]
          : { cName: "", cDob: "" };
        childrenInfo.push(child);
      }
      this.setState({ totalChildren: totalChild, childrenInfo });
    }
  };
  changeStep = (step) => {
    if (step === 2) {
      [
        "mobile",
        "email",
        "dob",
        "fName",
        "gender",
        "designation",
        "industry",
      ].forEach((name) => {
        let value = this.state[name];
        if (
          (name === "altFname" || name === "altPhone") &&
          this.state.altContactType ===
            getKeyByValue(this?.state?.actOpt, "None")
        ) {
          value = 1;
        } else if (
          (name === "spouseFname" || name === "spousePhone") &&
          this.state.maritalStatus ===
            getKeyByValue(this?.state?.maritalStatusOtp, "Single")
        ) {
          value = 1;
        }
        this.validate(name, value);
      });

      if (Object.keys(this.state.errorMessages).length === 0) {
        this.setState({ isStep2Done: true });
      }
    }
    if (step === 3) {
      ["mNo", "mType", "clubBelongsTo"].forEach((name) => {
        let value = this.state[name];
        if (
          (name === "altFname" || name === "altPhone") &&
          this.state.altContactType ===
            getKeyByValue(this?.state?.actOpt, "None")
        ) {
          value = 1;
        } else if (
          (name === "spouseFname" || name === "spousePhone") &&
          this.state.maritalStatus ===
            getKeyByValue(this?.state?.maritalStatusOtp, "Single")
        ) {
          value = 1;
        }
        this.validate(name, value);
      });

      if (Object.keys(this.state.errorMessages).length === 0) {
        this.setState({ isStep3Done: true });
      }
    }
    if (step === 4) {
      ["altContactType", "maritalStatus"].forEach((name) => {
        let value = this.state[name];
        if (
          (name === "altFname" || name === "altPhone") &&
          this.state.altContactType ===
            getKeyByValue(this?.state?.actOpt, "None")
        ) {
          value = 1;
        } else if (
          (name === "spouseFname" || name === "spousePhone") &&
          this.state.maritalStatus ===
            getKeyByValue(this?.state?.maritalStatusOtp, "Single")
        ) {
          value = 1;
        }
        this.validate(name, value);
      });

      if (Object.keys(this.state.errorMessages).length === 0) {
        this.setState({ isStep4Done: true });
      }
    }
    if (step === 5) {
      let {
        match: { params },
      } = this.props;
      this.setState({ isStep: step, showTable: false });
      this.handleGetMemberAnalytics(params.mid);
    }

    if (Object.keys(this.state.errorMessages).length === 0) {
      this.setState({ isStep: step });
    }
  };
  askConfirm = () => {
    this.props.updateModalData({
      showCustomModal: true,
      title: "",
      path: "member/confirmDelete",
      className: "modal-large",
      body: "",
      data: {
        delete: this.deleteItem,
        msg: "Are you sure you want to delete this member ?",
      },
    });
  };

  render() {
    let {
      isUploader,
      bulkFiles = [],
      MEMBERTYPE = {},
      HOBBIES = [],
      error,
      photo,
      errorMessages,
      isValid,
      isStep,
      isStep1Done,
      isStep2Done,
      isStep3Done,
      isStep4Done,
      mobile,
      email,
      dob,
      age,
      gender,
      fName,
      mName,
      lName,
      address,
      company,
      industry,
      educationInsti,
      selEducationInsti,
      designation,
      mNo,
      mType,
      startDate,
      endDate,
      altContactType,
      altFname,
      altLname,
      altEmail,
      altPhone,
      maritalStatus,
      spouseFname,
      spouseLname,
      spouseEmail,
      spousePhone,
      spouseDOB,
      spouseAnni,
      reference1,
      reference2,
      notes,
      drink,
      film,
      fitness,
      herb,
      intrest,
      isSaving,
      status,
      childrenInfo,
      totalChildren,
      ctype,
      industryOpt = [],
      eduInstOpt = [],
      genderOpt = [],
      jobTitleOpt = [],
      clubOpt = [],
      maritalStatusOtp = [],
      actOpt = [],
      basicDetails = {},
      analyticRecord = {},
      tableList = [],
      analyticsCodeList = [],
      showTable,
      isLoader,
      totalCount,
      page,
      educationInstiName,
    } = this.state;
    let {
      match: { params },
      itemLoader,
    } = this.props;

    if (itemLoader) {
      return <Loader />;
    }
    if (isUploader) {
      return (
        <div className="mt30 memberUploader">
          <Uploader
            files={bulkFiles}
            onComplete={this.setBulkPhotos}
            onBack={this.toggleUploader}
            folder={"temp"}
            id={""}
            uploadType="userImage"
            isSideNav={false}
            not_upload={true}
            isPopup={true}
            config={{ aspectRatio: "p2" }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column ">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title mb-0">
                <Link to={params.mid ? "/member-list/member" : "/admin-access"}>
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;{params.mid ? "Edit Member" : "Create A Club Member"}
              </h3>
            </div>
          </div>
          <div className="card bg-transferent margin-top-15 h-100 ">
            <div className="row">
              <div className="col-3">
                <div className="create-member-tab">
                  <ul
                    className="list-unstyled components"
                    style={{ border: "none" }}
                  >
                    <li
                      onClick={() => this.changeStep(1)}
                      className={isStep === 1 ? "current-tab" : ""}
                    >
                      <div className="row">
                        <div className="col-9 cursor-pointer">
                          <p style={{ textTransform: "capitalize" }}>
                            Member Details
                          </p>
                        </div>
                        <div className="col-3 text-center step">
                          <i className="fa fa-check done"></i>
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => this.changeStep(2)}
                      className={isStep === 2 ? "current-tab" : ""}
                    >
                      <div className="row">
                        <div className="col-9 cursor-pointer">
                          <p style={{ textTransform: "capitalize" }}>
                            Membership
                          </p>
                        </div>
                        <div className="col-3 text-center step">
                          {isStep2Done || params.mid ? (
                            <i className="fa fa-check done"></i>
                          ) : (
                            <i className="fa fa-times pending"></i>
                          )}
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => this.changeStep(3)}
                      className={isStep === 3 ? "current-tab" : ""}
                    >
                      <div className="row">
                        <div className="col-9 cursor-pointer">
                          <p style={{ textTransform: "capitalize" }}>
                            Family Details
                          </p>
                        </div>
                        <div className="col-3 text-center step">
                          {isStep3Done || params.mid ? (
                            <i className="fa fa-check done"></i>
                          ) : (
                            <i className="fa fa-times pending"></i>
                          )}
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => this.changeStep(4)}
                      className={isStep === 4 ? "current-tab" : ""}
                    >
                      <div className="row">
                        <div className="col-9 cursor-pointer">
                          <p style={{ textTransform: "capitalize" }}>
                            Preferences
                          </p>
                        </div>
                        <div className="col-3 text-center step">
                          {isStep4Done || params.mid ? (
                            <i className="fa fa-check done"></i>
                          ) : (
                            <i className="fa fa-times pending"></i>
                          )}
                        </div>
                      </div>
                    </li>
                    {params.mid && (
                      <li
                        onClick={() => this.changeStep(5)}
                        className={isStep === 5 ? "current-tab" : ""}
                      >
                        <div className="row">
                          <div className="col-9 cursor-pointer">
                            <p style={{ textTransform: "capitalize" }}>
                              Member Analytics
                            </p>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "3px" }}>
                <div
                  className={`card h-100 ${
                    isStep === 5 && params.mid && !showTable
                      ? "bg-transparent"
                      : ""
                  }`}
                >
                  <div className={`container-fluid`}>
                    {isStep === 1 && (
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                name="fName"
                                label="First Name"
                                value={fName}
                                isValid={isValid.fName}
                                errorMsg={errorMessages.fName}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="mName"
                                label="Middle Name"
                                value={mName}
                                isValid={isValid.mName}
                                errorMsg={errorMessages.mName}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="lName"
                                label="Last Name"
                                value={lName}
                                isValid={isValid.lName}
                                errorMsg={errorMessages.lName}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="Phone"
                                name="mobile"
                                value={mobile}
                                isValid={isValid.mobile}
                                errorMsg={errorMessages.mobile}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "", type: "number" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="email"
                                label="Email Id"
                                value={email}
                                isValid={isValid.email}
                                errorMsg={errorMessages.email}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "", type: "email" }}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                name="dob"
                                label="DOB"
                                value={dob}
                                isValid={isValid.dob}
                                errorMsg={errorMessages.dob}
                                inputChange={this.inputChange}
                                dom={{
                                  placeholder: "",
                                  popperPlacement: "top",
                                  type: "date",
                                  dateFormat: "dd/MM/yyyy",
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                name="age"
                                label="Age"
                                value={age}
                                isValid={isValid.age}
                                errorMsg={errorMessages.age}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "", type: "number" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="gender"
                                label="Gender"
                                value={gender}
                                isValid={isValid.gender}
                                errorMsg={errorMessages.gender}
                                inputChange={this.inputChange}
                                options={genderOpt}
                                dom={{ placeholder: "Select", type: "select" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="Address"
                                name="address"
                                value={address}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12 staff-member">
                              <label className="form-label">Photo</label>
                              <Dropzone
                                style={{ position: "relative" }}
                                accept="image/jpeg, image/png, image/jpg"
                                multiple={false}
                                onDrop={(accepted, rejected) => {
                                  this.onDrop(accepted, rejected);
                                }}
                              >
                                <div
                                  className={
                                    "image-style upload-photo " +
                                    (photo && "uploadtxt")
                                  }
                                  style={{
                                    backgroundImage: `url(${photo})`,
                                  }}
                                >
                                  <img
                                    src={"../../images/imagesize2_3.png"}
                                    className="w100per"
                                  />
                                </div>
                              </Dropzone>
                            </div>
                          </div>
                          <div className="col-12">
                            <InputField
                              name="designation"
                              label="Job Title"
                              value={designation}
                              isValid={isValid.designation}
                              errorMsg={errorMessages.designation}
                              inputChange={this.inputChange}
                              options={jobTitleOpt}
                              dom={{ placeholder: "Select", type: "select" }}
                            />
                          </div>
                          <div className="col-12">
                            <InputField
                              name="industry"
                              label="Industry"
                              value={industry}
                              isValid={isValid.industry}
                              errorMsg={errorMessages.industry}
                              inputChange={this.inputChange}
                              options={industryOpt}
                              dom={{ placeholder: "Select", type: "select" }}
                            />
                          </div>
                          <div className="col-12">
                            {company.length > 2 ? (
                              <>
                                <label className="form-label">Company</label>
                                <SearchInput
                                  endpoint={`${Endpoint.COMPANY}`}
                                  searchKey="searchKeyword"
                                  accessKey="companies"
                                  name="memberData"
                                  inputClassName="form_control"
                                  inputProps={{
                                    autoFocus: true,
                                  }}
                                  defaultValue={company}
                                  placeholder="Search"
                                  onChange={(value, onChangeSearch) => {
                                    this.inputChange({
                                      target: {
                                        name: "company",
                                        value: value?.company,
                                      },
                                    });
                                  }}
                                  onInputChange={(value) =>
                                    this.inputChange({
                                      target: { name: "company", value: value },
                                    })
                                  }
                                  renderItem={(item, i) => {
                                    return (
                                      <React.Fragment key={"auto_" + i}>
                                        <div className="flex-grow-1 p-2">
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {item?.company}
                                          </span>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <InputField
                                name="company"
                                label="Company"
                                value={company}
                                isValid={isValid.company}
                                errorMsg={errorMessages.company}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "", id: "companyId" }}
                              />
                            )}
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-10">
                                <InputField
                                  name="selEducationInsti"
                                  label="Educational Institute Category"
                                  value={selEducationInsti}
                                  inputChange={this.inputChange}
                                  options={eduInstOpt}
                                  dom={{
                                    placeholder: "Select",
                                    type: "select",
                                  }}
                                />
                              </div>
                              <div className="col-2 position-relative">
                                <Button
                                  className="btn btn-primary btn-primary-green"
                                  style={{ position: "absolute", bottom: 10 }}
                                  onClick={() => {
                                    if (selEducationInsti)
                                      this.setState({
                                        educationInsti: [
                                          ...educationInsti,
                                          selEducationInsti,
                                        ],
                                        selEducationInsti: "",
                                      });
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex">
                              {educationInsti &&
                                educationInsti.length > 0 &&
                                educationInsti?.map((data, i) => (
                                  <div
                                    className={`active select-tag-interests d-flex align-items-center`}
                                    key={i}
                                  >
                                    <p>{eduInstOpt[data]}</p>
                                    <div
                                      onClick={() => {
                                        const temp = [...educationInsti];
                                        temp.splice(i, 1);
                                        this.setState({
                                          educationInsti: temp,
                                        });
                                      }}
                                    >
                                      <h6
                                        style={{
                                          marginLeft: 10,
                                          marginBottom: 0,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i
                                          className="fa fa-times pending"
                                          style={{
                                            fontSize: 20,
                                            color: "#ea5b5b",
                                          }}
                                        ></i>
                                      </h6>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          {educationInsti.includes(
                            getKeyByValue(eduInstOpt, "Tier 1")
                          ) && (
                            <div className="col-12">
                              <InputField
                                name="educationInstiName"
                                label="Educational Institute Name"
                                value={educationInstiName}
                                isValid={isValid.educationInstiName}
                                errorMsg={errorMessages.educationInstiName}
                                inputChange={this.inputChange}
                                dom={{
                                  placeholder: "",
                                  type: "text",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-12 absolute-bottom-right text-end">
                          <Button
                            className="btn btn-primary btn-primary-green"
                            onClick={() => {
                              this.changeStep(2);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {isStep === 2 && (
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                label="Club Access"
                                name="clubBelongsTo"
                                value={this.state.clubBelongsTo}
                                errorMsg={errorMessages.clubBelongsTo}
                                options={clubOpt}
                                inputChange={this.inputChangeClub}
                                dom={{ type: "select", placeholder: "Select" }}
                              />
                              {errorMessages.clubBelongsTo &&
                                !this.state.clubBelongsTo && (
                                  <p class="error_msg">
                                    {" "}
                                    This field is required.
                                  </p>
                                )}
                            </div>
                            <div className="col-12">
                              <InputField
                                name="mNo"
                                label="Membership Number"
                                value={mNo}
                                isValid={isValid.mNo}
                                errorMsg={errorMessages.mNo}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="mType"
                                label="Membership Type"
                                value={mType}
                                isValid={isValid.mType}
                                errorMsg={errorMessages.mType}
                                inputChange={this.inputChange}
                                options={MEMBERTYPE}
                                dom={{ placeholder: "Select", type: "select" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="startDate"
                                label="Start Date"
                                value={startDate}
                                isValid={isValid.startDate}
                                errorMsg={errorMessages.startDate}
                                inputChange={this.inputChange}
                                dom={{
                                  placeholder: "",
                                  type: "date",
                                  popperPlacement: "top",
                                  dateFormat: "dd/MM/yyyy",
                                  maxDate: endDate ? new Date(endDate) : null,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="endDate"
                                label="End Date"
                                value={endDate}
                                isValid={isValid.endDate}
                                errorMsg={errorMessages.endDate}
                                inputChange={this.inputChange}
                                dom={{
                                  placeholder: "",
                                  type: "date",
                                  popperPlacement: "top",
                                  dateFormat: "dd/MM/yyyy",
                                  minDate: startDate
                                    ? new Date(startDate)
                                    : null,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 text-end">
                          <Button
                            className="btn btn-primary btn-primary-green"
                            onClick={() => this.changeStep(3)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {isStep === 3 && (
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                name="altContactType"
                                label="Alternate Contact"
                                value={altContactType}
                                isValid={isValid.altContactType}
                                errorMsg={errorMessages.altContactType}
                                inputChange={this.inputChange}
                                options={actOpt}
                                dom={{ placeholder: "Select", type: "select" }}
                              />
                            </div>
                            {altContactType &&
                              altContactType !==
                                getKeyByValue(this?.state?.actOpt, "None") && (
                                <>
                                  <div className="col-12">
                                    <InputField
                                      name="altFname"
                                      label="First Name"
                                      value={altFname}
                                      isValid={isValid.altFname}
                                      errorMsg={errorMessages.altFname}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="altLname"
                                      label="Last Name"
                                      value={altLname}
                                      isValid={isValid.altLname}
                                      errorMsg={errorMessages.altLname}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="altPhone"
                                      label="Phone Number"
                                      value={altPhone}
                                      isValid={isValid.altPhone}
                                      errorMsg={errorMessages.altPhone}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="altEmail"
                                      label="Email Id"
                                      value={altEmail}
                                      isValid={isValid.altEmail}
                                      errorMsg={errorMessages.altEmail}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                </>
                              )}
                            <div className="col-12">
                              <InputField
                                name="ctype"
                                label="Children"
                                value={ctype}
                                groupClass="mr15"
                                inputChange={this.inputChange}
                                options={{ y: "Yes", n: "No" }}
                                dom={{ type: "select" }}
                              />
                            </div>
                            {ctype === "y" && (
                              <>
                                <div className="col-12">
                                  <div className="_tags d-flex justify-content-between flex-wrap mt5">
                                    <div
                                      className={`select-tag ${
                                        totalChildren === 1 ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.addChildren(1);
                                      }}
                                    >
                                      1
                                    </div>
                                    <div
                                      className={`select-tag ${
                                        totalChildren === 2 ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.addChildren(2);
                                      }}
                                    >
                                      2
                                    </div>
                                    <div
                                      className={`select-tag ${
                                        totalChildren === 3 ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.addChildren(3);
                                      }}
                                    >
                                      3
                                    </div>
                                    <div
                                      className={`select-tag ${
                                        totalChildren === 4 ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.addChildren(4);
                                      }}
                                    >
                                      4
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  {childrenInfo &&
                                    childrenInfo.length > 0 &&
                                    childrenInfo.map((child, i) => {
                                      return (
                                        <div className="row" key={`child_${i}`}>
                                          <div className="col-6">
                                            <InputField
                                              name="cName"
                                              label="Child Name"
                                              value={child.cName}
                                              inputChange={(e) => {
                                                this.inputChange(
                                                  e,
                                                  i,
                                                  "childrenInfo"
                                                );
                                              }}
                                              dom={{ placeholder: "" }}
                                            />
                                          </div>
                                          <div className="col-6">
                                            <InputField
                                              name="cDob"
                                              label="DOB"
                                              value={child.cDob}
                                              inputChange={(e) => {
                                                this.inputChange(
                                                  e,
                                                  i,
                                                  "childrenInfo"
                                                );
                                              }}
                                              dom={{
                                                placeholder: "",
                                                type: "date",
                                                maxDate: new Date(),
                                                dateFormat: "dd/MM/yyyy",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                name="maritalStatus"
                                label="Marital Status"
                                value={maritalStatus}
                                isValid={isValid.maritalStatus}
                                errorMsg={errorMessages.maritalStatus}
                                inputChange={this.inputChange}
                                options={maritalStatusOtp}
                                dom={{ placeholder: "Select", type: "select" }}
                              />
                            </div>
                            {maritalStatus &&
                              maritalStatus !==
                                getKeyByValue(maritalStatusOtp, "Single") && (
                                <>
                                  <div className="col-12">
                                    <InputField
                                      name="spouseFname"
                                      value={spouseFname}
                                      label="Spouse First Name"
                                      isValid={isValid.spouseFname}
                                      errorMsg={errorMessages.spouseFname}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="spouseLname"
                                      label="Spouse Last Name"
                                      value={spouseLname}
                                      isValid={isValid.spouseLname}
                                      errorMsg={errorMessages.spouseLname}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="spousePhone"
                                      label="Phone Number"
                                      value={spousePhone}
                                      isValid={isValid.spousePhone}
                                      errorMsg={errorMessages.spousePhone}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "", type: "number" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="spouseEmail"
                                      label="Email Id"
                                      value={spouseEmail}
                                      isValid={isValid.spouseEmail}
                                      errorMsg={errorMessages.spouseEmail}
                                      inputChange={this.inputChange}
                                      dom={{ placeholder: "", type: "email" }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="spouseDOB"
                                      label="DOB"
                                      value={spouseDOB}
                                      isValid={isValid.spouseDOB}
                                      errorMsg={errorMessages.spouseDOB}
                                      inputChange={this.inputChange}
                                      dom={{
                                        placeholder: "",
                                        type: "date",
                                        dateFormat: "dd/MM/yyyy",
                                      }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <InputField
                                      name="spouseAnni"
                                      label="Anniversary"
                                      value={spouseAnni}
                                      isValid={isValid.spouseAnni}
                                      errorMsg={errorMessages.spouseAnni}
                                      inputChange={this.inputChange}
                                      dom={{
                                        placeholder: "",
                                        type: "date",
                                        dateFormat: "dd/MM/yyyy",
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="col-12 text-end">
                          <Button
                            className="btn btn-primary btn-primary-green"
                            onClick={() => this.changeStep(4)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {isStep === 4 && (
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                name="reference1"
                                label="Name 1"
                                value={reference1}
                                isValid={isValid.reference1}
                                errorMsg={errorMessages.reference1}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="reference2"
                                label="Name 2"
                                value={reference2}
                                isValid={isValid.reference2}
                                errorMsg={errorMessages.reference2}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="drink"
                                value={drink}
                                label="Favourite Drink"
                                isValid={isValid.drink}
                                errorMsg={errorMessages.drink}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="film"
                                value={film}
                                label="Favourite Film"
                                isValid={isValid.film}
                                errorMsg={errorMessages.film}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="fitness"
                                label="Goto Fitness Activity"
                                value={fitness}
                                isValid={isValid.fitness}
                                errorMsg={errorMessages.fitness}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                name="herb"
                                label="Favourite Herb/Spice"
                                value={herb}
                                isValid={isValid.herb}
                                errorMsg={errorMessages.herb}
                                inputChange={this.inputChange}
                                dom={{ placeholder: "" }}
                              />
                            </div>
                            <div className="col-12">
                              <label className="form-label">Notes</label>
                              <textarea
                                className="form-control"
                                placeholder=""
                                row="3"
                                name="notes"
                                value={notes}
                                onChange={this.inputChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <label className="form-label">Select Interests</label>
                          <div className="d-flex  flex-wrap">
                            {HOBBIES.map((h, i) => {
                              return (
                                <div
                                  className={`${
                                    intrest &&
                                    intrest.length > 0 &&
                                    intrest.includes(h._id)
                                      ? "active"
                                      : ""
                                  } select-tag-interests`}
                                  key={i}
                                  value={h._id}
                                  onClick={this.selectHobby}
                                >
                                  {h.title.toLowerCase()}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-12 mt-3 text-center">
                          {error && <p className="error_msg"> {error}</p>}
                        </div>
                        <div className="col-12 text-end mt-3">
                          {params.mid &&
                            (this?.props?.adminType === "Super Admin" ||
                              this?.props?.adminType === "Admin" ||
                              this?.props?.adminType === "Developer") && (
                              <Button
                                className="btn btn-primary btn-primary-green me-2"
                                onClick={this.logoutFromApp}
                              >
                                Logout
                              </Button>
                            )}
                          {params.mid &&
                            (this?.props?.adminType === "Super Admin" ||
                              this?.props?.adminType === "Admin" ||
                              this?.props?.adminType === "Developer") && (
                              <Button
                                className="btn btn-primary btn-primary-green me-2"
                                onClick={this.askConfirm}
                              >
                                Delete
                              </Button>
                            )}
                          {params.mid &&
                            (this?.props?.adminType === "Super Admin" ||
                              this?.props?.adminType === "Admin" ||
                              this?.props?.adminType === "Developer") && (
                              <Button
                                className="btn btn-primary btn-primary-green me-2"
                                onClick={() => this.statusChange(params.mid)}
                              >
                                {status === 1 ? "Inactive" : "Active"}
                              </Button>
                            )}
                          {(this?.props?.adminType === "Super Admin" ||
                            this?.props?.adminType === "Admin" ||
                            this?.props?.adminType === "Developer") && (
                            <Button
                              className={`btn btn-primary btn-primary-green ${
                                isSaving ? "btndisabled" : ""
                              }`}
                              onClick={this.onSubmit}
                            >
                              {params.mid ? "Update" : "Save"}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    {isStep === 5 && params.mid && (
                      <>
                        {!showTable ? (
                          <div className="row mx--15">
                            <div className="col-md-3">
                              <div className="box l app-members">
                                <div className="bk">
                                  <ul className="row list-unstyled">
                                    <li className=" mb-3 col-md-12 bg">
                                      <div className="rounded bg-white">
                                        <div
                                          className={
                                            "image-style upload-photo " +
                                            (basicDetails?.photo && "uploadtxt")
                                          }
                                          style={{
                                            backgroundImage: `url(${basicDetails?.photo})`,
                                          }}
                                        >
                                          <img
                                            src={
                                              "../../images/imagesize2_3.png"
                                            }
                                            className="w100per"
                                          />
                                        </div>
                                      </div>
                                    </li>
                                    <li className=" mb-3 col-md-12">
                                      <label className="mb-0">
                                        {basicDetails?.fName}{" "}
                                        {basicDetails?.mName}{" "}
                                        {basicDetails?.lName}
                                      </label>
                                      <div className="val_box">
                                        <span className="text-secondary fs15">
                                          M. No. :{basicDetails?.mNo}
                                        </span>
                                      </div>
                                    </li>
                                    <li className=" mb-3 col-md-12">
                                      <label className="mb-0">
                                        Last Seen On
                                      </label>
                                      <div className="val_box">
                                        <span className="text-secondary fs15">
                                          {basicDetails?.lastSeen
                                            ? lastDateOperation(
                                                basicDetails?.lastSeen
                                              )
                                            : "Not Logged In Yet"}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="  mb-3 col-md-12">
                                      <label className="mb-0">Device</label>
                                      <div className="val_box">
                                        <span className="text-secondary fs15">
                                          {basicDetails?.deviceType
                                            ? DEVICE_TYPE[
                                                basicDetails?.deviceType
                                              ]
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="  mb-3 col-md-12">
                                      <label className="mb-0">App Usage</label>
                                      <div className="val_box">
                                        <span className="text-secondary fs15">
                                          {basicDetails?.appUsage
                                            ? APP_USAGE[basicDetails?.appUsage]
                                            : "Dormant"}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-9 overflow-auto-div1`}>
                              <div className="d-flex justify-content-end">
                                <Button
                                  className={`btn btn-primary btn-primary-green mb-2`}
                                  onClick={() =>
                                    this.setState({ showTable: true })
                                  }
                                >
                                  View Log
                                </Button>
                              </div>
                              <div className="row card-analytics">
                                {isLoader && <Loader />}
                                {!isLoader &&
                                  (!basicDetails ||
                                    Object.keys(basicDetails).length === 0 ||
                                    !Object.keys(basicDetails).length) && (
                                    <NoRecord message="No Activity Performed" />
                                  )}
                                {!isLoader &&
                                  analyticRecord &&
                                  Object.keys(analyticRecord).length > 0 && (
                                    <>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalVisits}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Section Views</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalSection}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Check Ins from App
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalCheckInApp}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Check Ins from CMS
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalCheckInCms}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Guest Invites</label>
                                          <div className="val_box">
                                            <span>
                                              {
                                                analyticRecord?.totalGuestInvites
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Q Perks Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalQperk}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Q Perks Inquiries</label>
                                          <div className="val_box">
                                            <span>
                                              {
                                                analyticRecord?.totalQperkEnquiry
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Reservation Visits
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalReserVisit}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Reservation Made</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalReserMade}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Events Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalEvents}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total RSVPX</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalRsvp}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Events Ticket Clicks
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {
                                                analyticRecord?.totalEventsTicket
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Q Pay Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalQpay}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Global Access Visits
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalGlobal}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Book My Visit</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalBook}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Sonato Clicks</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalSonato}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Video Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalVideoPlayed}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Video Clicks</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalVideoClicks}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>Total Dine Visits</label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalDine}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="box">
                                          <label>
                                            Total Static Page Visits
                                          </label>
                                          <div className="val_box">
                                            <span>
                                              {analyticRecord?.totalStatic}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="flex-grow-1 d-flex align-items-center justify-content-end mb-2">
                              <Button
                                className={`btn btn-primary btn-primary-green`}
                                onClick={() =>
                                  this.setState({ showTable: false })
                                }
                              >
                                Back
                              </Button>
                            </div>
                            <div className="col-12">
                              {isLoader && <Loader />}
                              {!isLoader &&
                                (!tableList ||
                                  tableList.length === 0 ||
                                  !tableList.length) && (
                                  <NoRecord message="No records found" />
                                )}
                              {!isLoader && tableList && tableList.length > 0 && (
                                <>
                                  <div className="overflow-auto-div-pagination2">
                                    <table className="table table-borderless custom-table">
                                      <thead>
                                        <tr className="text-center">
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>Activity</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tableList.map((item, i) => {
                                          return (
                                            <tr key={i} className="text-center">
                                              <td className="text-center">
                                                {moment
                                                  .unix(item?.dateTime)
                                                  .format("DD-MM-YYYY")}
                                              </td>
                                              <td>
                                                {moment
                                                  .unix(
                                                    item?.dateTime,
                                                    "hh:mm a"
                                                  )
                                                  .format("HH:mm")}
                                              </td>
                                              <td className="text-center">
                                                {analyticsCodeList?.length > 0
                                                  ? analyticsCodeList?.find(
                                                      (d) =>
                                                        d?.activityDone ===
                                                          item?.activityDone &&
                                                        d?.moduleName ===
                                                          item?.moduleName
                                                    )?.text
                                                  : ""}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div>
                                    <Pagination
                                      paging={{
                                        count: totalCount,
                                        rows: 20,
                                        page: page,
                                      }}
                                      onPageClick={(page) => {
                                        this.setState({ page: page }, () => {
                                          this.handleGetLogs();
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({
  auth: { token, authData },
  page: { suggesions = {}, individualItem, itemLoader = false },
  clubs: { clubs },
}) {
  return {
    token,
    itemData: individualItem,
    itemLoader,
    suggesions,
    adminType: authData?.roleId?.roleName,
    clubs: clubs,
  };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    fetchSuggestions: (myParam) =>
      dispatch(fetchSuggestions(myParam, "suggesions")),
    updatePageData: (myParam, cb) => dispatch(updatePageData(myParam, cb)),
    getIndividualItem: (myParam) => dispatch(getIndividualItem(myParam)),
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    updateModalData: (myParam) => dispatch(updateModalData(myParam)),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const memberControllerData = useMember();
    const altContactControllerData = useAltContact();
    const designationControllerData = useDesignation();
    const genderControllerData = useGender();
    const industryControllerData = useIndustry();
    const mIntrestControllerData = useMIntrest();
    const mStatusControllerData = useMStatus();
    const mTypeControllerData = useMType();
    const eduInstControllerData = useEduInst();

    return (
      <Component
        {...props}
        memberControllerData={memberControllerData}
        altContactControllerData={altContactControllerData}
        designationControllerData={designationControllerData}
        genderControllerData={genderControllerData}
        industryControllerData={industryControllerData}
        mIntrestControllerData={mIntrestControllerData}
        mStatusControllerData={mStatusControllerData}
        mTypeControllerData={mTypeControllerData}
        eduInstControllerData={eduInstControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(AddMember));
