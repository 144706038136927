import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import { resetPageStore } from "../actions/page";
import { Button } from "reactstrap";
import useMType from "../controller/member/mType-controller";
import useGender from "../controller/member/gender-controller";
import useIndustry from "../controller/member/industry-controller";
import useDesignation from "../controller/member/designation-controller";
import useEduInst from "../controller/member/eduInst-controller";
import useMIntrest from "../controller/member/mIntrest-controller";
import useCompany from "../controller/member/company-controller";
import { getClubs } from "../actions/clubs";
import useMember from "../controller/member/member-controller";
import { dateToStr, exportToCsv } from "../utils/formatter";
import moment from "moment";
import { toast } from "react-toastify";

class MemberFilter extends Component {
  constructor(props) {
    super(props);
    this.keymapper = {
      header: [
        "Name",
        "Mobile",
        "Email",
        "DOB",
        "Gender",
        "Address",
        "Designation",
        "Industry",
        "Company",
        "Education Insti",
        "Club",
        "Member No",
        "Member Type",
        "Start Date",
        "End Date",
        "Alt Contact Type",
        "Alt First name",
        "Alt Last name",
        "Alt Email",
        "Alt Phone",
        "Marital Status",
        "Spouse Fname",
        "spouse Lname",
        "spouse Phone",
        "Spouse Email",
        "Spouse DOB",
        "Spouse Anni",
        "Children Info",
        "Reference1",
        "Reference2",
        "Drink",
        "Film",
        "Fitness",
        "Herb",
        "Notes",
        "Intrest",
        "Account Status",
        "Wallet Balance",
      ],
      keys: [
        "name",
        "mobile",
        "email",
        "dob",
        "gender",
        "address",
        "designation",
        "industry",
        "company",
        "educationInsti",
        "clubName",
        "mNo",
        "mType",
        "startDate",
        "endDate",
        "altContactType",
        "altFname",
        "altLname",
        "altEmail",
        "altPhone",
        "maritalStatus",
        "spouseFname",
        "spouseLname",
        "spousePhone",
        "spouseEmail",
        "spouseDOB",
        "spouseAnni",
        "childrenInfo",
        "reference1",
        "reference2",
        "drink",
        "film",
        "fitness",
        "herb",
        "notes",
        "intrest",
        "accountStatus",
        "walletBalance",
      ],
    };
    this.state = {
      isSaving: false,
      isLoader: false,
      isInit: true,
      isStep: 1,
      indSearch: "",
      eduSearch: "",
      compSearch: "",
      startAge: "",
      endAge: "",
      filterArr: {
        clubName: [],
        memberType: [],
        age: { startAge: "", endAge: "" },
        gender: [],
        industryType: [],
        designationType: [],
        eduInstType: [],
        intrestType: [],
        compType: [],
        appUsage: [],
      },
      activeFilter: [],
      list: [],
      isDataCompleted: false,
      page: 1,
      indSelectAll: false,
      compSelectAll: false,
    };
    this.listArr = [
      "Location",
      "Membership",
      "Age Range",
      "Gender",
      "Industry",
      "Designation",
      "Education",
      "Interests",
      "Company",
      "App Usage",
    ];
  }

  componentDidMount() {
    const { getAllMType } = this?.props?.mTypeControllerData;
    const { getAllGender } = this?.props?.genderControllerData;
    const { getAllIndustry } = this?.props?.industryControllerData;
    const { getAllDesignation } = this?.props?.designationControllerData;
    const { getAllEduInst } = this?.props?.eduInstControllerData;
    const { getAllMIntrest } = this?.props?.mIntrestControllerData;
    const { getAllCompany } = this?.props?.companyControllerData;

    this.props.getClubs();
    getAllMType();
    getAllGender();
    getAllIndustry();
    getAllDesignation();
    getAllEduInst();
    getAllMIntrest();
    getAllCompany();

    if (this?.props?.clubs?.length > 0) {
      const clubOtpData = this?.props?.clubs;
      this.setState({ clubOpt: clubOtpData });
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.mTypeControllerData?.isLoading !=
        this?.props?.mTypeControllerData?.isLoading ||
      prevProps?.genderControllerData?.isLoading !=
        this?.props?.genderControllerData?.isLoading ||
      prevProps?.industryControllerData?.isLoading !=
        this?.props?.industryControllerData?.isLoading ||
      prevProps?.designationControllerData?.isLoading !=
        this?.props?.designationControllerData?.isLoading ||
      prevProps?.eduInstControllerData?.isLoading !=
        this?.props?.eduInstControllerData?.isLoading ||
      prevProps?.mIntrestControllerData?.isLoading !=
        this?.props?.mIntrestControllerData?.isLoading ||
      prevProps?.companyControllerData?.isLoading !=
        this?.props?.companyControllerData?.isLoading
    ) {
      this.setState({
        isLoader:
          this?.props?.mTypeControllerData?.isLoading ||
          this?.props?.genderControllerData?.isLoading ||
          this?.props?.industryControllerData?.isLoading ||
          this?.props?.designationControllerData?.isLoading ||
          this?.props?.eduInstControllerData?.isLoading ||
          this?.props?.mIntrestControllerData?.isLoading ||
          this?.props?.companyControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.mTypeControllerData?.allMTypeData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }

    if (
      prevProps?.mTypeControllerData?.errMsg !==
        this?.props?.mTypeControllerData?.errMsg ||
      prevProps?.mIntrestControllerData?.errMsg !==
        this?.props?.mIntrestControllerData?.errMsg ||
      prevProps?.industryControllerData?.errMsg !==
        this?.props?.industryControllerData?.errMsg ||
      prevProps?.genderControllerData?.errMsg !==
        this?.props?.genderControllerData?.errMsg ||
      prevProps?.designationControllerData?.errMsg !==
        this?.props?.designationControllerData?.errMsg ||
      prevProps?.eduInstControllerData?.errMsg !==
        this?.props?.eduInstControllerData?.errMsg ||
      prevProps?.companyControllerData?.errMsg !==
        this?.props?.companyControllerData?.errMsg ||
      prevProps?.memberControllerData?.errMsg !==
        this?.props?.memberControllerData?.errMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.errMsg ||
        this?.props?.mIntrestControllerData?.errMsg ||
        this?.props?.industryControllerData?.errMsg ||
        this?.props?.genderControllerData?.errMsg ||
        this?.props?.designationControllerData?.errMsg ||
        this?.props?.eduInstControllerData?.errMsg ||
        this?.props?.companyControllerData?.errMsg ||
        this?.props?.memberControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.mTypeControllerData?.errMsg ||
            this?.props?.mIntrestControllerData?.errMsg ||
            this?.props?.industryControllerData?.errMsg ||
            this?.props?.genderControllerData?.errMsg ||
            this?.props?.designationControllerData?.errMsg ||
            this?.props?.eduInstControllerData?.errMsg ||
            this?.props?.companyControllerData?.errMsg ||
            this?.props?.memberControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.mTypeControllerData?.succMsg !==
        this?.props?.mTypeControllerData?.succMsg ||
      prevProps?.mIntrestControllerData?.succMsg !==
        this?.props?.mIntrestControllerData?.succMsg ||
      prevProps?.industryControllerData?.succMsg !==
        this?.props?.industryControllerData?.succMsg ||
      prevProps?.genderControllerData?.succMsg !==
        this?.props?.genderControllerData?.succMsg ||
      prevProps?.designationControllerData?.succMsg !==
        this?.props?.designationControllerData?.succMsg ||
      prevProps?.eduInstControllerData?.succMsg !==
        this?.props?.eduInstControllerData?.succMsg ||
      prevProps?.companyControllerData?.succMsg !==
        this?.props?.companyControllerData?.succMsg ||
      prevProps?.memberControllerData?.succMsg !==
        this?.props?.memberControllerData?.succMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.succMsg ||
        this?.props?.mIntrestControllerData?.succMsg ||
        this?.props?.industryControllerData?.succMsg ||
        this?.props?.genderControllerData?.succMsg ||
        this?.props?.designationControllerData?.succMsg ||
        this?.props?.eduInstControllerData?.succMsg ||
        this?.props?.companyControllerData?.succMsg ||
        this?.props?.memberControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.mTypeControllerData?.succMsg ||
            this?.props?.mIntrestControllerData?.succMsg ||
            this?.props?.industryControllerData?.succMsg ||
            this?.props?.genderControllerData?.succMsg ||
            this?.props?.designationControllerData?.succMsg ||
            this?.props?.eduInstControllerData?.succMsg ||
            this?.props?.companyControllerData?.succMsg ||
            this?.props?.memberControllerData?.succMsg
        );
      }
    }

    if (prevProps?.clubs !== this?.props?.clubs) {
      const clubOtpData = this?.props?.clubs;
      this.setState({ clubOpt: clubOtpData });
    }
    if (
      prevProps?.mTypeControllerData?.allMTypeData !=
      this?.props?.mTypeControllerData?.allMTypeData
    ) {
      const memberTypeData = this?.props?.mTypeControllerData?.allMTypeData;
      this.setState({ memberTypeOpt: memberTypeData });
    }
    if (
      prevProps?.genderControllerData?.allGenderData !=
      this?.props?.genderControllerData?.allGenderData
    ) {
      const genderOtpData = this?.props?.genderControllerData?.allGenderData;
      this.setState({
        genderTypeOpt: genderOtpData,
      });
    }
    if (
      prevProps?.industryControllerData?.allIndustryData !=
      this?.props?.industryControllerData?.allIndustryData
    ) {
      const industryOptData = this?.props?.industryControllerData
        ?.allIndustryData;
      this.setState({
        industryTypeOpt: industryOptData,
      });
    }
    if (
      prevProps?.designationControllerData?.allDesignationData !=
      this?.props?.designationControllerData?.allDesignationData
    ) {
      const designationOptData = this?.props?.designationControllerData
        ?.allDesignationData;
      this.setState({
        designationTypeOpt: designationOptData,
      });
    }
    if (
      prevProps?.eduInstControllerData?.allEduInstData !=
      this?.props?.eduInstControllerData?.allEduInstData
    ) {
      const eduInstOptData = this?.props?.eduInstControllerData?.allEduInstData;
      this.setState({
        eduInstTypeOpt: eduInstOptData,
      });
    }
    if (
      prevProps?.mIntrestControllerData?.allMIntrestData !=
      this?.props?.mIntrestControllerData?.allMIntrestData
    ) {
      this.setState({
        intrestTypeOpt: this?.props?.mIntrestControllerData?.allMIntrestData,
      });
    }
    if (
      prevProps?.companyControllerData?.allCompanyData !=
      this?.props?.companyControllerData?.allCompanyData
    ) {
      const compOptData = this?.props?.companyControllerData?.allCompanyData;
      this.setState({
        compTypeOpt: compOptData,
      });
    }

    if (prevState.indSearch !== this.state.indSearch) {
      const { getAllIndustry } = this?.props?.industryControllerData;
      getAllIndustry(`?searchKeyword=${this.state.indSearch}`);
    }

    if (prevState.eduSearch !== this.state.eduSearch) {
      const { getAllEduInst } = this?.props?.eduInstControllerData;
      getAllEduInst(`?searchKeyword=${this.state.eduSearch}`);
    }

    if (prevState.compSearch !== this.state.compSearch) {
      const { getAllCompany } = this?.props?.companyControllerData;
      getAllCompany(`?searchKeyword=${this.state.compSearch}`);
    }

    if (
      prevProps?.memberControllerData?.allFilterMemberData !==
      this?.props?.memberControllerData?.allFilterMemberData
    ) {
      this.setState({
        list: [
          ...this.state.list,
          ...this?.props?.memberControllerData?.allFilterMemberData?.members,
        ],
      });

      if (
        this?.props?.memberControllerData?.allFilterMemberData?.members
          ?.length > 0
      ) {
        this.getFilterlist(this.state.page + 1);
        this.setState({ page: this.state.page + 1 });
      } else {
        if (this.state.page == 1) {
        }
        this.setState({ isDataCompleted: true, isSaving: false });
      }
    }

    if (prevState.isDataCompleted !== this.state.isDataCompleted) {
      if (this.state.isDataCompleted) {
        exportToCsv(
          this.state?.list?.map((dt) => ({
            ...dt,
            name: `${dt?.fName || ""} ${dt?.mName || ""} ${dt?.lName || ""}`,
            dob: moment.unix(dt?.dob).format("DD-MM-YYYY"),
            gender: dt?.gender?.title,
            designation: dt?.designation?.title,
            industry: dt?.industry?.title,
            clubName: dt?.clubBelongsTo?.clubCity,
            mType: dt?.mType?.title,
            startDate: moment.unix(dt?.startDate).format("DD-MM-YYYY"),
            endDate: moment.unix(dt?.endDate).format("DD-MM-YYYY"),
            altContactType: dt?.altContactType?.title,
            maritalStatus: dt?.maritalStatus?.title,
            intrest: dt?.intrest?.map((d) => d.title).toString(),
            educationInsti: dt?.educationInsti?.map((d) => d.title).toString(),
            childrenInfo: dt?.childrenInfo?.map((d) => d.cName).toString(),
            spouseAnni: moment.unix(dt?.spouseAnni).format("DD-MM-YYYY"),
            spouseDOB: moment.unix(dt?.spouseDOB).format("DD-MM-YYYY"),
          })),
          "member_filter_list_" +
            dateToStr(null, "YYYY-MM-DD") +
            +"_DLon_" +
            moment().format("YYYY-MM-DD_HH.mm"),
          this.keymapper
        );
      }
    }
  }

  inputChange = (e, data, optName) => {
    const tempArr = { ...this.state.filterArr };
    let tempArr2 = [...this.state.activeFilter];
    const ind =
      optName === "compType"
        ? tempArr[optName].indexOf(data?.company)
        : tempArr[optName].indexOf(data?._id);

    if (ind !== -1) {
      tempArr[optName].splice(ind, 1);
    } else {
      tempArr[optName] = [
        ...tempArr[optName],
        optName === "compType" ? data.company : data._id,
      ];
    }

    if (tempArr2.includes(this.state.isStep)) {
      if (tempArr[optName]?.length === 0) {
        tempArr2.splice(tempArr2.indexOf(this.state.isStep), 1);
      }
    } else {
      if (tempArr[optName]?.length > 0) {
        tempArr2 = [...tempArr2, this.state.isStep];
      }
    }

    this.setState({ filterArr: tempArr });
    this.setState({ activeFilter: tempArr2 });
  };

  ageApply = () => {
    const { startAge, endAge } = this.state;
    let tempArr2 = [...this.state.activeFilter];

    if (tempArr2.includes(this.state.isStep)) {
      if (!startAge && !endAge) {
        tempArr2.splice(tempArr2.indexOf(this.state.isStep), 1);
      }
    } else {
      if (startAge && endAge) {
        tempArr2 = [...tempArr2, this.state.isStep];
      }
    }

    this.setState({
      filterArr: {
        ...this.state.filterArr,
        age: { startAge, endAge },
      },
    });
    this.setState({ activeFilter: tempArr2 });
  };

  onFilter = (e) => {
    this.setState({
      isSaving: true,
      isDataCompleted: false,
      list: [],
      page: 1,
    });
    e.preventDefault();
    this.getFilterlist(1);
  };

  getFilterlist = (page = this.state.page) => {
    const { getAllFilterMembers } = this?.props?.memberControllerData;
    const {
      clubName,
      memberType,
      age,
      gender,
      industryType,
      designationType,
      eduInstType,
      intrestType,
      compType,
      appUsage,
    } = this?.state?.filterArr;

    const url = `?page=${page}&limit=100&location=${clubName.toString()}&membership=${memberType.toString()}&age=${
      age.startAge
    }${
      age.endAge ? `,${age.endAge}` : ""
    }&gender=${gender.toString()}&industry=${industryType.toString()}&designation=${designationType.toString()}&intrest=${intrestType.toString()}&education=${eduInstType.toString()}&company=${encodeURIComponent(
      compType.toString()
    )}&usage=${appUsage.toString()}`;

    getAllFilterMembers(url, page);
  };

  resetFilter = (e) => {
    e.preventDefault();
    this.setState({
      isStep: 1,
      isSaving: false,
      isDataCompleted: false,
      indSearch: "",
      eduSearch: "",
      compSearch: "",
      startAge: "",
      endAge: "",
      filterArr: {
        clubName: [],
        memberType: [],
        age: { startAge: "", endAge: "" },
        gender: [],
        industryType: [],
        designationType: [],
        eduInstType: [],
        intrestType: [],
        compType: [],
        appUsage: [],
      },
      activeFilter: [],
      list: [],
      page: 1,
      indSelectAll: false,
      compSelectAll: false,
    });
  };

  render() {
    let {
      clubOpt = [],
      memberTypeOpt = [],
      genderTypeOpt = [],
      industryTypeOpt = [],
      designationTypeOpt = [],
      eduInstTypeOpt = [],
      intrestTypeOpt = [],
      compTypeOpt = [],
      isLoader,
      isInit,
      isStep,
      indSearch,
      eduSearch,
      compSearch,
      startAge,
      endAge,
      filterArr,
      activeFilter,
      isSaving,
      indSelectAll,
      compSelectAll,
    } = this.state;

    const appUsageOpt = [
      {
        name: "Dormant",
        _id: 1,
      },
      {
        name: "Passive",
        _id: 2,
      },
      {
        name: "Active",
        _id: 3,
      },
      {
        name: "Super Active",
        _id: 4,
      },
    ];

    if (isInit && isLoader) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column ">
          <div className="d-flex mb-0 align-items-center justify-content-between">
            <div>
              <h3 className="page-title mb-0">
                <Link to="/member-list/member">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Advanced Sorting
              </h3>
            </div>
            <div className="d-flex align-items-center reservation-page">
              {activeFilter?.length > 0 ? (
                <Button
                  className="btn btn-primary btn-primary-green"
                  onClick={this.resetFilter}
                >
                  Reset
                </Button>
              ) : null}
              &nbsp;&nbsp;
              {activeFilter?.length > 0 ? (
                <Button
                  className={`btn btn-primary btn-primary-green ${
                    isSaving ? "btndisabled" : ""
                  }`}
                  onClick={this.onFilter}
                >
                  Download List
                </Button>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent margin-top-15 h-100 ">
            <div className="row">
              <div className="col-3">
                <div className="filter-member-tab">
                  <ul className="list-unstyled" style={{ border: "none" }}>
                    {this.listArr.map((dt, i) => (
                      <li
                        onClick={() =>
                          this.setState({
                            isStep: i + 1,
                            indSearch: "",
                            eduSearch: "",
                            compSearch: "",
                          })
                        }
                        key={i}
                      >
                        <div className="row">
                          <div className="col-9 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === i + 1 ? "600" : "500",
                              }}
                            >
                              {dt}
                            </p>
                          </div>
                          <div className="col-3 text-center step">
                            {activeFilter.includes(i + 1) ? (
                              <i className="fa fa-check"></i>
                            ) : null}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "3px" }}>
                <div className="card h-100">
                  <div className="container-fluid overflow-auto-div1">
                    {isStep === 1 && (
                      <div className="row">
                        <div className="col-8">
                          {clubOpt?.length > 0
                            ? clubOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.clubName}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.clubName.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.clubName.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "clubName")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 2 && (
                      <div className="row">
                        <div className="col-8">
                          {memberTypeOpt?.length > 0
                            ? memberTypeOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.title}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.memberType.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.memberType.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "memberType")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 3 && (
                      <div>
                        <p className="mb-2">Age Range</p>
                        <div className="d-flex align-items-center">
                          <InputField
                            name="startAge"
                            value={startAge}
                            inputChange={(e) =>
                              this.setState({
                                startAge: e.target.value,
                              })
                            }
                            dom={{
                              placeholder: "",
                              type: "number",
                              style: {
                                width: 100,
                                marginBottom: 0,
                                marginRight: 10,
                              },
                            }}
                          />
                          To
                          <InputField
                            name="endAge"
                            value={endAge}
                            inputChange={(e) =>
                              this.setState({
                                endAge: e.target.value,
                              })
                            }
                            dom={{
                              placeholder: "",
                              type: "number",
                              style: {
                                width: 100,
                                marginBottom: 0,
                                marginRight: 40,
                                marginLeft: 10,
                              },
                            }}
                          />
                          <Button
                            disabled={
                              (startAge && !endAge) || (!startAge && endAge)
                            }
                            className={`btn btn-primary btn-primary-green`}
                            onClick={this.ageApply}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    )}
                    {isStep === 4 && (
                      <div className="row">
                        <div className="col-8">
                          {genderTypeOpt?.length > 0
                            ? genderTypeOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.title}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr?.gender?.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr?.gender?.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "gender")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 5 && (
                      <div>
                        <div className="row">
                          <div className="col-4 mb-4">
                            <InputField
                              name="indSearch"
                              value={indSearch}
                              inputChange={(e) =>
                                this.setState({
                                  indSearch: e.target.value,
                                })
                              }
                              dom={{
                                placeholder: "Search..",
                                type: "text",
                              }}
                            />
                          </div>
                          {industryTypeOpt?.length > 0 ? (
                            <div className="col-4 mb-4">
                              <Button
                                className={`btn btn-primary btn-primary-green ${
                                  isSaving ? "btndisabled" : ""
                                }`}
                                onClick={() => {
                                  let tempArr2 = [...this.state.activeFilter];
                                  if (indSelectAll) {
                                    tempArr2.splice(
                                      tempArr2.indexOf(this.state.isStep),
                                      1
                                    );
                                    this.setState({
                                      filterArr: {
                                        ...this.state.filterArr,
                                        industryType: [],
                                      },
                                      indSelectAll: false,
                                      activeFilter: tempArr2,
                                    });
                                  } else {
                                    tempArr2 = [...tempArr2, this.state.isStep];
                                    const arr = industryTypeOpt?.map(
                                      (dt) => dt._id
                                    );
                                    this.setState({
                                      filterArr: {
                                        ...this.state.filterArr,
                                        industryType: arr,
                                      },
                                      indSelectAll: true,
                                      activeFilter: tempArr2,
                                    });
                                  }
                                }}
                              >
                                {indSelectAll ? "Deselect All" : "Select All"}
                              </Button>
                            </div>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-8">
                            {industryTypeOpt?.length > 0
                              ? industryTypeOpt?.map((dt) => (
                                  <div className="row" key={dt?._id}>
                                    <div className="col-8 mb-3">
                                      <p
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {dt?.title}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <InputField
                                        value={filterArr.industryType.includes(
                                          dt?._id
                                        )}
                                        checked={filterArr.industryType.includes(
                                          dt?._id
                                        )}
                                        inputChange={(e) =>
                                          this.inputChange(
                                            e,
                                            dt,
                                            "industryType"
                                          )
                                        }
                                        dom={{
                                          type: "checkbox",
                                          label: "rte",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {isStep === 6 && (
                      <div className="row">
                        <div className="col-8">
                          {designationTypeOpt?.length > 0
                            ? designationTypeOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.title}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.designationType.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.designationType.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(
                                          e,
                                          dt,
                                          "designationType"
                                        )
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 7 && (
                      <div>
                        <div className="row">
                          <div className="col-4 mb-4">
                            <InputField
                              name="eduSearch"
                              value={eduSearch}
                              inputChange={(e) =>
                                this.setState({
                                  eduSearch: e.target.value,
                                })
                              }
                              dom={{
                                placeholder: "Search..",
                                type: "text",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-8">
                            {eduInstTypeOpt?.length > 0
                              ? eduInstTypeOpt?.map((dt) => (
                                  <div className="row" key={dt?._id}>
                                    <div className="col-8 mb-3">
                                      <p
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {dt?.title}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <InputField
                                        value={filterArr.eduInstType.includes(
                                          dt?._id
                                        )}
                                        checked={filterArr.eduInstType.includes(
                                          dt?._id
                                        )}
                                        inputChange={(e) =>
                                          this.inputChange(e, dt, "eduInstType")
                                        }
                                        dom={{
                                          type: "checkbox",
                                          label: "rte",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {isStep === 8 && (
                      <div className="d-flex flex-wrap">
                        {intrestTypeOpt?.length > 0
                          ? intrestTypeOpt?.map((dt, i) => {
                              return (
                                <div
                                  className={`${
                                    filterArr.intrestType.includes(dt?._id)
                                      ? "active"
                                      : ""
                                  } select-tag-interests`}
                                  key={i}
                                  onClick={(e) =>
                                    this.inputChange(e, dt, "intrestType")
                                  }
                                >
                                  {dt.title.toLowerCase()}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                    {isStep === 9 && (
                      <div>
                        <div className="row">
                          <div className="col-4 mb-4">
                            <InputField
                              name="compSearch"
                              value={compSearch}
                              inputChange={(e) =>
                                this.setState({
                                  compSearch: e.target.value,
                                })
                              }
                              dom={{
                                placeholder: "Search..",
                                type: "text",
                              }}
                            />
                          </div>
                          {compTypeOpt?.length > 0 ? (
                            <div className="col-4 mb-4">
                              <Button
                                className={`btn btn-primary btn-primary-green ${
                                  isSaving ? "btndisabled" : ""
                                }`}
                                onClick={() => {
                                  let tempArr2 = [...this.state.activeFilter];
                                  if (compSelectAll) {
                                    tempArr2.splice(
                                      tempArr2.indexOf(this.state.isStep),
                                      1
                                    );
                                    this.setState({
                                      filterArr: {
                                        ...this.state.filterArr,
                                        compType: [],
                                      },
                                      compSelectAll: false,
                                      activeFilter: tempArr2,
                                    });
                                  } else {
                                    tempArr2 = [...tempArr2, this.state.isStep];
                                    const arr = compTypeOpt?.map(
                                      (dt) => dt.company
                                    );
                                    this.setState({
                                      filterArr: {
                                        ...this.state.filterArr,
                                        compType: arr,
                                      },
                                      compSelectAll: true,
                                      activeFilter: tempArr2,
                                    });
                                  }
                                }}
                              >
                                {compSelectAll ? "Deselect All" : "Select All"}
                              </Button>
                            </div>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-8">
                            {compTypeOpt?.length > 0
                              ? compTypeOpt?.map((dt, i2) => (
                                  <div className="row" key={i2}>
                                    <div className="col-8 mb-3">
                                      <p
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {dt?.company}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <InputField
                                        value={filterArr.compType.includes(
                                          dt?.company
                                        )}
                                        checked={filterArr.compType.includes(
                                          dt?.company
                                        )}
                                        inputChange={(e) =>
                                          this.inputChange(e, dt, "compType")
                                        }
                                        dom={{
                                          type: "checkbox",
                                          label: "rte",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {isStep === 10 && (
                      <div className="row">
                        <div className="col-8">
                          {appUsageOpt?.length > 0
                            ? appUsageOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.name}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.appUsage.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.appUsage.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "appUsage")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { authData }, clubs: { clubs } }) {
  return {
    adminType: authData?.roleId?.roleName,
    clubs: clubs,
  };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const designationControllerData = useDesignation();
    const genderControllerData = useGender();
    const industryControllerData = useIndustry();
    const mIntrestControllerData = useMIntrest();
    const mTypeControllerData = useMType();
    const eduInstControllerData = useEduInst();
    const companyControllerData = useCompany();
    const memberControllerData = useMember();

    return (
      <Component
        {...props}
        designationControllerData={designationControllerData}
        genderControllerData={genderControllerData}
        industryControllerData={industryControllerData}
        mIntrestControllerData={mIntrestControllerData}
        mTypeControllerData={mTypeControllerData}
        eduInstControllerData={eduInstControllerData}
        companyControllerData={companyControllerData}
        memberControllerData={memberControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(MemberFilter));
