import React, { Component } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { logout } from "../../actions";
import { HOST_CONFIG } from "../../utils/constants";
import { toLowercase } from "../../utils/formatter";
import { removeCookie } from "./../../services/session";
import { setActiveClub, getClubs } from "./../../actions/clubs";

class Header extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      isOpen: false,
      optionalClubs: [],
      optionalClubsStaff: [],
      activeClub: "",
      staffClub: "",
      isInit: true,
    };
  }

  componentDidMount() {
    this.props.getClubs();

    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      this.setState({
        activeClub: localStorage.getItem("activeClubData")
          ? JSON.parse(localStorage.getItem("activeClubData"))?.[0]?.clubName
          : this?.props?.clubAccess?.[0]?.clubName,
      });
    } else {
      this.setState({
        staffClub: localStorage.getItem("staffClubData")
          ? JSON.parse(localStorage.getItem("staffClubData"))?.[0]?.clubName
          : this?.props?.clubAccess?.[0]?.clubName,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this?.props?.clubAccess?.length !== 0 &&
      this?.state?.optionalClubs?.length === 0 &&
      this.state.isInit
    ) {
      const filteredClubs = this?.props?.clubAccess?.filter((el, i) => {
        if (localStorage.getItem("activeClubData")) {
          return (
            JSON.parse(localStorage.getItem("activeClubData"))?.[0]?._id !==
            el?._id
          );
        } else if (i !== 0) {
          return el;
        }
      });
      this.setState({
        optionalClubs: filteredClubs?.length > 0 ? filteredClubs : [],
        isInit: false,
      });
    }

    if (
      this?.props?.clubAccess?.length !== 0 &&
      this?.state?.optionalClubsStaff?.length === 0 &&
      this.state.isInit
    ) {
      const filteredClubs = this?.props?.clubAccess?.filter((el, i) => {
        if (localStorage.getItem("staffClubData")) {
          return (
            JSON.parse(localStorage.getItem("staffClubData"))?.[0]?._id !==
            el?._id
          );
        } else if (i !== 0) {
          return el;
        }
      });
      this.setState({
        optionalClubsStaff: filteredClubs?.length > 0 ? filteredClubs : [],
        isInit: false,
      });
    }

    if (
      this?.props?.adminType !== "Super Admin" &&
      this?.props?.adminType !== "Admin" &&
      this?.props?.adminType !== "Developer"
    ) {
      if (
        this?.props?.clubAccess?.length !== 0 &&
        this?.state?.staffClub !== ""
      ) {
        this.props.setActiveClub(
          this?.props?.clubAccess,
          this.state.staffClub,
          this?.props?.adminType
        );
      }
    }

    if (
      this?.props?.adminType === "Super Admin" ||
      this?.props?.adminType === "Admin" ||
      this?.props?.adminType === "Developer"
    ) {
      if (
        this?.props?.clubAccess?.length !== 0 &&
        this?.state?.activeClub !== ""
      ) {
        this.props.setActiveClub(
          this.props?.clubAccess,
          this.state.activeClub,
          this?.props?.adminType
        );
      }
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logout({}, (response) => {
      // if (response?.data?.status && response?.data?.status !== "false") {
      localStorage.removeItem("activeClubData");
      localStorage.removeItem("staffClubData");
      localStorage.removeItem("quorum-refresh-token");
      removeCookie("quorum-token");
      removeCookie("userId");
      removeCookie("qpay-token");
      removeCookie("qpay-user-id");
      removeCookie("qpay-role-id");
      removeCookie("qpay-refresh-token");
      removeCookie("qpay-menu");
      window.location.href = "/login";
      // }
    });
  };

  clubChange = (e, el, type) => {
    const { _id } = el;
    const filteredClubs = this?.props?.clubAccess.filter((elem) => {
      if (_id !== elem._id) {
        return elem;
      }
    });
    if (type === "admin") {
      this.setState({ activeClub: el.clubName, optionalClubs: filteredClubs });
    } else {
      this.setState({
        staffClub: el.clubName,
        optionalClubsStaff: filteredClubs,
      });
    }
  };

  render() {
    return (
      <header>
        <ToastContainer />
        <div className="header">
          <div className="row w100p">
            <div className="col">
              <div className="d-flex align-items-center">
                <img width="50px" src={HOST_CONFIG.LOGO} alt="No_data" />
                <p className="logo-txt mb-0">
                  <b>{HOST_CONFIG.HEADER_TEXT}</b>
                </p>
              </div>
            </div>

            <div className="col-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="nav navbar-right mx-2">
                  {this?.props?.clubAccess?.length !== 0 ? (
                    this?.props?.adminType === "Super Admin" ||
                    this?.props?.adminType === "Admin" ||
                    this?.props?.adminType === "Developer" ? (
                      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
                        <DropdownToggle
                          caret
                          tag="p"
                          className="club-txt cursor-pointer mb-0 text-capitalize"
                        >
                          {toLowercase(this.state.activeClub)}
                        </DropdownToggle>
                        <DropdownMenu className="notificationLst" end>
                          {this?.state?.optionalClubs?.map((el, key) => {
                            return (
                              <DropdownItem
                                onClick={(e) => {
                                  this.clubChange(e, el, "admin");
                                }}
                                key={key}
                                className="text-capitalize"
                              >
                                {toLowercase(el.clubName)}
                              </DropdownItem>
                            );
                          })}
                          <DropdownItem onClick={this.logout}>
                            Logout
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
                        <DropdownToggle
                          caret
                          tag="p"
                          className="club-txt cursor-pointer mb-0 text-capitalize"
                        >
                          {toLowercase(this.state.staffClub)}
                        </DropdownToggle>
                        <DropdownMenu className="notificationLst" end>
                          {this?.state?.optionalClubsStaff?.map((el, key) => {
                            return (
                              <DropdownItem
                                onClick={(e) => {
                                  this.clubChange(e, el, "staff");
                                }}
                                key={key}
                                className="text-capitalize"
                              >
                                {toLowercase(el.clubName)}
                              </DropdownItem>
                            );
                          })}
                          <DropdownItem onClick={this.logout}>
                            Logout
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )
                  ) : (
                    // null
                    // in case if user don't have any club access
                    <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
                      <DropdownToggle
                        caret
                        tag="p"
                        className="club-txt cursor-pointer mb-0 text-capitalize"
                      >
                        {/* name */}
                      </DropdownToggle>
                      <DropdownMenu className="notificationLst" end>
                        <DropdownItem onClick={this.logout}>
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps({ auth: { authData } }) {
  return {
    adminType: authData?.roleId?.roleName,
    clubAccess: authData?.clubAccess,
  };
}

const mapDispatchToProps = function(dispatch) {
  return {
    logout: (myParams, cb) => dispatch(logout(myParams, cb)),
    setActiveClub: (clubs, activeClub, role) =>
      dispatch(setActiveClub(clubs, activeClub, role)),
    getClubs: () => dispatch(getClubs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
