/** @format */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import { getCookie, removeCookie } from "../services/session";
import { API_BASE_PATH, Endpoint } from "../utils/constants";
import { historyPushstate } from "../utils/history";
import { fetchSuggestions, updatePageData } from "../actions/page";
import { logout } from "../actions";
import { setActiveClub, getClubs } from "../actions/clubs";
import "../style/header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      isOpen: false,
      clubs: [],
      optionalClubs: [],
      activeClub: "",
      staffClub: "",
    };
  }

  componentDidMount() {
    // this.props.fetchSuggestions({ url: `${Endpoint.CLUB_MANAGE}?token=${this.props.token}` });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clubs.length === 0 && this.props.clubs.length !== 0) {
      this.setState({
        clubs: this.props.clubs,
        activeClub: this.props.clubs[0].clubName,
      });
    }

    if (
      this.state.clubs.length !== 0 &&
      this.state.optionalClubs.length === 0
    ) {
      const filteredClubs = this.state.clubs.filter((el, i) => {
        if (i !== 0) {
          return el;
        }
      });

      this.setState({ optionalClubs: filteredClubs });
    }

    if (
      this.props.adminType === 2 &&
      this.state.clubs.length !== 0 &&
      this.state.staffClub === ""
    ) {
      const staffClubName = this.state.clubs.filter((el) => {
        if (this.props.clubsAccess[0] === el._id) {
          return el;
        }
      });

      this.setState({ staffClub: staffClubName[0].clubName });
    }

    if (
      this.state.clubs.length !== 0 &&
      this.state.activeClub !== "" &&
      (this.props.adminType === 0 || this.props.adminType === 1)
    ) {
      this.props.setActiveClub(this.state.clubs, this.state.activeClub);
    }
    if (
      this.state.clubs.length !== 0 &&
      this.state.activeClub !== "" &&
      this.props.adminType === 2
    ) {
      this.props.setActiveClub(this.state.clubs, this.state.staffClub);
    }

    if (
      prevProps.clubList != this.props.clubList ||
      prevProps.authData.clubId != this.props.authData.clubId
    ) {
      this.setState({ clubName: this.getClubName() });
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  selectClub = (e, item) => {
    e.preventDefault();
    this.props.updatePageData(
      {
        params: { token: this.props.token },
        url: `${Endpoint.CLUB_MANAGE}/${item.id}`,
        method: "GET",
      },
      (response) => {
        if (response.status) {
          window.location.reload();
        } else {
          this.setState({ error: response.message });
        }
      }
    );
  };

  getClubName = () => {
    let { clubList, authData } = this.props;
    if (clubList && clubList.length) {
      let club = clubList.filter((club) => {
        return parseInt(authData.clubId) === parseInt(club.id);
      });
      return club && club.length ? club[0].clubName : "";
    }
    return "";
  };
  logout = (e) => {
    e.preventDefault();
    removeCookie("token");
    removeCookie("userId");
    this.props.logout(
      {
        url: `${Endpoint.LOGOUT}?token=${this.props.token}`,
        token: this.props.token,
      },
      (response) => {
        if (response.response && response.response.status) {
          //this.props.setAuthData({token: ''});
          // removeCookie('token');
          // removeCookie('userId');
          window.location = "/login";
        }
      }
    );
  };

  clubChange = (e, el) => {
    const { _id } = el;
    const filteredClubs = this.state.clubs.filter((elem) => {
      if (_id !== elem._id) {
        return elem;
      }
    });

    this.setState({ activeClub: el.clubName, optionalClubs: filteredClubs });
  };

  render() {
    let { clubList } = this.props;
    return (
      <nav className="">
        <ToastContainer />
        <div style={{ display: "none", minHeight: "60px" }}>
          <div className="fixedheight">
            <div className="_header  ">
              <div className="strip_black d-flex align-items-center">
                <div className="container-fluid">
                  <div className="row no-gutters d-flex align-items-center pr20">
                    <div className="col-6 col">
                      <div className="d-flex align-items-center">
                        <Link className="logo-quorum" to="/"></Link>
                        <div className="logo_txt pr d-flex align-items-center cursor-default">
                          <Link
                            to="/"
                            title="Select Casting Dir"
                            className="logo_txt"
                          >
                            CLUB MANAGEMENT SYSTEM
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      {this.state.clubs.length !== 0 ? (
                        this.props.adminType === 0 ||
                        this.props.adminType === 1 ? (
                          <Dropdown
                            isOpen={this.state.isOpen}
                            toggle={this.toggle}
                          >
                            <DropdownToggle
                              caret
                              tag="p"
                              className="logo_txt cursor-pointer"
                            >
                              {this.state.activeClub}
                            </DropdownToggle>
                            <DropdownMenu
                              className="notificationLst club-list"
                              right
                            >
                              {this.state.optionalClubs.map((el) => {
                                return (
                                  <DropdownItem
                                    tag="li"
                                    onClick={(e) => {
                                      this.clubChange(e, el);
                                    }}
                                  >
                                    Switch to{" "}
                                    {el.clubName.split("(")[1].split(")")[0]}
                                  </DropdownItem>
                                );
                              })}
                              <DropdownItem tag="li" onClick={this.logout}>
                                LOGOUT
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        ) : (
                          <Dropdown
                            isOpen={this.state.isOpen}
                            toggle={this.toggle}
                          >
                            <DropdownToggle
                              caret
                              tag="p"
                              className="logo_txt cursor-pointer"
                            >
                              {this.state.staffClub}
                            </DropdownToggle>
                            <DropdownMenu
                              className="notificationLst club-list"
                              right
                            >
                              <DropdownItem tag="li" onClick={this.logout}>
                                LOGOUT
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
function mapStateToProps({
  auth: { authData, token },
  page: { clubList = [] },
  clubs: { clubs },
}) {
  return {
    authData,
    clubList,
    token,
    adminType: authData.adminType,
    clubsAccess: authData.clubsAccess,
    clubs: clubs,
  };
}

const mapDispatchToProps = function(dispatch) {
  return {
    fetchSuggestions: (myParam) =>
      dispatch(fetchSuggestions(myParam, "clubList")),
    updatePageData: (myParam, cb) => dispatch(updatePageData(myParam, cb)),
    logout: (myParams, cb) => dispatch(logout(myParams, cb)),
    setActiveClub: (clubs, activeClub) =>
      dispatch(setActiveClub(clubs, activeClub)),
    getClubs: () => dispatch(getClubs),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
