import { takeEvery, put, call } from "redux-saga/effects";
import {
  UPDATE_PAGE_DATA,
  GET_PAGE_DATA,
  GET_INDIVIDUAL_DATA,
  FETCH_AUTO_SUGGESTIONS,
  UPLOAD_FILE,
} from "../actions/constants";
import { fetchService } from "../utils/index";
import {
  createComboList,
  groupReservationType,
  replaceUpdateData,
} from "./hooks";
import { S3_BUCKET_CRED } from "../utils/constants";
import { getCookie } from "../services/session";
import AWS from "aws-sdk";

export function* getPageData({ data }) {
  try {
    const response = yield call(fetchService, {
      // params: data.params,
      url: data.url,
      method: "GET",
      headers: {
        Authorization: getCookie("quorum-token", ""),
        "Content-Type": data.isMultipart
          ? "multipart/form-data"
          : "application/json; charset=UTF-8",
      },
    });
    if (response && response.status) {
      if (data && data.cb) {
        data.cb();
      }
      yield put({
        type: `REDUCER/${GET_PAGE_DATA}`,
        data: response.data,
        totalCount: response.totalCount,
      });
    } else {
      if (data && data.cb) {
        data.cb();
      }
      yield put({
        type: `REDUCER/${GET_PAGE_DATA}`,
        data: {
          data: [],
          errorMsg: response.response.message,
        },
      });
    }
  } catch (e) {
    if (data && data.cb) {
      data.cb();
    }
    yield put({
      type: `REDUCER/${GET_PAGE_DATA}`,
      data: {
        errorMsg: e.message,
      },
    });
  }
}

export function* getIndividualData({ data }) {
  try {
    const response = yield call(fetchService, {
      params: data.params,
      url: data.url,
      method: "GET",
      headers: {
        Authorization: getCookie("quorum-token", ""),
        "Content-Type": data.isMultipart
          ? "multipart/form-data"
          : "application/json; charset=UTF-8",
      },
    });
    if (response && response.status) {
      yield put({
        type: `REDUCER/${GET_INDIVIDUAL_DATA}`,
        data: response.data,
      });
    } else {
      yield put({
        type: `REDUCER/${GET_INDIVIDUAL_DATA}`,
        data: {
          errorMsg: response.response.message,
        },
      });
    }
  } catch (e) {
    yield put({
      type: `REDUCER/${GET_INDIVIDUAL_DATA}`,
      data: {
        errorMsg: e.message,
      },
    });
  }
}

function* updatePageData({ data, cb }) {
  try {
    const response = yield call(fetchService, {
      payload: data.payload ? data.payload : {},
      params: data.params ? data.params : {},
      method: data.method,
      url: data.url,
      headers: {
        Authorization: getCookie("quorum-token", ""),
        "Content-Type": data.isMultipart
          ? "multipart/form-data"
          : "application/json; charset=UTF-8",
      },
    });
    cb && cb(response);
    if (response && response.status && data.action && data.page) {
      // let dataToUpdate = data.dataToUpdate || [];
      // response.data = replaceUpdateData(response.data, dataToUpdate);
      yield put({
        type: `REDUCER/${UPDATE_PAGE_DATA}`,
        data: {
          ...response,
          action: data.action,
          page: data.page,
        },
      });
    }
  } catch (e) {
    console.log("SAdsaddasdsadada", e);
    cb && cb({ status: false, errorMsg: e.msg || e });
  }
}

function* uploadFile({ data, cb }) {
  if (data.india) {
    const file = data.payload.image;
    return new Promise((resolve, reject) => {
      var fileName = file.name.split(".").pop();
      AWS.config.update({
        accessKeyId: S3_BUCKET_CRED.BUCKET_ACCESS_KEY,
        secretAccessKey: S3_BUCKET_CRED.BUCKET_SECRET_KEY,
        region: S3_BUCKET_CRED.BUCKET_REGION,
      });
      //console.log("word", this.state.assetName.split(" ").slice(0, 1));
      var s3 = new AWS.S3();
      // var word = this.state.assetName.split(" ").slice(0, 1);
      var params = {
        Bucket: S3_BUCKET_CRED.BUCKET_NAME,
        Key: "asset/" + Date.now() + "." + fileName,
        ContentType: file.type,
        Body: file,
        ACL: "public-read",
      };

      s3.putObject(params, async (err, res) => {
        if (err) {
        } else {
          //console.log(params.Key);
          let link = `https://${S3_BUCKET_CRED.BUCKET_NAME}.s3.amazonaws.com/${params.Key}`;

          try {
            cb(link);
            //   resolve(link);
          } catch (e) {
            cb("error");
            //   reject(e);
          }
        }
      });
    });
  } else {
    // const file = data.payload.image;
    try {
      const response = yield call(fetchService, {
        payload: data.payload ? data.payload : {},
        params: data.params ? data.params : {},
        method: data.method,
        url: data.url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      cb(response);
    } catch (e) {
      cb({ status: false, message: e.message || e });
    }
  }
}

function* fetchAutoSuggestions({ data, namespace }) {
  try {
    let response = yield call(fetchService, {
      ...data,
    });

    if (
      namespace !== "reservationTypeList" &&
      namespace !== "membersList" &&
      namespace !== "continentList" &&
      namespace !== "qsCatList" &&
      namespace !== "countryList"
    ) {
      if (response.response.status) {
        let key = data.key || "id";
        let val = data.value || "name";
        yield put({
          type: `REDUCER/${namespace}/${FETCH_AUTO_SUGGESTIONS}`,
          data:
            data.type == "combo"
              ? createComboList(response.response.data, key, val)
              : response.response.data,
        });
      } else {
        yield put({
          type: `REDUCER/${namespace}/${FETCH_AUTO_SUGGESTIONS}`,
          data: {
            ...{ errorMsg: response.response.message },
          },
        });
      }
    } else {
      if (response.status) {
        yield put({
          type: `REDUCER/${namespace}/${FETCH_AUTO_SUGGESTIONS}`,
          data: response.data,
        });
      } else {
        yield put({
          type: `REDUCER/${namespace}/${FETCH_AUTO_SUGGESTIONS}`,
          data: {
            ...{ errorMsg: response.message },
          },
        });
      }
    }
  } catch (e) {
    yield put({
      type: `REDUCER/${namespace}/${FETCH_AUTO_SUGGESTIONS}`,
      data: {
        ...{ errorMsg: e.message },
      },
    });
  }
}

function* pageWatcher() {
  yield takeEvery(`SAGA/${GET_PAGE_DATA}`, getPageData);
  yield takeEvery(`SAGA/${UPDATE_PAGE_DATA}`, updatePageData);
  yield takeEvery(`SAGA/${GET_INDIVIDUAL_DATA}`, getIndividualData);
  yield takeEvery(
    "countryList/" + FETCH_AUTO_SUGGESTIONS,
    fetchAutoSuggestions
  );
  yield takeEvery(
    "membersList/" + FETCH_AUTO_SUGGESTIONS,
    fetchAutoSuggestions
  );
  yield takeEvery(
    "continentList/" + FETCH_AUTO_SUGGESTIONS,
    fetchAutoSuggestions
  );
  yield takeEvery("cityList/" + FETCH_AUTO_SUGGESTIONS, fetchAutoSuggestions);
  yield takeEvery("qsCatList/" + FETCH_AUTO_SUGGESTIONS, fetchAutoSuggestions);
  yield takeEvery(
    "reservationTypeList/" + FETCH_AUTO_SUGGESTIONS,
    fetchAutoSuggestions
  );
  yield takeEvery("suggesions/" + FETCH_AUTO_SUGGESTIONS, fetchAutoSuggestions);
  yield takeEvery("clubList/" + FETCH_AUTO_SUGGESTIONS, fetchAutoSuggestions);
  yield takeEvery("SAGA/" + UPLOAD_FILE, uploadFile);
}

export default pageWatcher;
