import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import { resetPageStore } from "../actions/page";
import { Button } from "reactstrap";
import useMType from "../controller/member/mType-controller";
import useGender from "../controller/member/gender-controller";
import { getClubs } from "../actions/clubs";
import useAppAnalytics from "../controller/app-analytics/app-analytics-controller";
import { dateToStr, exportToCsv, subDay } from "../utils/formatter";
import moment from "moment";
import { toast } from "react-toastify";

class AnalyticsFilter extends Component {
  constructor(props) {
    super(props);
    this.keymapper = {
      header: [
        "Unique Member Visits in Range",
        "Total Member Visits in Range",
        "Total Section Visits in Range",
        "Total New Members Joining App in Range",
        "Super Active Members in Last 60 Days",
        "Active Members in Last 60 Days",
        "Passive Members in Last 60 Days",
        "Dormant Members in Last 60 Days",
        "Total Check ins Via App in Range",
        "Total Check ins Via CMS in Range",
        "Total iOS Users in App",
        "Total Android Users in App",
        "Total Members Logged in App",
        "Total Members in DB",
        "Total Active Members in DB",
        "Total Inactive Members in DB",
      ],
      keys: [
        "uniqueMemberVisit",
        "totalMemberVisit",
        "totalSection",
        "totalNewJoining",
        "totalSuperActive",
        "totalActive",
        "totalPassive",
        "totalDormant",
        "totalCheckInApp",
        "totalCheckInCms",
        "totalIos",
        "totalAndroid",
        "totalLoggedIn",
        "totalMembersDB",
        "totalActiveInDB",
        "totalInActiveDB",
      ],
    };
    this.state = {
      isSaving: false,
      isLoader: false,
      isInit: true,
      isStep: 1,
      startAge: "",
      endAge: "",
      filterArr: {
        clubName: [],
        memberType: [],
        age: { startAge: "", endAge: "" },
        gender: [],
        appUsage: [],
      },
      activeFilter: [],
      date: {
        startDate: moment(subDay(0))
          .subtract(3, "months")
          .format("YYYY-MM-DD"),
        endDate: subDay(0),
      },
    };
    this.listArr = [
      "Location",
      "Membership",
      "Age Range",
      "Gender",
      "App Usage",
    ];
  }

  componentDidMount() {
    const { getAllMType } = this?.props?.mTypeControllerData;
    const { getAllGender } = this?.props?.genderControllerData;

    this.props.getClubs();
    getAllMType();
    getAllGender();

    if (this?.props?.clubs?.length > 0) {
      const clubOtpData = this?.props?.clubs;
      this.setState({ clubOpt: clubOtpData });
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.mTypeControllerData?.isLoading !==
        this?.props?.mTypeControllerData?.isLoading ||
      prevProps?.genderControllerData?.isLoading !==
        this?.props?.genderControllerData?.isLoading
    ) {
      this.setState({
        isLoader:
          this?.props?.mTypeControllerData?.isLoading ||
          this?.props?.genderControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.mTypeControllerData?.allMTypeData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }

    if (
      prevProps?.mTypeControllerData?.errMsg !==
        this?.props?.mTypeControllerData?.errMsg ||
      prevProps?.genderControllerData?.errMsg !==
        this?.props?.genderControllerData?.errMsg ||
      prevProps?.appStatisticsControllerData?.errMsg !==
        this?.props?.appStatisticsControllerData?.errMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.errMsg ||
        this?.props?.genderControllerData?.errMsg ||
        this?.props?.appStatisticsControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.mTypeControllerData?.errMsg ||
            this?.props?.genderControllerData?.errMsg ||
            this?.props?.appStatisticsControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.mTypeControllerData?.succMsg !==
        this?.props?.mTypeControllerData?.succMsg ||
      prevProps?.genderControllerData?.succMsg !==
        this?.props?.genderControllerData?.succMsg ||
      prevProps?.appStatisticsControllerData?.succMsg !==
        this?.props?.appStatisticsControllerData?.succMsg
    ) {
      if (
        this?.props?.mTypeControllerData?.succMsg ||
        this?.props?.genderControllerData?.succMsg ||
        this?.props?.appStatisticsControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.mTypeControllerData?.succMsg ||
            this?.props?.genderControllerData?.succMsg ||
            this?.props?.appStatisticsControllerData?.succMsg
        );
      }
    }

    if (prevProps?.clubs !== this?.props?.clubs) {
      const clubOtpData = this?.props?.clubs;
      this.setState({ clubOpt: clubOtpData });
    }
    if (
      prevProps?.mTypeControllerData?.allMTypeData !==
      this?.props?.mTypeControllerData?.allMTypeData
    ) {
      const memberTypeData = this?.props?.mTypeControllerData?.allMTypeData;
      this.setState({ memberTypeOpt: memberTypeData });
    }
    if (
      prevProps?.genderControllerData?.allGenderData !==
      this?.props?.genderControllerData?.allGenderData
    ) {
      const genderOtpData = this?.props?.genderControllerData?.allGenderData;
      this.setState({
        genderTypeOpt: genderOtpData,
      });
    }
    if (
      prevProps?.appStatisticsControllerData?.appStats !==
      this?.props?.appStatisticsControllerData?.appStats
    ) {
      this.setState({ isSaving: false });
      exportToCsv(
        [{ ...this?.props?.appStatisticsControllerData?.appStats }],
        "analytics_filter_list_" +
          dateToStr(null, "YYYY-MM-DD") +
          +"_DLon_" +
          moment().format("YYYY-MM-DD_HH.mm"),
        this.keymapper
      );
    }
  }

  inputChange = (e, data, optName) => {
    const tempArr = { ...this.state.filterArr };
    let tempArr2 = [...this.state.activeFilter];
    const ind = tempArr[optName].indexOf(data?._id);

    if (ind !== -1) {
      tempArr[optName].splice(ind, 1);
    } else {
      tempArr[optName] = [...tempArr[optName], data._id];
    }

    if (tempArr2.includes(this.state.isStep)) {
      if (tempArr[optName]?.length === 0) {
        tempArr2.splice(tempArr2.indexOf(this.state.isStep), 1);
      }
    } else {
      if (tempArr[optName]?.length > 0) {
        tempArr2 = [...tempArr2, this.state.isStep];
      }
    }

    this.setState({ filterArr: tempArr });
    this.setState({ activeFilter: tempArr2 });
  };

  ageApply = () => {
    const { startAge, endAge } = this.state;
    let tempArr2 = [...this.state.activeFilter];

    if (tempArr2.includes(this.state.isStep)) {
      if (!startAge && !endAge) {
        tempArr2.splice(tempArr2.indexOf(this.state.isStep), 1);
      }
    } else {
      if (startAge || endAge) {
        tempArr2 = [...tempArr2, this.state.isStep];
      }
    }

    this.setState({
      filterArr: {
        ...this.state.filterArr,
        age: { startAge, endAge },
      },
    });
    this.setState({ activeFilter: tempArr2 });
  };

  onFilter = (e) => {
    e.preventDefault();
    this.setState({ isSaving: true });
    const { getAppStatistics } = this?.props?.appStatisticsControllerData;
    const {
      clubName,
      memberType,
      age,
      gender,
      appUsage,
    } = this?.state?.filterArr;

    const allClubArr =
      this.state.clubOpt.length > 0 ? this.state.clubOpt.map((d) => d._id) : [];

    const url = `?location=${
      clubName.length < 1 ? allClubArr.toString() : clubName.toString()
    }&membership=${memberType.toString()}&age=${age.startAge}${
      age.endAge ? `,${age.endAge}` : age.startAge ? `,${age.startAge}` : ""
    }&gender=${gender.toString()}&usage=${appUsage.toString()}`;

    getAppStatistics(url);
  };

  resetFilter = (e) => {
    e.preventDefault();
    this.setState({
      isStep: 1,
      isSaving: false,
      startAge: "",
      endAge: "",
      filterArr: {
        clubName: [],
        memberType: [],
        age: { startAge: "", endAge: "" },
        gender: [],
        appUsage: [],
      },
      activeFilter: [],
    });
  };

  render() {
    let {
      clubOpt = [],
      memberTypeOpt = [],
      genderTypeOpt = [],
      isLoader,
      isInit,
      isStep,
      startAge,
      endAge,
      filterArr,
      activeFilter,
      isSaving,
      date,
    } = this.state;

    const appUsageOpt = [
      {
        name: "Dormant",
        _id: 1,
      },
      {
        name: "Passive",
        _id: 2,
      },
      {
        name: "Active",
        _id: 3,
      },
      {
        name: "Super Active",
        _id: 4,
      },
    ];

    if (isInit && isLoader) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column ">
          <div className="d-flex mb-0 align-items-center justify-content-between">
            <div>
              <h3 className="page-title mb-0">
                <Link to="/analytics/app-analytics">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Analytics Filter
              </h3>
            </div>
            <div className="d-flex align-items-center reservation-page">
              <div className="col-auto pe-0" style={{ width: "130px" }}>
                <InputField
                  name="startDate"
                  groupClass="mbi-0 text-center"
                  value={date["startDate"]}
                  dom={{
                    placeholder: "Start date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    disabled: true,
                  }}
                />
              </div>
              &nbsp;&nbsp;
              <div className="col-auto pe-0" style={{ width: "130px" }}>
                <InputField
                  name="endDate"
                  groupClass="mbi-0"
                  value={date["endDate"]}
                  dom={{
                    placeholder: "End date",
                    type: "date",
                    dateFormat: "dd/MM/yyyy",
                    disabled: true,
                  }}
                />
              </div>
              &nbsp;&nbsp;
              {activeFilter?.length > 0 ? (
                <Button
                  className="btn btn-primary btn-primary-green"
                  onClick={this.resetFilter}
                >
                  Reset
                </Button>
              ) : null}
              &nbsp;&nbsp;
              {activeFilter?.length > 0 ? (
                <Button
                  className={`btn btn-primary btn-primary-green ${
                    isSaving ? "btndisabled" : ""
                  }`}
                  onClick={this.onFilter}
                >
                  Download List
                </Button>
              ) : null}
            </div>
          </div>
          <div className="card bg-transferent h-100 ">
            <div className="row">
              <div className="col-3">
                <div className="filter-member-tab">
                  <ul className="list-unstyled" style={{ border: "none" }}>
                    {this.listArr.map((dt, i) => (
                      <li
                        onClick={() =>
                          this.setState({
                            isStep: i + 1,
                          })
                        }
                        key={i}
                      >
                        <div className="row">
                          <div className="col-9 cursor-pointer">
                            <p
                              style={{
                                fontWeight: isStep === i + 1 ? "600" : "500",
                              }}
                            >
                              {dt}
                            </p>
                          </div>
                          <div className="col-3 text-center step">
                            {activeFilter.includes(i + 1) ? (
                              <i className="fa fa-check"></i>
                            ) : null}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "3px" }}>
                <div className="card h-100">
                  <div className="container-fluid overflow-auto-div1">
                    {isStep === 1 && (
                      <div className="row">
                        <div className="col-8">
                          {clubOpt?.length > 0
                            ? clubOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.clubName}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.clubName.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.clubName.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "clubName")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 2 && (
                      <div className="row">
                        <div className="col-8">
                          {memberTypeOpt?.length > 0
                            ? memberTypeOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.title}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.memberType.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.memberType.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "memberType")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 3 && (
                      <div>
                        <p className="mb-2">Age Range</p>
                        <div className="d-flex align-items-center">
                          <InputField
                            name="startAge"
                            value={startAge}
                            inputChange={(e) =>
                              this.setState({
                                startAge: e.target.value,
                              })
                            }
                            dom={{
                              placeholder: "",
                              type: "number",
                              style: {
                                width: 100,
                                marginBottom: 0,
                                marginRight: 10,
                              },
                            }}
                          />
                          To
                          <InputField
                            name="endAge"
                            value={endAge}
                            inputChange={(e) =>
                              this.setState({
                                endAge: e.target.value,
                              })
                            }
                            dom={{
                              placeholder: "",
                              type: "number",
                              style: {
                                width: 100,
                                marginBottom: 0,
                                marginRight: 40,
                                marginLeft: 10,
                              },
                            }}
                          />
                          <Button
                            className={`btn btn-primary btn-primary-green`}
                            onClick={this.ageApply}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    )}
                    {isStep === 4 && (
                      <div className="row">
                        <div className="col-8">
                          {genderTypeOpt?.length > 0
                            ? genderTypeOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.title}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr?.gender?.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr?.gender?.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "gender")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                    {isStep === 5 && (
                      <div className="row">
                        <div className="col-8">
                          {appUsageOpt?.length > 0
                            ? appUsageOpt?.map((dt) => (
                                <div className="row" key={dt?._id}>
                                  <div className="col-8 mb-3">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {dt?.name}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <InputField
                                      value={filterArr.appUsage.includes(
                                        dt?._id
                                      )}
                                      checked={filterArr.appUsage.includes(
                                        dt?._id
                                      )}
                                      inputChange={(e) =>
                                        this.inputChange(e, dt, "appUsage")
                                      }
                                      dom={{
                                        type: "checkbox",
                                        label: "rte",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ clubs: { clubs } }) {
  return {
    clubs: clubs,
  };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const genderControllerData = useGender();
    const mTypeControllerData = useMType();
    const appStatisticsControllerData = useAppAnalytics();

    return (
      <Component
        {...props}
        genderControllerData={genderControllerData}
        mTypeControllerData={mTypeControllerData}
        appStatisticsControllerData={appStatisticsControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(AnalyticsFilter));
