import React from "react";
import { Spinner } from "reactstrap";

const Loader = ({ message }) => {
  return (
    <div className="loaderCustom">
      <div className="Fc">
        <Spinner size={"sm"} style={{ zIndex: 9999 }} />
      </div>
    </div>
  );
};

export default Loader;
