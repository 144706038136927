/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import InputField from "../components/customFields/inputField";
import { getValue } from "../utils/formatter";
import { validate } from "../utils/validation";
import { Endpoint } from "../utils/constants";
import moment from "moment";
import { getClubs } from "../actions/clubs";
import { Link } from "react-router-dom";
import useCheckin from "../controller/checkin/checkin-controller";
import SearchInput from "../components/customFields/search-input";
import { toast } from "react-toastify";

class InviteGuests extends Component {
  constructor(props) {
    super(props);
    this.state = this.intialiseState();
    //isSaving: false;
  }

  intialiseState = (data = {}) => {
    return {
      memberData: getValue(data, "memberId", {}),
      memberId: data?.memberId?._id,
      invitationDate: getValue(data, "invitationDate", null),
      invitationTime: getValue(data, "invitationTime", null),
      invitationType: getValue(data, "invitationType", ""),
      guests: getValue(data, "guestArr", this.guest()),
      errorMessages: {},
      isValid: {},
      guestErr: {},
      clubid: getValue(data, "clubid", ""),
    };
  };

  componentDidMount() {
    this.props.getClubs();
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ id });
      // this.props.getIndividualItem({
      //   params: { token: this.props.token },
      //   url: `${Endpoint.CHECKIN_GUEST}/${id}`,
      // });
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({
        clubid: this?.props?.activeClub?.[0]?._id,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.checkinControllerData?.succMsg !==
      this?.props?.checkinControllerData?.succMsg
    ) {
      if (this?.props?.checkinControllerData?.succMsg) {
        this.setState({ error: "" });
        // this.props.toastmessage(response.message);
        this.props.history.push("/guest-check-in");
        toast.success(this?.props?.checkinControllerData?.succMsg);
      }
    }

    if (
      prevProps?.checkinControllerData?.errMsg !==
      this?.props?.checkinControllerData?.errMsg
    ) {
      if (this?.props?.checkinControllerData?.errMsg) {
        this.setState({ error: this?.props?.checkinControllerData?.errMsg });
        toast.error(this?.props?.checkinControllerData?.errMsg);
      }
    }

    if (
      prevProps !== this.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({
        clubid: this?.props?.activeClub?.[0]?._id,
      });
    }
  }

  guest = (n = 1) => {
    let r = [];
    while (r.length < n) {
      r = [...r, { name: "", mobile: "", email: "" }];
    }
    return r;
  };

  validate = (name, value) => {
    let { isValid, errorMessages } = this.state;
    let error = validate(name, value, { type: "text" });
    isValid[name] = error.isValid;
    error.isValid
      ? delete errorMessages[name]
      : (errorMessages[name] = error.errorMsg);
    this.setState({ isValid, errorMessages });
  };

  inputChange = (e, id) => {
    let {
      target: { name, value, checked, type },
    } = e;
    if (id || id === 0) {
      let { guestErr, guests } = this.state;
      if (name === "withGuest") {
        if (value < 0 || value > 4) {
          return;
        }
      } else {
        let error = validate(name, value, {
          type: "text",
          regexType: ["email", "mobile"].indexOf(name) > -1 ? name : "noop",
        });
        guestErr[id] = guestErr[id] ? guestErr[id] : {};
        error.isValid
          ? delete guestErr[id][name]
          : (guestErr[id][name] = error.errorMsg);
      }

      if (
        (guestErr[id].mobile && !guestErr[id].email && guests[id].email) ||
        (!guestErr[id].mobile && guestErr[id].email && guests[id].mobile)
      ) {
        delete guestErr[id].mobile;
        delete guestErr[id].email;
      }

      guests[id][name] = value;

      this.setState({ guestErr, guests });
    } else {
      this.setState({ [name]: type === "checkbox" ? checked : value }, () => {
        this.validate(name, value);
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { addGuestCheckin } = this?.props?.checkinControllerData;
    let { guestErr, guests } = this.state;
    var isError = false;

    ["memberId", "invitationDate", "invitationTime", "invitationType"].forEach(
      (name) => {
        this.validate(name, this.state[name]);
      }
    );
    guests.forEach((g, id) => {
      ["name", "mobile", "email"].forEach((name) => {
        let value = g[name];
        let error = validate(name, value, {
          type: "text",
          regexType: ["email", "mobile"].indexOf(name) > -1 ? name : "noop",
        });
        guestErr[id] = guestErr[id] ? guestErr[id] : {};
        if (error.isValid) {
          delete guestErr[id][name];
        } else {
          guestErr[id][name] = error.errorMsg;
          isError = true;
        }
      });

      if (
        (guestErr[id].mobile && !guestErr[id].email) ||
        (!guestErr[id].mobile && guestErr[id].email)
      ) {
        delete guestErr[id].mobile;
        delete guestErr[id].email;

        if (Object.keys(guestErr[id]).length === 0) isError = false;
      }
    });
    this.setState({ error: "", guestErr });

    if (Object.keys(this.state.errorMessages).length === 0 && !isError) {
      let payload = Object.assign({}, this.state);
      payload.invitedBy = this.state?.memberData?._id;
      payload.invitationTime = moment(payload.invitationTime, "hh:mm a").format(
        "hh:mm a"
      );
      payload.invitationDate = moment(payload.invitationDate).format(
        "DD/MM/YYYY"
      );
      payload.guestArr = guests.map((dt) => ({
        guestName: dt.name,
        guestEmail: dt.email,
        guestMobile: dt.mobile,
      }));
      delete payload.errorMessages;
      delete payload.isValid;
      delete payload.error;
      delete payload.guestErr;
      delete payload.memberData;
      delete payload.memberId;
      delete payload.guests;

      addGuestCheckin(payload);
    }
    // this.setState({ isSaving: true });
  };

  addGuest = (e, n) => {
    e.preventDefault();
    let l = this.state.guests.length;
    if (n === l) {
      return;
    }
    if (n < l) {
      this.setState({
        guests: this.state.guests.filter((g, i) => {
          return i + 1 <= n;
        }),
      });
    } else {
      this.setState({
        guests: [...this.state.guests, ...this.guest(n - l)],
      });
    }
  };

  renderGusetCount = () => {
    let r = [];
    for (let i = 0; i < 10; i++) {
      r[i] = (
        <span
          key={i}
          className={`${
            this.state.guests.length === i + 1 ? "active" : ""
          } btn`}
          onClick={(e) => this.addGuest(e, i + 1)}
        >
          {i + 1}
        </span>
      );
    }
    return r;
  };

  geGuestOptions = () => {
    let r = {};
    for (let i = 0; i < 10; i++) {
      r[i] = i + 1;
    }
    return r;
  };

  render() {
    let {
      memberData,
      invitationTime,
      invitationDate,
      invitationType,
      guests,
      isValid,
      errorMessages,
      guestErr,
      isSaving,
    } = this.state;
    const { permissions = {} } = this?.props;

    return (
      <React.Fragment>
        <div className="h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="d-flex mb-0 align-items-center  justify-content-between">
            <div>
              <h3 className="page-title py-3 mb-0">
                <Link to="/guest-check-in">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Invite A Guest For A Member
              </h3>
            </div>
            <div
              className="row align-items-center"
              style={{ paddingRight: "15px" }}
            >
              {permissions?.create && (
                <div className="col-auto">
                  {memberData?._id && (
                    <button
                      className={`btn btn-primary btn-primary-green ${
                        isSaving ? "btndisabled" : ""
                      }`}
                      onClick={this.onSubmit}
                    >
                      Send Invite To Guest
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row h-100">
            <div className="col-3" style={{ width: "330px" }}>
              <div className="card mt-2">
                <div className=" h-100">
                  <div className="container-fluid mt-3">
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label">Select Member</label>
                        <SearchInput
                          endpoint={`searchMember/`}
                          searchKey="searchKeyword"
                          accessKey="members"
                          name="memberData"
                          inputClassName="form_control"
                          inputProps={{
                            autoFocus: true,
                          }}
                          placeholder="Search by First Name, Last Name or Member ID"
                          onChange={(value, onChangeSearch) => {
                            onChangeSearch(`${value?.fName} ${value?.lName}`);
                            this.inputChange({
                              target: { name: "memberData", value: value },
                            });
                            this.inputChange({
                              target: { name: "memberId", value: value?._id },
                            });
                          }}
                          renderItem={(item, i) => {
                            return (
                              <React.Fragment key={"auto_" + i}>
                                <div
                                  className="image-style"
                                  style={{
                                    backgroundImage: `url(${item?.photo})`,
                                  }}
                                >
                                  <img
                                    src={"/images/imagesize2_3.png"}
                                    alt={item?.fName}
                                    className="w100per"
                                  />
                                </div>

                                <div className="d-flex justify-content-between flex-grow-1">
                                  <span
                                    className="user-name"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item?.fName + " " + item?.lName}
                                  </span>
                                  {item?.mNo && (
                                    <span className="user-name1">
                                      {item?.mNo}
                                    </span>
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                      {memberData?._id && (
                        <>
                          <div className="col-12">
                            <label className="form-label">
                              <p className="txt-member pr15">
                                {`${memberData?.fName} ${memberData?.mName} ${memberData?.lName}`}
                              </p>
                              <p className="fs14 member-no">
                                M No. - {memberData?.mNo}
                              </p>
                            </label>
                          </div>
                          <div className="col-12">
                            <InputField
                              name="invitationDate"
                              label="Date"
                              value={invitationDate}
                              isValid={isValid.invitationDate}
                              errorMsg={errorMessages.invitationDate}
                              inputChange={this.inputChange}
                              dom={{
                                placeholder: "",
                                type: "date",
                                minDate: new Date(),
                                dateFormat: "dd/MM/yyyy",
                              }}
                            />
                          </div>
                          <div className="col-12 mt-2">
                            <InputField
                              name="invitationTime"
                              label="Time"
                              groupClass="date-input"
                              value={invitationTime}
                              isValid={isValid.invitationTime}
                              errorMsg={errorMessages.invitationTime}
                              inputChange={this.inputChange}
                              dom={{ placeholder: "", type: "time" }}
                            />
                          </div>
                          <div className="col-12 mt-2">
                            <InputField
                              name="invitationType"
                              label="Nature Of Invite"
                              value={invitationType}
                              options={{
                                event: "Event",
                                reservation: "Reservation",
                                checkin: "Checkin",
                              }}
                              isValid={isValid.invitationType}
                              errorMsg={errorMessages.invitationType}
                              inputChange={this.inputChange}
                              dom={{ placeholder: "Select", type: "select" }}
                            />
                          </div>
                          <div className="col-12">
                            <InputField
                              name="guests"
                              label="Number Of Guests"
                              inputChange={(e) => {
                                let chooseNumber = parseInt(e.target.value);
                                this.addGuest(e, chooseNumber + 1);
                              }}
                              options={this.geGuestOptions()}
                              dom={{ placeholder: "", type: "select" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-9 h-100"
              style={{ width: "calc(100% - 330px)" }}
            >
              {memberData?._id && (
                <div className="numberOfGuestSection">
                  {guests.map((guest, i) => {
                    let err = guestErr[i] ? guestErr[i] : {};
                    return (
                      <div
                        className="card pt-3 pl-3 pr-3 mt-2 mb-3"
                        style={{ width: "400px" }}
                        key={i}
                      >
                        <div className="row">
                          <div className="col-3 green-color guset-num">
                            {i + 1}.
                          </div>
                          <div className="col-9">
                            <div className="row">
                              <div className="col-12">
                                <div className="pr15">
                                  <InputField
                                    name="name"
                                    groupClass="member-detail"
                                    value={guest.name}
                                    isValid={err.name ? false : true}
                                    errorMsg={err.name}
                                    inputChange={(e) => this.inputChange(e, i)}
                                    dom={{ placeholder: "Name" }}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="pr15">
                                  <InputField
                                    name="mobile"
                                    groupClass="member-detail numberArrow"
                                    value={guest.mobile}
                                    isValid={err.mobile ? false : true}
                                    errorMsg={err.mobile}
                                    inputChange={(e) => this.inputChange(e, i)}
                                    dom={{
                                      placeholder: "Phone No.",
                                      type: "number",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 email">
                                <div className="pr15">
                                  <InputField
                                    name="email"
                                    groupClass="member-detail"
                                    value={guest.email}
                                    isValid={err.email ? false : true}
                                    errorMsg={err.email}
                                    inputChange={(e) => this.inputChange(e, i)}
                                    dom={{
                                      placeholder: "Email",
                                      type: "email",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ clubs: { activeClub } }) {
  return { activeClub };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const checkinControllerData = useCheckin();

    return (
      <Component {...props} checkinControllerData={checkinControllerData} />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {
  getClubs,
})(injectComp(InviteGuests));
