import React, { Component } from "react";
import { connect } from "react-redux";
import {
  API_BASE_PATH_NEW,
  Endpoint,
  MERITAL_STATUS,
  M_ALTER,
  USERMODEL,
} from "../utils/constants";
import { resetPageStore } from "../actions/page";
import { getClubs } from "../actions/clubs";
import { dateToStr } from "../utils/formatter";
import Checkin from "../components/home/checkin";
import Reservation from "../components/home/reservation";
import { getCookie } from "../services/session";
import "../style/newStyle.scss";
import InputField from "../components/customFields/inputField";
import { CSVLink } from "react-csv";
import { lastDateOperation } from "../utils/formatter";

class Home extends Component {
  csvButtonRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      time: dateToStr(null, "HH:mm"),
      section: "reservation",
      exporting: false,
      csvReport: {
        data: [],
        headers: [],
        filename: "member_export.csv",
        csvReport: [],
      },
      activeClub: "",
    };
  }

  componentDidMount() {
    this.props.getClubs();
    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    this.interval = setInterval(() => {
      this.setState({ time: dateToStr(null, "HH:mm") });
    }, 1000);
  }

  componentWillUnmount() {
    this.props.resetPageStore("pageData");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this?.props &&
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  setSection = (section) => {
    this.setState({ section });
  };

  getUserData = (page = 1) => {
    this.setState({ exporting: true });
    const request = new XMLHttpRequest();
    const url = `${API_BASE_PATH_NEW}${Endpoint.MEMBERS}${this?.props?.activeClub?.[0]?._id}?page=${page}&limit=200`;

    request.open("GET", url);
    request.setRequestHeader("Authorization", getCookie("quorum-token"));
    request.send("");
    request.onload = async () => {
      const res = request.response;
      const jsonRes = JSON.parse(res);
      const memborArray = jsonRes.members;

      const getTime = (timestamp) => {
        if (timestamp === 0) {
          return "Not Seen Yet";
        }
        const date = new Date(timestamp * 1000);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        return `${hours}:${minutes}`;
      };

      const memberdata = await memborArray.map((_) => {
        let tempObject = {
          status: _.accountStatus,
          mNo: _.mNo.toString(),
          // startDate: _.startDate,
          // endDate: _.endDate,
          // prefix: _.title.toString(),
          firstName: _.fName.toString(),
          middleName: _.mName.toString(),
          lastName: _.lName.toString(),
          // phoneNo: _.phoneNo,
          // email: _.email,
          // dob: _.dob,
          // address: _.address,
          // endDate: _.endDate.toString(),
          // prefix: _.title,
          // company: _.company,
          // industry: _.industry,
          // jobTitle: _.jobTitle,
          // acType: M_ALTER[_.acType],
          // acFName: _.acFName,
          // acLName: _.acLName,
          // acPhoneNo: _.acPhoneNo,
          // acEmail: _.acEmail,
          // founderName1: _.founderName1,
          // founderName2: _.founderName2,
          // fFilm: _.fFilm,
          // fDrink: _.fDrink,
          // industry: _.industry,
          // jobTitle: _.jobTitle,
          // fspice: _.fHerb,
          // fitnessActivity: _.fitnessActivity,
          // msType: MERITAL_STATUS[_.msType],
          // msFName: _.msFName,
          // msLName: _.msLName,
          // msEmail: _.msEmail,
          // msPhoneNo: _.msPhoneNo,
          // msDOB: _.msDOB,
          // msAnniversary: _.msAnniversary,
          // travel: "",
          // Ffood: "",
          // wine: "",
          // literature: "",
          // art: "",
          // design: "",
          // theater: "",
          // music: "",
          // cinema: "",
          // spirits_beverages: "",
          // sports: "",
          // cocktails: "",
          // enviorenment: "",
          // technology: "",
          // new_economy: "",
          // politics: "",
          // current_affairs: "",
          // wellness: "",
          // fashion: "",
          // cooking: "",
          // yoga: "",
          // meditation: "",
          // workshops: "",
          // skill_development: "",
          // entrepreneurship: "",
          // reading: "",
          // finance: "",
          // fitness: "",
          // "Child 1 Name": "",
          // "Child 1 DOB": "",
          // "Child 2 Name": "",
          // "Child 2 DOB": "",
          // "Child 3 Name": "",
          // "Child 3 DOB": "",
          // "Child 4 Name": "",
          // "Child 4 DOB": "",
          // "check ins": 0,
          // "guest invites": 0,
          // reservations: 0,
          // events: 0,
          // specials: 0,
          // reciprocals: 0,
          // "q connect": 0,
          // videos: 0,
          // "private hire": 0,
          // "edit profile": 0,
          // "all static pages": 0,
          club: this?.props?.activeClub?.[0]?.clubName,
          // "last seen":
          //   _.lastSeen !== 0
          //     ? `${lastDateOperation(_.lastSeen)}-${getTime(_.lastSeen)}`
          //     : "Not Seen Yet",
        };

        // if (Array.isArray(_.hobby)) {
        //   _.hobby.map((hb) => {
        //     tempObject[hb] = 1;
        //   });
        // } else {
        //   tempObject = {
        //     ...tempObject,
        //     ..._.hobby,
        //   };
        // }

        // if (Array.isArray(_.analyticsdata)) {
        //   _.analyticsdata.map((data) => {
        //     if (data._id === 0) {
        //       tempObject["q connect"] = data.count;
        //     } else if (data._id === 1) {
        //       tempObject["check ins"] = data.count;
        //     } else if (data._id === 2) {
        //       tempObject["guest invites"] = data.count;
        //     } else if (data._id === 3) {
        //       tempObject["reservations"] = data.count;
        //     } else if (data._id === 4) {
        //       tempObject["events"] = data.count;
        //     } else if (data._id === 5) {
        //       tempObject["specials"] = data.count;
        //     } else if (data._id === 6) {
        //       tempObject["reciprocals"] = data.count;
        //     } else if (data._id === 9) {
        //       tempObject["videos"] = data.count;
        //     } else if (data._id === 10) {
        //       tempObject["private hire"] = data.count;
        //     } else if (data._id === 11) {
        //       tempObject["edit profile"] = data.count;
        //     } else if (data._id === 12) {
        //       tempObject["all static pages"] = data.count;
        //     }
        //   });
        // }
        // _.childrens.map((childrens, index) => {
        //   tempObject[`Child ${index + 1} Name`] = childrens.name;
        //   tempObject[`Child ${index + 1} DOB`] = childrens.dob;
        // });

        return tempObject;
      });

      const objReport = {
        filename: "Quorum All Members Details.csv",
        headers: USERMODEL,
        data: [...this.state.csvReport.data, ...memberdata],
      };

      this.setState(
        (prevState) => {
          return {
            ...prevState,
            csvReport: objReport,
          };
        },
        () => {
          if (memborArray.length !== 0) {
            this.getUserData(page + 1);
          } else {
            setTimeout(() => {
              this.csvButtonRef.current &&
                this.csvButtonRef.current.link.click();
            }, 500);
          }
        }
      );
    };
  };

  render() {
    const { section } = this.state;
    const { permissions = {} } = this.props;

    return (
      <React.Fragment>
        <div className="h-100 d-flex flex-column  overflow-hidden">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">Dashboard</h3>
            </div>
            <div className="d-flex col align-items-center">
              <div className="d-flex  align-items-center flex-grow-1">
                <div className="mx-2 flex-grow-1 d-flex align-items-center justify-content-start">
                  <button
                    className={`btn btn-primary ${
                      section === "reservation" ? "active" : ""
                    }`}
                    onClick={() => {
                      this.setSection("reservation");
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    Reservations
                  </button>
                  <button
                    className={`btn btn-primary ${
                      section === "checkin" ? "active" : ""
                    }`}
                    onClick={() => {
                      this.setSection("checkin");
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    Check In
                  </button>
                  <button className="btn btn-primary">
                    <a
                      href={"https://api.whatsapp.com/send?phone=+917703908030"}
                      target="_blank"
                    >
                      whatsApp
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card margin-top-15 margin-bottom-15 h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-auto">
              <div className="container-fluid">
                {section === "checkin" && (
                  <Checkin
                    activeClub={
                      this.state.activeClub !== "" ? this.state.activeClub : ""
                    }
                    permissions={permissions}
                  />
                )}
                {section === "reservation" && (
                  <Reservation
                    clubID={
                      this.state.activeClub !== "" ? this.state.activeClub : ""
                    }
                    permissions={permissions}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between date-time-block p10">
            <p className="current_time">{this.state.time}</p>
            <div className="text-right">
              <p className="current_time">
                {dateToStr(null, "dddd")},&nbsp;{dateToStr(null, "MMMM Do[]")}
                ,&nbsp;{dateToStr(null, "yyyy")}
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth: { authData }, clubs: { activeClub } }) {
  return {
    adminType: authData?.roleId?.roleName,
    activeClub: activeClub,
  };
}

export default connect(mapStateToProps, {
  resetPageStore,
  getClubs,
})(Home);
