import { debounce } from "debounce";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/axios-new";
import { getCookie } from "../../services/session";
import InputField from "./inputField";

export async function get(endPoint, config = {}) {
  const token = getCookie("quorum-token");
  token && (axiosInstance.defaults.headers["authorization"] = token);
  return axiosInstance.get(endPoint, config);
}

const SearchInput = ({
  endpoint,
  searchKey,
  accessKey = "",
  params = {},
  placeholder,
  className = "",
  option,
  name,
  onInputChange,
  onChange,
  renderItem,
  inputClassName = "",
  optClassName = "",
  clear,
  PreIcon,
  itemClassName = "",
  onBlur,
  defaultValue,
  inputProps = {},
  p,
}) => {
  const [search, onChangeSearch] = useState(defaultValue || undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [options, setOptions] = useState(option || []);
  const searchref = React.createRef();
  const [isOpen, toggle] = useState(false);
  const [value, setValue] = useState();
  const [loader, setLoader] = useState(false);

  const getSearchItem = () => {
    return <div></div>;
  };

  const onIChange = useCallback(
    debounce((search) => {
      search && endpoint && setLoader(true);

      search &&
        endpoint &&
        searching(endpoint, {
          params: {
            [searchKey]: search,
            ...params,
          },
        })
          .then((data) => {
            setLoader(false);
            if (data?.data?.status && data?.data?.status !== "false") {
              try {
                const ops = data?.data[accessKey];
                setOptions(ops);
              } catch (e) {}
            }
          })
          .catch((e) => {
            setLoader(false);
          });
    }, 200),
    [endpoint]
  );

  useEffect(() => {
    setSelectedIndex(-1);
  }, [isOpen]);

  useEffect(() => {
    if (typeof value != "undefined") {
      onChange && onChange(value, onChangeSearch);
    }
  }, [value]);

  useEffect(() => {
    setOptions(option);
  }, [option]);
  useEffect(() => {
    (typeof search != "undefined") & isOpen &&
      onIChange &&
      onIChange(search || "");
  }, [search]);
  useEffect(() => {
    const el = document?.getElementsByClassName(
      `focus-${name}-${selectedIndex}`
    )?.[0];
    if (el) {
      scrollIntoView(searchref?.current, el);
    }
  }, [selectedIndex]);

  useEffect(() => {
    // if (defaultValue) {
    onChangeSearch(defaultValue);
    // }
  }, [defaultValue]);

  const searching = async (EndPoint, options) => {
    return await get(EndPoint, options);
  };

  const scrollTo = (el, top) => {
    el.scrollTop = top;
  };

  const scrollIntoView = (menuEl, focusedEl) => {
    const menuRect = menuEl.getBoundingClientRect();
    const focusedRect = focusedEl.getBoundingClientRect();
    const overScroll = focusedEl.offsetHeight / 3;

    if (focusedRect.bottom + overScroll > menuRect.bottom) {
      scrollTo(
        menuEl,
        Math.min(
          focusedEl.offsetTop +
            focusedEl.clientHeight -
            menuEl.offsetHeight +
            overScroll,
          menuEl.scrollHeight
        )
      );
    } else if (focusedRect.top - overScroll < menuRect.top) {
      scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
    }
  };

  const onKeyDown = (e) => {
    const focusedIndex = selectedIndex || 0;
    const keyCode = e.keyCode;
    let nextFocus = 0;

    if (keyCode === 9) {
      toggle(false);
    } else if (keyCode === 38) {
      nextFocus = focusedIndex > 0 ? focusedIndex - 1 : search?.length - 1;
      e.preventDefault();
      e.stopPropagation();
      setSelectedIndex(nextFocus);
    } else if (keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();
      nextFocus = options.length - 1 > focusedIndex ? focusedIndex + 1 : 0;
      setSelectedIndex(nextFocus);
    } else if (keyCode === 13) {
      if (
        selectedIndex != -1 &&
        search &&
        typeof options[selectedIndex] != "undefined"
      ) {
        toggle(false);
        setValue(options[selectedIndex]);
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [searchref]);

  const handleClickOutside = (event) => {
    if (searchref.current && !searchref.current.contains(event.target)) {
      toggle(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className="checkIn-search placeholder-small"
    >
      <div className="chips">
        <div className="search-prefix">
          {/* {PreIcon ? PreIcon() : <Megnifire></Megnifire>} */}
        </div>
        <InputField
          className={className}
          dom={{
            placeholder: placeholder || "",
            className: inputClassName || "search-input",
            value: search,
            ...inputProps,
            onChange: (e) => {
              const value = e.target.value;
              toggle(true);
              onInputChange && onInputChange(value);
              onChangeSearch(value);
            },
          }}
        ></InputField>
        {loader && (
          <div className="search-postfix cursor-pointer">
            {/* <Spinner className="text-primary"></Spinner> */}
          </div>
        )}
        {clear && (
          <div
            className="search-postfix cursor-pointer"
            onClick={(e) => {
              onChangeSearch("");
              onInputChange("");
            }}
          ></div>
        )}

        {isOpen && search && options && options?.length !== 0 && (
          <div
            className={`auto-suggest ${optClassName} ${
              options.length > 0 ? "show" : "hide"
            }`}
          >
            <div ref={searchref} className={`ul`}>
              {options?.length == 0 ? (
                <div className="font-semibold no-result text-sm text-center my-3">
                  No Results Found
                </div>
              ) : (
                options?.map((option, index) => {
                  return (
                    <div
                      tabIndex={index}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setValue(option);
                        toggle(false);
                      }}
                      className={`li search-${name} ${selectedIndex === index &&
                        `select-focus focus-${name}-${index}`}`}
                      data-item={JSON.stringify(option)}
                    >
                      {renderItem ? renderItem(option) : getSearchItem()}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchInput;
