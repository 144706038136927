/** @format */

import { Link } from "react-router-dom";
import ModalButton from "../modal/button";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getClubs } from "../../actions/clubs";

class StaticDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClub: "",
    };
  }

  componentDidMount() {
    this.props.getClubs();

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this.props &&
      prevProps.activeClub !== null &&
      prevProps.activeClub.length !== 0 &&
      this.props.activeClub !== null &&
      this.props.activeClub.length !== 0
    ) {
      this.setState({ activeClub: this.props.activeClub[0]._id });
    }
  }

  render() {
    const { permissions = {} } = this.props;

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title pt-1 pb-2 mb-0">
                <Link to="/admin-access">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Edit Static Pages
              </h3>
            </div>
          </div>
          <div className="card bg-transferent h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              <div className="">
                <div className="row">
                  {permissions?.create || permissions?.update ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <ModalButton
                        className="w-100 text-center pad-10"
                        data={{
                          showCustomModal: true,
                          title: "Club Timings",
                          path: "staticPages/staticPageEditor",
                          className: "modal-large",
                          body: "",
                          pageName: "timing",
                          activeClub: `${
                            this.state.activeClub !== ""
                              ? this.state.activeClub
                              : null
                          }`,
                        }}
                      >
                        <div className="w-100 text-center">
                          <i className="fa fa-clock-o"></i>
                        </div>
                        <div>
                          <p style={{ width: "100%" }}>Club Timings</p>
                        </div>
                      </ModalButton>
                    </div>
                  ) : null}
                  {permissions?.create || permissions?.update ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <ModalButton
                        className="w-100 text-center pad-10"
                        data={{
                          showCustomModal: true,
                          title: "Club Rules",
                          path: "staticPages/staticPageEditor",
                          body: "",
                          className: "modal-large",
                          pageName: "rules",
                          activeClub: `${
                            this.state.activeClub !== ""
                              ? this.state.activeClub
                              : null
                          }`,
                        }}
                      >
                        <div className="w-100 text-center">
                          <i className="fa fa-file-text"></i>
                        </div>
                        <div>
                          <p style={{ width: "100%" }}>Club Rules</p>
                        </div>
                      </ModalButton>
                    </div>
                  ) : null}
                  {permissions?.create || permissions?.update ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <ModalButton
                        className="w-100 text-center pad-10"
                        data={{
                          showCustomModal: true,
                          title: "Guest Policy",
                          path: "staticPages/staticPageEditor",
                          className: "modal-large",
                          body: "",
                          pageName: "policy",
                          activeClub: `${
                            this.state.activeClub !== ""
                              ? this.state.activeClub
                              : null
                          }`,
                        }}
                      >
                        <div className="w-100 text-center">
                          <i className="fa fa-shield" aria-hidden="true"></i>
                        </div>
                        <div>
                          <p style={{ width: "100%" }}>Guest Policy</p>
                        </div>
                      </ModalButton>
                    </div>
                  ) : null}
                  {/* <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                    <Link
                      to="/static-pages/the-q-traveller"
                      className="w-100 text-center"
                    >
                      <div className="w-100 text-center">
                        <i className="fa fa-suitcase"></i>
                      </div>
                      <div>
                        <p style={{ width: "100%" }}>The Q Traveller</p>
                      </div>
                    </Link>
                  </div> */}
                  {permissions?.view ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <div className="w-100 text-center pad-10">
                        <Link to="/static-pages/how-to-q" className="">
                          <div className="w-100 text-center">
                            <i className="fa fa-files-o"></i>
                          </div>
                          <div>
                            <p style={{ width: "100%" }}>Noticeboard</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {permissions?.view ? (
                    <div className="col-2 ps-0 pe-0 pt-3 pb-3 dashboard-box">
                      <div className="w-100 text-center pad-10">
                        <Link to="/static-pages/feedback" className="">
                          <div className="w-100 text-center">
                            <i className="fa fa-volume-up"></i>
                          </div>
                          <div>
                            <p style={{ width: "100%" }}>View Feedback</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeClub: state.clubs.activeClub,
});

export default connect(mapStateToProps, { getClubs })(StaticDashboard);
