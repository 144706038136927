import cookie from "js-cookie";

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      // expires: 1,
      path: "/",
    });
  } else {
  }
};

export const removeCookie = (key) => {
  cookie.remove(key);
};

export const getCookie = (key, req) => {
  let token = cookie.get(key);
  return token;
};

export const getCookiees = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers || !req.headers.cookie) {
    return null;
  }
  const rawCookie = req.headers.cookie
    .split(";")
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return null;
  }
  return rawCookie.split("=")[1];
};

export const forceLogout = () => {
  localStorage.clear();
  removeCookie("quorum-token");
  removeCookie("userId");
  removeCookie("qpay-token");
  removeCookie("qpay-user-id");
  removeCookie("qpay-role-id");
  removeCookie("qpay-refresh-token");
  removeCookie("qpay-menu");
  window.location.href = "/login";
  window.location.reload(true);
};
