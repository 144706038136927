import { GET_QPAY_MENU, GET_QPAY_ROLES, Q_PAY_LIST_DATA, Q_PAY_UPDATE_DATA, Q_PAY_LISTS_DATA } from './constants';

export function getQPayMenu(data = {}) {
    return {
        type: `SAGA/${GET_QPAY_MENU}`,
        data
    };
}

export function getQPayRoles(cb) {
    return {
        type: `SAGA/${GET_QPAY_ROLES}`,
        cb
    };
}

export function updateQpayData(data, cb) {
    return {
        type: `SAGA/${Q_PAY_UPDATE_DATA}`,
        data,
        cb
    };
}

export function listQpayData(data, cb) {
    return {
        type: `SAGA/${Q_PAY_LIST_DATA}`,
        data,
        cb
    };
}

export function listsQpayData(data, cb) {
    return {
        type: `SAGA/${Q_PAY_LISTS_DATA}`,
        data,
        cb
    };
}
