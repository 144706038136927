/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import { resetPageStore } from "../actions/page";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import { getClubs } from "../actions/clubs";
import useReservation from "../controller/reservation/reservation-controller";
import useSpaces from "../controller/spaces/spaces-controller";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Spaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoader: false,
      activeClub: "",
      category: {},
    };
  }

  componentDidMount() {
    const { getAllResCategory } = this?.props?.reservationControllerData;
    this.props.getClubs();
    getAllResCategory();

    if (this?.props?.activeClub?.[0]?._id) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
      this.getList();
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore(["pageData", "suggesions"]);
  }

  getList = () => {
    if (this?.props?.activeClub?.[0]?._id) {
      const { getAllReservationSpaces } = this?.props?.spacesControllerData;
      getAllReservationSpaces(this?.props?.activeClub?.[0]?._id);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.spacesControllerData?.allResSpaces !==
      this?.props?.spacesControllerData?.allResSpaces
    ) {
      this.setState({
        list: this?.props?.spacesControllerData?.allResSpaces,
      });
    }

    if (
      prevProps?.spacesControllerData?.isLoading !==
      this?.props?.spacesControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.spacesControllerData?.isLoading,
      });
    }

    if (
      prevProps?.spacesControllerData?.errMsg !==
        this?.props?.spacesControllerData?.errMsg ||
      prevProps?.reservationControllerData?.errMsg !==
        this?.props?.reservationControllerData?.errMsg
    ) {
      if (
        this?.props?.spacesControllerData?.errMsg ||
        this?.props?.reservationControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.spacesControllerData?.errMsg ||
            this?.props?.reservationControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.spacesControllerData?.succMsg !==
        this?.props?.spacesControllerData?.succMsg ||
      prevProps?.reservationControllerData?.succMsg !==
        this?.props?.reservationControllerData?.succMsg
    ) {
      if (
        this?.props?.spacesControllerData?.succMsg ||
        this?.props?.reservationControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.spacesControllerData?.succMsg ||
            this?.props?.reservationControllerData?.succMsg
        );
      }
    }

    if (
      prevProps?.reservationControllerData?.allResCat !==
      this?.props?.reservationControllerData?.allResCat
    ) {
      const arrData = this?.props?.reservationControllerData?.allResCat
        ?.filter((d) => d.isActive)
        ?.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value._id]: value.title,
          };
        }, {});

      this.setState({
        category: arrData,
      });
    }

    if (
      this?.state?.activeClub === "" &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    if (
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0 &&
      prevProps?.activeClub?.[0]?._id !== this?.props?.activeClub?.[0]?._id
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      this.getList();
    }
  }

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      return this.props.history.push("/spaces-settings");
    }
  };

  render() {
    let { list, isLoader, activeClub, category } = this.state;
    let { authData = {} } = this.props;

    const getCard = (item) => {
      const temp =
        item && item?.privateHire
          ? item?.images
            ? item?.images
            : ""
          : item?.spaceIcon
          ? item?.spaceIcon
          : "";
      const img = temp?.replace(
        temp?.substring(temp?.indexOf("(") - 1, temp?.indexOf(")") + 1),
        ""
      );
      const imagestyle = {
        backgroundImage: `url(${img})`,
      };

      return (
        <div className="card news-card event-card p-0 h-100 ">
          <div className=" card-title row  ps-2 pe-2 pt-2">
            <div className="col-12">
              <div className="default-font page-title-box">{item.name}</div>
            </div>
          </div>
          <div className="card-body p-0">
            {!item.privateHire ? (
              <div className="absolute-top-right card-absolute-header news-header">
                <ModalButton
                  className="cursor-pointer"
                  data={{
                    id: item._id,
                    itemData: item,
                    showCustomModal: true,
                    title: "Edit Space",
                    getList: this.getList,
                    activeClub: activeClub,
                    categoryData: category,
                    path: "spaces/addNewSpace",
                    body: "",
                    className: "modal-extra-large",
                  }}
                >
                  <i className="fa fa-edit" />
                </ModalButton>
              </div>
            ) : null}
            <div className="image-style" style={imagestyle}>
              <img
                src="./images/imagesize16_9.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
            <div className="card-btns d-flex justify-content-center"></div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">
                <Link to="/reservations">
                  <i className="fa fa-arrow-left cursor-pointer"></i>
                </Link>
                &nbsp;Spaces
              </h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              {authData?.roleId?.roleName === "Developer" ? (
                <div className="col-auto pe-0" onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp; &nbsp;
              <ModalButton
                className="btn btn-primary btn-primary-green"
                data={{
                  showCustomModal: true,
                  title: "Add New Space",
                  getList: this.getList,
                  activeClub: activeClub,
                  categoryData: category,
                  path: "spaces/addNewSpace",
                  body: "",
                  className: "modal-extra-large",
                }}
              >
                Add Space
              </ModalButton>
            </div>
          </div>
          <div className="card p-0 bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {!isLoader && list.length > 0 && (
                  <div className="row pe-3">
                    {list.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-3 mb-3"
                          style={{ width: "20%", paddingRight: "3px" }}
                        >
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { authData = {} }, clubs: { activeClub } }) {
  return {
    activeClub,
    authData,
  };
}
const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const reservationControllerData = useReservation();
    const spacesControllerData = useSpaces();
    return (
      <Component
        {...props}
        reservationControllerData={reservationControllerData}
        spacesControllerData={spacesControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, mapDispatchToProps)(injectComp(Spaces));
