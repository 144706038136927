import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalButton from "../components/modal/button";
import InputField from "../components/customFields/inputField";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import useEvents from "../controller/events/events-controller";
import { toast } from "react-toastify";

class EventsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCategoriesData: [],
      isLoader: false,
      isUpdate: false,
      isInit: true,
    };
  }

  componentDidMount() {
    this.handleGetAllCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.eventsControllerData?.allCategoriesData !==
      this?.props?.eventsControllerData?.allCategoriesData
    ) {
      this.setState({
        allCategoriesData: this?.props?.eventsControllerData?.allCategoriesData,
      });
    }
    if (
      prevProps?.eventsControllerData?.isLoading !==
      this?.props?.eventsControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.eventsControllerData?.isLoading,
      });

      if (
        this.state.isInit &&
        prevProps?.eventsControllerData?.allCategoriesData?.length !== 0
      ) {
        this.setState({ isInit: false });
      }
    }
    if (
      prevProps?.eventsControllerData?.isUpdating !==
      this?.props?.eventsControllerData?.isUpdating
    ) {
      this.setState({
        isUpdate: this?.props?.eventsControllerData?.isUpdating,
      });
    }
    if (
      prevProps?.eventsControllerData?.succMsg !==
      this?.props?.eventsControllerData?.succMsg
    ) {
      if (this?.props?.eventsControllerData?.succMsg) {
        this.setState({
          error: "",
          isSaving: false,
        });
        this.handleGetAllCategories();
        toast.success(this?.props?.eventsControllerData?.succMsg);
      }
    }
    if (
      prevProps?.eventsControllerData?.errMsg !==
      this?.props?.eventsControllerData?.errMsg
    ) {
      if (this?.props?.eventsControllerData?.errMsg) {
        this.setState({
          error: this?.props?.eventsControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.eventsControllerData?.errMsg);
      }
    }
  }

  handleGetAllCategories = () => {
    const { getAllCategories } = this?.props?.eventsControllerData;
    getAllCategories();
  };

  handleSwitchChange = (data) => {
    const { updateCategory } = this?.props?.eventsControllerData;
    updateCategory({ _id: data?._id, isActive: !data?.isActive });
  };

  render() {
    let { allCategoriesData, isLoader, isUpdate, isInit } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper h-100 w-100">
          <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
            <div className="d-flex pb-3 mb-0 align-items-center  justify-content-between">
              <div>
                <h3 className="page-title mb-0">
                  <Link to="/events">
                    <i className="fa fa-arrow-left cursor-pointer"></i>
                  </Link>
                  &nbsp;Event Settings
                </h3>
              </div>
            </div>
            <div className="card roles-card h-100 overflow-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="row mb-3">
                      <h4 className="col-8">Event Categories</h4>
                      <div className="col-4">
                        <ModalButton
                          data={{
                            handleGetAllCategories: this.handleGetAllCategories,
                            showCustomModal: true,
                            title: "Add Category",
                            path: "EventsSetting/addCategory",
                            body: "",
                            className: "modal-small",
                          }}
                        >
                          <h3
                            style={{
                              marginBottom: 0,
                              color: "#1c6765",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: 20 }}
                            ></i>
                          </h3>
                        </ModalButton>
                      </div>
                    </div>
                    {isInit && isLoader && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loader />
                      </div>
                    )}
                    {!isLoader && allCategoriesData?.length === 0 && (
                      <div className="d-flex h-100 justify-content-center align-items-center">
                        <NoRecord />
                      </div>
                    )}
                    {allCategoriesData && allCategoriesData?.length > 0
                      ? allCategoriesData?.map((cat, i) => (
                          <div
                            className="row cursor-pointer"
                            key={i}
                            onClick={() =>
                              this.setState({ activeCont: cat?._id })
                            }
                          >
                            <div className="col-8 mb-3">
                              <p style={{ textTransform: "capitalize" }}>
                                {cat?.title}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="d-flex align-items-center">
                                <ModalButton
                                  data={{
                                    itemData: cat,
                                    handleGetAllCategories: this
                                      .handleGetAllCategories,
                                    showCustomModal: true,
                                    title: "Edit Category",
                                    path: "EventsSetting/addCategory",
                                    body: "",
                                    className: "modal-small",
                                  }}
                                  disabled={isUpdate}
                                >
                                  <h3
                                    style={{
                                      marginRight: 10,
                                      marginBottom: 0,
                                      cursor: isUpdate ? "default" : "pointer",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ fontSize: 20 }}
                                    ></i>
                                  </h3>
                                </ModalButton>
                                <InputField
                                  value={cat.isActive}
                                  checked={cat.isActive}
                                  inputChange={() =>
                                    isUpdate ? {} : this.handleSwitchChange(cat)
                                  }
                                  dom={{
                                    type: "checkbox",
                                    ele: "switch",
                                    label: "rte",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ page: { isLoader = false } }) {
  return { isLoader };
}

function injectComp(Component) {
  const InjectedComp = function(props) {
    const eventsControllerData = useEvents();
    return <Component {...props} eventsControllerData={eventsControllerData} />;
  };
  return InjectedComp;
}

export default connect(mapStateToProps, {})(injectComp(EventsSettings));
