/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ModalButton from "../components/modal/button";
import { resetPageStore } from "../actions/page";
import Loader from "../components/customFields/loader";
import NoRecord from "../components/customFields/noRecord";
import moment from "moment";
import { getClubs } from "../actions/clubs";
import useEvents from "../controller/events/events-controller";
import useReservation from "../controller/reservation/reservation-controller";
import { toast } from "react-toastify";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoader: false,
      venues: [],
      category: [],
      activeClub: "",
    };
  }

  componentDidMount() {
    const { getAllCategories } = this?.props?.eventsControllerData;
    const { getAllPHSpaces } = this?.props?.reservationControllerData;

    this.props.getClubs();
    getAllCategories();

    // this.props.fetchSuggestions({ url: `${Endpoint.EVENT_CAT}?token=${this.props.token}` });
    if (this?.props?.activeClub?.[0]?._id) {
      getAllPHSpaces(this?.props?.activeClub?.[0]?._id);
      this.getList();
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore(["pageData", "suggesions"]);
  }

  getList = () => {
    if (this?.props?.activeClub?.[0]?._id) {
      const { getAllEvents } = this?.props?.eventsControllerData;
      getAllEvents(this?.props?.activeClub?.[0]?._id);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.eventsControllerData?.allEventsData !==
      this?.props?.eventsControllerData?.allEventsData
    ) {
      this.setState({
        list: this?.props?.eventsControllerData?.allEventsData,
      });
    }

    if (
      prevProps?.eventsControllerData?.allCategoriesData !==
      this?.props?.eventsControllerData?.allCategoriesData
    ) {
      this.setState({
        category: this?.props?.eventsControllerData?.allCategoriesData,
      });
    }

    if (
      prevProps?.reservationControllerData?.allSpaces !==
      this?.props?.reservationControllerData?.allSpaces
    ) {
      const arrData = this?.props?.reservationControllerData?.allSpaces;
      this.setState({
        venues: arrData,
      });
    }

    if (
      prevProps?.eventsControllerData?.isLoading !==
      this?.props?.eventsControllerData?.isLoading
    ) {
      this.setState({
        isLoader: this?.props?.eventsControllerData?.isLoading,
      });
    }

    if (
      prevProps?.reservationControllerData?.errMsg !==
        this?.props?.reservationControllerData?.errMsg ||
      prevProps?.eventsControllerData?.errMsg !==
        this?.props?.eventsControllerData?.errMsg
    ) {
      if (
        this?.props?.reservationControllerData?.errMsg ||
        this?.props?.eventsControllerData?.errMsg
      ) {
        toast.error(
          this?.props?.reservationControllerData?.errMsg ||
            this?.props?.eventsControllerData?.errMsg
        );
      }
    }

    if (
      prevProps?.reservationControllerData?.succMsg !==
        this?.props?.reservationControllerData?.succMsg ||
      prevProps?.eventsControllerData?.succMsg !==
        this?.props?.eventsControllerData?.succMsg
    ) {
      if (
        this?.props?.reservationControllerData?.succMsg ||
        this?.props?.eventsControllerData?.succMsg
      ) {
        toast.success(
          this?.props?.reservationControllerData?.succMsg ||
            this?.props?.eventsControllerData?.succMsg
        );
      }
    }

    if (
      this?.state?.activeClub === "" &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }

    if (
      prevProps?.activeClub !== null &&
      prevProps?.activeClub?.length !== 0 &&
      this?.props?.activeClub !== null &&
      this?.props?.activeClub?.length !== 0 &&
      prevProps?.activeClub?.[0]?._id !== this?.props?.activeClub?.[0]?._id
    ) {
      this.setState({ activeClub: this?.props?.activeClub?.[0]?._id });
    }
    if (
      this.state.activeClub !== "" &&
      prevState.activeClub !== this.state.activeClub
    ) {
      const { getAllPHSpaces } = this?.props?.reservationControllerData;
      this.getList();
      getAllPHSpaces(this?.props?.activeClub?.[0]?._id);
    }
  }

  checkAdmin = () => {
    if (this?.props?.authData?.roleId?.roleName === "Developer") {
      return this.props.history.push("/events-settings");
    }
  };

  render() {
    let { list, category, venues, isLoader, activeClub } = this.state;
    let { authData = {}, permissions = {} } = this.props;

    const getCard = (item, i) => {
      const imagestyle = {
        backgroundImage: `url(${
          item && item.image && typeof item.image != "undefined"
            ? item.image
            : ""
        })`,
      };
      return (
        <div className="card news-card event-card p-0 h-100 ">
          <div className=" card-title row  ps-2 pe-2 pt-2">
            <div className="col-12">
              <div className="default-font page-title-box">{item.name}</div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="absolute-top-right  card-absolute-header  news-header">
              <div className="d-flex align-items-center">
                {permissions?.update || permissions?.delete ? (
                  <div className="mx-3">
                    <ModalButton
                      className="cursor-pointer"
                      data={{
                        showCustomModal: true,
                        id: item._id,
                        category,
                        venues,
                        title: "Edit Event",
                        itmeData: item,
                        getList: this.getList,
                        activeClub: activeClub,
                        permissions: permissions,
                        path: "event/addnewEvent",
                        body: "",
                        className: "modal-large",
                      }}
                    >
                      <i className="fa fa-edit" />
                    </ModalButton>
                  </div>
                ) : null}
                <div className="mb-1">
                  <ModalButton
                    className="cursor-pointer"
                    data={{
                      showCustomModal: true,
                      title: "List Of Attendees",
                      path: "event/attendeesList",
                      body: "",
                      itemData: item,
                      id: item._id,
                      className: "modal-large",
                    }}
                  >
                    <i
                      className="fa fa-users"
                      title="List of Attendees"
                      style={{ fontSize: "13px" }}
                    />
                  </ModalButton>
                </div>
              </div>
            </div>
            <div className="image-style" style={imagestyle}>
              <img
                src="./images/imagesize16_9.png"
                className="img-fluid w100p"
                alt="No image found"
                width="100%"
              />
            </div>
            <div className="card-btns d-flex justify-content-center"></div>
          </div>
          <div className="card-footer border-top-none p-2">
            <div className="row">
              <div className="col-6 pe-0">
                <div className="d-flex  justify-content-between">
                  <p className="card-text">
                    {moment.unix(item.dateTime).format("ddd DD MMM YYYY")}
                    <br />
                    {moment.unix(item.dateTime, "HH:mm").format("HH:mm")}
                  </p>
                </div>
              </div>
              <div className="col-6 ps-0">
                <div className="d-flex  justify-content-between">
                  <p className="card-text capitalize-text">
                    {item?.categoryId?.title}
                    <br />
                    {item?.venueId?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="event-list h-100 w-100 d-flex flex-column overflow-hidden">
          <div className="row pb-3 align-items-center justify-content-between">
            <div className="col-auto">
              <h3 className="page-title mb-0">Events</h3>
            </div>
            <div className="d-flex col-auto align-items-center">
              {authData?.roleId?.roleName === "Developer" ? (
                <div onClick={this.checkAdmin}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#1c6765",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-gear"></i>
                  </h3>
                </div>
              ) : null}
              &nbsp;
              {permissions?.create ? (
                <ModalButton
                  className="btn btn-primary btn-primary-green"
                  data={{
                    showCustomModal: true,
                    title: "Add New Event",
                    category,
                    venues,
                    permissions: permissions,
                    activeClub: activeClub,
                    getList: this.getList,
                    path: "event/addnewEvent",
                    body: "",
                    className: "modal-large",
                  }}
                >
                  Add Event
                </ModalButton>
              ) : null}
            </div>
          </div>
          <div className="card p-0 bg-transferent  h-100 overflow-hidden">
            <div className=" h-100 overflow-x-hidden overflow-y-auto">
              {isLoader && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <Loader />
                </div>
              )}
              {!isLoader && list.length === 0 && (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <NoRecord />
                </div>
              )}
              <div className="">
                {!isLoader && list.length > 0 && (
                  <div className="row pe-3">
                    {list.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-3 mb-3"
                          style={{ width: "20%", paddingRight: "3px" }}
                        >
                          {getCard(item, i)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="  border-top-0 pt-3">
							{" "}
							{!isLoader && list.length !== 0 && (
								<Pagination
									paging={{
										count: totalCount,
										rows: 10,
										page: params.page || 0,
									}}
									onPageClick={onPageClick}
								/>
							)}
						</div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ auth: { authData = {} }, clubs: { activeClub } }) {
  return {
    activeClub,
    authData,
  };
}
const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const eventsControllerData = useEvents();
    const reservationControllerData = useReservation();
    return (
      <Component
        {...props}
        eventsControllerData={eventsControllerData}
        reservationControllerData={reservationControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(mapStateToProps, mapDispatchToProps)(injectComp(Events));
