/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import InputField from "../customFields/inputField";
import Loader from "../customFields/loader";
import { getValue, dateToStr } from "../../utils/formatter";
import { validate } from "../../utils/validation";
import { isObject, isObjEmpty } from "../../utils";
import { resetPageStore } from "../../actions/page";
import { Endpoint } from "../../utils/constants";
import "../../style/newStyle.scss";
import moment from "moment";
import { Button } from "reactstrap";
import useReservation from "../../controller/reservation/reservation-controller";
import { getClubs } from "../../actions/clubs";
import SearchInput from "../customFields/search-input";
import { toast } from "react-toastify";

class BookReservation extends Component {
  constructor(props) {
    super(props);
    this.state = this.intialiseState();
  }

  intialiseState = (data = {}, isinit = true) => {
    return {
      memberId: data?.memberId?._id,
      memberName:
        data?.memberId && Object.keys(data?.memberId).length > 0
          ? `${data?.memberId?.fName} ${data?.memberId?.mName} ${data?.memberId?.lName}`
          : "",
      startDateTime: data?.startDateTime
        ? moment.unix(data?.startDateTime, "hh:mm a").format("HH:mm")
        : "",
      endDateTime: data?.endDateTime
        ? moment.unix(data?.endDateTime, "hh:mm a").format("HH:mm")
        : "",
      date: dateToStr(this?.props?.date, "YYYY-MM-DD"),
      guest: getValue(data, "noGuest", ""),
      errorMessages: {},
      isValid: {},
      singleData: {},
      isinit,
    };
  };

  componentDidMount() {
    this.props.getClubs();
    this.setState({ singleData: this?.props?.singleData });
    if (this?.props?.singleData && this?.props?.singleData?._id) {
      this.setState({
        memberId: this?.props?.singleData?.memberId?._id,
        memberName:
          this?.props?.singleData?.memberId &&
          Object.keys(this?.props?.singleData?.memberId).length > 0
            ? `${this?.props?.singleData?.memberId?.fName} ${this?.props?.singleData?.memberId?.mName} ${this?.props?.singleData?.memberId?.lName}`
            : "",
        startDateTime: moment
          .unix(this?.props?.singleData?.startDateTime, "hh:mm a")
          .format("HH:mm"),
        endDateTime: moment
          .unix(this?.props?.singleData?.endDateTime, "hh:mm a")
          .format("HH:mm"),
        date: dateToStr(this?.props?.date, "YYYY-MM-DD"),
        guest: this?.props?.singleData?.noGuest,
      });
    }

    if (this?.props?.activeClub?.length !== 0) {
      this.setState({
        clubid: this?.props?.activeClub?.[0]?._id,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.singleData !== this.props.singleData &&
      prevState.isinit &&
      isObject(this.props.singleData) &&
      !isObjEmpty(this.props.singleData)
    ) {
      let stateD = this.intialiseState(this?.props?.singleData, false);
      this.setState({ ...stateD });
    }

    if (
      prevState?.singleData?._id !== this.props?.singleData?._id &&
      !this?.state?.isinit
    ) {
      this.setState({ isinit: true });
    }

    if (
      prevProps !== this.props &&
      prevProps.activeClub !== null &&
      prevProps.activeClub.length !== 0 &&
      this.props.activeClub !== null &&
      this.props.activeClub.length !== 0
    ) {
      this.setState({
        clubid: this.props.activeClub[0]._id,
      });
    }

    if (
      this.state.clubid &&
      prevState.clubid &&
      this.state.clubid !== "" &&
      prevState.clubid !== "" &&
      prevState.clubid !== this.state.clubid
    ) {
      this.props.goBack();
    }

    if (
      prevProps?.reservationControllerData?.succMsg !==
      this?.props?.reservationControllerData?.succMsg
    ) {
      if (this?.props?.reservationControllerData?.succMsg) {
        this.setState({ error: "", isSaving: false });
        this.props.goBack();
        toast.success(this?.props?.reservationControllerData?.succMsg);
      }
    }

    if (
      prevProps?.reservationControllerData?.errMsg !==
      this?.props?.reservationControllerData?.errMsg
    ) {
      if (this?.props?.reservationControllerData?.errMsg) {
        this.setState({
          error: this?.props?.reservationControllerData?.errMsg,
          isSaving: false,
        });
        toast.error(this?.props?.reservationControllerData?.errMsg);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPageStore("individualItem");
  }

  validate = (name, value) => {
    let { isValid, errorMessages } = this.state;
    let error = validate(name, value, {
      type: "text",
      errorMsg: name === "memberId" ? "No member selected" : "",
    });
    isValid[name] = error.isValid;
    error.isValid
      ? delete errorMessages[name]
      : (errorMessages[name] = error.errorMsg);
    this.setState({ isValid, errorMessages });
  };

  inputChange = (e) => {
    let {
      target: { name, value, checked, type },
    } = e;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  compareStartEndTime = () => {
    let { startDateTime, endDateTime, error } = this.state;
    if (!endDateTime) {
      return true;
    } else {
      let st = new Date("1970-01-01 " + startDateTime).getTime();
      let et = new Date("1970-01-01 " + endDateTime).getTime();
      if (st > et) {
        error = "Start time must be less than end time";
      } else {
        error = "";
      }
      this.setState({ error });
      return error ? false : true;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      updateReservation,
      addReservation,
    } = this?.props?.reservationControllerData;
    ["memberId", "startDateTime", "date"].forEach((name) => {
      this.validate(name, this.state[name]);
    });
    let isvalid = this.compareStartEndTime();

    if (Object.keys(this.state.errorMessages).length === 0 && isvalid) {
      this.setState({
        error: "",
        isSaving: true,
      });
      const {
        memberId,
        startDateTime,
        endDateTime,
        date,
        guest,
        clubid,
      } = this.state;

      const { selectedRoom } = this.props;

      let payload = {
        startDateTime: moment(`${date} ${startDateTime}`).unix(),
        endDateTime: moment(`${date} ${endDateTime}`).unix(),
        memberId: memberId,
        spaceId: selectedRoom?._id,
        privateSpaceId: selectedRoom?._id,
        clubId: clubid,
        noGuest: guest ? guest : 0,
      };

      selectedRoom?.privateHire && delete payload.spaceId;
      !selectedRoom?.privateHire && delete payload.privateSpaceId;
      !endDateTime && delete payload.endDateTime;

      if (this.props.singleData && this?.props?.singleData?._id) {
        payload._id = this.props.singleData._id;
        updateReservation(payload);
      } else {
        addReservation(payload);
      }
    }
  };

  setChips = (value, name, item = {}) => {
    this.setState({ [name]: item._id, [name + "_name"]: value });
    let { isValid, errorMessages } = this.state;
    isValid[name] = value.length ? true : false;
    value.length
      ? delete errorMessages[name]
      : (errorMessages[name] = "This field is required.");
    this.setState({ errorMessages, isValid });
  };

  render() {
    const {
      startDateTime,
      endDateTime,
      date,
      guest,
      errorMessages,
      isValid,
      error,
      isSaving,
      memberName,
    } = this.state;
    const { loadingSelected } = this.props;

    return (
      <div className="w-100">
        {loadingSelected && <Loader />}
        {!loadingSelected && (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <InputField
                  name="date"
                  label="Date"
                  value={date}
                  isValid={isValid.date}
                  errorMsg={errorMessages.date}
                  inputChange={this.inputChange}
                  dom={{
                    placeholder: "dd/mm/yyyy",
                    type: "date",
                    minDate: new Date(),
                    dateFormat: "dd/MM/yyyy",
                  }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="startDateTime"
                  label="Start Time"
                  groupClass="mbi-0"
                  value={startDateTime}
                  isValid={isValid.startDateTime}
                  errorMsg={errorMessages.startDateTime}
                  inputChange={this.inputChange}
                  dom={{ placeholder: "hh:mm", type: "time" }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="endDateTime"
                  label="End Time"
                  groupClass="mbi-0"
                  value={endDateTime}
                  inputChange={this.inputChange}
                  dom={{ placeholder: "hh:mm", type: "time" }}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Member Details</label>
                <SearchInput
                  endpoint={`searchMember/`}
                  searchKey="searchKeyword"
                  accessKey="members"
                  name="memberId"
                  inputClassName="form_control"
                  inputProps={{
                    autoFocus: true,
                  }}
                  placeholder="Search by First Name, Last Name or Member ID"
                  defaultValue={memberName || ""}
                  onChange={(value, onChangeSearch) => {
                    onChangeSearch(
                      `${value?.fName} ${value?.mName} ${value?.lName}`
                    );
                    this.inputChange({
                      target: { name: "memberId", value: value?._id },
                    });
                  }}
                  renderItem={(item, i) => {
                    return (
                      <React.Fragment key={"auto_" + i}>
                        <div
                          className="image-style"
                          style={{ backgroundImage: `url(${item?.photo})` }}
                        >
                          <img
                            src={"/images/imagesize2_3.png"}
                            alt={item?.fName}
                            className="w100per"
                          />
                        </div>

                        <div className="d-flex justify-content-between flex-grow-1">
                          <span
                            className="user-name"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item?.fName +
                              " " +
                              item?.mName +
                              " " +
                              item?.lName}
                          </span>
                          {item?.mNo && (
                            <span className="user-name1">{item?.mNo}</span>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  }}
                />
                {!isValid.memberId && (
                  <p className="error_msg">{errorMessages.memberId}</p>
                )}
              </div>
              <div className="col-12">
                <InputField
                  name="guest"
                  label="Number Of Guests"
                  groupClass="mbi-0"
                  value={guest}
                  isValid={isValid.guest}
                  errorMsg={errorMessages.guest}
                  inputChange={this.inputChange}
                  dom={{ placeholder: "0", type: "number" }}
                />
              </div>
              <div className="col-12 text-center mt-2">
                <Button
                  onClick={this.onSubmit}
                  style={{ borderColor: "transparent" }}
                  className={`btn btn=primary btn-primary-green ${
                    isSaving ? "btndisabled" : ""
                  }`}
                >
                  {this?.props?.singleData && this?.props?.singleData?._id
                    ? "Update Reservation"
                    : "Make a Reservation"}
                </Button>
              </div>
              {error && <p className="error_msg"> {error}</p>}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps({ clubs: { activeClub } }) {
  return { activeClub };
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    resetPageStore: (myParam) => dispatch(resetPageStore(myParam)),
    getClubs: () => dispatch(getClubs()),
  };
};

function injectComp(Component) {
  const InjectedComp = function(props) {
    const reservationControllerData = useReservation();

    return (
      <Component
        {...props}
        reservationControllerData={reservationControllerData}
      />
    );
  };
  return InjectedComp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectComp(BookReservation));
