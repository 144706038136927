/** @format */

import { GET_CLUBS, SET_ACTIVE_CLUB } from "../actions/constants";

const initialState = {
	clubs: null,
	activeClub: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE_CLUB:
			return { ...state, activeClub: action.payload };
		case GET_CLUBS:
			return {
				...state,
				clubs: action.payload
			};
		default:
			return { ...state };
	}
}
